import JsonList from '../Json/JsonList'

const Timestamps = ({ json, limit }) => {
  const { timestamps, ...rest } = json || {}

  // todo:  sort by date value

  const combinedTimestamps = { ...rest, ...timestamps }

  const filterdTimestamps = _.pickBy(
    combinedTimestamps,
    (value, key) => key.slice(-2) === 'At' && value,
  )

  return (
    <JsonList json={filterdTimestamps} compact humanizeKeys limit={limit} />
  )
}

export default Timestamps
