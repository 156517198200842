const defaultDrawRate = {
  interestRate: {
    annualRate: 0.123,
    startDate: '2021-08-24',
    endDate: '2021-08-24',
  },
  caseId: '',
}

export default defaultDrawRate
