import { useRef } from 'react'

import { useRect as useReachRect } from '@reach/rect'
import { useWindowSize } from '@reach/window-size'

const useRect = (passedRef) => {
  const localRef = useRef()

  const defaults = useWindowSize()

  const ref = passedRef || localRef

  const reachRect = useReachRect(ref)

  const rect = {
    width: reachRect ? reachRect.width : defaults.width,
    height: reachRect ? reachRect.height : defaults.height,
  }
  return [rect, ref]
}
export default useRect
