import { forwardRef } from 'react'

import { Portal } from '@reach/portal'
import color from 'peach/helpers/color/color'
import { useAnimatedIsOpen } from 'peach/hooks'
import styled, { css } from 'styled-components/macro'

import useClickOutside from '../useClickOutside'

import FadeInOut from './FadeInOut'
import SlidingWindow from './SlidingWindow'

const OverlayShade = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(64, 64, 64, 0.45);
`

const OverlayLayout = styled.div`
  width: 100%;
  ${(p) =>
    p.big
      ? css`
          height: 100%;
        `
      : css`
          min-height: 0;
          max-height: 100%;
        `}
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: auto;
`

const TopSpacer = styled.div`
  min-height: 0;
  flex-basis: 64px;
  flex-grow: 0;
  flex-shrink: 2;
`

const ContentsRow = styled.div`
  ${(p) =>
    p.big
      ? css`
          height: 80%;
        `
      : css`
          min-height: 240px;
          max-height: 75%;
        `}
  flex-basis: auto;
  flex-shrink: 1;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  overflow: hidden;
`

const BottomSpacer = styled.div`
  min-height: 0;
  flex-basis: 128px;
  flex-grow: 1;
  flex-shrink: 2;
`

const ContentsArea = styled.div`
  width: ${(p) => (p.big ? '90%' : '600px')};
  overflow: auto;
  flex-grow: 1;
  overflow: auto;
  padding: 24px;
`

const SideSpcaer = styled.div`
  flex-grow: 3;
  flex-basis: 24px;
`
const ContentsDiv = styled.div`
  height: 100%;
  flex-grow: 1;
  overflow: auto;
  border-radius: 4px;
  background-color: ${(p) => p.theme.background};
  border: 1px solid ${(p) => color.deepen(p.theme.border)};
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
`

const BigModalContainer = forwardRef((props, ref) => {
  const { children, isOpen, onClose, big } = props
  const [$ref] = useClickOutside(onClose, ref)

  const [isMounted, $isOpen] = useAnimatedIsOpen(isOpen)

  const show = !big || $isOpen

  return isMounted ? (
    <Portal>
      <FadeInOut isOpen={$isOpen}>
        <OverlayShade>
          <OverlayLayout big={big}>
            <TopSpacer />
            <ContentsRow big={big}>
              <SideSpcaer />
              <ContentsArea big={big}>
                <SlidingWindow isOpen={$isOpen}>
                  {show && <ContentsDiv ref={$ref}>{children}</ContentsDiv>}
                </SlidingWindow>
              </ContentsArea>
              <SideSpcaer />
            </ContentsRow>
            <BottomSpacer />
          </OverlayLayout>
        </OverlayShade>
      </FadeInOut>
    </Portal>
  ) : null
})

export default BigModalContainer
