const makeLightThemeFromConfig = (colors = {}) => {
  const highlight = colors.primary || 'hsl(211, 81%, 36%)'
  const linkText = colors.primary || 'hsl(211, 81%, 36%)'

  return {
    text: '#222',
    labelText: '#666',
    background: '#FFF',
    border: '#CCC',
    offset: '#f8f8f8',
    hover: 'rgba(0, 0, 0, 0.05)',
    banner: '#E8E8E8',
    highlight: highlight,
    mutedText: '#777',
    invertedText: '#FFF',
    disabledText: '#AAA',
    linkText: linkText,
  }
}

export default makeLightThemeFromConfig
