import { Input, Textarea, Select } from 'peach/components'

import CaseTypeIdSelect from '../case/CaseTypeSelect'

const CreateCaseForm = ({ person, personId }) => {
  return (
    <>
      <CaseTypeIdSelect formKey='typeId' required />

      <Input formKey='externalId' />

      <Select boolean formKey='autoCreated' />

      <Select
        formKey='status'
        options={[
          'initiated',
          'processing',
          'completed',
          'canceled',
          'reopened',
        ]}
        nullable
      />

      <Textarea.Json formKey='metaData' />
    </>
  )
}

export default CreateCaseForm
