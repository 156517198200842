import { FC } from 'react'

import { LoadingContainer } from 'peach/components'
import { StyledButton as Button } from 'peach/components/Button/Button'
import Input from 'peach/components/Input'
import { useApi, useWrite } from 'peach/hooks'
import { FormProvider, useForm } from 'react-hook-form'
import * as R from 'remeda'
import styled from 'styled-components'

import { Error, FormActions, Title } from './Components'
import { Company } from './types'

const emails = [
  'eddie+agent@peachfinance.com',
  'eran+agent@peachfinance.com',
  'brad.king+agent@peachfinance.com',
  'russel.braden+agent@peachfinance.com',
  'chris.kruse+agent@peachfinance.com',
]

type User = {
  type?: 'agent' | 'borrower' | 'service'
  roleIds?: string[]
  authType: {
    email?: string
  }
}

type CreateUsersFormValues = {
  users: User[]
}

type CreateUsersFormProps = {
  company: Company
  updateCompany: (obj: Company) => void
  title: string
}

const CreateUsersForm: FC<CreateUsersFormProps> = ({
  company,
  updateCompany,
  title,
}) => {
  const api: any = useApi()

  const form = useForm<CreateUsersFormValues>({
    values: {
      users: R.map(emails, (email) => ({
        type: 'agent',
        roleIds: R.pipe(
          company?.roles ?? [],
          R.reject(
            (role) => role.alias === 'api' || role.alias === 'peach-read-only',
          ),
          R.map((role) => role.id),
        ) as string[],
        authType: {
          email,
        },
      })),
    },
  })

  const { users } = form.watch()

  const [onCreate, isPending, , error, clear] = useWrite(
    async (values: { users: User[] }) => {
      const responses = await Promise.all(
        R.map(values.users, (user) =>
          api.company.users.post({
            body: {
              type: user.type,
              roleIds: user.roleIds,
              authType: user.authType,
            },
            pathArgs: { companyId: company?.companyId },
          }),
        ),
      )

      if (responses.every((response) => response.status === 201)) {
        updateCompany({
          ...company,
          users: [
            ...(company?.users ?? []),
            ...(R.map(responses, (response) => ({
              type: response.data.type,
              userId: response.data.userId,
              id: response.data.id,
            })) ?? []),
          ],
        })
      }
    },
  )

  return (
    <LoadingContainer loading={isPending}>
      <Title>{title}</Title>
      <form
        onSubmit={form.handleSubmit(async (values) => {
          try {
            clear()
            await onCreate(values)
          } catch (e) {
            console.log('Error with create actions: ', e)
          }
        })}
      >
        <FormProvider {...form}>
          {R.map.indexed(users, (user, index) => (
            <Row key={`${index}-${user.authType.email}`}>
              <Input
                type='text'
                label='email'
                required
                {...form.register(`users.${index}.authType.email`)}
              />
              <RemoveButton
                onClick={() =>
                  form.setValue(
                    'users',
                    R.reject.indexed(users, (user, i) => i === index),
                  )
                }
              >
                remove
              </RemoveButton>
            </Row>
          ))}

          <AddButton
            onClick={() =>
              form.setValue('users', [
                ...users,
                {
                  type: 'agent',
                  roleIds: R.pipe(
                    company?.roles ?? [],
                    R.reject(
                      (role) =>
                        role.alias === 'api' ||
                        role.alias === 'peach-read-only',
                    ),
                    R.map((role) => role.id),
                  ) as string[],
                  authType: {
                    email: '',
                  },
                },
              ])
            }
          >
            add
          </AddButton>

          {error && <Error>{error.message}</Error>}
          <FormActions>
            <Button type='submit' primary>
              Submit
            </Button>
          </FormActions>
        </FormProvider>
      </form>
    </LoadingContainer>
  )
}

export default CreateUsersForm

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 8px;
`

const RemoveButton = styled(Button)`
  margin-top: 18px;
`

const AddButton = styled(Button)`
  margin-top: 8px;
`
