import styled from 'styled-components/macro'

const Wrapper = styled.div`
  text-align: center;
  padding: 12px;
`

const Content = styled.div`
  margin: 0 auto;
  max-width: 800px;
  text-align: left;
`

const Page = ({ children }) => {
  return (
    <Wrapper>
      <Content>{children}</Content>
    </Wrapper>
  )
}

export default Page
