import { LoadingContainer } from 'peach/components'
import { usePersonConsentsQuery } from 'peach/data'
import { ListSubroutes } from 'peach/router'

const PersonConsents = () => {
  const [consents, query] = usePersonConsentsQuery()

  return (
    <LoadingContainer query={query}>
      <ListSubroutes
        title='Consents'
        paramKey='consentId'
        json={consents}
        page
      />
    </LoadingContainer>
  )
}

export default PersonConsents
