import styled from 'styled-components/macro'

const Message = styled.div`
  font-weight: bold;
  font-size: 12px;
  padding: 8px;
`

const Content = styled.div`
  text-align: center;
  color: ${(p) => p.theme.text};
  padding: 16px;
`

const ActionWrapper = styled.div`
  text-align: center;
  padding: 8px;
`
const Action = styled.button`
  border: none;
  background-color: transparent;
  color: ${(p) => p.theme.labelText};
  padding: 4px 12px;
  cursor: pointer;
  border-radius: 4px;

  &:hover {
    text-decoration: underline;
  }
`

const SearchResultsMessage = ({ message, onAction, actionLabel }) => {
  return (
    <Message>
      <Content>{message}</Content>
      {actionLabel && (
        <ActionWrapper>
          <Action onClick={onAction}>{actionLabel}</Action>
        </ActionWrapper>
      )}
    </Message>
  )
}

export default SearchResultsMessage
