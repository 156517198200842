import { MiniTable, Json } from 'peach/components'
import { humanizeKey } from 'peach/helpers'

const LoanLedgerTable = ({ ledger }) => {
  const columns = _.keys(_.first(ledger))

  const rows = _.map(ledger, (entry) => {
    const cells = _.map(columns, (key) => {
      const val = entry[key]
      return (
        <MiniTable.Cell key={key}>
          <Json.Value value={val} jsonKey={key} />
        </MiniTable.Cell>
      )
    })

    return <MiniTable.Row key={entry.id}>{cells}</MiniTable.Row>
  })

  const displayColumns = _.map(columns, humanizeKey)

  return <MiniTable columns={displayColumns}>{rows}</MiniTable>
}

export default LoanLedgerTable
