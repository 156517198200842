import { humanizeKey } from 'peach/helpers'

import MiniTable from '../MiniTable/MiniTable'

import { getTypeFromKey, sortEntries } from './jsonHelpers'
import JsonValue from './JsonValue'

const ArrayTable = ({ json, ...rest }) => {
  if (_.isArray(json) && _.isPlainObject(json[0])) {
    const keys = _.map(sortEntries(_.toPairs(json[0])), (entry) => entry[0])
    const columns = _.map(keys, humanizeKey)
    const rows = _.map(json, (row, index) => {
      const { id, object } = row || {}
      const cells = _.map(keys, (key) => {
        return (
          <JsonValue
            key={`key_${key}`}
            type={getTypeFromKey(key)}
            value={row[key]}
            object={object}
          />
        )
      })
      const _key = id ? `id_` + id : `index_` + index

      return (
        <MiniTable.Row key={_key}>
          <JsonValue key='index_column' type='index' value={`${index}`} />
          {cells}
        </MiniTable.Row>
      )
    })

    return (
      <MiniTable columns={['', ...columns]} {...rest}>
        {rows}
      </MiniTable>
    )
  }

  return (
    <MiniTable {...rest}>
      <JsonValue value={json} />
    </MiniTable>
  )
}

export default ArrayTable
