import { useAuthState } from 'core/auth/AuthProvider'
import Scratch from 'pages/scratch/Scratch'
import { Panels } from 'peach/components'
import { Switch, Route, Redirect } from 'peach/router'

import AppController from './AppController'
import LoginModal from './auth/LoginModal'
import AppHeader from './Header/AppHeader'
import UnauthenticatedRoutes, {
  UNAUTHENTICATED_ROUTES,
} from './UnauthenticatedRoutes'

const AuthRoutesController = ({ children }) => {
  const { isLoggedIn } = useAuthState()

  return (
    <Panels header={<AppHeader />}>
      <Switch>
        <Route path='/scratch'>
          <Scratch />
        </Route>

        <Route>
          <Switch>
            <Route path={UNAUTHENTICATED_ROUTES}>
              <UnauthenticatedRoutes />
            </Route>
            <Route
              render={() => {
                if (isLoggedIn) {
                  return <AppController />
                }
                return <Redirect to='/environments' />
              }}
            />
          </Switch>
        </Route>
      </Switch>
      <LoginModal />
    </Panels>
  )
}

export default AuthRoutesController
