import { forwardRef, useState, useRef } from 'react'

import { withLabel, withFormValue } from 'peach/decorators'
import styled from 'styled-components/macro'

import KeyboardNavArea from '../$internal/KeyboardNavArea'

import filterOptions from './filterOptions'
import formatOptions from './formatOptions'
import SelectBottomControls from './SelectBottomControls'
import SelectFrame from './SelectFrame'
import SelectItem from './SelectItem'
import SelectTopSearch from './SelectTopSearch'

const Content = styled.div`
  overflow-y: auto;
  padding: 4px;
`

const Check = styled.span`
  display: inline-block;
  line-height: 1;
  font-size: 16px;
  transition: opacity 0.2s;
  color: ${(p) => p.theme.highlight};
  opacity: ${(p) => (p.isChecked ? '1' : '0')};
`

const SingleSelect = forwardRef((props, ref) => {
  const {
    options,
    value: selectedValue,
    onChange,
    contentRef,
    notes,
    nullable,
    searchable,
    loading,
    disabled,
    label,
    prompt,
    ...rest
  } = props

  const inputRef = useRef()

  const formattedOptions = formatOptions(options, { notes })

  const [query, setQuery] = useState('')

  const filteredOptions = filterOptions(formattedOptions, query)

  const renderContents = ({ onClose }) => {
    const contents = _.map(filteredOptions, (option) => {
      const { label, value, note, disabled } = option
      const isChecked = selectedValue === value

      const handleClick = () => {
        onChange(value)
        if (onClose) onClose()
      }

      return (
        <SelectItem
          key={value}
          label={label}
          note={note}
          onClick={handleClick}
          isSelected={false && isChecked}
          disabled={disabled}
          left={<Check isChecked={isChecked}>{'›' || '✓' || '✔︎'}</Check>}
        />
      )
    })

    return <Content ref={contentRef}>{contents}</Content>
  }

  const selectedOption = _.find(filteredOptions, { value: selectedValue })

  const triggerLabel = loading
    ? 'Loading…'
    : selectedOption?.label || prompt || `Select${label ? ` ${label}` : ''}…`

  const searchControls = searchable && (
    <SelectTopSearch ref={inputRef} value={query} onChange={setQuery} />
  )

  const renderControls = ({ onClose }) => {
    const handleClear = () => {
      onChange(null)
      onClose()
    }

    return (
      <SelectBottomControls onClear={handleClear} canClear={!!selectedValue} />
    )
  }

  const handleTypeCharacter = () => {
    if (searchable && inputRef.current) {
      inputRef.current.focus()
    }
  }

  return (
    <SelectFrame
      {...rest}
      disabled={loading || disabled}
      triggerLabel={triggerLabel}
      triggerHasValue={!!selectedOption}
      triggerRef={ref}
      render={({ onClose }) => {
        return (
          <KeyboardNavArea onTypeCharacter={handleTypeCharacter}>
            {renderContents({ onClose })}
          </KeyboardNavArea>
        )
      }}
      searchControls={searchControls}
      controls={nullable && (({ onClose }) => renderControls({ onClose }))}
      label={label}
    ></SelectFrame>
  )
})

export { SingleSelect as RawSingleSelect }

export default withFormValue(withLabel(SingleSelect))
