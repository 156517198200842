import { Json } from 'peach/components'
import { useRouteMatch, useParams } from 'peach/router'

const Scratch = () => {
  const match = useRouteMatch()
  const { params } = useParams()

  return <Json title='Scratch Route' json={{ match, params }} />
}

export default Scratch
