import {
  Json,
  LoadingContainer,
  Modal,
  Input,
  NumericInput,
  Select,
  Button,
  Model,
} from 'peach/components'
import { PersonCaseSelect } from 'peach/data'
import { useApi, useWrite } from 'peach/hooks'

import ScheduleTable from './ScheduleTable'

const DeferScheduleForm = ({ value, personId, loanId }) => {
  const api = useApi()

  const [sendPreview, sending, resp, error] = useWrite(async () => {
    const resp = await api.loan.deferSchedule.post({
      pathArgs: { personId, loanId },
      body: { previewMode: true, ...value },
    })
    return resp.data
  })

  const { schedule, ...rest } = resp || {}

  return (
    <>
      <Modal.Page page='Options'>
        <NumericInput
          formKey='bufferToPushOutSchedule'
          note={`When deferring during the first period, this represents the number of calendar days that will be added to today's date. The resulting date will be used to push out due dates (today's date + buffer days). Interest  will continue to accrue. 

            When deferring after the first period, we defer all due dates that fall in the period of "today's date" and "today's date + buffer days" to the end of the loan one by one.`}
        />
        <Input
          formKey='agreementDocumentId'
          note={`The document desciptor ID corresponding to the accepted loan term change agreement. This is required unless 'previewMode=true' or 'isShowRevisedTermsOnFrequencyChange=false' on the loan type.`}
        />

        <Select boolean formKey='waiveFee' />

        <PersonCaseSelect formKey='caseId' />
      </Modal.Page>
      <Modal.Page page='Preview'>
        <LoadingContainer loading={sending} error={error}>
          <Json.Table tile='Defer Options' json={value} />
          <Button onClick={sendPreview}>Preview</Button>
          <Model.Item title='Defer Preview Results' json={rest} />
          <ScheduleTable schedule={schedule} />
        </LoadingContainer>
      </Modal.Page>
    </>
  )
}

export default DeferScheduleForm
