import { Route as RouterRoute } from 'react-router-dom'

import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'

const Route = ({ crumb, title, render, children, ...rest }) => {
  if (crumb) {
    return (
      <RouterRoute
        {...rest}
        render={(routeProps) => {
          return (
            <>
              <Breadcrumb title={title} to={routeProps.match.url} />
              {render ? render(routeProps) : children}
            </>
          )
        }}
      />
    )
  } else {
    return <RouterRoute render={render} children={children} {...rest} />
  }
}

export default Route
