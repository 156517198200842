import { forwardRef } from 'react'

import styled from 'styled-components/macro'

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 4px;
`

const Action = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: ${(p) => p.theme.highlight};
  font-size: 12px;
  margin-left: 8px;
  &:not(:disabled):hover {
    text-decoration: underline;
  }
  &:disabled {
    color: ${(p) => p.theme.mutedText};
  }
`

const SelectBottomControls = forwardRef((props, ref) => {
  const { onClear, canClear, onSelectAll, canSelectAll } = props

  return (
    <Controls>
      {onSelectAll && (
        <Action disabled={!canSelectAll} onClick={onSelectAll}>
          Select All
        </Action>
      )}
      {onClear && (
        <Action disabled={!canClear} onClick={onClear}>
          Clear
        </Action>
      )}
    </Controls>
  )
})

export default SelectBottomControls
