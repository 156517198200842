import { forwardRef } from 'react'

import styled from 'styled-components/macro'

import buttonStyles from '../Button/buttonStyles'

const Trigger = styled.button`
  ${buttonStyles}
`

const TriggerLabel = styled.span`
  flex-shrink: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Chevron = styled.span`
  padding-left: 8px;
  flex-shrink: 0;
  color: ${(p) => (p.disabled ? p.theme.disabledText : p.theme.mutedText)};
`

const MenuTrigger = forwardRef((props, ref) => {
  const { isOpen, onOpen, onClose, label, disabled } = props

  const handleKeyDown = (event) => {
    if (props.onKeyDown) props.onKeyDown(event)
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      onOpen()
    }
  }

  return (
    <Trigger
      ref={ref}
      onClick={isOpen ? onClose : onOpen}
      onKeyDown={handleKeyDown}
      disabled={disabled}
    >
      <TriggerLabel>{label}</TriggerLabel>
      <Chevron disabled={disabled}>{'▼'}</Chevron>
    </Trigger>
  )
})

export default MenuTrigger
