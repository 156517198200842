import { useDataQuery, useQueryApi } from '../useQueryApi'

const usePersonQuery = (options) => {
  const [api, { companyId, personId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'borrowers', personId],

    queryFn: () => {
      return api.company.person.get({
        pathArgs: { companyId, personId },
      })
    },
    enabled: !!(companyId && personId),
  })
}

export default usePersonQuery
