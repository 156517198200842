import JsonDisplayValue from './JsonDisplayValue'
import { isPeachIdValue } from './jsonHelpers'
import { DrawLink } from './links'
import CompanyLink from './links/CompanyLink'
import EmployeeLink from './links/EmployeeLink'
import InvestorLink from './links/InvestorLink'
import LoanLink from './links/LoanLink'
import LoanTypeLink from './links/LoanTypeLink'
import PaymentInstrumentLink from './links/PaymentInstrumentLink'
import PersonLink from './links/PersonLink'
import RoleLink from './links/RoleLink'
import TeamLink from './links/TeamLink'
import TransactionLink from './links/TransactionLink'
import UserLink from './links/UserLink'

const JsonLink = ({ value, jsonKey, noLink, object }) => {
  if (!noLink && jsonKey !== 'displayId' && isPeachIdValue(value)) {
    const prefix = value.slice(0, 2)

    if (prefix === 'CP' || jsonKey === 'companyId') {
      return <CompanyLink companyId={value} jsonKey={jsonKey} />
    }

    if (prefix === 'LT' || jsonKey === 'loanTypeId') {
      return <LoanTypeLink loanTypeId={value} jsonKey={jsonKey} />
    }

    if (prefix === 'RL') {
      return <RoleLink roleId={value} jsonKey={jsonKey} />
    }

    if (prefix === 'BO') {
      return <PersonLink personId={value} jsonKey={jsonKey} />
    }

    if (prefix === 'UR') {
      return <UserLink userId={value} jsonKey={jsonKey} />
    }

    if (prefix === 'EM') {
      return <EmployeeLink employeeId={value} jsonKey={jsonKey} />
    }

    if (prefix === 'TM') {
      return <TeamLink teamId={value} jsonKey={jsonKey} />
    }

    if (prefix === 'LN') {
      if (object === 'draw') {
        return <DrawLink drawId={value} jsonKey={jsonKey} />
      } else {
        return <LoanLink loanId={value} jsonKey={jsonKey} />
      }
    }

    if (prefix === 'PT') {
      return (
        <PaymentInstrumentLink paymentInstrumentId={value} jsonKey={jsonKey} />
      )
    }

    if (prefix === 'TX') {
      return <TransactionLink transactionId={value} jsonKey={jsonKey} />
    }

    if (prefix === 'IR') {
      return <InvestorLink investorId={value} jsonKey={jsonKey} />
    }
  }

  const type = value === null ? 'null' : value === '' ? 'emptyString' : 'id'

  return (
    <JsonDisplayValue type={type}>
      {value === null ? 'null' : value === '' ? `''` : `${value}`}
    </JsonDisplayValue>
  )
}

export default JsonLink
