import { useUserQuery } from 'peach/data'

import ModelLink from './ModelLink'

const UserLink = ({ userId, jsonKey }) => {
  const [user, query] = useUserQuery({ userId })

  return <ModelLink id={userId} model={user} query={query} jsonKey={jsonKey} />
}

export default UserLink
