import { forwardRef, useState } from 'react'

import { withLabel, withFormValue } from 'peach/decorators'
import { formatOptions } from 'peach/helpers'
import color from 'peach/helpers/color/color'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
`

const Item = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  padding: 0 8px;
`

const RADIUS = 3

const Radio = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  display: inline-block;
  line-height: 2;

  border-radius: 50%;
  width: 18px;
  height: 18px;
  margin: 0;
  padding: 0;

  cursor: pointer;

  border: 1px solid ${(p) => color.deepen(p.theme.border)};

  box-shadow: 0 0 0 1px transparent;
  transition: box-shadow 0.2s, background-color 0.2s, border-color 0.2s;

  &:checked {
    // border-color: ${(p) => p.theme.highlight};
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    margin-top: ${`-${RADIUS}px`};
    margin-left: ${`-${RADIUS}px`};
    width: ${`${2 * RADIUS}px`};
    height: ${`${2 * RADIUS}px`};
    border: ${`${RADIUS}px solid transparent`};
    border-color: ${(p) => p.theme.banner};
    transition: background-color 0.2s, border-color 0.2s;
    opacity: 0.8;
  }

  &:checked:after {
    border-color: ${(p) => p.theme.highlight};
    transform: scale(1.5);
  }

  outline: none;

  &:focus {
    border-color: ${(p) => p.theme.highlight};
    // box-shadow: 0 0 0 1px ${(p) => p.theme.highlight};
  }
`

const Label = styled.label`
  display: inline-block;
  line-height: 1;
  color: ${(p) => p.theme.text};
  padding-left: 8px;

  cursor: pointer;
`

const renderNullItem = (opts) => {
  const { nullable, nullLabel, groupName, selectedValue, onChange } = opts

  if (!nullable) return null

  const id = `${groupName}-null`
  const label = nullLabel || 'Null'

  return (
    <Item key={id}>
      <Radio
        name={groupName}
        id={id}
        type='radio'
        checked={selectedValue === null}
        value={label}
        onChange={() => onChange(null)}
      />
      <Label htmlFor={id}>{label}</Label>
    </Item>
  )
}

const RadioSelect = forwardRef((props, ref) => {
  const {
    onChange,
    value: selectedValue,
    options,
    nullable,
    nullLabel,
    notes,
    disabled,
  } = props

  const $options = formatOptions(options, { notes, disabled })

  const [groupName] = useState(() => _.uniqueId('radio-select-'))

  const radios = _.map($options, ({ label, value }) => {
    const isSelected = value === selectedValue
    const radioId = `${groupName}-value-${value}`
    return (
      <Item key={radioId}>
        <Radio
          name={groupName}
          id={radioId}
          type='radio'
          checked={isSelected}
          value={value}
          onChange={() => onChange(value)}
        />
        <Label htmlFor={radioId}>{label}</Label>
      </Item>
    )
  })

  const nullItem = renderNullItem({
    nullable,
    nullLabel,
    groupName,
    selectedValue,
    onChange,
  })

  return (
    <Wrapper ref={ref}>
      {radios}
      {nullItem}
    </Wrapper>
  )
})

export { RadioSelect as RawRadioSelect }

export default withFormValue(withLabel(RadioSelect))
