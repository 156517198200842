import styled from 'styled-components/macro'

const ImageSpan = styled.span`
  border: 1px solid
    ${(p) => (p.border || !p.url ? p.theme.border : 'transparent')};

  display: inline-block;
  width: ${(p) => (p.width ? `${p.width}px` : '0')};
  height: ${(p) => (p.height ? `${p.height}px` : '0')};
  background-image: ${(p) => (p.url ? `url(${p.url})` : '#967bb6')};
  background-repeat: no-repeat;
  background-position: ${(p) => p.backgroundPosition || 'center'};
  background-size: contain;
  line-height: 0;
`
const Image = ({ url, width, height, border, backgroundPosition }) => {
  return (
    <ImageSpan
      url={url}
      width={width}
      height={height}
      border={border}
      backgroundPosition={backgroundPosition}
    />
  )
}
export default Image
