import { useState } from 'react'

import { Popover, Page, Json, Input, Form, Button } from 'peach/components'

const defaultValue = ['One', 'Two', '3', 'Four', 'five']

const ScratchOrderable = () => {
  const [state, setState] = useState(defaultValue)

  return (
    <Page.Centered>
      <Json.Table title='State' json={{ state }} />

      <Popover triggerLabel='Edit Boops' title='Boops'>
        <Form.Provider>
          <Input formKey='Foo' />
          <Input formKey='Bar' />
          <Input formKey='Baz' />
          <Input formKey='Bleep' />
          <Input formKey='Blorp' />
          <Input formKey='Boop' />
        </Form.Provider>
      </Popover>

      <Popover triggerLabel='Action Menu...' title='Actions'>
        <Button>Hello</Button>
        <Button>Goodbye</Button>
      </Popover>

      <Popover
        triggerLabel='Edit Roles…'
        title='Roles'
        width='400px'
        onApply={() => setState('Applied')}
      >
        <Form.Provider>
          <Input formKey='Foo' />
          <Input formKey='Bar' />
          <Input formKey='Baz' />
          <Input formKey='Bleep' />
          <Input formKey='Blorp' />
          <Input formKey='Boop' />
        </Form.Provider>
      </Popover>
    </Page.Centered>
  )
}

export default ScratchOrderable
