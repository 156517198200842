import { Link as RawRouterLink, useRouteMatch } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

const RouterLink = ({ hasNote, ...rest }) => {
  return <RawRouterLink {...rest} />
}

const itemStyles = css`
  display: block;
  width: 100%;
  text-align: left;
  font-size: 14px;
  border: 1px solid transparent;
  padding: 4px 8px;
  border-radius: 3px;
  margin-bottom: 4px;
  background-color: ${(p) => (p.active ? p.theme.highlight : 'transparent')};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  text-decoration: none;

  &:hover {
    background-color: ${(p) => (p.active ? p.theme.highlight : p.theme.hover)};
  }
`

const Link = styled(RouterLink)`
  ${itemStyles};
`

const Title = styled.div`
  padding-top: ${(p) => (p.hasNote ? 0 : '4px')};
  padding-bottom: ${(p) => (p.hasNote ? 0 : '4px')};
  color: ${(p) => (p.active ? p.theme.invertedText : p.theme.highlight)};
`

const Note = styled.div`
  padding-top: 4px;
  font-size: 10px;
  color: ${(p) => (p.active ? p.theme.invertedText : p.theme.disabledText)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Button = styled.button`
  ${itemStyles};
`

const Item = ({ item }) => {
  const { label, note, to, isActive, onClick, exact } = item || {}

  const match = useRouteMatch({ path: to, exact })
  const active = match || isActive

  const contents = (
    <>
      <Title hasNote={!!note} active={active}>
        {label}
      </Title>
      {!!note && <Note active={active}>{note}</Note>}
    </>
  )
  return _.isString(to) ? (
    <Link active={active} to={to}>
      {contents}
    </Link>
  ) : (
    <Button active={active} onClick={onClick}>
      {contents}
    </Button>
  )
}

export default Item
