import { createContext, useContext, useCallback } from 'react'

import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
const QueryParamsContext = createContext({})

const QueryParamsProvider = ({ children }) => {
  const { search } = useLocation()
  const history = useHistory()

  const queryParams = queryString.parse(search)

  const setParams = useCallback(
    (newParams = {}) => {
      const newString = queryString.stringify(newParams, {
        skipNull: true,
        skipEmptyString: true,
      })

      if (!_.isEqual(queryParams, queryString.parse(newString))) {
        history.replace({ search: newString })
        // window.location.search = newString
      }
    },
    [queryParams, history],
  )

  const updateParams = useCallback(
    (newParams = {}) => setParams({ ...queryParams, ...newParams }),
    [setParams, queryParams],
  )

  const updateParam = useCallback(
    (key, value) => setParams({ ...queryParams, [key]: value }),
    [setParams, queryParams],
  )

  const value = {
    queryParams,
    queryFunctions: { setParams, updateParams, updateParam },
  }

  return (
    <QueryParamsContext.Provider value={value}>
      {children}
    </QueryParamsContext.Provider>
  )
}

const useQueryParams = () => {
  const { queryParams, queryFunctions } = useContext(QueryParamsContext)
  return [queryParams, queryFunctions]
}
export { QueryParamsProvider, useQueryParams }
