import { Select } from 'peach/components'
import { useContactsQuery } from 'peach/data'
import { getAddressName } from 'peach/helpers'

const ContactAddressSelect = (props) => {
  const [contacts, { isLoading }] = useContactsQuery()

  const addressContacts = _.filter(contacts, { contactType: 'address' })

  const options = _.map(addressContacts, ({ id, address }) => {
    return { value: id, label: getAddressName(address) }
  })

  return <Select {...props} disabled={isLoading} options={options} />
}

export default ContactAddressSelect
