import { useLoanDrawQuery } from 'peach/data'

import ModelLink from './ModelLink'

const DrawLink = ({ drawId, jsonKey }) => {
  const [draw, query] = useLoanDrawQuery({ drawId })

  return <ModelLink id={drawId} model={draw} query={query} jsonKey={jsonKey} />
}

export default DrawLink
