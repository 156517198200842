import { useDataQuery, useQueryApi } from '../useQueryApi'

const useLoanTypeNotificationsQuery = (options) => {
  const [api, { companyId, loanTypeId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: [
      'companies',
      companyId,
      'loan-types',
      loanTypeId,
      'notifications',
    ],

    queryFn: () => {
      return api.company.repaymentEngineNotifications.get({
        pathArgs: { companyId, loanTypeId },
      })
    },
  })
}

export default useLoanTypeNotificationsQuery
