import { forwardRef } from 'react'

import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components/macro'

import useAsyncOnChange from './useAsyncOnChange'

const StyledTextarea = styled(TextareaAutosize)`
  display: block;
  padding: 8px 12px;

  border: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  background-color: ${(p) => p.theme.background};
  color: ${(p) => p.theme.text};
  width: ${(p) => p.width};
  resize: none;
  line-height: 1.5;

  &:disabled {
    color: ${(p) => p.theme.mutedText};
    border-color: ${(p) => p.theme.border};
  }

  &:focus {
    border-color: ${(p) => p.theme.highlight};
    box-shadow: none;
  }
`

const RawTextarea = forwardRef((props, ref) => {
  const { value, onChange, onFocus, onBlur } = useAsyncOnChange(props)

  return (
    <StyledTextarea
      {...props}
      ref={ref}
      onChange={(e) => onChange(e.target.value)}
      value={value || ''}
      spellCheck='false'
      onFocus={onFocus}
      onBlur={onBlur}
    />
  )
})

export default RawTextarea
