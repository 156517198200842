import { Link as RouterLink } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

const linkStyles = css`
  color: ${(p) => p.color || p.theme.linkText};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  text-decoration: none;

  &:not(disabled):hover {
    text-decoration: underline;
  }
`
const StyledLink = styled.a`
  ${linkStyles}
`

const StyledRouterLink = styled(RouterLink)`
  ${linkStyles}
`

const Link = ({ href, children, ...rest }) => {
  return href ? (
    <StyledLink href={href} target='_blank' rel='noopener noreferrer' {...rest}>
      {children}
    </StyledLink>
  ) : (
    <StyledRouterLink {...rest}>{children}</StyledRouterLink>
  )
}

export default Link
