import { usePaymentInstrumentQuery } from 'peach/data'
import { useParams } from 'peach/router'

import ModelLink from './ModelLink'

const PaymentInstrumentLink = ({ paymentInstrumentId, jsonKey }) => {
  const { companyId, personId } = useParams()

  const [paymentInstrument, query] = usePaymentInstrumentQuery({
    paymentInstrumentId,
  })

  const link = `/companies/${companyId}/borrowers/${personId}/payment-instruments/${paymentInstrumentId}`

  return (
    <ModelLink
      id={paymentInstrumentId}
      model={paymentInstrument}
      link={link}
      query={query}
      jsonKey={jsonKey}
    />
  )
}

export default PaymentInstrumentLink
