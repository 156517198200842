import color from 'peach/helpers/color/color'
import styled, { css } from 'styled-components/macro'

const Bar = styled.div`
  padding: 8px 12px;
  background-color: ${(p) => p.theme.offset};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
const Title = styled.span`
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  color: ${(p) => p.theme.text};
  cursor: default;
`
const Left = styled.span``

const Right = styled.span`
  display: inline-flex;
  align-items: center;
`

const buttonStyles = css`
  margin: 0;
  font-size: ${(p) => p.fontSize || '24px'};
  font-weight: bold;
  line-height: 1;
  padding: 4px 12px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  color: ${(p) => p.theme.text};
  &:hover {
    background-color: ${(p) => color.getHoverColor(p.theme.offset)};
  }
  margin-left: 16px;
`

const CloseButton = styled.button`
  ${buttonStyles}
  font-size: 24px;
`
const DebugWrapper = styled.span`
  display: inline-block;
`
const LittleButton = styled.button`
  ${buttonStyles}
  font-size: 24px;
`

const ModalTitleBar = (props) => {
  const { title, onClose, onToggleDebugger } = props
  // ⊗×⨂⨷
  return (
    <Bar>
      <Left>
        <Title onDoubleClick={onToggleDebugger}>{title}</Title>
      </Left>
      <Right>
        <DebugWrapper>
          <LittleButton onClick={onToggleDebugger} title='Debugger'>
            {'☕️'}
          </LittleButton>
        </DebugWrapper>
        <CloseButton onClick={onClose} title='Close'>
          {'✗'}
        </CloseButton>
      </Right>
    </Bar>
  )
}

export default ModalTitleBar
