import { humanizeKey } from 'peach/helpers'

import MiniTable from '../MiniTable/MiniTable'

import { getTypeFromKey, sortObject } from './jsonHelpers'
import JsonList from './JsonList'
import JsonValue from './JsonValue'

const getWidth = (json) => {
  const keys = _.keys(json)
  const maxKeyLength = _.max(_.map(keys, (key) => key.length))
  return _.max([maxKeyLength * 8 + 20, 120])
}

const JsonTable = (props) => {
  const { json, title, compact, bare, humanizeKeys, ...rest } = props
  if (_.isEmpty(json)) {
    return (
      <MiniTable title={title} compact={compact} bare={bare} {...rest}>
        <JsonValue value={json} />
      </MiniTable>
    )
  }

  if (_.isArray(json) && _.isPlainObject(_.first(json))) {
    return (
      <>
        {_.map(json, (item, index) => (
          <JsonTable
            {...rest}
            key={`${item?.id}-${index}`}
            title={`[${index}]`}
            json={item}
            compact
          />
        ))}
      </>
    )
  }

  if (_.isArray(json) && !_.isObject(_.first(json))) {
    return <JsonList json={json} compact={compact} {...rest} />
  }

  if (_.isPlainObject(json)) {
    const { object } = json
    const width = getWidth(json)
    const sorted = sortObject(json)

    const pairs = _.toPairs(sorted)
    // converting to pairs becasue _.map breaks on objects with a length proeprty

    const rows = _.map(pairs, ([key, val]) => {
      const type = getTypeFromKey(key)
      const isObject = _.isObject(val) && !_.isEmpty(val)
      return (
        <MiniTable.Row key={key}>
          <MiniTable.Cell width={`${width}px`} top={isObject}>
            <JsonValue
              type='key'
              value={humanizeKeys ? humanizeKey(key) : key}
              {...rest}
            />
          </MiniTable.Cell>
          {isObject ? (
            <JsonTable {...rest} title={humanizeKey(key)} json={val} compact />
          ) : (
            <JsonValue
              type={type}
              value={val}
              jsonKey={key}
              object={object}
              {...rest}
            />
          )}
        </MiniTable.Row>
      )
    })

    return (
      <MiniTable title={title} compact={compact} bare={bare}>
        {rows}
      </MiniTable>
    )
  }

  return <JsonValue value={json} />
}

export default JsonTable
