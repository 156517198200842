import {
  LoadingContainer,
  Page,
  Modal,
  Model,
  Panels,
  Bar,
  Input,
} from 'peach/components'
import { useLoanDrawQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { useParams } from 'peach/router'
import { LoanStatusSelect } from 'peach/scopes/loan'
// import { humanizeKey } from 'peach/helpers'

const LoanDraw = () => {
  const { personId, loanId, drawId } = useParams()
  const [draw, query] = useLoanDrawQuery()
  const api = useApi()

  const header = <Bar title='Draw' />

  const sendEditLoanDraw = async (options, diff) => {
    await api.loan.draw.put({
      pathArgs: { personId, loanId, drawId },
      body: diff,
    })
    query.refetch()
  }

  const sendActivateDraw = async () => {
    await api.draw.activate.post({
      pathArgs: { personId, loanId, drawId },
    })
    query.refetch()
  }

  const activateDraw = (
    <Modal
      title='Activate Draw'
      onSubmit={sendActivateDraw}
      disabled={draw?.status === 'active'}
    ></Modal>
  )

  const editLoanDraw = (
    <Modal
      title='Edit Loan Draw'
      onSubmit={sendEditLoanDraw}
      initialValue={draw}
    >
      <LoanStatusSelect formKey='status' />
      <Input formKey='nickname' />
    </Modal>
  )

  return (
    <Panels header={header}>
      {activateDraw}
      {editLoanDraw}
      <LoadingContainer query={query}>
        <Page.Full>
          <Model.Item json={draw} />
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default LoanDraw
