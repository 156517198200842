import { Switch, Redirect, Route, useRouteMatch } from 'peach/router'

import Document from './Document'
import Documents from './Documents'

const DocumentRoutes = () => {
  const { path, url } = useRouteMatch()

  return (
    <Switch>
      <Route path={path} exact>
        <Documents />
      </Route>
      <Route path={`${path}/:documentId`} crumb>
        <Document />
      </Route>
      <Redirect to={url} />
    </Switch>
  )
}

export default DocumentRoutes
