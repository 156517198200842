import { forwardRef } from 'react'

import styled, { css } from 'styled-components/macro'

import SmallSpinner from '../Loading/SmallSpinner'

import buttonStyles from './buttonStyles'

const SpinnerWrapper = styled.span`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -10px;
  margin-left: -10px;
`

export const StyledButton = styled.button<{
  primary?: boolean
  sending?: boolean
  // iconLeft?: boolean
  // iconRight?: boolean
}>`
  ${buttonStyles};
  position: relative;
  ${(p) =>
    p.sending &&
    css`
      color: transparent;
    `}
`

const RightIcon = styled.span<{
  disabled?: boolean
}>`
  padding-left: 12px;
  color: ${(p) => (p.disabled ? p.theme.disabledText : p.theme.mutedText)};
`

const Button = forwardRef((props: any, ref) => {
  const {
    isOpen,
    onClose,
    onOpen,
    primary,
    onClick,
    children,
    iconRight,
    sending,
    ...rest
  } = props

  const handleClick = (event) => {
    if (isOpen) {
      if (onClose) onClose()
    } else {
      if (onOpen) onOpen()
    }

    if (onClick) onClick(event)
  }

  return (
    <StyledButton
      ref={ref}
      onClick={handleClick}
      primary={primary}
      iconRight={iconRight}
      sending={sending}
      {...rest}
    >
      {children}
      {iconRight && <RightIcon>{iconRight}</RightIcon>}
      {sending && (
        <SpinnerWrapper>
          <SmallSpinner />
        </SpinnerWrapper>
      )}
    </StyledButton>
  )
})

export default Button
