import { useParams } from 'peach/router'

import CompanyManager from './CompanyManager'
import CompanyAgentUserSelect from './components/CompanyAgentUserSelect'
import CompanyEmployeeSelect from './components/CompanyEmployeeSelect'
import CompanyPermissionSelect from './components/CompanyPermissionSelect'
import CompanyRolesSelect from './components/CompanyRolesSelect'
import CompanyTeamSelect from './components/CompanyTeamSelect'
import LoanTypeSelect from './components/LoanTypeSelect'

const useCompanyId = () => {
  const { companyId } = useParams()
  return companyId
}

export {
  CompanyAgentUserSelect,
  CompanyEmployeeSelect,
  CompanyManager,
  CompanyPermissionSelect,
  CompanyRolesSelect,
  CompanyTeamSelect,
  LoanTypeSelect,
  useCompanyId,
}
