import { formatDate, truncateString, formatAmount } from 'peach/helpers'
import { useToggle, useDebounceValue } from 'peach/hooks'

import Value from './JsonDisplayValue'
import { getTypeFromValue, getTypeFromKey } from './jsonHelpers'
import JsonLink from './JsonLink'

const JsonString = ({ string, limit = 1000 }) => {
  const [isExpanded, expand] = useToggle()
  const val = isExpanded || !limit ? string : truncateString(string, limit)
  return (
    <Value onDoubleClick={expand} type='string'>
      {val}
    </Value>
  )
}

const JsonValue = (props) => {
  const { type, jsonKey, value, stringLimit, noLink, object } = props

  const [showRaw, , , toggleRaw] = useToggle()
  const [debouncedShowRaw, isDebouncing] = useDebounceValue(showRaw, 200)

  if (value === null) {
    return <Value type={'null'}>null</Value>
  }

  const _type =
    type || getTypeFromKey(jsonKey, value) || getTypeFromValue(value)

  switch (_type) {
    case 'array':
      return <Value type='array'>{`[ ${_.size(value)} items… ]`}</Value>
    case 'object':
      return <Value type='object'>{`{ ${_.size(value)} keys… }`}</Value>
    case 'number':
      return <Value type='number'>{value}</Value>
    case 'amount':
      return <Value type='amount'>{formatAmount(value)}</Value>
    case 'text':
    case 'string':
      return <JsonString string={value} limit={stringLimit} />
    case 'emptyString':
      return <Value type={_type}>{`'${value}'`}</Value>
    case 'id':
      return (
        <JsonLink
          value={value}
          jsonKey={jsonKey}
          noLink={noLink}
          object={object}
        />
      )
    case 'key':
      return <Value type={_type}>{value}</Value>
    case 'date':
      return (
        <Value
          type={value === null ? 'null' : _type}
          title={value}
          onDoubleClick={toggleRaw}
        >
          {isDebouncing ? '...' : debouncedShowRaw ? value : formatDate(value)}
        </Value>
      )
    case 'emptyObject':
      return <Value type={_type}>{'{}'}</Value>
    case 'emptyArray':
      return <Value type={_type}>{'[]'}</Value>
    case 'null':
      return <Value type={_type}>null</Value>
    case 'undefined':
      return <Value type={_type}>undefined</Value>
    case 'boolean':
      return <Value type={_type}>{value ? 'true' : 'false'}</Value>
    case 'index':
      return <Value type={_type}>{`[${value}]`}</Value>
    default: {
      let $value
      try {
        $value = `${value}`
      } catch {
        console.warn(`couldn't coerce to string value`, value)
        $value = '[non-stringifable value]'
      }
      return <Value type='unknown'>{$value}</Value>
    }
  }
}

export default JsonValue
