import {
  Input,
  NumericInput,
  Select,
  Form,
  StateCodeSelect,
  CountryCodeSelect,
  Modal,
} from 'peach/components'

/*


DynamicFeeTypeData:
  title: Dynamic Fee Type Data
  required:
    - feeType
    - name
    - amountLogic
    - capLogic
  type: object
  properties:
    feeType:
      type: string
      enum:
        - lateFee
        - modificationFee
      description: The two main types of dynamic fee.
    name:
      type: object
      required:
        - displayName
        - apiName
      properties:
        displayName:
          type: string
          description: |
            The display name of the dynamic fee as shown to the borrower in the UI. For example: `Late Fee` or `Breather Fee`.
        apiName:
          type: string
          description: |
            The API name of the dynamic fee. For example: `lateFee` or `breatherFee`.
    chargeLogic:
      type: object
      description: The configurations for charge logic. Required for feeType `lateFee`.
      properties:
        type:
          type: string
          enum:
            - days
        buffer:
          type: integer
        bufferDayType:
          $ref: "#/DayType"
    amountLogic:
      type: array
      description: |
        Specifies one or more configurations for calculating the fee amount. For each configuration, the `fixedAmount` and 
        `percentageOfPrincipal` are mutually exclusive.
      items:
        type: object
        required:
          - type
          - country
        properties:
          type:
            type: string
            enum:
              - fixed
              - percentageOfPrincipal
              - custom
          fixedAmount:
            type: number
            format: float
            description: Specifies the fixed fee amount charged.
          percentageOfPrincipal:
            type: number
            format: float
            description: |
              Specifies the variable fee amount charged. For example, 0.02 would result in a charge of 2% of the total
              outstanding principal of the loan.
          country:
            type: string
            description: |
              English short name according to
              [ISO 3166-1](https://en.wikipedia.org/wiki/ISO_3166-1) standard.
            minLength: 2
            maxLength: 2
          state:
            type: string
            nullable: true
            description: |
              English short name. To make an amount logic configuration applicable to any state, set the `state` field to
              null or simply omit the `state` field.
          customFunctionId:
            type: integer
    capLogic:
      type: array
      required:
        - type
      description: |
        The configuration for any caps on fees charged. A dynamic fee type can have both number-based and amount-based caps.
      properties:
        type:
          type: array
          description: |
            Specifies the types of cap configurations for the dynamic fee type. `unlimited` represents the absence of
            any caps.
          items:
            type: string
            enum:
              - unlimited
              - annualAmountCap
              - totalAmountCap
              - annualNumberCap
        annualAmountCaps:
          type: array
          description: |
            Specifies one or more `annualAmountCap` configurations. This cap represents the total amount of fees that can
            be charged annually. If the total fees charged so far (including the new fee amount to be charged) exceeds the 
            annual cap, the fee will not be charged.

            To specify an annual cap for a single year, the `yearStart`and `yearEnd` will be the same. For example, a `yearStart`
            of 1 and a `yearEnd` of 1 contains the annual cap configuration for a full year starting from the date of activation.
            If the annual cap is the same for consecutive years, specify a different `yearStart` and `yearEnd` date.
            Marking the `yearEnd` as null represents an ongoing annual cap, and covers every year until the end of the loan.
          items:
            type: object
            required:
              - yearStart
              - yearEnd
              - amount
              - country
            properties:
              yearStart:
                type: integer
              yearEnd:
                type: integer
                nullable: true
              amount:
                type: number
                format: float
              country:
                type: string
                description: |
                  English short name according to
                  [ISO 3166-1](https://en.wikipedia.org/wiki/ISO_3166-1) standard.
                minLength: 2
                maxLength: 2
              state:
                type: string
                nullable: true
                description: |
                  English short name. To make a cap configuration applicable to any state, set the `state` field to
                  null or simply omit the `state` field.
        totalAmountCaps:
          type: array
          description: |
            Specifies one or more `totalAmountCap` configurations. This cap represents the total amount of fees that
            can be charged over the lifetime of a loan. If the total fees charged so far (including the new fee amount 
            to be charged) exceeds the cap, the fee will not be charged.
          items:
            type: object
            required:
              - amount
              - country
            properties:
              amount:
                type: number
                format: float
              country:
                type: string
                description: |
                  English short name according to
                  [ISO 3166-1](https://en.wikipedia.org/wiki/ISO_3166-1) standard.
                minLength: 2
                maxLength: 2
              state:
                type: string
                description: |
                  English short name. To make a cap configuration applicable to any state, set the `state` field to
                  null or simply omit the `state` field.
        annualNumberCaps:
          type: array
          description: |
            Specifies one or more `annualNumberCap` configurations. This cap represents the number of fees that can be charged 
            annually. If the total number of fees charged so far (including the "new" fee to be charged) exceeds the annual cap, 
            the fee will not be charged.

            To specify an annual cap for a single year, the `yearStart`and `yearEnd` will be the same. For example, a `yearStart`
            of 1 and a `yearEnd` of 1 contains the annual cap configuration for a full year starting from the date of activation.
            If the annual cap is the same for consecutive years, specify a different `yearStart` and `yearEnd` date.
            Marking the `yearEnd` as null represents an ongoing annual cap, and covers every year until the end of the loan.
          items:
            type: object
            required:
              - yearStart
              - yearEnd
              - number
              - country
            properties:
              yearStart:
                type: integer
              yearEnd:
                type: integer
                nullable: true
              number:
                type: integer
              country:
                type: string
                description: |
                  English short name according to
                  [ISO 3166-1](https://en.wikipedia.org/wiki/ISO_3166-1) standard.
                minLength: 2
                maxLength: 2
              state:
                type: string
                description: |
                  English short name. To make a cap configuration applicable to any state, set the `state` field to
                  null or simply omit the `state` field.


*/

const Name = () => {
  return (
    <Modal.Page formKey='name'>
      <Input
        formKey='displayName'
        note='The display name of the dynamic fee as shown to the borrower in the UI. For example: `Late Fee` or `Breather Fee`.'
      />
      <Input
        formKey='apiName'
        note='The API name of the dynamic fee. For example: `lateFee` or `breatherFee`.'
      />
    </Modal.Page>
  )
}

const Type = () => {
  return (
    <Modal.Page page='Type'>
      <Select
        formKey='feeType'
        options={[
          'lateFee',
          'modificationFee',
          'serviceFee',
          'foreignTransactionFee',
          'purchaseFee',
          'nsfFee',
        ]}
      />

      <Form.If if={{ feeType: 'lateFee' }}>
        <Form.Provider
          formKey='chargeLogic'
          note='The configurations for charge logic. Required for feeType `lateFee`.'
          section
        >
          <Select formKey='type' options={['days']} />
          <NumericInput formKey='buffer' />
          <Select formKey='bufferDayType' options={['business', 'calendar']} />
        </Form.Provider>
      </Form.If>

      <Form.If if={{ feeType: 'serviceFee' }}>
        <Form.Provider
          formKey='chargeLogic'
          note='The configurations for charge logic. Required for feeType `serviceFee`.'
          section
        >
          <Select
            formKey='type'
            options={[
              'loanPeriod',
              'everyOtherLoanPeriod',
              'calendarPeriod',
              'adHoc',
            ]}
          />

          <NumericInput formKey='buffer' />

          <Select boolean formKey='chargeWhenNoOutstandingPrincipal' />

          <NumericInput formKey='startFromPeriod' />
        </Form.Provider>
      </Form.If>
    </Modal.Page>
  )
}

const AmountLogic = () => {
  return (
    <Modal.Page page='Amount Logic'>
      <Form.List
        formKey='amountLogic'
        add
        remove
        defaultValue={{ country: 'US' }}
      >
        <Select
          formKey='type'
          options={[
            'fixed',
            'percentageOfPrincipal',
            'percentageOfAmountFinanced',
            'percentageOfTotalOverdueBalance',
            'percentageOfOverdueBalanceInPeriod',
            'percentageOfOverdueBalanceInPeriodCapped',
            'percentageOfOverduePrincipalBalanceCapped',
            'percentageOfPurchase',
            'percentageOfPurchaseAmountCapped',
            'percentageOfPurchaseAmountAtLeast',
            'passedAtOrigination',
            'custom',
            'adHoc',
          ]}
        />

        <Form.If if={{ type: 'fixed' }}>
          <NumericInput formKey='fixedAmount' />
        </Form.If>
        <Form.If if={{ type: 'percentageOfPrincipal' }}>
          <NumericInput
            formKey='percentageOfPrincipal'
            note='Specifies the variable fee amount charged. For example, 0.02 would result in a charge of 2% of the total outstanding principal of the loan.'
          />
        </Form.If>

        <CountryCodeSelect formKey='country' />

        <Form.If if={{ country: 'US' }}>
          <StateCodeSelect formKey='state' nullable />
        </Form.If>
      </Form.List>
    </Modal.Page>
  )
}

const CapLogic = () => {
  return (
    <Modal.Page page='Cap Logic'>
      <Form.List formKey='capLogic' add remove>
        <Select
          formKey='type'
          options={[
            'unlimited',
            'annualAmountCap',
            'annualNumberCap',
            'annualPercentageOfCreditLimitCap',
            'annualPercentageOfDrawCreditLimitCap',
            'totalAmountCap',
            'totalAmountCapPassedAtOrigination',
            'totalPercentageOfAmountFinancedCap',
            'totalPercentageOfCreditLimitCap',
            'totalPercentageOfDrawCreditLimitCap',
          ]}
        />

        <Form.If if={({ type }) => _.includes(type, 'annualAmountCap')}>
          <Form.List
            formKey='annualAmountCaps'
            defaultValue={{ yearEnd: null, country: 'US' }}
            add
            remove
          >
            <NumericInput formKey='yearStart' />
            <NumericInput formKey='yearEnd' />
            <NumericInput formKey='amount' />
            <CountryCodeSelect formKey='country' />
            <Form.If if={{ country: 'US' }}>
              <StateCodeSelect formKey='state' nullable />
            </Form.If>
          </Form.List>
        </Form.If>

        <Form.If if={({ type }) => _.includes(type, 'totalAmountCap')}>
          <Form.List
            formKey='totalAmountCaps'
            defaultValue={{ country: 'US' }}
            add
            remove
          >
            <NumericInput formKey='amount' />
            <CountryCodeSelect formKey='country' />
            <Form.If if={{ country: 'US' }}>
              <StateCodeSelect formKey='state' nullable />
            </Form.If>
          </Form.List>
        </Form.If>

        <Form.If if={({ type }) => _.includes(type, 'annualNumberCap')}>
          <Form.List
            formKey='annualNumberCaps'
            defaultValue={{ yearEnd: null, country: 'US' }}
            add
            remove
          >
            <NumericInput formKey='yearStart' />
            <NumericInput formKey='yearEnd' />
            <NumericInput formKey='number' />
            <CountryCodeSelect formKey='country' />
            <Form.If if={{ country: 'US' }}>
              <StateCodeSelect formKey='state' nullable />
            </Form.If>
          </Form.List>
        </Form.If>
      </Form.List>
    </Modal.Page>
  )
}

const CreateDynamicFeeTypeForm = () => {
  return (
    <>
      <Name />

      <Type />

      <AmountLogic />

      <CapLogic />
    </>
  )
}

export default CreateDynamicFeeTypeForm
