import color from 'peach/helpers/color/color'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import {
  tomorrow,
  solarizedlight,
} from 'react-syntax-highlighter/dist/esm/styles/prism'
import { useTheme } from 'styled-components/macro'

const CodeBlock = ({ node, inline, className, children, ...rest }) => {
  const { background } = useTheme()

  const style = color.isLight(background) ? solarizedlight : tomorrow

  const language = /language-(\w+)/.exec(className || '')?.[1] || 'js'

  if (inline) {
    return (
      <code className={className} {...rest}>
        {children}
      </code>
    )
  }

  const contents = String(children).replace(/\n$/, '')

  return (
    <SyntaxHighlighter
      style={style}
      language={language}
      PreTag='div'
      className='react-syntax-highlighter--codeblock'
      {...rest}
    >
      {contents}
    </SyntaxHighlighter>
  )
}

export default CodeBlock
