import { forwardRef } from 'react'

import styled from 'styled-components/macro'

const Item = styled.button`
  width: 100%;
  text-align: left;
  display: flex;

  border: 1px solid transparent;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
  padding: ${(p) => (p.hasNote ? '6px' : '8px')} 8px;
  border-radius: 4px;
  background-color: ${(p) => (p.isSelected ? p.theme.banner : 'transparent')};
  cursor: pointer;
  transition: border-color 0s, background-color 0.1s;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
  &:disabled {
    cursor: default;
  }
  &:not(:disabled) :hover {
    background-color: ${(p) => p.theme.hover};
  }
`

const Left = styled.span`
  padding-right: 8px;
`

const Label = styled.div`
  flex-grow: 1;
`

const Right = styled.span`
  padding-left: 8px;
`

const LabelText = styled.div`
  color: ${(p) => p.theme.text};
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: ${(p) => (p.disabled ? '0.25' : '1')};
`

const Note = styled.div`
  font-size: 12px;
  color: ${(p) => p.theme.mutedText};
  padding-top: 6px;
  opacity: ${(p) => (p.disabled ? '0.25' : '1')};
`

const SelectItem = forwardRef((props, ref) => {
  const { isSelected, onClick, left, right, label, note, disabled } = props

  return (
    <Item
      onClick={onClick}
      isSelected={isSelected}
      disabled={disabled}
      hasNote={!!note}
    >
      {left && <Left>{left}</Left>}
      <Label>
        <LabelText disabled={disabled}>{label}</LabelText>
        {note && <Note disabled={disabled}>{note}</Note>}
      </Label>
      {right && <Right>{right}</Right>}
    </Item>
  )
})

export default SelectItem
