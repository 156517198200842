import TippyBase from '@tippyjs/react'
import styled from 'styled-components/macro'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/themes/light.css'
import 'tippy.js/animations/shift-away.css'

const Tooltip = ({
  animation,
  appendTo = 'parent',
  arrow = true,
  backgroundColor = 'white',
  color,
  content,
  disabled = false,
  hideOnClick = true,
  iconMargin = '0',
  placement = 'bottom',
  width = 648,
}) => {
  return (
    <Tippy
      data-testid='tippy-tooltip'
      appendTo={appendTo}
      arrow={arrow}
      animation={animation}
      backgroundColor={backgroundColor}
      color={color}
      content={content}
      hideOnClick={hideOnClick}
      interactive
      maxWidth={width}
      placement={placement}
      theme='light'
      trigger='mouseenter click'
      disabled={disabled}
    >
      <IconWrapper margin={iconMargin}>?</IconWrapper>
    </Tippy>
  )
}

export default Tooltip

/**
 * If you attempt to pass props into Tippy that are not part of their
 * documentation, it will output a console warning in dev mode. This
 * helps us remove the prop that Tippy doesn't allow which will consequently
 * remove the console warnings in the browser. For now Tippy doesn't
 * allow the props 'backgroundColor', 'color', and 'fontSize'. If you
 * want to use additional props on the Tippy styled component that are
 * not part of their doc, simply add those in the object destructring
 * of the props of PropsOmittedTippyBase.
 */
const PropsOmittedTippyBase = ({
  backgroundColor,
  color,
  'data-testid': dataTestid,
  ...rest
}) => <TippyBase {...rest} />

export const Tippy = styled(PropsOmittedTippyBase)`
  border-radius: 8px;
  padding: 8px;
  background-color: ${(p) => p.backgroundColor} !important;
  font-size: 12px;
  font-weight: 500;

  > .tippy-arrow {
    color: ${(p) => p.backgroundColor};
  }

  ${(p) => (p.color ? `color: ${p.color};` : '')}
`
export const IconWrapper = styled.span.attrs(() => ({
  role: 'button',
  'data-cy': 'tippy-tooltip',
}))`
  display: inline-flex;
  align-items: baseline;
  margin: ${(p) => p.margin};
  cursor: pointer;
  border-radius: 50%;
  padding: 1px 0 0 4px;
  background-color: #ccc;
  width: 13px;
  height: 13px;
  font-size: 9px;
`
