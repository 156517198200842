import { Children, Fragment } from 'react'

import styled from 'styled-components/macro'

const Wrapper = styled.div`
  padding: ${(p) => p.padding};
  display: flex;
  flex-direction: column;
`
const Spacer = styled.div`
  height: ${(p) => p.gap};
`
const Item = styled.div``

const Stack = ({ children, padding, gap }) => {
  const list = _.compact(Children.toArray(children))

  const count = _.size(list)

  const items = _.map(list, (item, index) => {
    return (
      <Fragment key={index}>
        <Item>{item}</Item>
        {index < count - 1 && <Spacer gap={gap || '8px'} />}
      </Fragment>
    )
  })

  return <Wrapper padding={padding || 0}>{items}</Wrapper>
}

export default Stack
