import { useMemo } from 'react'

import {
  useIsUnmounted,
  useOnUnmountEffect,
  useStableCallback,
} from 'peach/hooks'

import { getNewValue, removeKey } from './stateHelpers'
import {
  useFormValue,
  useFormOnChange,
  useFormClearKey,
  useFormValidation,
  useFormOnValidationChange,
  useFormIsHidden,
} from './useFormState'

const getIsFullValue = (formKey) => formKey === false
const getIsSubKey = (formKey) => _.isString(formKey)

const mergeAt = (baseObj = {}, key, newVal) => {
  const oldVal = baseObj[key]
  return { ...baseObj, [key]: { ...oldVal, ...newVal } }
}

const useFormValueAndOnChange = (options = {}) => {
  const {
    formKey,
    value: passedValue,
    onChange: passedOnChange,
    persistOnUnmount,
    validation: passedValidation,
    onValidationChange: passedOnValidationChange,
    isHidden: passedIsHidden,
  } = options

  const isUnmountedRef = useIsUnmounted()

  const contextValue = useFormValue()
  const contextOnChange = useFormOnChange()
  const contextClearKey = useFormClearKey()
  const contextValidation = useFormValidation()
  const contextOnValidationChange = useFormOnValidationChange()
  const isHidden = useFormIsHidden() || passedIsHidden

  const isFullValue = getIsFullValue(formKey)
  const isSubKey = getIsSubKey(formKey)

  const value = useMemo(() => {
    const val = isFullValue
      ? contextValue
      : isSubKey
      ? contextValue?.[formKey]
      : passedValue

    return val
  }, [isFullValue, contextValue, isSubKey, formKey, passedValue])

  const validation = useMemo(() => {
    if (isFullValue) return contextValidation
    if (isSubKey) return contextValidation?.nested?.[formKey]
    return passedValidation
  }, [isFullValue, contextValidation, isSubKey, formKey, passedValidation])

  const handleChange = useStableCallback((newValOrFn) => {
    if (isFullValue) {
      contextOnChange(newValOrFn)
    } else if (isSubKey) {
      contextOnChange((prevVal = {}) => {
        const newSubKeyVal = getNewValue(newValOrFn, prevVal[formKey])
        return { ...prevVal, [formKey]: newSubKeyVal }
      })
    }

    if (passedOnChange) passedOnChange(newValOrFn)
  })

  const clearKey = useStableCallback((key) => {
    if (!isUnmountedRef.current) {
      if (isSubKey) handleChange((value) => removeKey(value, key))
    }
  })

  const handleValidationChange = useStableCallback((newVal) => {
    if (isFullValue) {
      contextOnValidationChange((prev) => getNewValue(newVal, prev))
    } else if (isSubKey) {
      contextOnValidationChange((prev) => {
        const oldSubVal = prev?.nested?.[formKey]
        const newSubVal = getNewValue(newVal, oldSubVal)
        return mergeAt(prev, 'nested', { [formKey]: newSubVal })
      })
    }

    if (passedOnValidationChange) {
      passedOnValidationChange((prev) => {
        const result = getNewValue(newVal, prev)
        return result
      })
    }
  })

  useOnUnmountEffect(() => {
    // todo: figure out why clearing isn't happening inside of a list group
    if (!persistOnUnmount && isSubKey) contextClearKey(formKey)
  })

  return {
    value,
    onChange: (isFullValue || isSubKey || passedOnChange) && handleChange,
    clearKey,
    validation,
    onValidationChange:
      (isFullValue || isSubKey || passedOnValidationChange) &&
      handleValidationChange,
    isHidden,
  }
}

export { useFormValueAndOnChange }
