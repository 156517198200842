import { useRef, useEffect } from 'react'

const useIsUnmounted = () => {
  const unmountedRef = useRef(false)

  useEffect(() => {
    return () => (unmountedRef.current = true)
  }, [])

  return unmountedRef
}

export default useIsUnmounted
