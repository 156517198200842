import combineStrings from './combineStrings'

const getAddressName = (address = {}) => {
  const {
    POBox,
    addressLine1,
    addressLine2,
    city,
    country,
    countyOrRegion,
    postalCode,
    state,
  } = address

  return combineStrings([
    POBox,
    addressLine1,
    addressLine2,
    city,
    country,
    countyOrRegion,
    postalCode,
    state,
  ])
}

export default getAddressName
