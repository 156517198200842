import styled, { createGlobalStyle } from 'styled-components/macro'

import AuthRoutesController from './AuthRoutesController'
import SystemProviders from './SystemProviders'

const GlobalStyle = createGlobalStyle`
  
  *:focus {
    outline: none;
  }

  *.focus-visible {
    box-shadow: 0 0 0 1px ${(p) => p.theme.highlight};
  }

  a {
    border-radius: 2px;
  }
`

const AppMain = styled.div`
  background-color: ${({ theme }) => theme.background};
  height: 100%;
  overflow-y: auto;
`

const App = () => {
  return (
    <SystemProviders>
      <GlobalStyle />
      <AppMain>
        <AuthRoutesController />
      </AppMain>
    </SystemProviders>
  )
}

export default App
