import {
  LoadingContainer,
  Model,
  Input,
  Button,
  NumericInput,
} from 'peach/components'
import { useApi, useWrite } from 'peach/hooks'
import { useLoanId, LoanFeeTypeSelect } from 'peach/scopes/loan'
import { usePersonId } from 'peach/scopes/person'

const LoanFeeForm = ({ value, onChange, loanTypeId }) => {
  const api = useApi()
  const personId = usePersonId()
  const loanId = useLoanId()

  const [sendPreview, sending, resp, error] = useWrite(async () => {
    return api.loan.fees
      .post({
        pathArgs: { personId, loanId },
        body: { previewMode: true, ...value },
      })
      .then((resp) => resp.data)
  })

  return (
    <LoadingContainer loading={sending} error={error}>
      <LoanFeeTypeSelect formKey='apiName' loanTypeId={loanTypeId} />

      <NumericInput formKey='feeAmount' />

      <Input formKey='caseId' />

      <Button onClick={sendPreview}>Preview</Button>

      <Model.Item json={resp} />
    </LoadingContainer>
  )
}

export default LoanFeeForm
