import queryString from 'query-string'

import PaginationControl from './PaginationControl'

const getOptionsFromUrl = (url) => {
  const [, paramsString] = (url || '').split('?')
  return queryString.parse(paramsString) || {}
}

// const arrows = `<❯❮⟫⟪⟩⟨><»«›‹' ✕↺⟲⥀↻⟳⤾⤿`
const cleanOptions = (options) => {
  const { endingBefore, startingAfter, ...oldOPtions } = options
  return oldOPtions
}

const Pagination = (props) => {
  const { resp, loading, refresh, label, singular, options, setOptions } = props

  const { endingBefore, startingAfter } = options || {}

  const { count, nextUrl, previousUrl, total } = resp || {}

  const prevOptions = getOptionsFromUrl(previousUrl)
  const nextOptions = getOptionsFromUrl(nextUrl)

  // console.log({ options, loading, resp, nextOptions, prevOptions })

  const disablePrevious =
    loading ||
    !prevOptions.endingBefore ||
    endingBefore === prevOptions.endingBefore

  const onPrevious = () => {
    setOptions((options) => ({ ...cleanOptions(options), ...prevOptions }))
  }

  const disableNext =
    loading ||
    !nextOptions.startingAfter ||
    startingAfter === nextOptions.startingAfter

  const onNext = () => {
    setOptions((options) => ({ ...cleanOptions(options), ...nextOptions }))
  }

  const isCurrentlyPaged = startingAfter || endingBefore

  const onClearPagination = () => setOptions((options) => cleanOptions(options))

  const $label = label || 'Items'

  const displayCount = total ? `${count || 0} of ${total}` : '0'

  const displayLabel = (count === 1 && singular) || $label

  const caption =
    loading && _.isEmpty(resp) ? 'Loading…' : `${displayCount} ${displayLabel}`

  return (
    <PaginationControl
      caption={caption}
      disablePrevious={disablePrevious}
      onPrevious={onPrevious}
      disableNext={disableNext}
      onNext={onNext}
      loading={loading}
      onRefresh={refresh}
      isCurrentlyPaged={isCurrentlyPaged}
      onClearPagination={onClearPagination}
    />
  )
}

export default Pagination
