import { Switch, Route, useRouteMatch, Redirect } from 'peach/router'

import Template from './Template'
import TemplateSubject from './TemplateSubject'
import TemplateSubjectChannel from './TemplateSubjectChannel'
import TemplateSubjects from './TemplateSubjects'

const TemplateSubjectRoutes = () => {
  const { url, path } = useRouteMatch()

  const subjectPath = `${path}/:subjectKey`
  const channelPath = `${subjectPath}/:channelKey`
  const templatePath = `${channelPath}/:templateId`

  return (
    <Switch>
      <Route path={path} exact>
        <TemplateSubjects />
      </Route>

      <Route path={subjectPath} crumb>
        <Switch>
          <Route path={subjectPath} exact>
            <TemplateSubject />
          </Route>

          <Route path={channelPath} crumb>
            <Switch>
              <Route path={channelPath} exact>
                <TemplateSubjectChannel />
              </Route>

              <Route path={templatePath} crumb>
                <Template />
              </Route>
            </Switch>
          </Route>
        </Switch>
      </Route>

      <Redirect to={url} />
    </Switch>
  )
}

export default TemplateSubjectRoutes
