import { useState } from 'react'

import { OrderableList, Page, Json } from 'peach/components'
import KeyboardOrderableList from 'peach/components/List/KeyboardOrderableList'

const defaultValue = ['One', 'Two', '3', 'Four', 'five']

const ScratchOrderable = () => {
  const [state, setState] = useState()

  return (
    <Page.Centered>
      <Json.Table title='State' json={{ state, defaultValue }} />
      <OrderableList
        label='An Ordered List'
        value={state}
        onChange={setState}
        defaultValue={defaultValue}
        remove
        add
        note='Use arrow keys to move items up and down multiple rows quickly'
      />

      <KeyboardOrderableList
        label='A  New Ordered List'
        value={state}
        onChange={setState}
        defaultValue={defaultValue}
        remove
        add
        note='Use arrow keys to move items up and down multiple rows quickly'
      />
    </Page.Centered>
  )
}

export default ScratchOrderable
