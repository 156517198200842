import { LoadingContainer, Page, Model, Modal, Select } from 'peach/components'
import { useLoanFeesQuery, useLoanQuery } from 'peach/data'
import { getName } from 'peach/helpers'
import { useApi } from 'peach/hooks'
import { useLoanId } from 'peach/scopes/loan'
import { usePersonId } from 'peach/scopes/person'

import LoanFeeForm from './LoanFees/LoanFeeForm'

const LoanFees = () => {
  const personId = usePersonId()
  const loanId = useLoanId()
  const [loan] = useLoanQuery()

  const api = useApi()

  const [fees, query] = useLoanFeesQuery()

  const refresh = () => query.refetch()

  const sendCancelFee = async ({ loanFeeId, caseId }) => {
    await api.loan.cancelFee.post({
      pathArgs: { personId, loanId, loanFeeId },
      body: { caseId },
    })
    refresh()
  }

  const $fees = _.filter(fees, (fee) => fee?.dynamicFeeDetails?.loanFeeId)

  const cancelFee = (
    <Modal
      title='Cancel Fee'
      onSubmit={sendCancelFee}
      initialValue={{ loanFeeId: null, caseId: null }}
    >
      <Select
        formKey='loanFeeId'
        options={_.map($fees, (fee) => {
          const value = fee?.dynamicFeeDetails?.loanFeeId
          return { label: getName(fee), value, disabled: !value }
        })}
      />
    </Modal>
  )

  const sendChargeFee = async (fee) => {
    await api.loan.fees.post({ pathArgs: { personId, loanId }, body: fee })
    refresh()
  }

  const chargeFee = (
    <Modal
      title='Charge Fee'
      onSubmit={sendChargeFee}
      render={({ value, onChange }) => {
        return (
          <LoanFeeForm
            value={value}
            onChange={onChange}
            loanTypeId={loan?.loanTypeId}
          />
        )
      }}
    />
  )

  return (
    <LoadingContainer query={query}>
      {cancelFee}
      {chargeFee}
      <Page.Full>
        <Model.Viewer json={fees} />
      </Page.Full>
    </LoadingContainer>
  )
}

export default LoanFees
