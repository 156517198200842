import { LoadingContainer, Page, Model, Panels, Bar } from 'peach/components'
import { useDrawBalancesQuery } from 'peach/data'

const DrawBalances = () => {
  const [purchase, query] = useDrawBalancesQuery()

  const header = <Bar title='Balances' />

  return (
    <Panels header={header}>
      <LoadingContainer query={query}>
        <Page.Full>
          <Model.Item json={purchase} />
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default DrawBalances
