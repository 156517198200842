import { useState, useLayoutEffect, useRef } from 'react'

import useStableResult from './useStableResult'

const useDebounceValue = (value, wait = 300) => {
  const [val, setVal] = useState(value)

  const timeoutRef = useRef()

  useLayoutEffect(() => {
    const onTimeout = () => setVal(value)
    timeoutRef.current = setTimeout(onTimeout, wait)
    return () => clearTimeout(timeoutRef.current)
  }, [wait, value])

  const isDebouncing = val !== value

  const override = (value) => {
    setVal(value)
    clearTimeout(timeoutRef.current)
  }

  return useStableResult(val, isDebouncing, override)
}

export default useDebounceValue
