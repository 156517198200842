import { forwardRef } from 'react'

import styled from 'styled-components/macro'

const Trigger = styled.button`
  width: 100%;
  min-width: 180px;
  text-align: left;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.border};
  background-color: ${(p) => p.theme.background};
  color: ${(p) => (p.triggerHasValue ? p.theme.text : p.theme.mutedText)};
  width: ${(p) => p.width};
  background-color: ${(p) => p.theme.background};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};

  &:disabled {
    color: #888;
    border-color: ${(p) => p.theme.border};
    background-color: ${(p) => p.theme.offset};
  }
`

const TriggerLabel = styled.span`
  flex-shrink: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const Chevron = styled.span`
  padding-left: 8px;
  flex-shrink: 0;
  color: ${(p) => (p.disabled ? p.theme.disabledText : p.theme.mutedText)};
`

const SelectTrigger = forwardRef((props, ref) => {
  const {
    isOpen,
    onOpen,
    onClose,
    triggerLabel,
    triggerHasValue,
    disabled,
    width,
  } = props

  const handleKeyDown = (event) => {
    if (props.onKeyDown) props.onKeyDown(event)
    if (event.key === 'ArrowDown') {
      event.preventDefault()
      onOpen()
    }
  }

  return (
    <Trigger
      ref={ref}
      onClick={isOpen ? onClose : onOpen}
      onKeyDown={handleKeyDown}
      disabled={disabled}
      width={width}
      triggerHasValue={triggerHasValue}
    >
      <TriggerLabel>{triggerLabel}</TriggerLabel>
      <Chevron disabled={disabled}>▼</Chevron>
    </Trigger>
  )
})

export default SelectTrigger
