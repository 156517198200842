import { LoadingContainer, ModelViewer, Page } from 'peach/components'
import { useLoanBalancesQuery } from 'peach/data'

const LoanBalances = () => {
  const [balances, query] = useLoanBalancesQuery()

  return (
    <LoadingContainer query={query}>
      <Page.Full>
        <ModelViewer json={balances} />
      </Page.Full>
    </LoadingContainer>
  )
}

export default LoanBalances
