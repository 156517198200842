import { Input, Form, Select } from 'peach/components'
import {
  CompanyEmployeeSelect,
  CompanyTeamSelect,
  CompanyRolesSelect,
} from 'peach/scopes/company'

const AddEmployeeForm = () => {
  return (
    <>
      <Form.Section label='User Record Properties'>
        <Select
          formKey='authorizationType'
          options={['emailPassword', 'saml']}
        />

        <Form.If if={{ authorizationType: 'emailPassword' }}>
          <Form.Provider formKey='authType' section>
            <Input formKey='email' />
            <Input formKey='password' />
          </Form.Provider>
        </Form.If>

        <Form.If if={{ authorizationType: 'saml' }}>
          <Form.Provider formKey='authType' section>
            <Input formKey='samlSubjectNameId' />
          </Form.Provider>
        </Form.If>

        <CompanyRolesSelect formKey='roles' />
      </Form.Section>

      <Form.Section label='Employee Record Properties'>
        <Input formKey='firstName' />
        <Input formKey='lastName' />
        <Input formKey='jobName' />

        <CompanyEmployeeSelect
          formKey='managerEmployeeId'
          label='Manager Employee'
          nullable
          searchable
        />
      </Form.Section>

      <Form.Section label='Teams'>
        <CompanyTeamSelect formKey='teamId' />
      </Form.Section>
    </>
  )
}

export default AddEmployeeForm
