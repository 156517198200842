import { getName, combineStrings } from 'peach/helpers'

import Json from '../Json'

const DataObject = ({ json, compact, index }) => {
  const displayIndex = _.isFinite(index) ? `[${index}] ` : ''
  const name = getName(json)
  const title = combineStrings([displayIndex, name])

  return (
    <Json.Table title={title} json={json} compact={compact} stringLimit={0} />
  )
}

const Data = ({ json, compact }) => {
  if (_.isArray(json) && !_.isEmpty(json)) {
    return _.map(json, (obj, index) => {
      const { id } = obj || {}
      const key = id ? `id_${id}` : `index_${index}`
      return <DataObject index={index} key={key} json={obj} compact />
    })
  } else if (_.isPlainObject(json) && !_.isEmpty(json)) {
    return <DataObject json={json} compact />
  } else {
    return <Json json={json} compact={compact} />
  }
}

export default Data
