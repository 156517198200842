import { Json } from 'peach/components'

const SchdeuleTable = ({ schedule }) => {
  /*
periodId    PE-6JYE-15B3
status    modified
isDeferred    false
amount    96.1
date    2021-01-07
interestAmount    15.95
interestBeforeDiscountAmount    15.95
originalAmount    96.1
paymentType    periodicPayment
principalAmount    80.15
dynamicFeeDetails    
Dynamic Fee Details
dynamicFeeTypeId    null
loanFeeId



"amount":682.84
"date":"2020-09-04"
"dynamicFeeDetails":{4 items
"apiName":NULL
"displayName":NULL
"dynamicFeeTypeId":NULL
"loanFeeId":NULL
}
"interestAmount":37.43
"interestBeforeDiscountAmount":37.43
"isDeferred":false
"originalAmount":682.84
"paymentType":"periodicPayment"
"periodId":"PE-VLKD-R8B4"
"principalAmount":645.41
"status":"booked"
*/

  const data = _.map(schedule, (item) => {
    const { periodId, status, isDeferred, amount, date } = item

    return {
      date,
      periodId,
      status,
      amount,
      isDeferred,
    }
  })

  return <Json.ArrayTable title='Schedule' json={data} />
}

export default SchdeuleTable
