import { useEffect } from 'react'

import { useAuthState } from 'core/auth/AuthProvider'
import { Select, Form, Page } from 'peach/components'
import { withFormValue } from 'peach/decorators'
import { getName } from 'peach/helpers'
import { useReadData, useApi, usePrevious } from 'peach/hooks'

const getOptions = (list) => {
  return _.map(list, (item) => ({ value: item.id, label: getName(item) }))
}

const TemplateRenderOptions = ({ value, onChange }) => {
  const api = useApi()
  const authState = useAuthState()
  const { companyId } = authState

  const { personId } = value || {}
  const previousPersonId = usePrevious(personId)
  useEffect(() => {
    if (personId !== previousPersonId) {
      onChange({
        ...value,
        paymentInstrumentId: undefined,
        loanId: undefined,
        caseId: undefined,
      })
    }
  }, [personId, previousPersonId, value, onChange])

  const [people, loadingPeople] = useReadData(() => {
    return api.company.people.get({ pathArgs: { companyId } })
  }, [companyId])

  const [cases, loadingCases] = useReadData(() => {
    return api.person.cases.get({ pathArgs: { personId } })
  }, [personId])

  const [loans, loadingLoans] = useReadData(() => {
    return api.person.loans.get({ pathArgs: { personId } })
  }, [personId])

  const [paymentInstruments, loadingInstruments] = useReadData(() => {
    return api.person.paymentInstruments.get({
      pathArgs: { personId },
    })
  }, [personId])

  return (
    <Page.Full>
      <Form.Provider value={value} onChange={onChange}>
        <Select formKey='fmt' options={['html', 'text']} />

        <Select
          formKey='personId'
          options={getOptions(people)}
          loading={loadingPeople}
        />
        <Select
          formKey='paymentInstrumentId'
          options={getOptions(paymentInstruments)}
          loading={loadingInstruments}
        />
        <Select
          formKey='loanId'
          options={getOptions(loans)}
          loading={loadingLoans}
        />
        <Select
          formKey='caseId'
          options={getOptions(cases)}
          loading={loadingCases}
        />
      </Form.Provider>
    </Page.Full>
  )
}

export default withFormValue(TemplateRenderOptions)
