import { useRef, useEffect } from 'react'

// allow passing a list of deps and an func in either order
const parseArgs = (...args) => {
  const [list, func] = args
  if (_.isArray(list) && _.isFunction(func)) {
    return [list, func]
  } else if (_.isFunction(list) && _.isArray(func)) {
    return [func, list]
  }
}

const useOnChangeEffect = (...args) => {
  const [list, onChange] = parseArgs(...args)

  const prevListRef = useRef(list)

  useEffect(() => {
    prevListRef.current = list
  }, [list])

  const prevList = prevListRef.current

  useEffect(() => {
    if (!prevList || !list) return

    // if deps value previous existed (i.e. not initial render)
    // and any of the values have changed,
    const hasChanged = _.some(list, (item, index) => item !== prevList[index])

    if (hasChanged) {
      const returnValue = onChange(prevList || [])

      // handle useEffect cleanup
      if (_.isFunction(returnValue)) return returnValue
    }
  }, [list, prevList, onChange])
}

export default useOnChangeEffect
