import {
  LoadingContainer,
  ModelViewer,
  Page,
  Modal,
  Select,
  NumericInput,
  Form,
  Input,
} from 'peach/components'
import { useWorkflowTypeQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { useParams } from 'peach/router'

const WorkflowType = () => {
  const { companyId, workflowTypeId } = useParams()
  const [workflowType, query] = useWorkflowTypeQuery({
    companyId,
    workflowTypeId,
  })

  const api = useApi()

  const sendEditWorkflowType = async (workflowType) => {
    const {
      name,
      description,
      version,
      versionStatus,
      steps,
      approvalConditions,
    } = workflowType
    await api.company.workflowType.put({
      pathArgs: { companyId, workflowTypeId },
      body: _.omitBy(
        {
          name,
          description,
          version,
          versionStatus,
          steps,
          approvalConditions,
        },
        _.isNil,
      ),
    })
    query.refetch()
  }

  const editWorkflowType = (
    <Modal
      trigger
      title='Edit Workflow Type'
      onSubmit={sendEditWorkflowType}
      initialValue={workflowType}
      pages
    >
      <Modal.Page page='Workflow Type'>
        <Input formKey='refId' disabled />
        <Input formKey='name' />
        <Input formKey='description' />
        <NumericInput formKey='version' />
        <Select
          formKey='versionStatus'
          options={['draft', 'active', 'deprecated']}
        />
        <Form.List
          formKey='steps'
          add
          remove
          renderItem={(props) => <Input {...props} />}
        />
        <Form.List formKey='approvalConditions' add remove>
          <Input formKey='stepTypeRefId' />
          <Select
            formKey='stepStatus'
            options={['due', 'approved', 'rejected', 'skipped']}
          />
        </Form.List>
      </Modal.Page>
    </Modal>
  )

  return (
    <LoadingContainer query={query}>
      {editWorkflowType}
      <Page.Full>
        <ModelViewer json={workflowType} />
      </Page.Full>
    </LoadingContainer>
  )
}

export default WorkflowType
