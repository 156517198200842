import makeDarkThemeFromConfig from './makeDarkThemeFromConfig'
import makeLightThemeFromConfig from './makeLightThemeFromConfig'

const getTheme = (darkMode, colors) => {
  return darkMode === 'light'
    ? makeLightThemeFromConfig(colors)
    : makeDarkThemeFromConfig(colors)
}

export default getTheme
