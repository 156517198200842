import { useState, useEffect } from 'react'

import { withLabel, withFormValue } from 'peach/decorators'
import { humanizeKey } from 'peach/helpers'
import { useOnMountEffect } from 'peach/hooks'
import styled, { css } from 'styled-components/macro'

const StyledList = styled.div`
  border: 1px solid ${(p) => p.theme.border};
  padding: 6px;
  border-radius: 4px;
  background-color: ${(p) => p.theme.background};
`

const ListContent = styled.div`
  border: 1px solid ${(p) => p.theme.border};
`

const StyledItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${(p) => p.theme.border};
  background-color: ${(p) => p.theme.offset};
  padding: 4px;
  font-size: 14px;

  &:last-child {
    border-bottom: none;
  }

  ${(p) =>
    p.isSelected &&
    css`
      border-left: 3px solid ${p.theme.highlight};
    `}
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const inputStyles = css`
  padding: 4px 8px;
  border: 1px solid ${(p) => p.theme.border};
  color: ${(p) => p.theme.labelText};
  &:focus {
    color: ${(p) => p.theme.text};
  }
`

const OrderInput = styled.input`
  ${inputStyles};
  font-size: 12px;
  width: 48px;
  text-align: center;
`

const KeyInput = styled.input`
  ${inputStyles};
  width: 100%;
  margin-left: 8px;
`

const Actions = styled.div`
  padding-top: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Action = styled.button`
  border: none;
  padding: 2px 4px;
  background-color: transparent;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  font-size: 14px;
  color: ${(p) => (p.disabled ? p.theme.disabledText : p.theme.linkText)};
`

const Arrow = styled.button`
  border: none;
  padding: 2px 6px;
  margin-left: 4px;
  font-size: 14px;
  color: ${(p) => (p.disabled ? p.theme.disabled : p.theme.linkText)};
  background-color: transparent;
  cursor: pointer;
  &:active,
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`

const parseValue = (list) => {
  return _.map(list, (item, index) => {
    return { order: `${index}`, value: item }
  })
}

const parseList = (list) => {
  const sorted = _.sortBy(list, (item) => parseFloat(item.order))
  return _.map(sorted, 'value')
}

const ValueWrapper = styled.div`
  display: inline-block;
  padding-left: 8px;
`

const DisplayValue = styled.div``

const KeyValue = styled.div`
  padding-top: 4px;
  font-size: 10px;
  color: ${(p) => p.theme.mutedText};
`

const Value = ({ value }) => {
  return (
    <ValueWrapper>
      <DisplayValue>{humanizeKey(value)}</DisplayValue>
      <KeyValue>{value}</KeyValue>
    </ValueWrapper>
  )
}

const KeyboardOrderableList = ({
  value,
  onChange,
  defaultValue = [],
  remove,
  add,
}) => {
  // const valueString = JSON.stringify(value)

  const [list, setList] = useState(() => parseValue(value))

  // const listString = JSON.stringify(list)

  useEffect(() => {
    setList(parseValue(value))
  }, [value])

  const reset = () => onChange(defaultValue)

  useOnMountEffect(() => {
    if (!value && defaultValue) reset()
  })

  const onReset = () => {
    setList(parseValue(defaultValue))
    onChange(defaultValue)
  }

  const onApply = () => {
    const newValue = parseList(list)
    setList(parseValue(newValue))
    onChange(newValue)
  }

  const size = _.size(list)

  const canReset =
    !_.isEmpty(defaultValue) && !_.isEqual(list, parseValue(defaultValue))

  const canApply = !_.isEqual(list, parseValue(value))

  const [selectedValues, setSelectedValues] = useState([])

  const onClickItem = (value) => {
    setSelectedValues((values) => {
      return _.includes(values, value)
        ? _.without(values, value)
        : values.concat(value)
    })
  }

  const displayItems = _.map(list, (item, i) => {
    const { order, value } = item

    const updateListOrder = (list, order) => {
      const newItem = { order, value }
      const newList = [...list]
      newList[i] = newItem
      return newList
    }

    const onOrderChange = (order) => {
      setList((list) => updateListOrder(list, order))
    }

    const onApplyOrder = (order) => {
      const newList = updateListOrder(list, order)
      const newValue = parseList(newList)
      setList(parseValue(newValue))
      onChange(newValue)
    }

    const onUp = () => onApplyOrder(`${parseFloat(order) - 1.5}`)

    const onDown = () => onApplyOrder(`${parseFloat(order) + 1.5}`)

    const onRemove = () => {
      const newList = _.reject(list, { value })
      const newValue = parseList(newList)
      setList(parseValue(newValue))
      onChange(newValue)
    }
    // symbols = '⬆︎⬇︎⇩⇧×⊕⊖⊗⨂⨁⨀'

    const isSelected = _.includes(selectedValues, value)

    return (
      <StyledItem
        key={value}
        isSelected={isSelected}
        onClick={() => onClickItem(value)}
      >
        <Section>
          <OrderInput
            value={order}
            onChange={(event) => onOrderChange(event.target.value)}
          />
          <Value value={value} />
        </Section>
        <Section>
          <Arrow disabled={canApply || i <= 0} onClick={onUp}>
            {'⇧'}
          </Arrow>
          <Arrow disabled={canApply || i >= size - 1} onClick={onDown}>
            {'⇩'}
          </Arrow>
          {remove && (
            <Arrow disabled={canApply} onClick={onRemove}>
              {'⨂'}
            </Arrow>
          )}
        </Section>
      </StyledItem>
    )
  })

  const [addValueStr, setAddValueStr] = useState('')

  const onAdd = () => {
    const newList = [...list, { order: size, value: addValueStr }]
    const newValue = parseList(newList)
    setList(parseValue(newValue))
    onChange(newValue)
    setAddValueStr('')
  }

  const addItem = add && (
    <StyledItem>
      <Section>
        <OrderInput disabled value={size} />
        <KeyInput
          value={addValueStr}
          onChange={(event) => setAddValueStr(event.target.value)}
          placeholder='Add Value…'
        />
      </Section>
      <Section>
        <Arrow disabled={canApply || !addValueStr} onClick={onAdd}>
          {'⨁'}
        </Arrow>
      </Section>
    </StyledItem>
  )

  return (
    <StyledList>
      <ListContent>
        {displayItems}
        {addItem}
      </ListContent>
      <Actions>
        <Action onClick={onApply} disabled={!canApply}>
          Apply
        </Action>
        <Action onClick={() => {}} disabled={false}>
          {'⇧'}
        </Action>
        <Action onClick={() => {}} disabled={false}>
          {'⇩'}
        </Action>
        <Arrow onClick={() => {}} disabled={false}>
          {'⨂'}
        </Arrow>
        <Action onClick={onReset} disabled={!canReset}>
          Reset
        </Action>
      </Actions>
    </StyledList>
  )
}

export default withFormValue(withLabel(KeyboardOrderableList))
