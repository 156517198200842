import PeopleList from 'pages/people/PeopleList'
import {
  Panels,
  ListNav2,
  ModelViewer,
  LoadingContainer,
  Input,
  Modal,
  Page,
  Model,
} from 'peach/components'
import { useVerificationTypesQuery } from 'peach/data'
import { getName } from 'peach/helpers'
import { useApi, useReadData } from 'peach/hooks'
import {
  Switch,
  Route,
  Redirect,
  useRouteMatch,
  useParams,
  ListSubroutes,
} from 'peach/router'

import CaseType from './CaseTypes/CaseType'
import CaseTypes from './CaseTypes/CaseTypes'
import CompanyBootstrap from './CompanyBootstrap'
import CompanyConfig from './CompanyConfig'
import CompanyImportTemplates from './CompanyImportTemplates'
import CompanyInvestors from './CompanyInvestors'
import CompanyEmployeesRoutes from './employees/CompanyEmployeesRoutes'
import LoanTypesRoutes from './LoanTypes/LoanTypesRoutes'
import CompanyRoles from './roles/CompanyRoles'
import CompanyTeams from './teams/CompanyTeams'
import CompanyDescriptors from './templates/CompanyDescriptors'
import CompanyTemplatesRoutes from './templates/CompanyTemplatesRoutes'
import TemplateSubjectRoutes from './templates/subjects/TemplateSubjectRoutes'
import CompanyUsersRoutes from './users/CompanyUsersRoutes'
import WorkflowStepType from './workflowStepTypes/WorkflowStepType'
import WorkflowStepTypes from './workflowStepTypes/WorkflowStepTypes'
import WorkflowType from './workflowTypes/WorkflowType'
import WorkflowTypes from './workflowTypes/WorkflowTypes'

const Company = () => {
  const { path, url } = useRouteMatch()
  const { companyId } = useParams()

  const api = useApi()

  const [company, companyQuery] = useReadData(() => {
    if (!companyId) return []
    return api.companies.company.get({ pathArgs: { companyId } })
  }, [companyId])

  const [verificationTypes] = useVerificationTypesQuery()

  const [permissions, permissionsQuery] = useReadData(() => {
    if (!companyId) return []
    return api.company.permissions.get({ pathArgs: { companyId } })
  }, [companyId])

  const formState = _.pick(company, ['legalName', 'domainName'])

  const send = async (company) => {
    await api.companies.company.put({
      pathArgs: { companyId },
      body: company,
    })

    companyQuery.refetch()
  }

  const updateCompany = (
    <Modal
      title='Edit Company'
      onSubmit={send}
      initialValue={formState}
      trigger
    >
      <Input formKey='legalName' />
      <Input formKey='domainName' />
    </Modal>
  )

  const items = [
    [getName(company) || 'Company', '', true],
    '/bootstrap',
    '/all-permissions',
    '/roles',
    '/config',
    {
      label: 'Types',
      items: [
        '/loan-types',
        '/case-types',
        '/workflow-types',
        '/workflow-step-types',
        '/verification-types',
      ],
    },
    {
      label: 'Records',
      items: ['/borrowers', '/users', '/employees', '/teams', '/investors'],
    },
    {
      label: 'Templates',
      items: ['/subjects', '/templates', '/descriptors', '/import-templates'],
    },
  ]

  const nav = <ListNav2 baseUrl={url} items={items} searchable />

  const { config, ...restCompany } = company || {}

  return (
    <Panels left={nav}>
      <LoadingContainer query={companyQuery}>
        <Switch>
          <Route path={`${path}`} exact>
            <Page.Full>
              {updateCompany}
              <ModelViewer json={restCompany} />
            </Page.Full>
          </Route>

          <Route path={`${path}/bootstrap`} exact crumb>
            <CompanyBootstrap />
          </Route>

          <Route path={`${path}/config`} exact crumb>
            <CompanyConfig />
          </Route>

          <Route path={`${path}/roles`} crumb>
            <CompanyRoles />
          </Route>

          <Route path={`${path}/all-permissions`} crumb>
            <LoadingContainer query={permissionsQuery}>
              <Page.Full>
                <Model.Item title='All Permissions' json={permissions} />
              </Page.Full>
            </LoadingContainer>
          </Route>

          <Route path={`${path}/loan-types`} crumb>
            <LoanTypesRoutes />
          </Route>

          <Route path={`${path}/case-types`} crumb>
            <ListSubroutes
              paramKey='caseTypeId'
              list={<CaseTypes />}
              item={<CaseType />}
            />
          </Route>

          <Route path={`${path}/workflow-types`} crumb>
            <ListSubroutes
              title='Workflow Types'
              paramKey='workflowTypeId'
              list={<WorkflowTypes />}
              item={<WorkflowType />}
              page
            />
          </Route>

          <Route path={`${path}/verification-types`} crumb>
            <ListSubroutes
              title='Verification Types'
              paramKey='verificationTypeId'
              json={verificationTypes}
              page
            />
          </Route>

          <Route path={`${path}/workflow-step-types`} crumb>
            <ListSubroutes
              title='Workflow Step Types'
              paramKey='workflowStepTypeId'
              list={<WorkflowStepTypes />}
              item={<WorkflowStepType />}
              page
            />
          </Route>

          <Route path={`${path}/investors`} crumb>
            <CompanyInvestors />
          </Route>

          <Route path={`${path}/borrowers`} exact crumb>
            <PeopleList />
          </Route>

          <Route path={`${path}/employees`} crumb>
            <CompanyEmployeesRoutes />
          </Route>

          <Route path={`${path}/teams`} crumb>
            <CompanyTeams />
          </Route>

          <Route path={`${path}/users`} crumb>
            <CompanyUsersRoutes />
          </Route>

          <Route path={`${path}/templates`} crumb>
            <CompanyTemplatesRoutes />
          </Route>

          <Route path={`${path}/subjects`} crumb>
            <TemplateSubjectRoutes />
          </Route>

          <Route path={`${path}/descriptors`} crumb>
            <CompanyDescriptors />
          </Route>

          <Route path={`${path}/import-templates`} crumb>
            <CompanyImportTemplates />
          </Route>

          <Redirect to={`${path}`} />
        </Switch>
      </LoadingContainer>
    </Panels>
  )
}

export default Company
