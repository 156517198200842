import { Page, Model, LoadingContainer, Panels, Bar } from 'peach/components'
import { useTemplateQuery, useTemplateDescriptorQuery } from 'peach/data'

import TemplateEditor from '../TemplateEditor/TemplateEditor'

const TemplateSubject = () => {
  const [template, tQuery] = useTemplateQuery()

  const [descriptor, dQuery] = useTemplateDescriptorQuery({
    templateDesriptorId: template?.descriptorId,
  })

  const onSaveTemplate = (newTemplateData, diff) => {
    console.log(newTemplateData, diff)
  }

  const editTemplate = template && descriptor && (
    <TemplateEditor
      descriptor={descriptor}
      template={template}
      onSaveTemplate={onSaveTemplate}
      triggerButtonLabel='Edit Template'
    />
  )

  return (
    <LoadingContainer queries={[tQuery, dQuery]}>
      <Panels header={<Bar title='Template' right={editTemplate} />}>
        <Page.Full>
          <Model.Item title='Template' json={template} />
          <Model.Item title='Descriptor' json={descriptor} noLink />
        </Page.Full>
      </Panels>
    </LoadingContainer>
  )
}

export default TemplateSubject
