import { useState } from 'react'

import { Input, Page, Stack, Form } from 'peach/components'

const ScratchContext = () => {
  const [val, setVal] = useState({
    upperValueA: 'A Value',
    upperValueB: 'B Value',
    nestedObjects: [
      {
        lowerValueC: 'C Value',
        aNestedObject: {
          lowestValueD: 'D Value',
          lowestValueE: 'E Value',
        },
      },
      {
        lowerValueC: 'C Value',
        aNestedObject: {
          lowestValueD: 'D Value',
          lowestValueE: 'E Value',
        },
      },
    ],
  })

  return (
    <Page.Full>
      <Stack>
        <Form.Provider value={val} onChange={setVal}>
          <Form.Row>
            <Input formKey='upperValueA' />
            <Input formKey='upperValueB' />
          </Form.Row>
          <Form.List formKey='nestedObjects'>
            <Input formKey='lowerValueC' />
            <Form.Provider formKey='aNestedObject' section>
              <Input formKey='lowestValueD' />
            </Form.Provider>
          </Form.List>
        </Form.Provider>
      </Stack>
    </Page.Full>
  )
}

export default ScratchContext
