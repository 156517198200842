import { Switch, Route, Redirect, useRouteMatch } from 'peach/router'

import CompanyUser from './CompanyUser'
import CompanyUsers from './CompanyUsers'

const CompanyUsersRoutes = () => {
  const { path, url } = useRouteMatch()

  return (
    <Switch>
      <Route path={path} exact>
        <CompanyUsers />
      </Route>

      <Route path={`${path}/:userId`}>
        <CompanyUser />
      </Route>

      <Redirect to={url} />
    </Switch>
  )
}

export default CompanyUsersRoutes
