import { useState } from 'react'

const useHasBlurred = ({ onBlur } = {}) => {
  const [hasBlurred, setHasBlurred] = useState(false)

  const handleBlur = (...args) => {
    if (!hasBlurred) setHasBlurred(true)
    if (onBlur) onBlur(...args)
  }

  return { hasBlurred, onBlur: handleBlur }
}

export default useHasBlurred
