import Select from 'peach/components/Select/Select'
import { usePersonCasesQuery } from 'peach/data'
import { getName } from 'peach/helpers'

const PersonCaseSelect = (props) => {
  const [cases, query] = usePersonCasesQuery()

  const options = _.map(cases, ($case) => {
    return { value: $case.id, label: getName($case) }
  })

  return <Select query={query} {...props} options={options} />
}

export default PersonCaseSelect
