import { LoadingContainer, Model, Page, Json } from 'peach/components'
import { useCaseTypesQuery } from 'peach/data'

const CaseTypes = () => {
  const [caseTypes, query] = useCaseTypesQuery()

  const columns = [
    {
      label: 'Custom Fields',
      render: (item) => {
        const fields = _.map(
          _.sortBy(item.customFieldSchemas, 'displayOrder'),
          (cf) => cf.name,
        )
        return <Json.List title='Custom Fields' json={fields} compact />
      },
    },
  ]

  return (
    <LoadingContainer query={query}>
      <Page.Full>
        <Model.List
          title='Case Types'
          json={caseTypes}
          columns={columns}
          withRelated
          withDetails
          withTimestamps
        />
      </Page.Full>
    </LoadingContainer>
  )
}

export default CaseTypes
