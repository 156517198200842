import { createContext, useContext } from 'react'

import makeStorage from './makeStorage'

const defaultStorage = makeStorage('__DEV_TOOLS__')

const StorageContext = createContext(defaultStorage)

const StorageProvider = ({ storage, children }) => {
  return (
    <StorageContext.Provider value={storage}>
      {children}
    </StorageContext.Provider>
  )
}

const useStorage = () => useContext(StorageContext)

export { StorageProvider, useStorage }
