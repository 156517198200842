import {
  LoadingContainer,
  Page,
  Model,
  Panels,
  Bar,
  Breadcrumb,
} from 'peach/components'
import { useLoanTypeDynamicFeeTypesQuery } from 'peach/data'
import { getName } from 'peach/helpers'
import { useParams, useRouteMatch } from 'peach/router'

const DynamicFeeTypes = () => {
  const { url } = useRouteMatch()
  const { dynamicFeeTypeId } = useParams()

  const [dynamicFeeType, loading, error] =
    useLoanTypeDynamicFeeTypesQuery(dynamicFeeTypeId)

  const name = getName(dynamicFeeType)

  const header = <Bar title={name} />

  return (
    <Panels header={header}>
      <Breadcrumb title={name} to={url} />

      <LoadingContainer loading={loading} error={error}>
        <Page.Centered>
          <Model.Item title={name} json={dynamicFeeType} />
        </Page.Centered>
      </LoadingContainer>
    </Panels>
  )
}

export default DynamicFeeTypes
