import { useState } from 'react'

import { FormProvider } from './FormProvider'

const Form = ({ children, value, onChange, initialValue }) => {
  const [localValue, setLocalValue] = useState(initialValue)

  const formValue = value ? value : localValue

  const handleChange = (newValue) => {
    if (onChange) onChange(newValue)
    setLocalValue(newValue)
  }

  return (
    <FormProvider value={formValue} onChange={handleChange}>
      {children}
    </FormProvider>
  )
}

export default Form
