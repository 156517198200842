const combine = (list, seperator = ' ') => {
  const $list = _.isArray(list) ? list : _.isString(list) ? [list] : []
  return _.compact($list).join(seperator).trim()
}

const formatAddress = (address = {}) => {
  const {
    addressLine1,
    addressLine2,
    city,
    countyOrRegion,
    postalCode,
    POBox,
    state,
    country,
  } = address

  return _.compact([
    combine([addressLine1, POBox]),
    combine([addressLine2]),
    combine([
      combine([city, state], ', '),
      postalCode,
      countyOrRegion,
      country,
    ]),
  ]).join('\n')
}

export default formatAddress
