import { useState, useEffect, useRef } from 'react'

import { useStableCallback, usePrevious } from 'peach/hooks'

const useAsyncOnChange = (props, delay = 250) => {
  const { value, onChange, onFocus, onBlur } = props || {}
  const [focus, setFocus] = useState(false)
  const [localValue, setLocalValue] = useState(value)

  const nextValRef = useRef(value)
  const delayRef = useRef()

  const handleFocus = (...args) => {
    setFocus(true)
    if (onFocus) onFocus(...args)
  }

  const handleChange = useStableCallback((val) => {
    nextValRef.current = val
    setLocalValue(val)
    clearTimeout(delayRef.current)
    delayRef.current = _.delay(() => onChange(nextValRef.current), delay)
  })

  const handleBlur = (...args) => {
    setFocus(false)
    clearTimeout(delayRef.current)
    if (nextValRef.current !== value) {
      onChange(nextValRef.current)
    }
    if (onBlur) onBlur(...args)
  }

  const prevValue = usePrevious(value, true)

  useEffect(() => {
    if (prevValue !== value && !focus) {
      setLocalValue(value)
      nextValRef.current = value
    }
  }, [value, prevValue, focus])

  if (delay) {
    return {
      value: localValue,
      onChange: handleChange,
      onFocus: handleFocus,
      onBlur: handleBlur,
    }
  }
  return { value, onChange, onFocus, onBlur }
}

export default useAsyncOnChange
