import { Input, Textarea, Select, Page, Form } from 'peach/components'
import { humanizeKey } from 'peach/helpers'

const methods = ['get', 'post', 'put', 'patch', 'delete']

const methodOptions = _.map(methods, (method) => {
  return { label: method.toUpperCase(), value: method }
})

const ApiCallForm = ({ value, onChange, pathArgsList, onSubmit }) => {
  const { method, pathArgs } = value || {}

  const onPathArgChange = (key, val) => {
    onChange({ ...value, pathArgs: { ...pathArgs, [key]: val } })
  }

  const hasBody = _.includes(['post', 'put', 'patch'], method)
  return (
    <Page.Full>
      <Form.Provider value={value} onChange={onChange}>
        <Select formKey='method' options={methodOptions} />
        {_.map(pathArgsList, (argKey) => {
          const value = pathArgs[argKey]
          const onChange = (val) => onPathArgChange(argKey, val)
          return (
            <Input
              key={argKey}
              label={humanizeKey(argKey)}
              value={value}
              onChange={onChange}
            />
          )
        })}
        <Textarea.Json formKey='queryParams' />
        {hasBody && <Textarea.Json formKey='body' />}
      </Form.Provider>
    </Page.Full>
  )
}

export default ApiCallForm
