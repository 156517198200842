import { Button } from 'peach/components'
import { humanizeKey } from 'peach/helpers'

import ModalErrorBar from '../Modal/ModalErrorBar'

import { FormProvider } from './FormProvider'
import FromSection from './FormSection'

const InlineForm = (props) => {
  const {
    title,
    children,
    formKey,
    value,
    onChange,

    otherActions,

    onSubmit,
    submitLabel,
    canSubmit,

    onCancel,
    canCancel,
    cancelLabel,

    error,
    onClearError,

    bare,
  } = props

  const displayTitle = title || humanizeKey(formKey)

  const rightActions = (
    <>
      <Button onClick={onCancel} disabled={!onCancel || canCancel === false}>
        {cancelLabel || 'Cancel'}
      </Button>
      <Button
        primary
        onClick={onSubmit}
        disabled={!onSubmit || canSubmit === false}
      >
        {submitLabel || 'Submit'}
      </Button>
    </>
  )

  return (
    <FormProvider formKey={formKey} value={value} onChange={onChange}>
      <FromSection
        bare={bare}
        title={displayTitle}
        leftActions={otherActions}
        rightActions={rightActions}
      >
        {error && <ModalErrorBar error={error} onClear={onClearError} />}
        {children}
      </FromSection>
    </FormProvider>
  )
}

export default InlineForm
