import { useAuthApi, useAuthState } from 'core/auth/AuthProvider'
import { useEnvApi } from 'core/env/useEnv'
import { Page, Button, Json, Markdown } from 'peach/components'
import { useHistory } from 'peach/router'

import EnvTable from '../env/EnvTable'

import environments from './environments.md'

const Environments = () => {
  const history = useHistory()
  const goToApp = () => history.push('/')

  const { envs, env, activeId } = useEnvApi()
  const authState = useAuthState()
  const { showAuth } = useAuthApi()

  const { isLoggedIn } = authState

  return (
    <Page.Centered>
      {_.map(envs, (env) => (
        <EnvTable key={env.id} env={env} isActive={env.id === activeId} />
      ))}
      {_.isEmpty(envs) ? (
        <Markdown url={environments} />
      ) : (
        <Json.Table title='Current Auth State' json={authState} />
      )}

      {isLoggedIn ? (
        <Button onClick={goToApp}>Go To App</Button>
      ) : env ? (
        <Button onClick={showAuth}>Login</Button>
      ) : null}
    </Page.Centered>
  )
}

export default Environments
