import {
  Bar,
  LoadingContainer,
  Panels,
  Breadcrumb,
  ListNav2,
} from 'peach/components'
import { useLoanQuery, useRefreshAllLoanData } from 'peach/data'
import { useClickAction } from 'peach/decorators/withActionTrigger'
import { getName } from 'peach/helpers'
import { useApi } from 'peach/hooks'
import { Switch, Route, useRouteMatch } from 'peach/router'
import { useLoanId } from 'peach/scopes/loan'
import { usePersonId } from 'peach/scopes/person'

import LoanDrawRoutes from './LoanDraws/LoanDrawRoutes'
import LoanRoutes from './LoanRoutes'
import LoanTransaction from './LoanTransaction'

const Loan = () => {
  const api = useApi()
  const loanId = useLoanId()
  const [loan, query] = useLoanQuery()

  const { url, path } = useRouteMatch()
  const refreshALlLoanData = useRefreshAllLoanData()

  const loanName = getName(loan)

  const title = loanName || (query?.isLoading ? 'Loading…' : loanId)

  const personId = usePersonId()

  const syncLoan = async () => {
    await api.loan.refresh.post({ pathArgs: { personId, loanId } })
    _.defer(() => refreshALlLoanData(), 1000)
  }

  useClickAction({
    label: 'Refresh All Loan Data',
    onClick: refreshALlLoanData,
  })

  useClickAction({
    label: 'Sync Loan',
    onClick: syncLoan,
  })

  const header = <Bar title={title} />

  const isLOC = loan?.type === 'lineOfCredit'

  const items = _.compact([
    ['Loan', '', true],
    '/timeline',
    '/rates',
    '/transactions',
    '/expected-payments',
    '/obligations',
    '/balances',
    isLOC && '/draws',
    '/refunds',
    '/fees',
    '/documents',
    '/statements',
    '/periods',
    '/autopay',
    '/ledger',
    '/advances',
  ])

  const nav = <ListNav2 items={items} baseUrl={url} searchable />

  return (
    <>
      <Breadcrumb title={loanName} to={url} />
      <Switch>
        <Route path={`${path}/draws/:drawId`}>
          <Breadcrumb to={`${url}/draws`} />
          <LoanDrawRoutes />
        </Route>
        <Route>
          <Panels left={nav}>
            <Switch>
              <Route path={`${path}/transactions/:transactionId`}>
                <Breadcrumb to={`${url}/transactions`} />
                <LoanTransaction />
              </Route>
              <Route path={path}>
                <Panels header={header}>
                  <LoadingContainer query={query}>
                    <LoanRoutes />
                  </LoadingContainer>
                </Panels>
              </Route>
            </Switch>
          </Panels>
        </Route>
      </Switch>
    </>
  )
}

export default Loan
