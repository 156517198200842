import { Switch, Redirect, Route, useRouteMatch } from 'peach/router'

import PersonContact from './PersonContact'
import PersonContacts from './PersonContacts'

const PersonContactRoutes = () => {
  const { path, url } = useRouteMatch()

  return (
    <Switch>
      <Route path={path} exact>
        <PersonContacts />
      </Route>
      <Route path={`${path}/:contactId`}>
        <PersonContact />
      </Route>
      <Redirect to={url} />
    </Switch>
  )
}

export default PersonContactRoutes
