import { useState } from 'react'

import { useOnChangeEffect } from 'peach/hooks'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  line-height: 1;
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 3px;
  padding: 2px;
  background-color: ${(p) => p.theme.background};
`

const Label = styled.span`
  line-height: 1;
  display: inline-block;
  padding: 2px 8px;
  font-size: 12px;
  color: ${(p) => p.theme.mutedText};
`

const Input = styled.input`
  width: 60px;
  text-align: center;
  border-radius: 3px;
  border: 1px solid transparent;
  background-color: ${(p) => p.theme.background};
  color: ${(p) => p.theme.text};
  width: ${(p) => p.width};
  background-color: ${(p) => p.theme.background};

  &:disabled {
    color: #888;
    border-color: ${(p) => p.theme.border};
    background-color: ${(p) => p.theme.offset};
  }
  &:focus {
    border-color: ${(p) => p.theme.highlight};
    box-shadow: none;
  }
`

const Button = styled.button`
  line-height: 1;
  border: none;
  background-color: transparent;
  cursor: default;
  padding: 4px 8px;
  border-radius: 3px;
  color: ${(p) => p.theme.mutedText};
  &:disabled {
    color: ${(p) => p.theme.disabledText};
  }
  &:not(:disabled):hover {
    background-color: ${(p) => p.theme.hover};
    color: ${(p) => p.theme.text};
    cursor: pointer;
  }
`

const Spacer = styled.span`
  display: inline-block;
  width: 4px;
`

const PaginationInputControl = (props) => {
  const {
    caption,
    totalPages,
    page,
    onPageChange,
    onRefresh,
    loading,
    disabled,
    showSkip,
  } = props

  const passedValue = _.isFinite(page) ? `${page}` : ''

  const [focused, setFocused] = useState(false)

  const [localPage, setLocalPage] = useState(passedValue)

  const value = focused ? localPage : passedValue

  const handleChange = (str) => {
    const num = parseInt(str)

    if (str === '') {
      setLocalPage('')
    } else if (_.isFinite(num) && `${num}` === str) {
      setLocalPage(str)
    }
  }

  useOnChangeEffect(() => {
    if (!focused) setLocalPage(passedValue)
  }, [passedValue])

  const handleBlur = () => {
    const num = parseInt(localPage)

    const newValue = Math.max(Math.min(num, totalPages), 1)

    setFocused(false)
    setLocalPage(`${newValue}`)

    _.defer(() => onPageChange(newValue))
  }

  const disablePrev = disabled || page <= 1

  const disableNext = disabled || page >= totalPages

  return (
    <Wrapper>
      <Button disabled={disablePrev} onClick={() => onPageChange(1)}>
        {'❮❮'}
      </Button>

      <Spacer />

      <Button disabled={disablePrev} onClick={() => onPageChange(page - 1)}>
        {'❮'}
      </Button>

      <Spacer />

      <Input
        value={value}
        onChange={(evt) => handleChange(evt.target.value)}
        onFocus={() => setFocused(true)}
        onBlur={handleBlur}
      />

      <Spacer />

      <Button disabled={disableNext} onClick={() => onPageChange(page + 1)}>
        {'❯'}
      </Button>

      <Spacer />

      {showSkip && (
        <Button disabled={disableNext} onClick={() => onPageChange(totalPages)}>
          {'❯❯'}
        </Button>
      )}

      <Spacer />

      {onRefresh && (
        <>
          <Button disabled={loading} onClick={onRefresh}>
            {'↻'}
          </Button>
          <Spacer />
        </>
      )}

      <Spacer />

      <Label>{caption}</Label>
    </Wrapper>
  )
}

export default PaginationInputControl
