import { useEffect } from 'react'

import { useAuthState } from 'core/auth/AuthProvider'

import usePrevious from '../usePrevious/usePrevious'

import useRead from './useRead'

const useAuthenticatedRead = (promiseFn, deps) => {
  const { isRenewing, isLoggingIn } = useAuthState()

  const readArray = useRead(promiseFn, deps)

  const [, , error, refresh] = readArray

  const errorStatus = error?.status

  const isGettingNewSession = isRenewing || isLoggingIn

  const previousIsGettingNewSession = usePrevious(isGettingNewSession)

  useEffect(() => {
    if (previousIsGettingNewSession && !isGettingNewSession) {
      if (errorStatus === 401) {
        refresh()
      }
    }
  }, [previousIsGettingNewSession, isGettingNewSession, errorStatus, refresh])

  return readArray
}

export default useAuthenticatedRead
