import { css } from 'styled-components/macro'

const headerItemStyle = css`
  padding: 4px 8px;
  box-shadow: none;
  border-radius: 3px;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  color: ${(p) => p.theme.text};
  text-decoration: none;
  white-space: pre;

  &:hover {
    background-color: ${(p) => p.theme.hover};
  }

  &[aria-expanded],
  &.active {
    background-color: ${(p) => p.theme.highlight};
    color: ${(p) => p.theme.invertedText};
  }
`

export { headerItemStyle }
