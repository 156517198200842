import { Switch, Route, Redirect, useRouteMatch, useParams } from 'peach/router'

import PersonContactRoutes from './contacts/PersonContactRoutes'
import DocumentRoutes from './documents/DocumentRoutes'
import PaymentInstrumentRoutes from './PaymentInstruments/PaymentInstrumentRoutes'
import PersonCasesRoutes from './PersonCases/PersonCasesRoutes'
import PersonConsents from './PersonConsents'
import PersonInteractions from './PersonInteractions'
import PersonLoans from './PersonLoans'
import PersonRecord from './PersonRecord'

const PersonRoutes = ({ person, loans }) => {
  const { path, url } = useRouteMatch()
  const { personId } = useParams()

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <PersonRecord />
      </Route>

      <Route path={`${path}/loans`} exact crumb>
        <PersonLoans person={person} loans={loans} personId={personId} />
      </Route>

      <Route path={`${path}/consents`} crumb>
        <PersonConsents person={person} personId={personId} />
      </Route>

      <Route path={`${path}/contacts`} crumb>
        <PersonContactRoutes />
      </Route>

      <Route path={`${path}/payment-instruments`} crumb>
        <PaymentInstrumentRoutes />
      </Route>

      <Route path={`${path}/cases`} crumb>
        <PersonCasesRoutes />
      </Route>

      <Route path={`${path}/documents`} crumb>
        <DocumentRoutes />
      </Route>

      <Route path={`${path}/interactions`} crumb>
        <PersonInteractions />
      </Route>

      <Redirect to={`${url}`} />
    </Switch>
  )
}

export default PersonRoutes
