import { useState } from 'react'

import { QueryClient, QueryClientProvider } from 'react-query'

const MINUTE = 60 * 1000

const getQueryClient = () => {
  const options = {
    defaultOptions: {
      queries: {
        staleTime: 1 * MINUTE,
        retry: 0,
      },
    },
  }

  return new QueryClient(options)
}

const QueryProvider = ({ children }) => {
  const [queryClient] = useState(() => getQueryClient())

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  )
}

export default QueryProvider
