import styled from 'styled-components/macro'

const Group = styled.div`
  padding-bottom: 4px;
`

const Label = styled.div`
  font-weight: bold;
  font-size: 12px;
  padding: 8px;
  color: ${(p) => p.theme.text};
`

const Message = styled.div`
  color: ${(p) => p.theme.mutedText};
`

const SearchResultsGroup = ({ label, loading, children }) => {
  return (
    <Group>
      <Label>{label}</Label>
      {_.isEmpty(children) && loading ? (
        <Message>Loading...</Message>
      ) : (
        children
      )}
    </Group>
  )
}

export default SearchResultsGroup
