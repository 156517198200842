import { Model, Page, LoadingContainer } from 'peach/components'
import { useLoanPeriodsQuery } from 'peach/data'
import { useRouteMatch, Switch, Route, Redirect } from 'peach/router'

import LoanPeriod from './LoanPeriod'

const LoanPeriods = () => {
  const { url, path } = useRouteMatch()

  const [periods, query] = useLoanPeriodsQuery()

  return (
    <LoadingContainer query={query}>
      <Switch>
        <Route path={`${path}/:periodId`} crumb>
          <LoanPeriod />
        </Route>

        <Route path={path} exact>
          <Page.Full>
            <Model.List json={periods} />
          </Page.Full>
        </Route>

        <Redirect to={url} />
      </Switch>
    </LoadingContainer>
  )
}

export default LoanPeriods
