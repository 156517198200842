import getNodes from './getNodes'

const focusable =
  'a, button, input, textarea, select, details,[tabindex]:not([tabindex="-1"])'

const getList = (el) => {
  return el?.querySelectorAll ? [...el.querySelectorAll(focusable)] : []
}

const getFocusableElements = (...elementsOrRefs) => {
  const nodes = getNodes(elementsOrRefs)

  return _.compact(
    _.flatten(
      _.map(nodes, (parentElement) => {
        const self = parentElement?.matches?.(focusable)
          ? parentElement
          : undefined

        const list = _.compact([self, ...getList(parentElement)])

        return _.reject(list, (el) => {
          if (!el) return true
          if (!el.hasAttribute) return true
          if (el.hasAttribute('disabled')) return true
        })
      }),
    ),
  )
}

export default getFocusableElements
