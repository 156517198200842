const defaultDraw = {
  metaData: {},
  nickname: '',
  status: 'pending',
  atOrigination: {
    interestRates: [
      {
        days: 30,
        rate: 0.1,
      },
    ],
    minPaymentCalculation: {
      percentageOfPrincipal: 0,
      minAmount: 0,
    },
    autoAmortization: {
      amortizationLogic: 'amortizeAfterEachPurchase',
      duration: 0,
      periodicPaymentAmount: 0,
    },
  },
}

export { defaultDraw }
