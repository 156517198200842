const formatMilliseconds = (duration) => {
  if (!_.isFinite(duration)) return undefined

  const seconds = Math.floor((duration / 1000) % 60)
  const minutes = Math.floor((duration / (1000 * 60)) % 60)
  const hours = Math.floor((duration / (1000 * 60 * 60)) % 24)
  const days = Math.floor(duration / (1000 * 60 * 60 * 24))

  const $hours = hours < 10 ? '0' + hours : hours
  const $minutes = minutes < 10 ? '0' + minutes : minutes
  const $seconds = seconds < 10 ? '0' + seconds : seconds

  return `${days} days ${$hours} hours ${$minutes} minutes ${$seconds} seconds`
}

export default _.memoize(formatMilliseconds)
