import { useDataQuery, useQueryApi } from '../useQueryApi'

const useTemplateQuery = (options) => {
  const [api, { companyId, templateId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'templates', templateId],

    queryFn: () => {
      return api.communicator.template.get({
        pathArgs: { templateId },
        queryParams: { companyId },
      })
    },
  })
}

export default useTemplateQuery
