import { withLabel, withFormValue } from 'peach/decorators'

import { RawSingleSelect } from './SingleSelect'

const options = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
]

const BooleanSelect = ({ onChange, value, placeholder, label, ...rest }) => {
  const $value = value === true ? 'true' : value === false ? 'false' : null

  const handleChange = (value) => {
    onChange(value === 'true' ? true : value === 'false' ? false : null)
  }

  const $placeholder = placeholder || `Select${label ? ` ${label}` : ''}…`

  return (
    <RawSingleSelect
      {...rest}
      value={$value}
      onChange={handleChange}
      options={options}
      label={label}
      placeholder={$placeholder}
    />
  )
}

export default withFormValue(withLabel(BooleanSelect))
