const STATUSES_IN_PRIORITY_ORDER = ['error', 'loading', 'success', 'idle']

const combineStatues = (statuses) => {
  for (const status of STATUSES_IN_PRIORITY_ORDER) {
    if (_.includes(statuses, status)) return status
  }
  return 'idle'
}

const list = (key) => (queries) => _.map(queries, key)

const combineFn = (key) => (queries) => () =>
  _.each(queries, (query) => query[key]())

const any = (key) => (queries) => _.some(queries, key)
const all = (key) => (queries) => _.every(queries, key)
const earliest = (key) => (queries) => _.first(_.map(queries, key))
const latest = (key) => (queries) => _.last(_.map(queries, key))
const first = (key) => (queries) => _.first(_.compact(_.map(queries), key))
const combineStatus = (key) => (queries) => combineStatues(_.map(queries, key))

const sum = (key) => (queries) => {
  return _.reduce(_.map(queries, key), (tot, i) => tot + i, 0)
}

const combineFns = {
  data: list,
  dataUpdatedAt: earliest,
  error: first,
  errorUpdatedAt: latest,
  failureCount: sum,
  isError: any,
  isFetched: all,
  isFetchedAfterMount: any,
  isFetching: any,
  isIdle: all,
  isLoading: any,
  isLoadingError: any,
  isPlaceholderData: all,
  isPreviousData: any,
  isRefetchError: any,
  isStale: any,
  isSuccess: all,
  refetch: combineFn,
  remove: combineFn,
  status: combineStatus,
}

const combineQueries = (...queries) => {
  return _.mapValues(combineFns, (fn, key) => fn(key)(queries))
}

export default combineQueries
