import { parseError } from 'peach/helpers'
import styled from 'styled-components/macro'

import Spinner from './Spinner'

const Wrapper = styled.div`
  height: 100%;
  position: relative;
`

const Message = styled.span``

const parseProps = (props) => {
  const { loading, error, query, queries } = props || {}
  return {
    loading: loading || query?.isLoading || _.some(queries, 'isLoading'),
    error: error || query?.error || _.first(_.compact(_.map(queries, 'error'))),
  }
}

const LoadingPlaceholder = (props) => {
  const { loading, error } = parseProps(props)

  const errorMessage = parseError(error)
  return loading ? (
    <Wrapper>
      <Spinner size={50} />
    </Wrapper>
  ) : error ? (
    <Wrapper>
      <Message>{errorMessage}</Message>
    </Wrapper>
  ) : null
}

export default LoadingPlaceholder
