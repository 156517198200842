import { FC } from 'react'

import { LoadingContainer } from 'peach/components'
import { StyledButton } from 'peach/components/Button/Button'
import Input from 'peach/components/Input'
import SelectInput from 'peach/components/SelectInput'
import { useApi, useWrite } from 'peach/hooks'
import { FormProvider, useForm } from 'react-hook-form'
import * as R from 'remeda'

import { Error, FormActions, Title } from './Components'
import { Company } from './types'
import { booleanOptions } from './utils'

type CreateInvestorFormValues = {
  legalName?: string
  default?: boolean
}

type CreateInvestorFormProps = {
  company: Company
  updateCompany: (obj: Company) => void
  title: string
}

const CreateInvestorForm: FC<CreateInvestorFormProps> = ({
  company,
  updateCompany,
  title,
}) => {
  const api: any = useApi()

  const form = useForm<CreateInvestorFormValues>({
    values: {
      legalName:
        company?.legalName ?? company?.name ?? company?.brandName ?? '',
      default: true,
    },
  })

  const [onCreate, isPending, , error, clear] = useWrite(async (values) => {
    const resp = await api.company.investors.post({
      body: values,
      pathArgs: { companyId: company?.companyId },
    })

    if (resp?.status === 201 && !R.isEmpty(resp?.data)) {
      updateCompany({
        ...company,
        investors: [
          ...(company?.investors ?? []),
          {
            id: resp?.data?.id,
            legalName: resp?.data?.legalName,
            default: resp?.data?.default,
          },
        ],
      })
    }

    return resp
  })

  return (
    <LoadingContainer loading={isPending}>
      <Title>{title}</Title>
      <form
        onSubmit={form.handleSubmit(async (values) => {
          try {
            clear()
            await onCreate(values)
          } catch (e) {
            console.log('Error with create actions: ', e)
          }
        })}
      >
        <FormProvider {...form}>
          <Input
            type='text'
            label='Legal Name'
            required
            {...form.register('legalName')}
          />

          <SelectInput
            control={form.control}
            name='default'
            options={booleanOptions}
            placeholder='Select true/false'
            label='Set Lender As Default Investor'
          />

          {error && <Error>{error.message}</Error>}
          <FormActions>
            <StyledButton type='submit' primary>
              Submit
            </StyledButton>
          </FormActions>
        </FormProvider>
      </form>
    </LoadingContainer>
  )
}

export default CreateInvestorForm
