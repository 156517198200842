import { useRef } from 'react'

const useStableResult = (...args) => {
  const resultRef = useRef([])
  _.each(args, (value, index) => {
    resultRef.current[index] = value
  })
  return resultRef.current
}

export default useStableResult
