import { useSessionCompaniesWithPermissions } from 'peach/data'

import ModelLink from './ModelLink'

const CompanyLink = ({ companyId, jsonKey }) => {
  const [companies, query] = useSessionCompaniesWithPermissions()

  const company = _.find(companies, { id: companyId })

  return (
    <ModelLink id={companyId} model={company} query={query} jsonKey={jsonKey} />
  )
}

export default CompanyLink
