import {
  LoadingContainer,
  Modal,
  Input,
  Select,
  Page,
  Form,
  Model,
} from 'peach/components'
import { useReadData, useApi } from 'peach/hooks'
import { useParams } from 'peach/router'
import { CompanyRolesSelect } from 'peach/scopes/company'

const CompanyUsers = () => {
  const { companyId } = useParams()

  const api = useApi()

  const [users, loading, error, refresh] = useReadData(() => {
    // todo: change to getALl() when API is updated
    return api.company.users.get({
      pathArgs: { companyId },
      queryParams: { limit: 100, userType: 'agent' },
    })
  }, [companyId])

  const groupedUsers = _.groupBy(users, 'type')

  const sendAddUser = async (data) => {
    const { authorizationType, ...user } = data
    await api.company.users.post({ pathArgs: { companyId }, body: user })
    refresh()
  }

  const addUser = (
    <Modal trigger title='Add User' onSubmit={sendAddUser}>
      <Select formKey='userType' options={['service', 'borrower', 'agent']} />
      <Select formKey='authorizationType' options={['emailPassword', 'saml']} />

      <Form.If if={{ authorizationType: 'emailPassword' }}>
        <Form.Provider formKey='authType' section>
          <Input formKey='email' />
          <Input formKey='password' />
        </Form.Provider>
      </Form.If>

      <Form.If if={{ authorizationType: 'saml' }}>
        <Form.Provider formKey='authType' section>
          <Input formKey='samlSubjectNameId' />
        </Form.Provider>
      </Form.If>

      <CompanyRolesSelect formKey='roles' />

      <Form.If if={{ userType: 'borrower' }}>
        <Input formKey='associatedPersonId' />
      </Form.If>
    </Modal>
  )

  return (
    <LoadingContainer loading={loading} error={error}>
      {addUser}
      <Page.Full>
        {_.map(groupedUsers, (group, type) => {
          const $type = _.str.titleize(type)
          return <Model.List key={type} title={`${$type} Users`} json={group} />
        })}
      </Page.Full>
    </LoadingContainer>
  )
}

export default CompanyUsers
