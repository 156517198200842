import {
  LoadingContainer,
  ModelViewer,
  Modal,
  Panels,
  Bar,
  Select,
  Breadcrumb,
  Page,
} from 'peach/components'
import { useLoanTransactionsQuery } from 'peach/data'
import { getName } from 'peach/helpers'
import { useApi } from 'peach/hooks'
import { useParams, useRouteMatch } from 'peach/router'
import { useLoanId } from 'peach/scopes/loan'
import { usePersonId } from 'peach/scopes/person'

const LoanTransaction = () => {
  const personId = usePersonId()
  const loanId = useLoanId()
  const { url } = useRouteMatch()
  const { transactionId } = useParams()

  const api = useApi()

  const [transactions, query] = useLoanTransactionsQuery()

  const refresh = () => query.refetch()

  const transaction = _.find(transactions, { id: transactionId })

  const sendEditTransaction = async (updatedTransaction, diff) => {
    await api.loan.transaction.put({
      pathArgs: { personId, loanId, transactionId },
      body: diff,
    })
    refresh()
  }

  const statuses = [
    'scheduled',
    'initiated',
    'pending',
    'succeeded',
    'failed',
    'canceled',
    'inDispute',
    'chargeback',
  ]

  const editTransaction = (
    <Modal
      trigger
      title='Edit Transaction'
      onSubmit={sendEditTransaction}
      initialValue={transaction}
    >
      <Select formKey='status' options={statuses} />
    </Modal>
  )
  const name = getName(transaction) || 'Transaction'

  const header = <Bar title={name} right={editTransaction} />

  return (
    <Panels header={header}>
      <Breadcrumb to={url} title={name} />
      <LoadingContainer query={query}>
        <Page.Full>
          <ModelViewer title='Transaction' json={transaction} />
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default LoanTransaction
