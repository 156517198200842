import styled from 'styled-components/macro'

const StyledDivider = styled.hr`
  margin: ${(p) => p.$margin};
  border: none;
  background-color: #ccc;
  height: 1px;
`

const Divider = ({ margin = '0' }) => {
  return <StyledDivider $margin={margin} />
}

export default Divider
