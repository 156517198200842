const getToday = () => {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
  const yyyy = today.getFullYear()

  return `${yyyy}-${mm}-${dd}`
}

const defaultPurchase = {
  purchaseDate: getToday(),
  type: 'regular',
  purchaseDetails: {
    metadata: {},
  },
  metadata: {},
}

export { defaultPurchase }
