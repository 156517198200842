import { Breadcrumb } from 'peach/components'
import { useParams } from 'peach/router'
import { PersonManager, PersonBreadcrumb } from 'peach/scopes/person'

import Loan from './Loan'

const LoanView = () => {
  const { companyId, personId } = useParams()

  return (
    <PersonManager personId={personId}>
      <Breadcrumb title='Borrowers' to={`/companies/${companyId}/borrowers`} />
      <PersonBreadcrumb
        personId={personId}
        to={`/companies/${companyId}/borrowers/${personId}`}
      />
      <Breadcrumb
        title='Loans'
        to={`/companies/${companyId}/borrowers/${personId}/loans`}
      />

      <Loan />
    </PersonManager>
  )
}

export default LoanView
