import { useDataQuery, useQueryApi } from '../useQueryApi'

const useDocumentsQuery = (props) => {
  const { includeArchived, uploadedBy, ...rest } = props || {}
  const [api, { personId, loanId }] = useQueryApi(rest)

  return useDataQuery({
    queryKey: [
      'people',
      personId,
      'documents',
      _.pickBy({ includeArchived, uploadedBy, loanId }),
    ],

    queryFn: async () => {
      return api.person.documents.get({
        pathArgs: { personId },
        queryParams: { includeArchived, uploadedBy, loanId },
        // note that loanId is passed as optional query param to this API
      })
    },

    enabled: !!personId,
  })
}

export default useDocumentsQuery
