import { useCallback, useState, useDebugValue } from 'react'

const useToggle = (val) => {
  const propValue = _.isFunction(val) ? val : !!val

  const [value, setValue] = useState(propValue)

  const setTrue = useCallback(() => setValue(true), [])
  const setFalse = useCallback(() => setValue(false), [])
  const toggle = useCallback(() => setValue((value) => !value), [])

  useDebugValue(value ? 'On' : 'Off')

  return [value, setTrue, setFalse, toggle, setValue]
}

export default useToggle
