import ApiRoutes from 'pages/apiCalls/ApiRoutes'
import Home from 'pages/home/Home'
import { Switch, Route } from 'peach/router'

import CompaniesRoutes from './CompaniesRoutes'

// Routes which do not have the base home navigation

const AppRoutes = () => {
  return (
    <Switch>
      <Route path='/companies/:companyId'>
        <CompaniesRoutes />
      </Route>

      <Route path='/api' crumb title='API'>
        <ApiRoutes />
      </Route>

      <Route>
        <Home />
      </Route>
    </Switch>
  )
}

export default AppRoutes
