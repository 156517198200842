const EDGE_GAP = 24 // 8

const opensUp = (tRect) => {
  const spaceAbove = tRect.top
  const spaceBelow = window.innerHeight - tRect.bottom
  return spaceAbove > 2 * (spaceBelow - 50)
}

const opensLeft = (tRect) => {
  const spaceLeft = tRect.left
  const spaceRight = window.innerWidth - tRect.right
  return spaceLeft > 2 * (spaceRight - 50)
}

const getTopPosition = (tRect) => {
  if (opensUp(tRect)) {
    const tRectBottom = window.innerHeight - tRect.bottom
    const bottom = tRectBottom - window.pageYOffset - 1
    const maxHeight = window.innerHeight - tRectBottom - EDGE_GAP
    return {
      bottom: `${bottom}px`,
      maxHeight: `${maxHeight}px`,
    }
  } else {
    const top = tRect.top + tRect.height + window.pageYOffset - 1

    const maxHeight = window.innerHeight - tRect.top - tRect.height - EDGE_GAP

    return {
      top: `${top}px`,
      maxHeight: `${maxHeight}px`,
    }
  }
}

const getLeftPosition = (tRect) => {
  const minWidth = tRect.width

  if (opensLeft(tRect)) {
    const right = window.innerWidth - tRect.right
    const spaceRemaining = tRect.left + tRect.width - EDGE_GAP
    const maxWidth = Math.max(minWidth, spaceRemaining)
    return {
      right: `${right}px`,
      minWidth: `${minWidth}px`,
      maxWidth: `${maxWidth}px`,
    }
  } else {
    const left = tRect.left
    const spaceRemaining = window.innerWidth - tRect.left - EDGE_GAP
    const maxWidth = Math.max(minWidth, spaceRemaining)

    return {
      left: `${left}px`,
      minWidth: `${minWidth}px`,
      maxWidth: `${maxWidth}px`,
    }
  }
}

const positionMatchWidthMaxHeight = (tRect, pRect) => {
  if (!tRect) return {}

  return { ...getLeftPosition(tRect), ...getTopPosition(tRect) }
}

export { positionMatchWidthMaxHeight }
