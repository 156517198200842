import { useState } from 'react'

import {
  Input,
  Page,
  Stack,
  NumericInput,
  Json,
  Textarea,
  Form,
} from 'peach/components'

const ScratchForm = () => {
  const [number, setNumber] = useState(null)

  const [val, setVal] = useState({
    stringValue: 'a string',
    numericValue: 12,
    initallyEmptyNumericValue: null,
    anObject: { aNestedString: 'Hello' },
  })

  return (
    <Page>
      <Stack>
        <Json.Table title='Numeric Value' json={{ number }} />

        <NumericInput
          value={number}
          onChange={setNumber}
          label='Numeric Value'
          nullable
        />
        <Json.Table title='Form Value' json={val} />
        <Form.Provider value={val} onChange={setVal}>
          <Input
            formKey='stringValue'
            nullable
            onValidate={({ value }) => {
              return value !== 'HELLO' && { errorMessage: `must be 'HELLO'` }
            }}
          />

          <Form.Row>
            <NumericInput formKey='numericValue' />
            <NumericInput formKey='initallyEmptyNumericValue' />
          </Form.Row>
          <Input
            formKey='stringValue'
            required
            note='this is a duplicate formkey (which will cause other parts of the form to be spookily updated)'
          />
          <Form.Provider formKey='anObject' section>
            <Input formKey='aNestedString' required />
            <Textarea formKey='aSecondNestedString' required />
          </Form.Provider>
          <Textarea.Json formKey='anObject' />
          <Form.Debugger />
        </Form.Provider>
      </Stack>
    </Page>
  )
}

export default ScratchForm
