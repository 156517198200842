const planets = [
  'Mercury',
  'Venus',
  'Earth',
  'Mars',
  'Jupiter',
  'Saturn',
  'Urinus',
  'Neptune',
  'Pluto',
]

const banks = _.map(planets, (planet) => `Bank of ${planet}`)

const defaultInstallmentLoan = {
  type: 'installment',
  loanTypeId: null,
  servicedBy: 'creditor',
  status: 'originated',
  atOrigination: {
    amountFinanced: _.random(2, 200) * 100,
    duration: _.random(1, 6) * 6,
    originationLicense: 'nationalBank',
    originatingCreditorName: _.sample(banks),
    interestRates: [
      {
        days: null,
        rate: 0.145,
      },
    ],
    aprNominal: 0.2,
    aprEffective: 0.2,
    paymentFrequency: 'monthly',
    originationFeeAmount: 0,
    personAddressId: null,
  },
}

const defaultLineOfCreditLoan = {
  type: 'lineOfCredit',
  loanTypeId: null,
  servicedBy: 'creditor',
  status: 'pending',
  atOrigination: {
    // amountFinanced: _.random(2, 200) * 100,
    // duration: _.random(1, 6) * 6,
    originationLicense: 'nationalBank',
    interestRates: [
      {
        days: null,
        rate: 0.145,
      },
    ],
    // aprNominal: 0.2,
    // aprEffective: 0.2,
    paymentFrequency: 'monthly',
    // specificDays: [7],
    originatingCreditorName: 'Bank of Mars', //+
    // originationFeeAmount: 0,
    personAddressId: null,
    creditLimitAmount: 5000,
  },
}

const exampleLineOfCreditLoan = {
  type: 'lineOfCredit',
  servicedBy: 'creditor',
  status: 'originated',
  newDrawsAllowed: true,
  atOrigination: {
    interestRates: [
      {
        days: 60,
        rate: 0.05,
      },
      {
        days: null,
        rate: 0.1,
      },
    ],
    paymentFrequency: 'monthly',
    originationLicense: 'nationalBank',
    originatingCreditorName: 'Bank of Mars',
    creditLimitAmount: 5000,
  },
}

export {
  defaultInstallmentLoan,
  defaultLineOfCreditLoan,
  exampleLineOfCreditLoan,
}
