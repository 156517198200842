import useSessionCompaniesQuery from './useSessionCompaniesQuery'
import useSessionPermissionsQuery from './useSessionPermissionsQuery'

// const properties = [
//   'data',
//   'error',
//   'failureCount',
//   'isError',
//   'isFetchedAfterMount',
//   'isFetching',
//   'isIdle',
//   'isLoading',
//   'isPreviousData',
//   'isStale',
//   'isSuccess',
//   'refetch',
//   'remove',
//   'status',
// ]

const useSessionCompaniesWithPermissions = (permissionKeys) => {
  const keys = _.isString(permissionKeys)
    ? [permissionKeys]
    : _.isArray(permissionKeys)
    ? permissionKeys
    : []

  const [companies, companiesQuery] = useSessionCompaniesQuery()

  const [permissions, permissionsQuery] = useSessionPermissionsQuery()

  const permittedCompanies = _.filter(companies, (company) => {
    return _.every(keys, (permission) => {
      const permissionsList = permissions?.[company.id]
      return _.includes(permissionsList, permission)
    })
  })

  const combinedQuery = {
    data: permittedCompanies,
    isLoading: companiesQuery.isLoading || permissionsQuery.isLoading,
  }

  return [permittedCompanies, combinedQuery]
}

export default useSessionCompaniesWithPermissions
