import {
  LoadingContainer,
  ModelList,
  Form,
  Input,
  Bar,
  Panels,
  Select,
  Modal,
  Page,
  StateCodeSelect,
  CountryCodeSelect,
} from 'peach/components'
import { useContactsQuery } from 'peach/data'
import { humanizeKey } from 'peach/helpers'
import { useApi } from 'peach/hooks'
import { useParams } from 'peach/router'

const PersonContacts = () => {
  const api = useApi()
  const { personId } = useParams()

  const [contacts, query] = useContactsQuery()

  const send = async (newContact) => {
    await api.person.contacts.post({
      pathArgs: { personId },
      body: newContact,
    })
    query.refetch()
  }

  const createContact = (
    <Modal trigger title='Create Contact' onSubmit={send} initialValue={{}}>
      <Select
        formKey='status'
        options={['primary', 'secondary', 'additional', 'archived']}
      />
      <Select
        formKey='affiliation'
        options={[
          'self',
          'spouse',
          'parent',
          'guardian',
          'executor',
          'administrator',
          'otherRelative',
          'legal',
          'businessOwner',
          'businessExecutive',
          'businessEmployee',
        ]}
      />
      <Select formKey='label' options={['personal', 'home', 'work']} />
      <Select
        formKey='contactType'
        options={['phone', 'email', 'address', 'whatsApp', 'fbMessenger']}
      />
      <Form.If
        if={{ contactType: 'address' }}
        then={
          <Form.Provider formKey='address' section>
            <Input formKey='addressLine1' />
            <Input formKey='addressLine2' />
            <Input formKey='city' />
            <Input formKey='countyOrRegion' />
            <StateCodeSelect formKey='state' nullable />
            <Input formKey='postalCode' />
            <CountryCodeSelect formKey='country' nullable />
          </Form.Provider>
        }
        else={<Input formKey='value' />}
      />
    </Modal>
  )
  const header = <Bar title='Contacts' right={createContact} />

  const contactGroups = _.groupBy(contacts, 'contactType')

  return (
    <Panels header={header}>
      <LoadingContainer query={query}>
        <Page.Full>
          {_.map(contactGroups, (group, type) => {
            return (
              <ModelList
                key={type}
                title={`${humanizeKey(type)} Contacts`}
                json={group}
              />
            )
          })}
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default PersonContacts
