import { forwardRef, useRef } from 'react'

import { Popover as ReachPopover } from '@reach/popover'
import useOnClickOutside from 'peach/components/Modal/useClickOutside'
import { useToggle } from 'peach/hooks'
import styled, { css } from 'styled-components/macro'

import KeyboardNavArea from '../$internal/KeyboardNavArea'
import Button from '../Button/Button'
// `positionDefault, positionMatchWidth, positionRight`
import { positionMatchWidthMaxHeight } from '../Select/positions'

const Content = styled.div`
  margin-top: 8px;
  margin-bottom: 8px;
  max-height: inherit;
  overflow-y: auto;
  ${(p) =>
    p.width &&
    css`
      min-width: ${p.width};
    `}
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.offset};
  border: 1px solid ${(p) => p.theme.border};
  padding: 0;
  border-radius: 4px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
`

const Title = styled.div`
  flex-shrink: 0;
  padding: 8px;
  text-align: center;
  color: ${(p) => p.theme.text};
`

const Contents = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  background-color: ${(p) => p.theme.background};
  border-top: 1px solid ${(p) => p.theme.border};
  border-bottom: 1px solid ${(p) => p.theme.border};
  padding: ${(p) => (p.fullContent ? '0' : '4px 8px')};
  // border-radius: 4px;
`

const BottomControls = styled.div`
  flex-shrink: 0;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const TriggerWrap = styled.span`
  display: inline-block;
`

const Popover = forwardRef((props, ref) => {
  const {
    startOpen,
    children,
    render,
    triggerLabel,
    trigger,
    renderTrigger,
    searchControls,
    controls,
    title,
    disabled,
    width,
    fullContent,
    onApply,
    applyLabel,
    ...rest
  } = props

  const localRef = useRef()
  const $ref = ref || localRef

  const [isOpen, onOpen, onClose] = useToggle(startOpen)

  const contentRef = useRef()

  useOnClickOutside(onClose, contentRef, $ref)

  const renderProps = { onClose, isOpen, onOpen, disabled, ref: $ref }

  const displayTrigger = renderTrigger ? (
    renderTrigger(renderProps)
  ) : trigger ? (
    <TriggerWrap ref={$ref} onClick={onOpen} disabled={disabled}>
      {trigger}
    </TriggerWrap>
  ) : (
    <Button {...renderProps}>{triggerLabel || 'Open Popover'}</Button>
  )

  const handleApply = () => {
    if (onApply) onApply()
    onClose()
  }

  const bottomControls = onApply ? (
    <Button onClick={handleApply}>{applyLabel || 'Apply'}</Button>
  ) : _.isFunction(controls) ? (
    controls(renderProps)
  ) : (
    controls
  )

  return (
    <>
      {displayTrigger}

      {!disabled && isOpen && (
        <ReachPopover
          ref={contentRef}
          targetRef={$ref}
          position={positionMatchWidthMaxHeight}
        >
          <KeyboardNavArea focusOnMount returnFocusOnUnmount onEscape={onClose}>
            <Content
              {...rest}
              width={width}
              ref={contentRef}
              fullContent={fullContent}
            >
              {title && <Title>{title}</Title>}
              <Contents>{render ? render(renderProps) : children}</Contents>
              {bottomControls && (
                <BottomControls>{bottomControls}</BottomControls>
              )}
            </Content>
          </KeyboardNavArea>
        </ReachPopover>
      )}
    </>
  )
})

export default Popover
