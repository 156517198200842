import { useCallback } from 'react'

import { useInvalidateQuery, useCombinedParams } from '../internal'

const useRefreshAllLoanData = (options) => {
  const { personId, loanId } = useCombinedParams()
  const invalidateQueries = useInvalidateQuery()

  const refreshAllLoanData = useCallback(() => {
    invalidateQueries(['people', personId, 'loans', loanId])
  }, [invalidateQueries, personId, loanId])

  return refreshAllLoanData
}

export default useRefreshAllLoanData
