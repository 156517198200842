import {
  LoadingContainer,
  Page,
  Modal,
  Model,
  Panels,
  Bar,
  Input,
  NumericInput,
  DateInput,
  Select,
  Textarea,
  Form,
} from 'peach/components'
import { useDrawPurchasesQuery, useCreateDrawPurchase } from 'peach/data'

import { defaultPurchase } from './defaultPurchase'

const DrawPurchases = () => {
  const [purchases, query] = useDrawPurchasesQuery()

  const [sendCreatePurchase, mutation] = useCreateDrawPurchase()

  const header = <Bar title='Purchases' />

  const createPurchase = (
    <Modal
      title='Create Purchase'
      onSubmit={sendCreatePurchase}
      initialValue={defaultPurchase}
    >
      <NumericInput formKey='amount' required />
      <DateInput formKey='purchaseDate' required />
      <Input formKey='externalId' />
      <Select formKey='type' options={['regular', 'refund', 'cashBack']} />
      <Select formKey='status' options={['settled', 'pending', 'canceled']} />
      <Form.Provider formKey='purchaseDetails' section>
        <Input
          formKey='description'
          note='The description of the purchase. Normally shown in Borrower Portal and on statements.'
        />
        <Select
          formKey='pointOfSaleType'
          options={['online', 'physical', 'recurring']}
        />
        <Input formKey='categoryId' />
        <Input formKey='externalCardId' />
        <Input formKey='merchantId' />
        <Input formKey='merchantName' />
        <NumericInput formKey='originalCurrencyAmount' />
        <Input
          formKey='originalCurrencyCode'
          note='The currency in which the purchase was made before currency conversion. Three letters according to ISO 4217.'
        />
        <NumericInput formKey='conversionRate' />
        <Textarea.Json formKey='metadata' />
      </Form.Provider>
      <Input
        fromKey='originalPurchaseId'
        note="Peach's unique or lender's external identifier."
      />
      <Textarea.Json formKey='metadata' />
    </Modal>
  )

  return (
    <Panels header={header}>
      {createPurchase}
      <LoadingContainer queries={[query, mutation]}>
        <Page.Full>
          <Model.List json={purchases} />
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default DrawPurchases
