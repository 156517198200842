import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
} from 'react'

import styled, { css } from 'styled-components/macro'

import Bar from '../Bar/Bar'
import Panels from '../Panels/Panels'

const FormPage = styled.div`
  height: 100%;
  display: ${(p) => (p.visible ? 'block' : 'none')};
  padding: 16px;
`
const Tabs = styled.div`
  padding: 8px;
`

const Tab = styled.button`
  cursor: pointer;
  font-size: 14px;
  display: block;
  width: 100%;
  text-align: left;
  background: transparent;
  border: none;
  padding: 8px 12px;
  margin-top: 8px;
  border-radius: 4px;
  &:hover {
    ${(p) =>
      !p.isActive &&
      css`
        background-color: ${(p) => p.theme.hover};
      `}
  }
  ${(p) =>
    p.isActive &&
    css`
      background-color: ${(p) => p.theme.highlight};
      color: ${(p) => p.theme.invertedText};
      cursor: default;
    `}
`

const PagesContext = createContext({
  addPage: () => {},
  removePage: () => {},
  activePage: '',
})

const Page = ({ page, isDefault, children }) => {
  const { addPage, removePage, activePage } = useContext(PagesContext)

  const visible = page && activePage === page

  useEffect(() => {
    addPage({ page, isDefault })
    return () => removePage(page)
  }, [page, addPage, removePage, isDefault])
  return <FormPage visible={visible}>{children}</FormPage>
}

const FormTabs = ({ children }) => {
  const [activePage, setActivePage] = useState(undefined)

  const [pages, setPages] = useState([])

  const addPage = useCallback(({ page, isDefault }) => {
    setPages((pages) => _.uniq(pages.concat({ page, isDefault })))
  }, [])

  const removePage = useCallback((page) => {
    setPages((pages) => _.reject(pages, { page }))
  }, [])

  const value = useMemo(() => {
    return { activePage, addPage, removePage }
  }, [activePage, addPage, removePage])

  useEffect(() => {
    if (!activePage) {
      const theDefault = _.find(pages, 'isDefault') || _.first(pages)

      if (theDefault?.page) setActivePage(theDefault.page)
    }
  }, [activePage, pages])

  useEffect(() => {
    setActivePage(activePage)
  }, [activePage])

  const tabs = _.map(pages, ({ page }) => {
    return (
      <Tab
        key={page}
        isActive={activePage === page}
        onClick={() => setActivePage(page)}
      >
        {page}
      </Tab>
    )
  })

  const left = <Tabs>{tabs}</Tabs>

  const header = <Bar title={activePage || 'No Page Selected'} />

  return (
    <PagesContext.Provider value={value}>
      <Panels left={left}>
        <Panels header={header}>{children}</Panels>
      </Panels>
    </PagesContext.Provider>
  )
}
FormTabs.Page = Page

export { Page }

export default FormTabs
