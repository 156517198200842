import { Json } from 'peach/components'

const EnvTable = ({ env, isActive }) => {
  const { label, apiBase, companyId, userType } = env || {}

  const $env = { apiBase, companyId, userType }

  const $label = `${label}${isActive ? ' (Current)' : ''}`

  return <Json.Table title={$label} json={$env} compact humanizeKeys />
}
export default EnvTable
