import { Fragment } from 'react'

import {
  Json,
  Panels,
  MiniTable,
  Link,
  Modal,
  Bar,
  Page,
  Input,
  Breadcrumb,
  Select,
} from 'peach/components'
import { useCompanyRolesQuery } from 'peach/data'
import { getName } from 'peach/helpers'
import { useApi, useReadData } from 'peach/hooks'
import { Switch, Route, Redirect, useRouteMatch, useParams } from 'peach/router'
import { CompanyPermissionSelect } from 'peach/scopes/company'
import styled from 'styled-components/macro'

import CompanyPermission from './CompanyPermission' // todo: rename to CompanyRolePermission

const LightText = styled.span`
  color: ${(p) => p.theme.mutedText};
`

const CompanyRole = () => {
  const { url, path } = useRouteMatch()
  const { companyId, roleId } = useParams()
  const [roles, query] = useCompanyRolesQuery()
  const [companyPermissions] = useReadData(() => {
    if (!companyId) return []
    return api.company.permissions.get({ pathArgs: { companyId } })
  }, [companyId])

  const api = useApi()

  const role = _.find(roles, { id: roleId })

  const { permissions, ...rest } = role || {}

  const sendEditRole = async (role, diff) => {
    await api.company.role.put({
      pathArgs: { companyId, roleId },
      body: diff,
    })
    query.refetch()
  }

  const sendAddPermission = async ({ actions }) => {
    await api.company.rolePermissions.post({
      pathArgs: { companyId, roleId },
      body: { actions },
    })
    query.refetch()
  }

  const sendRemovePermissions = async ({ actions }) => {
    await api.company.rolePermissions.delete({
      pathArgs: { companyId, roleId },
      queryParams: { actions },
    })
    query.refetch()
  }

  const editRole = (
    <Modal title='Edit Role' onSubmit={sendEditRole} initialValue={role}>
      <Input formKey='alias' />
    </Modal>
  )

  const addPermissions = (
    <Modal title='Add Permissions' onSubmit={sendAddPermission}>
      <CompanyPermissionSelect
        multi
        omitPermissions={permissions}
        formKey='actions'
      />
    </Modal>
  )

  const removePermissions = (
    <Modal title='Remove Permissions' onSubmit={sendRemovePermissions}>
      <Select
        multi
        searchable
        options={_.map(permissions, 'action')}
        formKey='actions'
      />
    </Modal>
  )

  const permissionsIndex = _.keyBy(permissions, 'action')

  const permissionsList = _.map(companyPermissions, ({ id, action }) => {
    const [group, verb] = action?.split(':')
    return { id, action, rolePermission: permissionsIndex[action], group, verb }
  })

  const groupedPerms = _.groupBy(permissionsList, 'group')

  const rows = _.map(groupedPerms, (perms, type) => {
    const sortedPerms = _.sortBy(perms, 'verb')

    const actions = _.map(sortedPerms, (perm, index) => {
      const { verb, rolePermission } = perm
      const { id } = rolePermission || {}

      return (
        <Fragment key={perm.id}>
          {id ? (
            <Link key={id} to={`${url}/permissions/${id}`}>
              {verb}
            </Link>
          ) : (
            <LightText>{verb}</LightText>
          )}
          {index < perms.length - 1 ? <LightText>{', '}</LightText> : null}
        </Fragment>
      )
    })

    return (
      <MiniTable.Row key={type}>
        <MiniTable.Cell width='300px'>
          <Json.Value value={type} />
        </MiniTable.Cell>

        <MiniTable.Cell>
          <div style={{ lineHeight: 1.75 }}>{actions}</div>
        </MiniTable.Cell>
      </MiniTable.Row>
    )
  })

  const name = getName(role)

  const header = <Bar title={name} />

  return (
    <>
      {editRole}
      {addPermissions}
      {removePermissions}

      <Breadcrumb to={url} title={name} />
      <Switch>
        <Route path={path} exact>
          <Panels header={header}>
            <Page.Full>
              <Json.Table title='Role' json={rest} />
              <MiniTable title='Permissions'>{rows}</MiniTable>
            </Page.Full>
          </Panels>
        </Route>
        <Route path={`${path}/permissions`} crumb>
          <Route path={`${path}/permissions`} exact>
            <Redirect to={url} />
          </Route>
          <Route path={`${path}/permissions/:permissionId`}>
            <CompanyPermission />
          </Route>
        </Route>
        <Redirect to={url} />
      </Switch>
    </>
  )
}

export default CompanyRole
