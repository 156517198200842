import styled from 'styled-components/macro'

const SectionWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 12px;
  padding: 12px;
  border-top: 1px solid ${(p) => p.theme.border};
`

const Content = styled.div``

const Section = ({ children }) => {
  return (
    <SectionWrapper>
      <Content>{children}</Content>
    </SectionWrapper>
  )
}

export default Section
