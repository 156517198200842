import { useInvestorQuery } from 'peach/data'
import { useCompanyId } from 'peach/scopes/company'

import ModelLink from './ModelLink'

const InvestorLink = ({ investorId, jsonKey }) => {
  const companyId = useCompanyId()

  const [investor, query] = useInvestorQuery({ investorId })

  const link = companyId && `/companies/${companyId}/investors/${investorId}`

  return (
    <ModelLink
      id={investorId}
      model={investor}
      link={link}
      query={query}
      jsonKey={jsonKey}
    />
  )
}

export default InvestorLink
