import { useState } from 'react'

import { humanizeKey } from 'peach/helpers'
import { useStableCallback } from 'peach/hooks'

import FormSection from '../FormSection'

import { getNewValue } from './stateHelpers'
import { FormStateProvider } from './useFormState'
import { useFormValueAndOnChange } from './useFormValueAndOnChange'

const FormProvider = (props) => {
  const {
    children,
    formKey,
    value: passedValue,
    onChange: passedOnChange,
    render,
    section,
    label,
    persistOnUnmount,
    onValidationChange: passedOnValidationChange,
    isHidden: passedIsHidden,
  } = props

  const [localValidation, setLocalValidation] = useState({})

  const handleValidationChange = useStableCallback((newVal) => {
    if (passedOnValidationChange) {
      passedOnValidationChange((prev) => getNewValue(newVal, prev))
    } else {
      setLocalValidation((prev) => getNewValue(newVal, prev))
    }
  })

  const {
    value,
    onChange,
    clearKey,
    validation,
    onValidationChange,
    isHidden,
  } = useFormValueAndOnChange({
    formKey,
    value: passedValue,
    onChange: passedOnChange,
    persistOnUnmount,
    validation: localValidation,
    onValidationChange: handleValidationChange,
    isHidden: passedIsHidden,
  })

  const $label =
    label || (_.isString(formKey) ? humanizeKey(formKey) : undefined)

  const contents = render
    ? render({
        value,
        onChange,
        clearKey,
        validation,
        onValidationChange,
        isHidden,
      })
    : children

  const displayContents = section ? (
    <FormSection label={$label}>{contents}</FormSection>
  ) : (
    contents
  )

  return (
    <FormStateProvider
      value={value}
      onChange={onChange}
      clearKey={clearKey}
      validation={validation}
      onValidationChange={onValidationChange}
      isHidden={isHidden}
    >
      {displayContents}
    </FormStateProvider>
  )
}

export { FormProvider, useFormValueAndOnChange }
