import {
  LoadingContainer,
  Panels,
  Bar,
  Modal,
  Page,
  ModelList,
} from 'peach/components'
import { useLoansQuery } from 'peach/data'
import { humanizeKey } from 'peach/helpers'
import { useApi } from 'peach/hooks'

import {
  CreateInstallmentLoanForm,
  CreateLineOfCreditLoanForm,
} from './CreateLoanForm/CreateLoanForm'
import {
  defaultInstallmentLoan,
  defaultLineOfCreditLoan,
} from './CreateLoanForm/defaultLoan'

const PersonLoans = ({ person, personId }) => {
  const [loans, loansQuery] = useLoansQuery()

  const displayLoans = _.sortBy(loans, 'createdAt').reverse()

  const api = useApi()

  const refresh = () => loansQuery.refetch()

  const send = async (loan) => {
    try {
      await api.person.loans.post({
        pathArgs: { personId },
        queryParams: { validate: false },
        body: loan,
      })
      refresh()
    } catch (err) {
      refresh()
      throw err
    }
  }

  const createInstallmentLoan = (
    <Modal
      title='Create Installment Loan'
      onSubmit={send}
      initialValue={defaultInstallmentLoan}
      tabs
      render={({ onChange }) => (
        <CreateInstallmentLoanForm onChange={onChange} />
      )}
    />
  )

  const createLineOfCreditLoan = (
    <Modal
      title='Create Line Of Credit Loan'
      onSubmit={send}
      initialValue={defaultLineOfCreditLoan}
      tabs
    >
      <CreateLineOfCreditLoanForm />
    </Modal>
  )

  const groupedLoans = _.groupBy(displayLoans, 'type')

  const tables = _.map(groupedLoans, (group, key) => {
    return (
      <ModelList
        key={key}
        title={`${humanizeKey(key)} Loans`}
        json={group}
        columns={[['nickname', 'object']]}
        withDetails={[
          'loanTypeId',
          'type',
          'aprEffectiveAtActivation',
          'apiNominalAtActivation',
          'endDate',
          'productType',
          'servicedBy',
        ]}
        withTimestamps
      />
    )
  })

  return (
    <Panels header={<Bar title='All Loans' />}>
      {createInstallmentLoan}
      {createLineOfCreditLoan}
      <LoadingContainer query={loansQuery}>
        <Page.Full>{tables}</Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default PersonLoans
