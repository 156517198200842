import styled, { css } from 'styled-components/macro'

import Bar from '../../Bar/Bar'
import Panels from '../../Panels/Panels'

import { ModalPagesProvider, useModalPages } from './useModalPages'

const Tabs = styled.div`
  padding: 8px;
`

const Tab = styled.button`
  cursor: pointer;
  font-size: 14px;
  display: block;
  width: 100%;
  text-align: left;
  border: 1px solid transparent;
  padding: 8px 12px;
  margin-top: 8px;
  border-radius: 4px;
  background-color: transparent;
  color: ${(p) => p.theme.text};

  &:hover {
    ${(p) =>
      !p.isActive &&
      css`
        background-color: ${(p) => p.theme.hover};
      `}
  }

  ${(p) =>
    p.isActive &&
    css`
      background-color: ${(p) => p.theme.highlight};
      color: ${(p) => p.theme.invertedText};
      cursor: default;
    `}
`

const ModalPagesContainer = ({ children }) => {
  const { activePage, setActivePage, pages } = useModalPages()

  const tabs = _.map(pages, ({ page }) => {
    return (
      <Tab
        key={page}
        isActive={activePage === page}
        onClick={() => setActivePage(page)}
      >
        {page}
      </Tab>
    )
  })

  const left = <Tabs>{tabs}</Tabs>

  const header = <Bar title={activePage || 'No Page Selected'} />

  return (
    <Panels left={left}>
      <Panels header={header}>{children}</Panels>
    </Panels>
  )
}

const ModalPagesContainerWrapper = ({ children }) => {
  return (
    <ModalPagesProvider>
      <ModalPagesContainer>{children}</ModalPagesContainer>
    </ModalPagesProvider>
  )
}

export default ModalPagesContainerWrapper
