import { LoadingContainer, Page, Json, MiniTable } from 'peach/components'
import {
  useLoanPeriodsQuery,
  useLoanExpectedPaymentsQuery,
  useLoanObligationsQuery,
  useLoanStatementsQuery,
} from 'peach/data'

import DeferScheduleModal from '../ExpectedPayments/DeferScheduleModal'

const getToday = () => {
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0') //January is 0!
  const yyyy = today.getFullYear()

  return `${yyyy}-${mm}-${dd}`
}

const today = getToday()

const LoanTimeline = () => {
  const [periods, periodsQuery] = useLoanPeriodsQuery()
  const [expectedPayments, expPaymentsQuery] = useLoanExpectedPaymentsQuery()
  const [obligationsResp, obligationsQuery] = useLoanObligationsQuery()
  const [statements, statementsQuery] = useLoanStatementsQuery()

  const { schedule } = expectedPayments || {}

  const { obligations } = obligationsResp || {}

  const expPaymentsByPeriodId = _.groupBy(schedule, 'periodId')
  const obligationsByPeriodId = _.groupBy(obligations, 'periodId')
  const statementsByPeriodId = _.groupBy(statements, 'periodId')

  const results = _.map(periods, (period) => {
    const { id, startDate, endDate, dueDate, statementDate } = period
    const expPaymenents = expPaymentsByPeriodId[id]
    const statements = statementsByPeriodId[id]

    const obligations = obligationsByPeriodId[id]

    const obligation = _.first(obligations)

    const { isOpen, isFulfilled, isOverdue, obligationAmount } =
      obligation || {}

    const sequence =
      today > endDate ? 'past' : today >= startDate ? 'current' : 'future'

    const paymentStatus =
      dueDate < today
        ? isFulfilled
          ? 'paid'
          : 'overdue'
        : endDate < today
        ? isFulfilled
          ? 'fulfilled'
          : 'due'
        : startDate < today
        ? 'open'
        : 'future'

    return {
      id,
      today,
      startDate,
      endDate,
      dueDate,
      sequence,
      paymentStatus,
      obligationAmount,
      isOpen,
      isFulfilled,
      isOverdue,
      statementDate,
      obligations,
      obligationsCount: _.size(obligations),
      expPaymenentsCount: _.size(expPaymenents),
      statementsCount: _.size(statements),
    }
  })

  const rows = _.map(results, (result) => {
    const {
      id,
      startDate,
      endDate,
      dueDate,
      sequence,
      paymentStatus,
      obligationAmount,
    } = result
    return (
      <MiniTable.Row key={id}>
        {startDate}
        {endDate}
        {dueDate}
        {sequence}
        {`${paymentStatus} ${obligationAmount ?? ''}`}
      </MiniTable.Row>
    )
  })

  return (
    <LoadingContainer
      queries={[
        periodsQuery,
        expPaymentsQuery,
        obligationsQuery,
        statementsQuery,
      ]}
    >
      <DeferScheduleModal />
      <Page.Full>
        <MiniTable
          columns={['Start', 'End', 'Due', 'Sequence', 'Payment Status']}
        >
          {rows}
        </MiniTable>
        <Json.Table title='Timeline' json={results} />
      </Page.Full>
    </LoadingContainer>
  )
}

export default LoanTimeline
