import { useDataQuery, useQueryApi } from '../useQueryApi'

const useWorkflowStepTypesQuery = (options) => {
  const [api, { companyId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'workflow-step-types'],

    queryFn: () => {
      return api.company.workflowStepTypes.get({ pathArgs: { companyId } })
    },
  })
}

export default useWorkflowStepTypesQuery
