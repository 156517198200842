import Auth from 'pages/auth/Auth'
import Companies from 'pages/companies/Companies'
import CreateCompany from 'pages/companies/CreateCompany'
import Env from 'pages/env/Env'
import AllRoles from 'pages/home/AllRoles'
import { Markdown, ListNav2, Panels } from 'peach/components'
import { Switch, Route, Redirect } from 'peach/router'

import development from './development.md'
import localData from './local-data.md'
import notes from './notes.md'
import Status from './Status'

const markdown = `
  # Welcome to 🍑 Dev Tools\n
  Read the [readme.md](https://github.com/peachfinance/dev-tools/blob/master/README.md) for more.
`

// but in this particular case though, we are importing from outside of the /src folder and that doesn't work, BUT the macro plugin does, so we improt the readme this way.
const items = [
  ['Home', '', true],
  '/status',
  '/local-data',
  '/notes',
  '/development',
  {
    label: 'Sections',
    items: ['/companies', '/create-company', '/all-roles', '/auth', '/env'],
  },
]

const nav = <ListNav2 items={items} />

const Home = () => {
  return (
    <Panels left={nav}>
      <Switch>
        <Route path='/home' exact crumb>
          <Markdown.Page markdown={markdown} />
        </Route>

        <Route path='/status' crumb>
          <Status />
        </Route>

        <Route path='/local-data' crumb>
          <Markdown.Page url={localData} />
        </Route>

        <Route path='/development' crumb>
          <Markdown.Page url={development} />
        </Route>

        <Route path='/notes' crumb>
          <Markdown.Page url={notes} />
        </Route>

        <Route path='/companies' exact crumb>
          <Companies />
        </Route>

        <Route path='/create-company' exact crumb>
          <CreateCompany />
        </Route>

        <Route path='/all-roles' crumb title='All Roles'>
          <AllRoles />
        </Route>

        <Route path='/auth' crumb>
          <Auth />
        </Route>

        <Route path='/env' crumb>
          <Env />
        </Route>

        <Redirect to='/home' />
      </Switch>
    </Panels>
  )
}

export default Home
