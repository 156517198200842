import {
  Json,
  Page,
  Modal,
  Panels,
  Bar,
  LoadingContainer,
  Breadcrumb,
} from 'peach/components'
import { useCompanyRolesQuery } from 'peach/data'
import { getName } from 'peach/helpers'
import { useApi } from 'peach/hooks'
import { useRouteMatch, useParams } from 'peach/router'
import { useCompanyId } from 'peach/scopes/company'

const CompanyPermission = () => {
  const companyId = useCompanyId()
  const api = useApi()
  const { url } = useRouteMatch()
  const { roleId, permissionId } = useParams()
  const [roles, rolesQuery] = useCompanyRolesQuery()

  const role = _.find(roles, { id: roleId })

  const { permissions } = role || {}

  const permission = _.find(permissions, { id: permissionId })

  const sendDeletePermission = async () => {
    await api.company.rolePermissions.delete({
      pathArgs: { companyId, roleId },
      queryParams: { actions: [permission.action] },
    })
    rolesQuery.refetch()
  }

  const deletePermission = (
    <Modal trigger title='Delete Permission' onSubmit={sendDeletePermission}>
      <Json.Table json={permission} />
    </Modal>
  )

  const header = <Bar title='Permission' right={deletePermission} />

  return (
    <Panels header={header}>
      <Breadcrumb to={url} title={getName(permission)} />
      <LoadingContainer query={rolesQuery}>
        <Page>
          <Json.Table json={permission} />
        </Page>
      </LoadingContainer>
    </Panels>
  )
}

export default CompanyPermission
