import styled from 'styled-components/macro'

const SearchResultsBox = styled.div`
  padding: 8px 4px;
  background-color: ${(p) => p.theme.background};
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  max-height: inherit;
  overflow-y: auto;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
`

export default SearchResultsBox
