import { useState, useCallback } from 'react'

import { getComponentName } from 'peach/helpers'
import { useAnimatedIsOpen } from 'peach/hooks'
import styled from 'styled-components/macro'

import Button from '../../components/Button/Button'

const Warning = styled.span`
  display: inline-block;
  padding: 4px;
  margin: 4px;
  color: red;
  background-color: #fee;
  border: 1px solid red;
  border-radius: 3px;
`
const showWarning = false

const renderTrigger = (props, parentIsOpen) => {
  const {
    _trigger: trigger,
    title,
    formKey,
    isOpen,
    onOpen,
    onClose,
    triggerButtonLabel,
    ellipsis,
  } = props

  if (triggerButtonLabel || trigger === true) {
    const label = triggerButtonLabel || title || formKey || 'Edit'
    return (
      <Button onClick={onOpen}>
        {label}
        {ellipsis !== false && '…'}
      </Button>
    )
  }

  if (_.isFunction(trigger)) {
    return trigger({ isOpen, onOpen, onClose })
  }

  if (trigger) {
    return <span onClick={onOpen}>{trigger}</span>
  }

  if (
    showWarning &&
    _.isUndefined(parentIsOpen) &&
    !trigger &&
    !triggerButtonLabel
  ) {
    return (
      <Warning>
        {`Triggerable items requires one of [isOpen, trigger, triggerButtonLabel]`}
      </Warning>
    )
  }
  return null
}

const withTrigger = (Component) => {
  const Trigger = (props) => {
    const { isOpen, onOpen, onClose, startOpen, hideTrigger, ...rest } = props

    const [localOpen, setLocalOpen] = useState(!!startOpen)

    const $isOpen = isOpen ?? localOpen

    const [isMounted, _isOpen] = useAnimatedIsOpen($isOpen)

    const handleOpen = useCallback(() => {
      if (onOpen) onOpen()
      setLocalOpen(true)
    }, [onOpen])

    const handleClose = useCallback(() => {
      if (onClose) onClose()
      setLocalOpen(false)
    }, [onClose])

    const renderProps = {
      isOpen: _isOpen,
      onOpen: handleOpen,
      onClose: handleClose,
      ...rest,
    }

    const displayTrigger = !hideTrigger && renderTrigger(renderProps, isOpen)

    return (
      <>
        {displayTrigger}
        {isMounted && <Component {...renderProps} />}
      </>
    )
  }

  Trigger.displayName = getComponentName(Component, 'withTrigger')

  return Trigger
}

export default withTrigger
