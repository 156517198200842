import {
  LoadingContainer,
  Page,
  Model,
  Panels,
  Bar,
  Json,
} from 'peach/components'
import { useDocumentsQuery } from 'peach/data'
import { humanizeKey } from 'peach/helpers'
import { useParams } from 'peach/router'

const LoanDocuments = () => {
  const { loanId } = useParams()
  const [documents, query] = useDocumentsQuery({ loanId })

  const header = <Bar title='Documents' />

  const documentsByStatus = _.groupBy(documents, 'status')

  const displayGroups = _.map(documentsByStatus, (docs, status) => {
    return (
      <Model.List
        title={`${humanizeKey(status)} Documents`}
        json={docs}
        noLink
      />
    )
  })

  return (
    <Panels header={header}>
      <LoadingContainer query={query}>
        <Page.Full>
          {_.isEmpty(documents) ? <Json.Table json={[]} /> : displayGroups}
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default LoanDocuments
