import { Select } from 'peach/components'
import { useSessionCompaniesQuery, useAllSessionRoles } from 'peach/data'
import { getName } from 'peach/helpers'

const CrossCompanyRolesSelect = (props) => {
  const { companyId, ...rest } = props

  const [companies] = useSessionCompaniesQuery()

  const [roles] = useAllSessionRoles()

  const roleOptions = _.map(roles, (role) => {
    const { id, companyId: roleCompanyId } = role

    const company = _.find(companies, { id: roleCompanyId })
    const isExternal = companyId === roleCompanyId
    const prefix = companyId === roleCompanyId ? '' : 'EXT: '

    return {
      value: id,
      label: prefix + getName(role),
      note: getName(company),
      isExternal,
    }
  })

  const sortedOptions = _.sortBy(roleOptions, 'isExternal').reverse()

  return (
    <Select
      multi
      {...rest}
      notes
      searchable
      label='Roles'
      options={sortedOptions}
    />
  )
}

export default CrossCompanyRolesSelect
