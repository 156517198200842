import { useDataQuery, useQueryApi } from '../useQueryApi'

const useLoansQuery = (options) => {
  const [api, { personId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['people', personId, 'loans'],

    queryFn: async () => {
      return api.person.loans.getAll({
        pathArgs: { personId },
        queryParams: { status: 'all' },
      })
    },
  })
}

export default useLoansQuery
