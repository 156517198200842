import { forwardRef, useRef, useEffect } from 'react'

import { focusNextFocusableElement } from 'peach/helpers/focus'
import styled, { css } from 'styled-components/macro'

const Wrapper = styled.div`
  max-height: inherit;
  ${(p) =>
    p.fullHeight &&
    css`
      height: 100%;
    `}
`

const KeyboardNavArea = forwardRef((props, ref) => {
  const {
    children,
    arrowFocus,
    focusOnMount,
    returnFocusOnUnmount,
    onArrowUp,
    onArrowDown,
    onEnter,
    stopPropagationOnEnter,
    preventDefaultOnEnter,
    onEscape,
    onSpace,
    onKeyDown,
    onTypeCharacter,
    ...rest
  } = props

  const localRef = useRef()

  const $ref = ref || localRef

  const previousFocusRef = useRef()

  useEffect(() => {
    previousFocusRef.current = document.activeElement

    if (focusOnMount) {
      _.delay(() => {
        focusNextFocusableElement($ref.current)
      }, 10)
    }
    return () => {
      if (focusOnMount && returnFocusOnUnmount && previousFocusRef.current) {
        previousFocusRef.current.focus()
      }
    }
  }, []) // eslint-disable-line

  const handleKeyDown = (event) => {
    switch (event.key) {
      case 'Enter':
        if (onEnter) onEnter(event)
        if (stopPropagationOnEnter) event.stopPropagation()
        if (preventDefaultOnEnter) event.preventDefault()
        break
      case 'Spacebar':
      case ' ':
        if (onSpace) onSpace(event)
        break
      case 'Escape':
        if (onEscape) onEscape(event)
        break
      case 'ArrowUp':
        if (onArrowUp) onArrowUp(event)
        if (arrowFocus) {
          focusNextFocusableElement($ref.current, { increment: -1 })
        }
        break
      case 'ArrowDown':
        if (onArrowDown) onArrowDown(event)
        if (arrowFocus) {
          focusNextFocusableElement($ref.current, { increment: 1 })
        }
        break
      default: {
        if (_.size(event.key) === 1 && onTypeCharacter) {
          onTypeCharacter(event.key)
        }
      }
    }
    if (onKeyDown) onKeyDown(event)
  }

  return (
    <Wrapper {...rest} onKeyDown={handleKeyDown} ref={$ref}>
      {children}
    </Wrapper>
  )
})

export default KeyboardNavArea
