import { Fragment } from 'react'

import styled, { css } from 'styled-components/macro'

const Main = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
`

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Center = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const sectionStyles = css`
  display: flex;
  flex-wrap: no-wrap;
  width: auto;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  flex-basis: auto;
  flow-grow: 1;
`

const Title = styled.span`
  display: inline-block;
  font-size: 18px;
  align-items: center;
  flex-grow: 0;
  color: ${(p) => p.theme.text};
  margin-right: 12px;
`

const LeftSection = styled.div`
  ${sectionStyles}
  justify-content: flex-start;
  & > * {
    margin-right: ${(p) => p.gap};
    &:last-child {
      margin-right: none;
    }
  }
`
const RightSection = styled.div`
  ${sectionStyles}
  justify-content: flex-end;
  & > * {
    margin-left: ${(p) => p.gap};
    &:first-child {
      margin-left: none;
    }
  }
`

const CenterSection = styled.div`
  ${sectionStyles}
  justify-content: center;
  & > * {
    margin-left: ${(p) => p.gap};
    &:first-child {
      margin-left: none;
    }
  }
`

const Spacer = styled.span`
  display: inline-block;
  align-self: stretch;
  width: 16px;
`

const Bar = ({ title, left, right, center, gap = '12px' }) => {
  return (
    <Main>
      <Left>
        {title && <Title>{title}</Title>}
        {left && <LeftSection gap={gap}>{left}</LeftSection>}
      </Left>

      {center && (
        <Center>
          <CenterSection>{center}</CenterSection>
        </Center>
      )}

      {right && (
        <Right>
          <RightSection gap={gap}>{right}</RightSection>
        </Right>
      )}
    </Main>
  )
}

Bar.Section = Fragment

export { Spacer, Fragment as Section }

export default Bar
