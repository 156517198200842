import { LoadingContainer, Modal, Input, Page } from 'peach/components'
import { useTeamsQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { ListSubroutes, useParams } from 'peach/router'

const CompanyPeople = () => {
  const { companyId } = useParams()

  const api = useApi()

  const [teams, query] = useTeamsQuery()

  const sendAddTeam = async (newTeam) => {
    await api.company.teams.post({
      pathArgs: { companyId },
      body: newTeam,
    })
    query.refetch()
  }

  const addTeam = (
    <Modal title='Add Team' onSubmit={sendAddTeam}>
      <Input formKey='name' />
    </Modal>
  )

  return (
    <LoadingContainer query={query}>
      {addTeam}
      <Page.Full>
        <ListSubroutes title='Teams' json={teams} />
      </Page.Full>
    </LoadingContainer>
  )
}

export default CompanyPeople
