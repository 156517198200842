import { Switch, Redirect, Route, useRouteMatch } from 'peach/router'

import PaymentInstrument from './PaymentInstrument'
import PaymentInstruments from './PaymentInstruments'

const PaymentInstrumentRoutes = () => {
  const { path, url } = useRouteMatch()

  return (
    <Switch>
      <Route path={path} exact>
        <PaymentInstruments />
      </Route>
      <Route path={`${path}/:paymentInstrumentId`}>
        <PaymentInstrument />
      </Route>
      <Redirect to={url} />
    </Switch>
  )
}

export default PaymentInstrumentRoutes
