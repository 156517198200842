import { LoadingContainer } from 'peach/components'
import { useInteractionsQuery } from 'peach/data'
import { ListSubroutes } from 'peach/router'

const PersonInteractions = ({ person, personId }) => {
  const [interactions, query] = useInteractionsQuery()

  return (
    <LoadingContainer query={query}>
      <ListSubroutes
        title='Interactions'
        paramKey='interactionId'
        json={interactions}
      />
    </LoadingContainer>
  )
}

export default PersonInteractions
