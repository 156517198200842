import getNodes from './getNodes'

const hasFocusInside = (...elements) => {
  const active = document.activeElement

  if (!active) return false

  const nodes = getNodes(elements)

  return _.some(nodes, (node) => {
    if (!node) return false
    return node === active || node.contains?.(active)
  })
}

export default hasFocusInside
