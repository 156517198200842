import { humanizeKey, truncateString, isIdKey, isIdValue } from 'peach/helpers'
import { useRouteMatch } from 'peach/router'

import Json from '../Json'
import MiniTable from '../MiniTable/MiniTable'

import ItemInfo from './ItemInfo'
import Timestamps from './Timestamps'

const exists = (val) => !_.isUndefined(val) && !_.isNull(val)

const isRelated = (key, val) => key !== 'id' && (isIdKey(key) || isIdValue(val))

const LIMIT = 2

const STRING_LIMIT = 120

const renderList = (json, object) => {
  return (
    <Json.List
      json={json}
      compact
      humanizeKeys
      limit={LIMIT}
      stringLimit={STRING_LIMIT}
      object={object}
    />
  )
}

const nameColumn = {
  key: 'item',
  label: 'Item',
  render: (item, { url, noLink }) => (
    <ItemInfo item={item} url={url} noLink={noLink} />
  ),
}

const relatedColumn = {
  key: 'related',
  label: 'Related To',
  render: (item, { withDetails, withRelated }) => {
    const { object } = item || {}

    const related = _.pickBy(item, (val, key) => {
      return _.isArray(withRelated)
        ? exists(val) && withRelated.includes(key)
        : exists(val) && isRelated(key, val)
    })
    return renderList(related, object)
  },
}

const detailsExcludes = ['status', 'id', 'object', 'timestamps']

const detailsColumn = {
  key: 'details',
  label: 'Details',
  render: (item, { withDetails }) => {
    const details = _.pickBy(item, (val, key) => {
      return _.isArray(withDetails)
        ? exists(val) && withDetails.includes(key)
        : exists(val) &&
            key.slice(-2) !== 'At' &&
            !detailsExcludes.includes(key) &&
            !isRelated(key, val)
    })
    return renderList(details)
  },
}

const jsonColumn = {
  key: 'json',
  label: 'Json',
  render: (item) => <Json json={item} collapsed={0} />,
}

const timestampsColumn = {
  key: 'timestamps',
  label: 'Timestamps',
  render: (item) => <Timestamps json={item} limit={LIMIT} />,
}

const ModelTable = (props) => {
  const {
    title,
    url,
    columns = [],
    withRelated,
    withJson,
    withDetails,
    withTimestamps,
    json,
    noLink,
  } = props

  const { url: matchUrl } = useRouteMatch()

  const $url = url || matchUrl

  const explicitColumns = _.compact(
    _.map(columns, (column) => {
      if (_.isArray(column)) {
        return {
          key: `array_column_${column.join('_')}`,
          label: humanizeKey(column[0]),
          render: (item) => {
            const obj = _.fromPairs(
              _.map(column, (key) => [key, truncateString(item[key])]),
            )
            return renderList(obj)
          },
        }
      }
      if (_.isString(column)) {
        return {
          key: `string_column_${column}`,
          label: humanizeKey(column),
          render: (item) => {
            return <Json jsonKey={column} json={item[column]} />
          },
        }
      }
      if (
        column.label &&
        (column.render || column.renderJson || column.jsonKey)
      ) {
        const { label, render, renderJson, jsonKey, jsonType } = column

        const key = `render_column_${column.label}`

        const $render = render
          ? (item) => render(item, { url: $url })
          : (item) => {
              const json = jsonKey ? item[jsonKey] : renderJson(item)
              return _.isObject(json) ? (
                renderList(json)
              ) : (
                <Json.Value type={jsonType} value={json} compact />
              )
            }
        return {
          label,
          render: $render,
          key,
        }
      }
    }),
  )

  const showDefaults =
    _.isEmpty(columns) && !_.isArray(withDetails) && !_.isArray(withRelated)

  const $columns = _.compact([
    nameColumn,
    (withRelated || showDefaults) && relatedColumn,
    ...explicitColumns,
    (withDetails || showDefaults) && detailsColumn,
    withJson && jsonColumn,
    (withTimestamps || showDefaults) && timestampsColumn,
  ])

  const displayColumns = _.map($columns, 'label')

  const rows = _.map(json, (item, index) => {
    const { id } = item || {}

    const $key = id ? `id_${id}` : `index_${index}`

    const cells = _.map($columns, ({ render, key }) => (
      <MiniTable.Cell key={key}>
        {render(item, { url: $url, withDetails, withRelated, noLink })}
      </MiniTable.Cell>
    ))

    return <MiniTable.Row key={$key}>{cells}</MiniTable.Row>
  })

  return (
    <MiniTable columns={displayColumns} title={title} fixed>
      {rows}
    </MiniTable>
  )
}

export default ModelTable
