import CenteredPage from './CenteredPage'
import FullPage from './FullPage'
import Section from './Section'

const Page = CenteredPage

Page.Centered = CenteredPage
Page.Section = Section
Page.Full = FullPage

export default Page
