import { useCallback } from 'react'

import { useEnvApi } from 'core/env/useEnv'
import { useQueryClient } from 'react-query'

const useInvalidateQuery = () => {
  const queryClient = useQueryClient()

  const { activeKey } = useEnvApi()

  const invalidateQueries = useCallback(
    (queryKey, ...args) => {
      queryClient.invalidateQueries([activeKey, ...queryKey], ...args)
    },
    [queryClient, activeKey],
  )

  return invalidateQueries
}

export default useInvalidateQuery
