import { useDataMutation, useQueryApi } from 'peach/data/useQueryApi'

const useCreateDrawPurchase = (options) => {
  const [api, params] = useQueryApi(options)

  const { companyId } = params

  return useDataMutation({
    invalidationKeys: [
      ['companies', companyId, 'users'],
      ['companies', companyId, 'employees'],
    ],
    mutationFn: async (options) => {
      const {
        // user options
        authType,
        roles,
        // employee options
        firstName,
        lastName,
        jobName,
        managerEmployeeId,

        // team options

        teamId,
      } = options

      const user = _.pickBy({
        userType: 'agent',
        authType,
        roles,
      })

      const userResp = await api.company.users.post({
        pathArgs: { companyId },
        body: user,
      })

      const userId = userResp.data.id

      const employeeResp = await api.company.employees.post({
        pathArgs: { companyId },
        body: { firstName, lastName, jobName, userId, managerEmployeeId },
      })

      const employeeId = employeeResp.data.id

      if (teamId) {
        await api.company.employeeTeam.put({
          pathArgs: { companyId, employeeId, teamId },
        })
      }
    },
  })
}

export default useCreateDrawPurchase
