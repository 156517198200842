import {
  LoadingContainer,
  Page,
  Model,
  Panels,
  Bar,
  Modal,
  Select,
  NumericInput,
} from 'peach/components'
import { useLoanRefundsQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { useLoanId } from 'peach/scopes/loan'
import { usePersonId } from 'peach/scopes/person'

const LoanRefunds = () => {
  const personId = usePersonId()
  const loanId = useLoanId()
  const api = useApi()

  const [refunds, query] = useLoanRefundsQuery()

  const refresh = () => query.refetch()

  const initialRefund = {
    refundAmount: 0,
    atFaultParty: 'loanOwner',
    caseId: null,
  }

  const sendIssueRefund = async (newRefund) => {
    await api.loan.refunds.post({
      pathArgs: { personId, loanId },
      body: newRefund,
    })
    refresh()
  }

  const createRefund = (
    <Modal
      trigger
      title='Create Refund'
      onSubmit={sendIssueRefund}
      inititialState={initialRefund}
    >
      <NumericInput formKey='refundAmount' />
      <Select
        formKey='atFaultParty'
        options={['loanOwner', 'loanServicer', 'merchant', 'borrower']}
      />
    </Modal>
  )

  const header = <Bar title='Refunds' right={createRefund} />

  return (
    <Panels header={header}>
      <LoadingContainer query={query}>
        <Page.Full>
          <Model.Viewer json={refunds} />
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default LoanRefunds
