import { forwardRef, useRef } from 'react'

import { Popover } from '@reach/popover'
import useOnClickOutside from 'peach/components/Modal/useClickOutside'
import { useToggle } from 'peach/hooks'
import styled from 'styled-components/macro'

import KeyboardNavArea from '../$internal/KeyboardNavArea'

import { positionMatchWidthMaxHeight } from './positions'
import SelectTrigger from './SelectTrigger'
// `positionDefault, positionMatchWidth, positionRight`

const List = styled.div`
  max-height: inherit;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background-color: ${(p) => p.theme.background};
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
`

const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`

const TopControls = styled.div`
  flex-shrink: 0;
  border-bottom: 1px solid ${(p) => p.theme.border};
  background-color: ${(p) => p.theme.offset};
`

const BottomControls = styled.div`
  flex-shrink: 0;
  border-top: 1px solid ${(p) => p.theme.border};
  background-color: ${(p) => p.theme.offset};
`

const SelectFrame = forwardRef((props, ref) => {
  const {
    startOpen,
    children,
    render,
    triggerLabel,
    triggerHasValue,
    searchControls,
    controls,
    disabled,
    width,
    onTypeCharacter,
    ...rest
  } = props

  const localRef = useRef()
  const $ref = ref || localRef

  const [isOpen, onOpen, onClose] = useToggle(startOpen)

  const contentRef = useRef()

  useOnClickOutside(onClose, contentRef, $ref)

  const renderProps = { onClose }

  return (
    <>
      <SelectTrigger
        ref={$ref}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        disabled={disabled}
        triggerLabel={triggerLabel}
        triggerHasValue={triggerHasValue}
        width={width}
      />

      {!disabled && isOpen && (
        <Popover
          ref={contentRef}
          targetRef={$ref}
          position={positionMatchWidthMaxHeight}
        >
          <KeyboardNavArea
            focusOnMount
            returnFocusOnUnmount
            arrowFocus
            stopPropagationOnEnter
            onEscape={onClose}
            onTypeCharacter={onTypeCharacter}
          >
            <List {...rest} ref={contentRef}>
              {/*<TopSpacer />*/}
              {searchControls && <TopControls>{searchControls}</TopControls>}
              <Content>{render ? render(renderProps) : children}</Content>
              {controls && (
                <BottomControls>
                  {_.isFunction(controls) ? controls(renderProps) : controls}
                </BottomControls>
              )}
            </List>
          </KeyboardNavArea>
        </Popover>
      )}
    </>
  )
})

export default SelectFrame
