import {
  LoadingContainer,
  Panels,
  Bar,
  Model,
  Breadcrumb,
  Page,
} from 'peach/components'
import { usePaymentInstrumentQuery } from 'peach/data'
import { getName } from 'peach/helpers'
import { useParams, useRouteMatch } from 'peach/router'

const PaymentInstrument = () => {
  const { url } = useRouteMatch()
  const { paymentInstrumentId } = useParams()

  const [paymentInstrument, query] = usePaymentInstrumentQuery({
    paymentInstrumentId,
  })

  const name = getName(paymentInstrument)

  const header = <Bar title={name} />

  return (
    <Panels header={header}>
      <Breadcrumb title={name} to={url} />
      <LoadingContainer query={query}>
        <Page.Full>
          <Model.Item json={paymentInstrument} />
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default PaymentInstrument
