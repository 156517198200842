import { useParams } from 'peach/router'

import ContactAddressSelect from './components/ContactAddressSelect'
import PaymentInstrumentSelect from './components/PaymentInstrumentSelect'
import PersonBreadcrumb from './components/PersonBreadcrumb'
import PersonManager from './PersonManager'

const usePersonId = () => {
  const { personId } = useParams()
  return personId
}

export {
  ContactAddressSelect,
  PaymentInstrumentSelect,
  PersonBreadcrumb,
  PersonManager,
  usePersonId,
}
