import { useStorage } from 'core/storage/StorageProvider'
import { Json, Page } from 'peach/components'
import { truncateString } from 'peach/helpers'

const Env = () => {
  const storage = useStorage()

  const { token, ...rest } = storage.getAll()

  const $token = truncateString(token)

  const result = { token: $token, ...rest }

  return (
    <Page.Full>
      <Json.Table title='Storage' json={result} />
    </Page.Full>
  )
}

export default Env
