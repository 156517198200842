import { useDataQuery, useQueryApi } from '../useQueryApi'

const MINUTE = 60 * 1000

const useCompanyQuery = (options) => {
  const [api, { companyId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId],

    queryFn: () => api.companies.company.get({ pathArgs: { companyId } }),

    staleTime: 5 * MINUTE,
  })
}

export default useCompanyQuery
