import { FC, useEffect, useMemo, useState } from 'react'

import { ResponseAPIKeyNoSecretAPIVersioning } from 'core/types'
import { StyledButton } from 'peach/components/Button/Button'
import FullPage from 'peach/components/Page/FullPage'
import { useDataQuery } from 'peach/data/useQueryApi'
import { useApi, useStorageState } from 'peach/hooks'
import * as R from 'remeda'
import styled from 'styled-components/macro'

import Complete from './Complete'
import CreateAppDomainsForm from './CreateAppDomainsForm'
import CreateCompanyForm from './CreateCompanyForm'
import CreateInvestorForm from './CreateInvestorForm'
import CreateRoleForm from './CreateRoleForm'
import CreateTeamForm from './CreateTeamForm'
import CreateUserAndAPIKeyForm from './CreateUserAndAPIKeyForm'
import CreateUsersForm from './CreateUsersForm'
import { Company, Role, Storage } from './types'

const STEPS = [
  'Create company',
  'Create role: API',
  'Create role: Admin',
  'Create role: Agent',
  'Create role: Supervisor',
  'Create role: Borrower',
  'Create role: peach-read-only',
  'Create investor: Lender',
  'Create user & API key: Peach super admin user',
  'Create user & API key: Lender service user',
  'Create team: "Peach Tier 2 Support"',
  'Create users: Peach',
  'Update app domains: Lender',
  'Complete',
]

const CreateCompany: FC = () => {
  const api: any = useApi()

  const [step, setStep] = useState<number | null>(0)
  const [reset, setReset] = useState(false)
  const [apiKeys, setApiKeys] = useState<
    ResponseAPIKeyNoSecretAPIVersioning[] | []
  >([])

  const [createCompany, setCreateCompany] = useStorageState<Storage>(
    'createCompany',
    null,
  )

  const company: Company = useMemo(
    () => R.find(createCompany ?? [], (c) => c?.completed === false) ?? {},
    [createCompany],
  )

  const updateCompany = (data: Company) =>
    setCreateCompany([
      data,
      ...R.reject(createCompany ?? [], (c) => c?.companyId === data?.companyId),
    ])

  const [roles, rolesQuery] = useDataQuery({
    queryKey: ['companies', company?.companyId ?? undefined, 'roles'],

    queryFn: () =>
      api.company.roles.get({
        pathArgs: { companyId: company?.companyId },
      }),
    enabled: !!company?.companyId,
  })

  const common = {
    title: STEPS[step ?? 0],
    company,
    updateCompany,
  }

  const rolesCommon = {
    ...common,
    rolesQuery,
  }

  const userAndAPIKeyCommon = {
    ...common,
    apiKeys,
    setApiKeys,
    roles,
  }

  useEffect(() => {
    if (R.isNil(company) || R.isEmpty(company)) {
      setStep(0)
    } else if (
      R.find(
        company.appDomains ?? [],
        (domain) => domain.type === 'borrower',
      ) &&
      R.find(company.appDomains ?? [], (domain) => domain.type === 'agent') &&
      R.find(company.appDomains ?? [], (domain) => domain.type === 'admin')
    ) {
      setStep(13)
    } else if (
      // All we care about is that there are more than 2 users at this step.
      // It's possible that the default set of Peach users will be different
      // so we're not going to specifically check the auth emails of any of the
      // users here.
      R.length(company.users ?? []) > 2
    ) {
      setStep(12)
    } else if (
      R.find(
        company?.teams ?? [],
        (team) =>
          team.name === 'Peach Tier 2 Support' &&
          team.companyId === company?.companyId,
      )
    ) {
      setStep(11)
    } else if (
      R.find(company?.users ?? [], (user) => user.userType === 'lender')
    ) {
      setStep(10)
    } else if (
      R.find(company?.users ?? [], (user) => user.userType === 'peach')
    ) {
      setStep(9)
    } else if (R.length(company.investors ?? []) > 0) {
      setStep(8)
    } else if (
      R.find(
        company.roles ?? [],
        (role: Role) => role.alias === 'peach-read-only',
      )
    ) {
      setStep(7)
    } else if (
      R.find(company.roles ?? [], (role: Role) => role.alias === 'borrower')
    ) {
      setStep(6)
    } else if (
      R.find(company.roles ?? [], (role: Role) => role.alias === 'supervisor')
    ) {
      setStep(5)
    } else if (
      R.isArray(company?.roles) &&
      R.find(company.roles, (role: Role) => role.alias === 'agent')
    ) {
      setStep(4)
    } else if (
      R.isArray(company?.roles) &&
      R.find(company.roles, (role: Role) => role.alias === 'admin')
    ) {
      setStep(3)
    } else if (
      R.isArray(company?.roles) &&
      R.find(company.roles, (role: Role) => role.alias === 'api')
    ) {
      setStep(2)
    } else if (company?.companyId) {
      setStep(1)
    }
  }, [company, roles])

  return (
    <FullPage>
      <TopContainer>
        <Title>Create Company</Title>
        <ActionsContainer>
          {reset ? (
            <ActionsContainer>
              <AreYouSure>
                Are you sure you want to reset everything? This will start the
                "Create Company" flow to the beginning.
              </AreYouSure>
              <ButtonsContainer>
                <StyledButton
                  type='button'
                  primary
                  onClick={() => {
                    setCreateCompany(
                      R.reject(
                        createCompany ?? [],
                        (c) => c?.companyId === company?.companyId,
                      ),
                    )
                    setReset(false)
                  }}
                >
                  Confirm
                </StyledButton>
                <StyledButton type='button' onClick={() => setReset(false)}>
                  Cancel
                </StyledButton>
              </ButtonsContainer>
            </ActionsContainer>
          ) : (
            <StyledButton
              type='button'
              onClick={() => setReset(true)}
              disabled={R.isNil(createCompany) || R.isEmpty(createCompany)}
            >
              Reset
            </StyledButton>
          )}
        </ActionsContainer>
      </TopContainer>
      <Container>
        <Cell>
          <Steps>
            {STEPS.map((s, i) => (
              <Step key={i} $current={step === i}>
                {s}
              </Step>
            ))}
          </Steps>
          {!R.isEmpty(company) && (
            <NewCompanyJson>
              <pre>{JSON.stringify(company, null, 2)}</pre>
            </NewCompanyJson>
          )}
        </Cell>
        <Cell>
          {step === 0 ? (
            <CreateCompanyForm updateCompany={updateCompany} title={STEPS[0]} />
          ) : step === 1 ? (
            <CreateRoleForm role='api' {...rolesCommon} />
          ) : step === 2 ? (
            <CreateRoleForm role='admin' {...rolesCommon} />
          ) : step === 3 ? (
            <CreateRoleForm role='agent' {...rolesCommon} />
          ) : step === 4 ? (
            <CreateRoleForm role='supervisor' {...rolesCommon} />
          ) : step === 5 ? (
            <CreateRoleForm role='borrower' {...rolesCommon} />
          ) : step === 6 ? (
            <CreateRoleForm role='peach-read-only' {...rolesCommon} />
          ) : step === 7 ? (
            <CreateInvestorForm {...common} />
          ) : step === 8 ? (
            <CreateUserAndAPIKeyForm
              key='peach'
              userType='peach'
              {...userAndAPIKeyCommon}
            />
          ) : step === 9 ? (
            <CreateUserAndAPIKeyForm
              key='lender'
              userType='lender'
              {...userAndAPIKeyCommon}
            />
          ) : step === 10 ? (
            <CreateTeamForm {...common} />
          ) : step === 11 ? (
            <CreateUsersForm {...common} />
          ) : step === 12 ? (
            <CreateAppDomainsForm {...common} />
          ) : step === 13 ? (
            <Complete {...common} />
          ) : null}
        </Cell>
      </Container>
    </FullPage>
  )
}

export default CreateCompany

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.div`
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 600;
  color: #222;
`

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: flex-end;
  margin-bottom: 16px;
`

const AreYouSure = styled.div`
  margin-bottom: 8px;
  width: 380px;
  font-size: 12px;
`

const ButtonsContainer = styled.div`
  display: flex;
  gap: 8px;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 320px auto;
`

const Cell = styled.div`
  padding: 24px 16px;
  border: 1px solid black;
`

const Steps = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`

const Step = styled.div<{ $current: boolean }>`
  ${(p) => (p.$current ? 'opacity: 1' : 'opacity: 0.7')};
  font-size: 14px;
  font-weight: 600;
  color: #222;
`

const NewCompanyJson = styled.div<{ $showAll?: boolean }>`
  margin-top: 16px;
  border-radius: 4px;
  background-color: #f8f8f8;
  padding: 4px 8px;
  white-space: break-spaces;
  font-size: 10px;
  color: #222;

  pre {
    overflow: auto;
    height: auto;
  }
`
