import { useAllSessionRoles } from 'peach/data'

import ModelLink from './ModelLink'

const RoleLink = ({ roleId, jsonKey }) => {
  const [roles, query] = useAllSessionRoles()

  const role = _.find(roles, { id: roleId })

  return <ModelLink id={roleId} model={role} query={query} jsonKey={jsonKey} />
}

export default RoleLink
