import { useRef, useEffect } from 'react'

import { getFocusableElements } from 'peach/helpers/focus'

import LoadingContainer from '../Loading/LoadingContainer'
import Panels from '../Panels/Panels'

import BigModalContainer from './BigModalContainer/BigModalContainer'
import ModalActionsBar from './ModalActionsBar'
import ModalContents from './ModalContents'
import ModalTitleBar from './ModalTitleBar'

const focusFirstFocusableItem = (...nodes) => {
  for (const node of nodes) {
    const els = getFocusableElements(node)
    if (els[0]) {
      els[0].focus()
      return
    }
  }
}

const BigModal = (props) => {
  const {
    title,
    big,
    noPadding,
    children,

    onCancel,

    onSubmit,
    isSubmitting,
    sending,
    submitLabel,

    error,
    onClearError,

    isOpen,
    onClose,

    onToggleDebugger,
    debugValue,
    onDebugReset,
  } = props

  const modalRef = useRef()
  const contentsRef = useRef()

  useEffect(() => {
    const onTimeout = () => {
      focusFirstFocusableItem(contentsRef.current, modalRef.current)
    }
    const id = setTimeout(onTimeout, 100)
    return () => clearTimeout(id)
  }, [])

  const displayTitle = title || 'Edit'

  return (
    <BigModalContainer
      ref={modalRef}
      isOpen={isOpen}
      onClose={onClose}
      big={big || debugValue}
    >
      <Panels
        header={
          <ModalTitleBar
            title={displayTitle}
            onClose={onClose}
            onToggleDebugger={onToggleDebugger}
            onDebugReset={onDebugReset}
          />
        }
        right={debugValue}
        rightWidth='40%'
        footer={
          <ModalActionsBar
            onCancel={onCancel || onClose}
            onSubmit={onSubmit}
            isSubmitting={isSubmitting}
            submitLabel={submitLabel || title}
            sending={isSubmitting || sending}
          />
        }
      >
        <LoadingContainer
          loading={isSubmitting || sending}
          message='Submitting…'
        >
          <ModalContents
            isSubmitting={isSubmitting}
            error={error}
            onClearError={onClearError}
            ref={contentsRef}
            noPadding={noPadding}
          >
            {children}
          </ModalContents>
        </LoadingContainer>
      </Panels>
    </BigModalContainer>
  )
}

export default BigModal
