import { forwardRef } from 'react'

import BooleanRadio from './Boolean/BooleanRadio'
import BooleanSelect from './BooleanSelect'
import MultiSelect from './MultiSelect'
import RadioSelect from './Radio/RadioSelect'
import SingleSelect from './SingleSelect'

const Select = forwardRef((props, ref) => {
  const { multi, boolean, radio, booleanSelect, loading, query, ...rest } =
    props

  const isLoading = query?.isLoading || loading

  const renderProps = {
    loading: isLoading,
    ...rest,
  }

  if (booleanSelect) {
    return <BooleanSelect {...renderProps} ref={ref} />
  }

  if (boolean) {
    return <BooleanRadio {...renderProps} ref={ref} />
  }

  if (radio) {
    return <RadioSelect {...renderProps} ref={ref} />
  }

  if (multi) {
    return <MultiSelect {...renderProps} ref={ref} />
  }

  return <SingleSelect {...renderProps} ref={ref} />
})

export default Select
