import {
  LoadingContainer,
  Modal,
  Select,
  NumericInput,
  Input,
  Page,
  ModelList,
  DateInput,
} from 'peach/components'
import { useLoanTransactionsQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { useLoanId } from 'peach/scopes/loan'
import { usePersonId, PaymentInstrumentSelect } from 'peach/scopes/person'

const LoanTransactions = () => {
  const personId = usePersonId()
  const loanId = useLoanId()

  const api = useApi()

  const [transactions, query] = useLoanTransactionsQuery()

  const refresh = () => query.refetch()

  const sendCreateTransaction = async (transaction, diff) => {
    await api.loan.transactions.post({
      pathArgs: { personId, loanId },
      body: transaction,
    })
    refresh()
  }

  const types = ['oneTime', 'service', 'downPayment']

  const newTransaction = {
    paymentInstrumentId: null,
    amount: 100,
    type: 'oneTime',
  }

  const sendCreateExternalCheck = async (options) => {
    const {
      // instrument props
      accountNumber,
      routingNumber,
      checkNumber,
      accountHolderType,
      accountHolderName,
      // transaction props
      effectiveDate,
      amount,
    } = options

    const resp = await api.person.paymentInstruments.post({
      pathArgs: { personId },
      body: {
        isExternal: true,
        instrumentType: 'check',
        accountNumber,
        routingNumber,
        checkNumber,
        accountHolderType,
        accountHolderName,
      },
    })

    const instrument = _.isArray(resp.data) ? _.first(resp.data) : resp.data

    const paymentInstrumentId = instrument.id

    await api.loan.transactions.post({
      pathArgs: { personId, loanId },
      body: {
        isExternal: true,
        type: 'oneTime',
        paymentInstrumentId,
        amount,
        effectiveDate,
      },
    })

    refresh()
  }

  const createTransaction = (
    <Modal
      title='Create Transaction'
      onSubmit={sendCreateTransaction}
      initialValue={newTransaction}
    >
      <Select formKey='type' options={types} />
      <PaymentInstrumentSelect formKey='paymentInstrumentId' />
      <NumericInput formKey='amount' />
      <Input formKey='scheduledDate' />
    </Modal>
  )

  const createExternalTransaction = (
    <Modal
      title='Create External Check Transaction'
      onSubmit={sendCreateExternalCheck}
      initialValue={newTransaction}
    >
      <Input formKey='accountNumber' />
      <Input formKey='routingNumber' />
      <Input formKey='checkNumber' />
      <Select formKey='accountHolderType' options={['business', 'personal']} />
      <Input formKey='accountHolderName' />
      <NumericInput formKey='amount' />
      <DateInput formKey='effectiveDate' />
    </Modal>
  )

  const columns = [
    {
      label: 'Info',
      renderJson: (txn) => {
        return {
          effectiveDate: txn.timestamps.effectiveDate,
          createdBy: txn.createdBy?.name,
        }
      },
    },
  ]

  return (
    <LoadingContainer query={query}>
      {createExternalTransaction}
      {createTransaction}
      <Page.Full>
        <ModelList
          title='Transactions'
          json={transactions}
          columns={columns}
          withRelated
          withDetails
          withTimestamps
        />
      </Page.Full>
    </LoadingContainer>
  )
}

export default LoanTransactions
