import {
  Model,
  LoadingContainer,
  Breadcrumb,
  Panels,
  Bar,
  Page,
  Tab,
  Tabs,
} from 'peach/components'
import { useLoanTypeQuery } from 'peach/data'
import { getName } from 'peach/helpers'
import { Switch, Route, Redirect, useRouteMatch } from 'peach/router'

import DynamicFeeTypes from './FeeTypes/DynamicFeeTypes'
import LoanTypeNotifications from './LoanTypeNotifications'

// todo:  add back fee types but using queries

const LoanType = () => {
  const { url, path } = useRouteMatch()

  const [loanType, query] = useLoanTypeQuery()

  const title = getName(loanType)

  const tabs = (
    <Tabs basePath={url}>
      <Tab to='' exact>
        Loan Type
      </Tab>
      <Tab to='/notifications'>Notifications</Tab>
      <Tab to='/fee-types'>Fee Types</Tab>
    </Tabs>
  )

  const header = <Bar title={title} right={tabs} />

  return (
    <Panels header={header}>
      <LoadingContainer query={query}>
        <Breadcrumb title={getName(loanType)} to={url} />

        <Switch>
          <Route path={path} exact>
            <Page.Full>
              <Model.Item json={loanType} />
            </Page.Full>
          </Route>

          <Route path={`${path}/notifications`} crumb>
            <LoanTypeNotifications />
          </Route>

          <Route path={`${path}/fee-types`} crumb>
            <DynamicFeeTypes />
          </Route>

          <Redirect to={url} />
        </Switch>
      </LoadingContainer>
    </Panels>
  )
}

export default LoanType
