import { LoadingContainer, Page, Modal, Input, Select } from 'peach/components'
import { useInvestorsQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { ListSubroutes, useParams } from 'peach/router'

const CompanyInvestors = () => {
  const api = useApi()

  const { companyId } = useParams()

  const [investors, query] = useInvestorsQuery()

  const sendAddInvestor = async (newInvestor) => {
    await api.company.investors.post({
      pathArgs: { companyId },
      body: newInvestor,
    })
    query.refetch()
  }

  const addInvestor = (
    <Modal trigger title='Add Investor' onSubmit={sendAddInvestor}>
      <Input formKey='legalName' />
      <Select boolean formKey='default' />
    </Modal>
  )

  return (
    <LoadingContainer query={query}>
      {addInvestor}
      <Page.Full>
        <ListSubroutes
          title='Investors'
          json={investors}
          paramKey='investorId'
        />
      </Page.Full>
    </LoadingContainer>
  )
}

export default CompanyInvestors
