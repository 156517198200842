import { useApi } from 'core/api/ApiProvider'
import { useAuthState } from 'core/auth/AuthProvider'
import { useParams } from 'peach/router'

import useDataMutation from './internal/useDataMutation'
import useDataQuery from './internal/useDataQuery'

const useQueryApi = ({ companyId: passedCompanyId, ...options } = {}) => {
  const { companyId: paramsCompanyId, ...params } = useParams()

  const { companyId: sessionCompanyId } = useAuthState()

  const companyId = passedCompanyId || paramsCompanyId || sessionCompanyId

  const api = useApi()

  const $options = { companyId, ...params, ...options }

  return [api, $options, { params, options }]
}

export { useQueryApi, useDataQuery, useDataMutation }
