import { Breadcrumb } from 'peach/components'
import { Switch, Route, Redirect, useRouteMatch } from 'peach/router'

import LoanAutopay from './Autopay/LoanAutopay'
import LoanLedger from './Ledger/Ledger'
import LoanAdvances from './LoanAdvances/LoanAdvances'
import LoanBalances from './LoanBalances'
import LoanBasics from './LoanBasics'
import LoanDocuments from './LoanDocuments/LoanDocuments'
import DrawsRoutes from './LoanDraws/DrawsRoutes'
import LoanExpectedPayments from './LoanExpectedPayments'
import LoanFees from './LoanFees'
import LoanObligations from './LoanObligations'
import LoanPeriods from './LoanPeriods'
import LoanRates from './LoanRates'
import LoanRefunds from './LoanRefunds'
import LoanStatements from './LoanStatements/LoanStatements'
import LoanTransaction from './LoanTransaction'
import LoanTransactions from './LoanTransactions'
import LoanTimeline from './Timeline/LoanTimeline'

// import { usePersonId } from 'peach/scopes/person'
// import { useLoanId } from 'peach/scopes/loan'

const LoanRoutes = () => {
  const { path, url } = useRouteMatch()

  return (
    <Switch>
      <Route path={`${path}`} exact>
        <LoanBasics />
      </Route>

      <Route path={`${path}/timeline`} crumb>
        <LoanTimeline />
      </Route>

      <Route path={`${path}/rates`} crumb>
        <LoanRates />
      </Route>

      <Route path={`${path}/transactions`} exact crumb>
        <LoanTransactions />
      </Route>

      <Route path={`${path}/transactions/:transactionId`}>
        <Breadcrumb title='Transactions' to={`${url}/transactions`} />

        <LoanTransaction />
      </Route>

      <Route path={`${path}/expected-payments`} crumb>
        <LoanExpectedPayments />
      </Route>

      <Route path={`${path}/obligations`} crumb>
        <LoanObligations />
      </Route>

      <Route path={`${path}/autopay`} crumb>
        <LoanAutopay />
      </Route>

      <Route path={`${path}/balances`} crumb>
        <LoanBalances />
      </Route>

      <Route path={`${path}/draws`} crumb>
        <DrawsRoutes />
      </Route>

      <Route path={`${path}/refunds`} crumb>
        <LoanRefunds />
      </Route>

      <Route path={`${path}/fees`} crumb>
        <LoanFees />
      </Route>

      <Route path={`${path}/documents`} crumb>
        <LoanDocuments />
      </Route>

      <Route path={`${path}/statements`} crumb>
        <LoanStatements />
      </Route>

      <Route path={`${path}/periods`} crumb>
        <LoanPeriods />
      </Route>

      <Route path={`${path}/ledger`} crumb>
        <LoanLedger />
      </Route>

      <Route path={`${path}/advances`} crumb>
        <LoanAdvances />
      </Route>

      <Redirect to={`${url}`} />
    </Switch>
  )
}

export default LoanRoutes
