import { useDataQuery, useQueryApi } from '../useQueryApi'

const MINUTE = 60 * 1000

const useSessionCompaniesQuery = (options) => {
  const [api] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['session', 'companies'],

    queryFn: async () => {
      return api.companies.companies.get({
        queryParams: { limit: 100, excludeInactive: true },
      })
    },

    staleTime: 5 * MINUTE,
  })
}

export default useSessionCompaniesQuery
