import { useState, useRef } from 'react'

import { useOnMountEffect, useOnChangeEffect } from 'peach/hooks'

const wrapItem = (item) => {
  return { listId: _.uniqueId('list-item-'), item }
}

const wrapValue = (value, prevList) => {
  return _.map(value, (item, index) => {
    const prevEntry = prevList?.[index]

    if (prevEntry && item === prevEntry.item) {
      return prevEntry
    } else {
      return wrapItem(item)
    }
  })
}

const unWrap = (listItem) => listItem?.item

const unWrapList = (list) => _.map(list, unWrap)

const updateAt = (list, index, item) => {
  const newList = [...list]
  newList[index] = item
  return newList
}

const matches = (arr1, arr2) => {
  return (
    _.size(arr1) === _.size(arr2) &&
    _.every(arr1, (item1, index) => item1 === arr2[index])
  )
}

const useListOnChange = ({ value, onChange, defaultValue } = {}) => {
  useOnMountEffect(() => {
    console.log('mounting', { value })
  })
  const [list, setList] = useState(() => wrapValue(value))

  const nextListRef = useRef(value)

  const onListChange = (newList) => {
    nextListRef.current = newList
    setList(newList)
    if (onChange) onChange(unWrapList(newList))
  }

  useOnChangeEffect([value], ([prevValue]) => {
    const currentListValue = unWrapList(list)
    const nextListValue = unWrapList(nextListRef.current)

    const matchesValue = matches(value, prevValue)
    const matchesCurrent = matches(value, currentListValue)
    const matchesNext = matches(value, nextListValue)

    const shouldUpdate = !matchesValue && !matchesCurrent && !matchesNext

    // console.log('onValueChange', {
    //   value,
    //   prevValue,
    //   nextListValue,
    //   matchesValue,
    //   matchesCurrent,
    //   matchesNext,
    // })

    if (shouldUpdate) {
      const listItem1 = list?.[0]?.item
      const valueItem1 = value?.[0]

      const isEqual = listItem1 === valueItem1
      console.log('onShouldUpdate', {
        value,
        list,
        listItem1,
        valueItem1,
        isEqual,
      })

      const newList = wrapValue(value, list)
      nextListRef.current = newList
      setList(newList)
    }
  })

  const onAddItem = (...args) => {
    const newItem = _.size(args) === 0 ? defaultValue : args[0]
    const newList = [...list, wrapItem(newItem)]
    console.log('onAddItem', { newList })
    onListChange(newList)
  }

  const onRemoveItem = (listId) => {
    const newList = _.reject(list, { listId })
    console.log('onRemove', { newList })
    onListChange(newList)
  }

  const onUpdateItem = (listId, newItem) => {
    const index = _.findIndex(list, { listId })
    if (index < 0) return list
    const prevItem = list[index].item
    const updatedItem = _.isFunction(newItem) ? newItem(prevItem) : newItem

    const newListItem = { listId, item: updatedItem }
    const newList = updateAt(list, index, newListItem)
    console.log('onUpdate', { newList })
    onListChange(newList)
  }

  return { value, onChange, list, onAddItem, onRemoveItem, onUpdateItem }
}

export default useListOnChange
