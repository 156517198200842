import { Switch, Route, Redirect, useRouteMatch } from 'peach/router'

import CompanyEmployee from './CompanyEmployee'
import CompanyEmployees from './CompanyEmployees'

const CompanyEmployeesRoutes = () => {
  const { path, url } = useRouteMatch()

  return (
    <Switch>
      <Route path={path} exact>
        <CompanyEmployees />
      </Route>

      <Route path={`${path}/:employeeId`}>
        <CompanyEmployee />
      </Route>

      <Redirect to={url} />
    </Switch>
  )
}

export default CompanyEmployeesRoutes
