import { useDataQuery, useQueryApi } from '../useQueryApi'

const useLoanTypesQuery = (options) => {
  const [api, { companyId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'loan-types'],

    queryFn: () => {
      return api.company.loanTypes.get({
        pathArgs: { companyId },
        queryParams: { limit: 100 },
      })
    },
  })
}

export default useLoanTypesQuery
