import { Input, Textarea, Select, Form } from 'peach/components'

import CaseTypeIdSelect from '../case/CaseTypeSelect'

const UpdateCaseForm = () => {
  return (
    <>
      <CaseTypeIdSelect formKey='typeId' disabled />

      <Input formKey='externalId' />

      <Select boolean formKey='autoCreated' />

      <Select
        formKey='status'
        options={[
          'initiated',
          'processing',
          'completed',
          'canceled',
          'reopened',
        ]}
        nullable
      />

      <Textarea.Json formKey='metaData' />

      <Form.If if={{ caseType: '' }} />
    </>
  )
}

export default UpdateCaseForm
