import QueryProvider from 'core/data/QueryProvider'
import { CrumbProvider } from 'peach/components/Breadcrumbs/Crumb'
import { LastCompanyIdProvider } from 'peach/scopes/company/LastCompanyIdProvider'

import { ApiProvider } from './api/ApiProvider'
import useAuthedRequest from './api/useAuthedRequest'
import usePeachApi from './api/usePeachApi'
import { AuthProvider } from './auth/AuthProvider'
import useAuthManagement from './auth/useAuthManagement'
import useSessionPersonManagement from './auth/useSessionPersonManagement'
import { StorageProvider } from './storage/StorageProvider'

const AppProviders = ({ children, apiBase, storage }) => {
  const [authState, authApi] = useAuthManagement({ apiBase, storage })
  const { token, apiKey, personId } = authState
  const { setRequiresReauth } = authApi
  const [executeRequest] = useAuthedRequest({
    apiBase,
    token,
    apiKey,
    on401: setRequiresReauth,
  })
  const person = useSessionPersonManagement(personId, executeRequest)
  const [peachApi] = usePeachApi(executeRequest)

  return (
    <StorageProvider storage={storage}>
      <LastCompanyIdProvider>
        <AuthProvider authState={authState} authApi={authApi} person={person}>
          <QueryProvider>
            <ApiProvider api={peachApi}>
              <CrumbProvider>{children}</CrumbProvider>
            </ApiProvider>
          </QueryProvider>
        </AuthProvider>
      </LastCompanyIdProvider>
    </StorageProvider>
  )
}

export default AppProviders
