import {
  Model,
  LoadingContainer,
  Modal,
  Panels,
  Bar,
  Input,
  Page,
  Breadcrumb,
} from 'peach/components'
import { getName } from 'peach/helpers'
import { useReadData, useApi } from 'peach/hooks'
import { CrossCompanyRolesSelect } from 'peach/scopes/session'
import { useParams, useRouteMatch } from 'react-router-dom'

const CompanyUser = () => {
  const { url } = useRouteMatch()
  const { companyId, userId } = useParams()

  const api = useApi()

  const [user, loading, error, refresh] = useReadData(() => {
    return api.company.user.get({ pathArgs: { companyId, userId } })
  }, [companyId, userId])

  const sendEditUser = async (user, diff) => {
    await api.company.user.put({
      pathArgs: { companyId, userId },
      body: diff,
    })
    refresh()
  }

  const initialValue = { roles: user?.roleIds || [], ...user }

  const editUser = (
    <Modal
      trigger
      title='Edit User'
      onSubmit={sendEditUser}
      initialValue={initialValue}
    >
      <Input formKey='associatedPersonId' />

      <CrossCompanyRolesSelect formKey='roles' companyId={companyId} />
    </Modal>
  )

  const header = <Bar title={getName(user)} right={editUser} />

  return (
    <Panels header={header}>
      <Breadcrumb title={getName(user)} to={url} />
      <LoadingContainer loading={loading} error={error}>
        <Page.Full>
          <Model.Item json={user} />
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default CompanyUser
