import { forwardRef } from 'react'

import { Link as RouterLink } from 'react-router-dom'

const Link = forwardRef((props, ref) => {
  // peeling off props that react-router yells about
  const { isSelected, ...rest } = props
  return <RouterLink {...rest} ref={ref} />
})

export default Link
