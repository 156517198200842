import { useDataQuery, useQueryApi } from '../useQueryApi'

const useDrawRateQuery = (options) => {
  const [api, params] = useQueryApi(options)

  const { companyId, personId, loanId, drawId } = params

  return useDataQuery({
    queryKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'draws',
      drawId,
      'rates',
    ],

    queryFn: () => {
      return api.draw.rates.get({
        pathArgs: { companyId, personId, loanId, drawId },
      })
    },

    enabled: !!(companyId && personId && loanId && drawId),
  })
}

export default useDrawRateQuery
