import { useEffect } from 'react'

import { usePrevious } from 'peach/hooks'
import { useHistory, useLocation } from 'peach/router'
import { QueryParamsProvider } from 'peach/router/useQueryParams'

import AppProviders from './AppProviders'
import useEnv, { EnvProvider } from './env/useEnv'
import DarkModeProvider from './theme/DarkModeProvider'

const SystemProviders = ({ children }) => {
  const [apiBase, storage, envApi, envId] = useEnv()

  const { setApiBase } = envApi

  const previousEnvId = usePrevious(envId)
  const { pathname } = useLocation()

  const history = useHistory()
  // redirect only when switching envs.  this prevents getting 403s from ID, etc
  // that exist in one env but not another, but preserves what seciton
  // the user was in
  useEffect(() => {
    if (envId && previousEnvId && previousEnvId !== envId) {
      const [, url] = pathname.split('/')
      history.push(url ? `/${url}` : '/')
    }
  }, [history, pathname, previousEnvId, envId])

  return (
    <QueryParamsProvider>
      <EnvProvider
        key={envId}
        apiBase={apiBase}
        setApiBase={setApiBase}
        envApi={envApi}
      >
        <AppProviders key={envId} apiBase={apiBase} storage={storage}>
          <DarkModeProvider>{children}</DarkModeProvider>
        </AppProviders>
      </EnvProvider>
    </QueryParamsProvider>
  )
}

export default SystemProviders
