import {
  Json,
  ModelViewer,
  Pagination,
  Page,
  Bar,
  Panels,
} from 'peach/components'

const ApiCallResponse = ({ resp, loading, params, onSetParams }) => {
  if (resp?.data) {
    const { data, ...rest } = resp

    const pagination = (
      <Pagination resp={resp} options={params} setOptions={onSetParams} />
    )

    const header = <Bar left={pagination} />

    return (
      <Panels header={_.has(rest, 'nextUrl') && header}>
        <Page.Full>
          <Json.Table title='Metadata' json={rest} />
          <ModelViewer title='Data' json={data} />
        </Page.Full>
      </Panels>
    )
  }
  return (
    <Page.Full>
      <ModelViewer json={resp} />
    </Page.Full>
  )
}

export default ApiCallResponse
