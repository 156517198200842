import { useParams } from 'peach/router'

import LoanFeeTypeSelect from './components/LoanFeeTypeSelect'
import LoanStatusSelect from './components/LoanStatusSelect'

// todo: replace with direct usage of useParams, or useCombinedParams if local overload is needed
const useLoanId = () => {
  const { loanId } = useParams()
  return loanId
}

export { LoanStatusSelect, LoanFeeTypeSelect, useLoanId }
