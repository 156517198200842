import styled from 'styled-components/macro'

const Wrapper = styled.div`
  padding: 12px;
`

const Content = styled.div``

const FullPage = ({ children }) => {
  return (
    <Wrapper>
      <Content>{children}</Content>
    </Wrapper>
  )
}

export default FullPage
