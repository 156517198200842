import { useState, useCallback } from 'react'

// this should be considered deprecated and the few spots that use it should just use regular state

const useIsOpen = ({ isOpen, onOpen, onClose } = {}) => {
  const [localIsOpen, setLocalIsOpen] = useState(isOpen)

  const $isOpen = onOpen ? isOpen : localIsOpen

  const $onOpen = useCallback(() => {
    if (onOpen) onOpen()
    setLocalIsOpen(true)
  }, [onOpen])

  const $onClose = useCallback(() => {
    if (onClose) onClose()
    setLocalIsOpen(false)
  }, [onClose])

  const result = { isOpen: $isOpen, onOpen: $onOpen, onClose: $onClose }

  return result
}

export default useIsOpen
