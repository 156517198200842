import { useDataQuery, useQueryApi } from '../useQueryApi'

const useCompanyPermissionsQuery = (options) => {
  const [api, { companyId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId],

    queryFn: () => {
      return api.company.permissions.get({ pathArgs: { companyId } })
    },
  })
}

export default useCompanyPermissionsQuery
