import Select from './Select'

/* 

import Select from './Select'

import BooleanSelect from './BooleanSelect'

Select.Boolean = BooleanSelect

export default Select
*/

export default Select
