import { parseError } from 'peach/helpers'
import styled from 'styled-components/macro'

const Bar = styled.div`
  padding: 4px;
  position: absolute;
  top: 8px;
  left: 12px;
  right: 12px;
`

const Contents = styled.div`
  padding: 8px 12px;
  background-color: ${(p) => p.theme.offset};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: red;
  background-color: #fcc;
  border: 1px solid red;
  border-radius: 3px;
  white-space: pre-wrap;
`
const Message = styled.span`
  display: inline-block;
  font-size: 12px;
`

const ClearButton = styled.button`
  margin: 0;
  padding: 4px 12px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  font-size: 14px;
  color: red;
  &:hover {
    text-decoration: underline;
  }
`

const ModalErrorBar = ({ error, onClear }) => {
  return (
    <Bar>
      <Contents>
        <Message>{parseError(error)}</Message>
        <ClearButton onClick={onClear}>Clear</ClearButton>
      </Contents>
    </Bar>
  )
}

export default ModalErrorBar
