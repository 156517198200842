import { forwardRef } from 'react'

import {
  useFormValueAndOnChange,
  useOnFormValidate,
} from 'peach/components/Form/FormProvider'
import { getComponentName } from 'peach/helpers'

import useLogKeyWarning from './useLogKeyWarning'

const withFormValue = (Component, options = {}) => {
  const { renderWhenHidden } = options
  const name = getComponentName(Component)

  const FormValue = forwardRef((props, ref) => {
    const {
      formKey,
      required,
      value: passedValue,
      onChange: passedOnChange,
      validation: passedValidation,
      onValidationChange: passedOnValidationChange,
      persistOnUnmount,
      onValidate,
      label,
      onBlur: passedOnBlur,
      ...rest
    } = props

    useLogKeyWarning(props, name)

    const { value, onChange, onValidationChange, isHidden } =
      useFormValueAndOnChange({
        formKey,
        value: passedValue,
        onChange: passedOnChange,
        onValidationChange: passedOnValidationChange,
        persistOnUnmount,
      })

    const { meta, onBlur } = useOnFormValidate({
      value,
      formKey,
      label,
      required,
      onValidate,
      onValidationChange,
      onBlur: passedOnBlur,
    })

    const hide = !renderWhenHidden && isHidden

    return hide ? null : (
      <Component
        ref={ref}
        formKey={formKey}
        value={value}
        onChange={onChange}
        required={required}
        label={label}
        // validation props
        meta={meta}
        onBlur={onBlur}
        // rest
        {...rest}
      />
    )
  })

  FormValue.displayName = getComponentName(Component, 'withFormValue')

  return FormValue
}

export default withFormValue
