import { forwardRef } from 'react'

import styled from 'styled-components/macro'

const ClearButton = styled.button`
  display: inline-block;
  background-color: transparent;
  border: none;
  border-radius: 4px;
  height: 20px;
  width: 20px;
  text-align: center;
  vertical-align: middle;
  color: ${(p) => p.theme.disabledText};
  font-size: 14px;
  cursor: pointer;
  &:hover {
    color: ${(p) => p.theme.mutedText};
  }
  &:disabled {
    color: ${(p) => p.theme.disabledText};
    opacity: 0.5;
  }
`

const X = styled.span`
  position: relative;
  // top: -1px;
  // right: 0;
`

const SelectTopSearch = forwardRef((props, ref) => {
  const { onClick, show, disabled } = props

  const isShown = _.has(props, 'show') ? !!show : true

  return isShown ? (
    <ClearButton onClick={onClick} disabled={disabled} ref={ref}>
      <X>✗</X>
    </ClearButton>
  ) : null
})

export default SelectTopSearch
