import { useEmployeesQuery } from 'peach/data'
import { getName, getLink, searchObjects } from 'peach/helpers'

const useEmployeePages = ({ query, companyId }) => {
  const [employees, employeesQuery] = useEmployeesQuery({ companyId })

  const pages = _.map(employees, (employee) => {
    return {
      group: 'Employees',
      label: getName(employee),
      to: getLink(employee),
      subtitle: 'Employee',
    }
  })

  const results = searchObjects(pages, query, { max: 5, keys: ['label'] })

  return [results, employeesQuery]
}

export default useEmployeePages
