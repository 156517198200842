import { useDataQuery, useQueryApi } from '../useQueryApi'

const useLoanBalancesQuery = (options) => {
  const [api, { personId, loanId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['people', personId, 'loans', loanId, 'balances'],

    queryFn: () => {
      return api.loan.balances.get({
        pathArgs: { personId, loanId },
      })
    },
  })
}

export default useLoanBalancesQuery
