import { Select } from 'peach/components'
import { useLoanTypesQuery } from 'peach/data'
import { getName, formatDate, combineStrings } from 'peach/helpers'

const TYPES = [
  {
    name: 'Installment',
    object: 'installmentConfiguration',
    kind: 'installment',
  },
  {
    name: 'Line of credit',
    object: 'lineOfCreditConfiguration',
    kind: 'lineOfCredit',
  },
  {
    name: 'Unknown LoanType Type',
    kind: 'unknown',
  },
]

const getInfoByObject = (object) => {
  return _.find(TYPES, { object }) || _.find(TYPES, { kind: 'unknown' })
}

const LoanTypeSelect = ({ kind, ...rest }) => {
  const [loanTypes] = useLoanTypesQuery()

  const sortedLoanTypes = _.orderBy(loanTypes, ['createdAt'], ['desc'])

  const options = _.map(sortedLoanTypes, (loanType) => {
    const { createdAt, description, id, object } = loanType

    const { kind, name } = getInfoByObject(object)

    const note = combineStrings(
      [description, name, createdAt && `Created ${formatDate(createdAt)}`],
      ' / ',
    )

    return { value: id, label: getName(loanType), note, kind }
  })

  const filteredOptions = kind ? _.filter(options, { kind }) : options

  return (
    <Select
      {...rest}
      notes
      label='Loan Type'
      options={filteredOptions}
      searchable
    />
  )
}

export default LoanTypeSelect
