import { useDataQuery, useQueryApi } from '../useQueryApi'

const useDrawPurchaseQuery = (options) => {
  const [api, params] = useQueryApi(options)

  const { companyId, personId, loanId, drawId, purchaseId } = params

  return useDataQuery({
    queryKey: [
      'people',
      personId,
      'loans',
      loanId,
      'draws',
      drawId,
      'purchases',
      purchaseId,
    ],

    queryFn: () => {
      return api.draw.purchase.get({
        pathArgs: { personId, loanId, drawId, purchaseId },
      })
    },

    enabled: !!(companyId && personId && loanId && drawId && purchaseId),
  })
}

export default useDrawPurchaseQuery
