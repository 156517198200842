import { useState } from 'react'

import { humanizeKey } from 'peach/helpers'
import styled from 'styled-components/macro'

import { sortEntries } from './jsonHelpers'
import JsonValue from './JsonValue'

const Table = styled.table`
  table-layout: auto;
  // width: auto;
  border-collapse: collapse;
`
const Tr = styled.tr``

const Index = styled.td`
  padding: ${(p) => (p.compact ? '2px' : '8px')};
  // min-width: 24px;
  // white-space: pre-wrap;
  word-break: keep-all;
`

const IndexInner = styled.div`
  padding-right: 4px;
  width: 100%;
  color: ${(p) => p.theme.mutedText};
  text-align: right;
`

const Expander = styled.td`
  padding: ${(p) => (p.compact ? '2px' : '8px')};
`

const Value = styled.td`
  padding: ${(p) => (p.compact ? '2px' : '8px')};
`

const Wrapper = styled.div``

const Row = styled.div`
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`
const Key = styled.div`
  padding-left: ${(p) => (p.compact ? '2px' : '8px')};
  padding-bottom: 2px;
  color: ${(p) => p.theme.mutedText};
  font-size: 11px;
  font-weight: bold;
`

const ObjectValue = styled.div`
  padding: ${(p) => (p.compact ? '2px' : '8px')};
`

const Button = styled.button`
  color: ${(p) => p.theme.highlight};
  padding: ${(p) => (p.compact ? '2px' : '8px')};
  margin: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
`

const JsonList = (props) => {
  const { json, compact, humanizeKeys, limit = 16, stringLimit, object } = props

  const [isExpanded, setIsExpanded] = useState(false)

  if (_.isArray(json)) {
    const count = _.size(json)
    const exceedsLimit = count > limit + 1
    const showAll = !exceedsLimit || isExpanded

    const items = showAll ? json : _.take(json, limit)
    return (
      <Table>
        <tbody>
          {_.map(items, (value, i) => {
            return (
              <Tr key={i}>
                <Index compact={compact}>
                  <IndexInner>{i}</IndexInner>
                </Index>
                <Value compact={compact}>
                  <JsonValue value={value} stringLimit={stringLimit} />
                </Value>
              </Tr>
            )
          })}
          {exceedsLimit && (
            <Tr key='expander'>
              <Expander compact={compact}>
                <Button
                  compact={compact}
                  onClick={() => setIsExpanded((val) => !val)}
                >
                  {isExpanded ? 'Less…' : 'More…'}
                </Button>
              </Expander>
            </Tr>
          )}
        </tbody>
      </Table>
    )
  } else if (_.isPlainObject(json)) {
    const pairs = sortEntries(_.toPairs(json))
    const count = _.size(pairs)
    const exceedsLimit = count > limit + 1
    const showAll = !exceedsLimit || isExpanded

    const $pairs = showAll ? pairs : _.take(pairs, limit)

    return (
      <Wrapper>
        {_.map($pairs, ([key, value]) => {
          return (
            <Row key={key}>
              <Key compact={compact}>
                {humanizeKeys ? humanizeKey(key) : key}
              </Key>
              <ObjectValue compact={compact}>
                <JsonValue
                  jsonKey={key}
                  value={value}
                  stringLimit={stringLimit}
                  object={object}
                />
              </ObjectValue>
            </Row>
          )
        })}
        {exceedsLimit && (
          <Row>
            <Button
              compact={compact}
              onClick={() => setIsExpanded((val) => !val)}
            >
              {isExpanded ? 'Less…' : 'More…'}
            </Button>
          </Row>
        )}
      </Wrapper>
    )
  } else {
    return <JsonValue value={json} />
  }
}

export default JsonList
