import './global/underscore'
import { Suspense, StrictMode, version } from 'react'

import { ErrorBoundary } from 'core/errors'
import { BrowserRouter } from 'react-router-dom'

import 'focus-visible'
import 'firacode'
import './index.css'
import App from './core/App.js'
import { ReactDom } from './global'
;(window as any)._ = _

const fallback = <div>Loading...</div>

const rootElement = document.getElementById('root')

const app = (
  <StrictMode>
    <BrowserRouter>
      <ErrorBoundary>
        <Suspense fallback={fallback}>
          <App />
        </Suspense>
      </ErrorBoundary>
    </BrowserRouter>
  </StrictMode>
)
const log = console.log.bind(console)

log(`starting app with: \n - React ${version}\n - ReactDom ${ReactDom.version}`)

ReactDom.render(app, rootElement)
