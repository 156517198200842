import { useDataQuery, useQueryApi } from '../useQueryApi'

import useSessionCompaniesWithPermissions from './useSessionCompaniesWithPermissions'

// todo:  update this to use `useDataQueries` so that it can independently cache these using company ID

const useAllSessionRoles = () => {
  const [companies] = useSessionCompaniesWithPermissions('role:list')

  const companyIdsString = _.map(companies, 'id').join(', ')

  const [api] = useQueryApi()

  const queryFn = async () => {
    const companyIds = companyIdsString.split(', ')
    const promises = _.map(companyIds, async (companyId) => {
      const resp = await api.company.roles.get({ pathArgs: { companyId } })
      return resp?.data || resp || []
    })
    const result = await Promise.all(promises)
    return _.compact(_.flatten(result))
  }

  return useDataQuery({
    queryKey: ['session', 'all-roles'],

    queryFn,

    enabled: !!companyIdsString,
  })
}

export default useAllSessionRoles
