import { useState } from 'react'

import {
  Form,
  Input,
  Panels,
  Bar,
  Modal,
  Page,
  ModelList,
  LoadingContainer,
  Select,
  CountryCodeSelect,
  StateCodeSelect,
  DateInput,
  PaginationInput,
} from 'peach/components'
import usePaginatedQuery from 'peach/components/Pagination/usePaginatedQuery'
import { useCompanyId } from 'peach/scopes/company'

import useCreatePerson from './useCreatePerson'

const PeopleList = () => {
  const companyId = useCompanyId()

  const [createPerson] = useCreatePerson()

  const [page, setPage] = useState(1)

  const [people, queryInfo] = usePaginatedQuery({
    queryKey: ['companies', companyId, 'borrowers'],
    queryParams: {
      limit: 100,
    },
    page,
    queryFnPath: 'company.people.get',
  })

  const onPageChange = (pageNum) => {
    setPage(pageNum)
  }

  const paginationInfo = {
    onPageChange,
    page,
    pageCount: Math.ceil(queryInfo?.data?.total / 100),
    count: queryInfo?.data?.count,
    limit: queryInfo?.data?.total,
  }

  const pagination = (
    <PaginationInput
      label='Borrowers'
      loading={queryInfo?.isLoading}
      paginationInfo={paginationInfo}
      query={queryInfo}
    />
  )

  const initalPerson = {
    status: 'active',
    identity: {
      identityType: 'SSN',
      value: '999999999',
    },
  }

  const createBorrower = (
    <Modal
      trigger
      title='Create Borrower'
      onSubmit={createPerson}
      initialValue={initalPerson}
    >
      <Input formKey='externalId' />
      <Select formKey='borrowerType' options={['person', 'business']} />
      <Select formKey='status' options={['active', 'inactive']} />

      <Form.If if={{ borrowerType: 'person' }}>
        <Form.Provider formKey='name' section>
          <Input formKey='firstName' />
          <Input formKey='lastName' />
        </Form.Provider>
        <DateInput formKey='dateOfBirth' />
      </Form.If>

      <Form.If if={{ borrowerType: 'business' }}>
        <Form.Provider formKey='businessDetails' section>
          <Select
            formKey='businessType'
            options={[
              'LLC',
              'cCorporation',
              'sCorporation',
              'generalPartnership',
              'limitedPartnership',
              'soleProprietor',
              'nonProfit',
              'other',
            ]}
          />
          <Input formKey='businessName' />
          <Input formKey='businessLegalName' />
          <CountryCodeSelect formKey='incorporatedCountry' />
          <StateCodeSelect formKey='incorporatedState' />
        </Form.Provider>
      </Form.If>

      <Form.Provider formKey='identity' section>
        <Input formKey='identityType' />
        <Input formKey='value' />
      </Form.Provider>
    </Modal>
  )

  const header = (
    <Bar title='Borrowers' left={pagination} right={createBorrower} />
  )

  return (
    <Panels header={header}>
      <LoadingContainer
        loading={queryInfo?.isLoading || queryInfo?.isFetching}
        error={queryInfo?.isError}
      >
        <Page.Full>
          <ModelList title='Borrowers' json={people} />
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default PeopleList
