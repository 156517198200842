import { Link as RouterLink, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components/macro'

const ListNav = styled.div`
  padding: 8px;
`

const Title = styled.div`
  font-weight: bold;
  font-size: 14px;
  padding: 4px 8px;
  color: ${(p) => p.theme.text};
`

const DevSection = ({ title, children }) => {
  return (
    <div>
      {title && <Title>{title}</Title>}
      {children}
    </div>
  )
}

const ListNavSection = styled(DevSection)`
  border: 1px solid green;
`

const LinkTitle = styled.div`
  color: ${(p) => (p.active ? p.theme.invertedText : p.theme.highlight)};
`
const SubTitle = styled.div`
  padding-top: 4px;
  font-size: 10px;
  color: ${(p) => (p.active ? p.theme.invertedText : p.theme.disabledText)};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const $RouterLink = ({ hasSubtitle, ...rest }) => {
  return <RouterLink {...rest} />
}

const StyledLink = styled($RouterLink)`
  display: block;
  font-size: 14px;
  border: 1px solid transparent;
  padding: ${(p) => (p.hasSubtitle ? '4px 4px' : '8px')};
  margin: 4px;
  border-radius: 3px;

  background-color: ${(p) => (p.active ? p.theme.highlight : 'transparent')};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  text-decoration: none;

  &:hover {
    background-color: ${(p) => (p.active ? p.theme.highlight : p.theme.hover)};
  }
`

const ListNavLink = ({ children, subtitle, to, exact, ...rest }) => {
  const match = useRouteMatch({ path: to, exact })
  return (
    <StyledLink active={match} to={to} hasSubtitle={!!subtitle} {...rest}>
      <LinkTitle active={match}>{children}</LinkTitle>
      {!!subtitle && <SubTitle active={match}>{subtitle}</SubTitle>}
    </StyledLink>
  )
}

ListNav.Section = ListNavSection
ListNav.Link = ListNavLink

export { ListNav, ListNavSection, ListNavLink }

export default ListNav
