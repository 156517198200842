const AM = 'am'
const PM = 'pm'

const getHours = (hrs) => {
  const hours = parseInt(hrs)
  if (hours === 0) return [12, AM]
  if (hours >= 13) return [hours - 12, PM]
  return [hours, AM]
}

const pad = (n, el = ' ') => (`${n}`.length === 1 ? `${el}${n}` : `${n}`)

const renderDisplay = (date, { withOffset = false } = {}) => {
  const [time, offset] = date.toTimeString().split(' ')
  // const timezone = tz.join(' ')
  const [hrs, minutes] = time.split(':')

  const [hours, ampm] = getHours(hrs)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  const offsetString = withOffset
    ? offset.replace('(', '').replace(')', '').replace('GMT', '')
    : ''

  const dateString = [year, pad(month, '0'), pad(day, '0')].join('-')
  const timeString = [pad(parseInt(hours), ' '), pad(minutes, '0')].join(':')

  return `${dateString}  ${timeString}${ampm}  ${offsetString}`.trim()
}

const formatDate = (value) => {
  return !value
    ? 'null'
    : _.isString(value) && _.size(value) === 10
    ? value
    : _.isFinite(value) || _.isString(value)
    ? renderDisplay(new Date(value))
    : value
}

export default _.memoize(formatDate)
