import { Select } from 'peach/components'
import { humanizeKey } from 'peach/helpers'

const statuses = [
  'pending',
  'originated',
  'declined',
  'active',
  'frozen',
  'accelerated',
  'chargedOff',
  'fraudulent',
  'paidOff',
  'canceled',
]

const LoanStatusSelect = ({ loan, ...rest }) => {
  const options = _.map(statuses, (status) => {
    return { value: status, label: humanizeKey(status) }
  })

  return <Select {...rest} label='Status' options={options} />
}

export default LoanStatusSelect
