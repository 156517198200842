import { Switch, Redirect, Route, useRouteMatch } from 'peach/router'

import LoanDraw from './LoanDraw'
import LoanDraws from './LoanDraws'

const DrawRoutes = () => {
  const { path, url } = useRouteMatch()

  return (
    <Switch>
      <Route path={path} exact>
        <LoanDraws />
      </Route>
      <Route path={`${path}/:drawId`} crumb>
        <LoanDraw />
      </Route>
      <Redirect to={url} />
    </Switch>
  )
}

export default DrawRoutes
