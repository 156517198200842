import { getName, getLink } from 'peach/helpers'
import { useReadData, useApi, useDebounceValue } from 'peach/hooks'

const useBorrowerAppSearch = ({ query, companyId }) => {
  const api = useApi()

  const [debouncedQuery, isDebouncing] = useDebounceValue(query)

  const [people, loading, error] = useReadData(() => {
    if (!companyId) return []
    if (!debouncedQuery) return []
    return api.people.search.get({
      queryParams: { _hasTheWords: debouncedQuery },
    })
  }, [debouncedQuery])

  const $people = debouncedQuery && !loading && !error ? _.take(people, 5) : []

  const results = _.map($people, (person) => {
    return {
      group: 'Borrowers',
      label: getName(person),
      to: getLink(person),
      subtitle: 'Borrower',
    }
  })

  return [results, { isFetching: isDebouncing || loading }]
}

export default useBorrowerAppSearch
