import { useMemo, useEffect } from 'react'

import { humanizeKey } from 'peach/helpers'
import { usePrevious } from 'peach/hooks'

import useHasBlurred from './hooks/useHasBlurred'
import useHasChanged from './hooks/useHasChanged'

const isEmpty = (value) => {
  if (_.isObject(value)) return _.isEmpty(value)
  if (value === '') return true
  if (_.isFinite(value)) return false
  return !value
}

const validateIsRequired = ({ value, hasBlurred, hasChanged, label }) => {
  if (isEmpty(value)) {
    return { errorMessage: `'${label}' is required` }
  }
}

const checkValue = (options) => {
  const {
    value,
    formKey,
    label,
    required,
    onValidate,
    hasBlurred,
    hasChanged,
  } = options || {}

  const $label = label || (formKey && humanizeKey(formKey)) || 'field'

  const checkFns = _.compact([required && validateIsRequired, onValidate])

  for (const checkFn of checkFns) {
    const res = checkFn({ value, label: $label, hasBlurred, hasChanged })
    if (res) return res
  }
}

const useOnFormValidate = (options) => {
  const {
    value,
    formKey,
    label,
    required,
    onValidate,
    onValidationChange,
    onBlur: passedOnBlur,
  } = options || {}

  const { hasChanged } = useHasChanged({ value, formKey })

  const { hasBlurred, onBlur } = useHasBlurred({ onBlur: passedOnBlur })

  const { errorMessage } = useMemo(() => {
    return (
      checkValue({
        value,
        formKey,
        label,
        required,
        onValidate,
        hasBlurred,
        hasChanged,
      }) || {}
    )
  }, [value, formKey, label, required, onValidate, hasBlurred, hasChanged])

  const meta = useMemo(() => {
    return { errorMessage, hasChanged, hasBlurred }
  }, [errorMessage, hasChanged, hasBlurred])

  const prevMeta = usePrevious(meta)

  useEffect(() => {
    if (onValidationChange) {
      if (prevMeta !== meta) onValidationChange((prev) => ({ ...prev, meta }))
    }
  }, [prevMeta, meta, onValidationChange])

  return { meta, onBlur }
}

export default useOnFormValidate
