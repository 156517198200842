import { Select } from 'peach/components'
import { usePaymentInstrumentsQuery } from 'peach/data'

const PaymentInstrumentSelect = ({ isExternal, ...rest }) => {
  const [paymentInstruments, query] = usePaymentInstrumentsQuery({ isExternal })

  const options = _.map(paymentInstruments, (paymentInstrument) => {
    const { id, nickname, status, verified } = paymentInstrument

    return {
      value: id,
      label: `${nickname || id} (${status}) ${verified ? '(Verified!)' : ''}`,
    }
  })

  return (
    <Select
      {...rest}
      loading={query.isLoading}
      label='Payment Instruments'
      options={options}
    />
  )
}

export default PaymentInstrumentSelect
