const filterOptions = (optionsList, query = '') => {
  const $query = query.trim().toLowerCase()

  if (!$query) return optionsList

  const tokens = _.compact($query.split(' ').map((str) => str.trim()))

  const matchQuery = (str) => {
    const $str = `${str}`.toLowerCase()
    return _.some(tokens, (token) => $str.includes(token))
  }

  return _.filter(optionsList, ({ value, label, subtitle, note }) => {
    return _.some([value, label, subtitle, note], matchQuery)
  })
}

export default filterOptions
