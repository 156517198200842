import { useDataQuery, useQueryApi } from '../useQueryApi'

const useDocumentQuery = (options) => {
  const [api, { personId, documentId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['people', personId, 'documents', documentId],

    queryFn: () => {
      return api.person.document.get({
        pathArgs: {
          personId,
          documentDescriptorId: documentId,
        },
      })
    },

    enabled: !!(personId && documentId),
  })
}

export default useDocumentQuery
