import { useAuthApi, useAuthState } from 'core/auth/AuthProvider'
import { Json, Page, Button } from 'peach/components'
import { formatMilliseconds } from 'peach/helpers'
import { useReadData, useApi, useNow } from 'peach/hooks'

const Settings = () => {
  const authState = useAuthState()
  const { renewSession } = useAuthApi()

  const { companyId, personId, userId, employeeId } = authState || {}

  const api = useApi()

  const [permissionsHash] = useReadData(() => {
    return api.auth.permissions.get()
  }, [])

  const [person] = useReadData(() => {
    return api.people.person.get({
      pathArgs: { personId },
    })
  }, [personId])

  const [user] = useReadData(() => {
    return api.company.user.get({
      pathArgs: { companyId, userId },
    })
  }, [companyId, userId])

  const [employee] = useReadData(() => {
    return api.employees.employee.get({
      pathArgs: { employeeId },
    })
  }, [employeeId])

  const displayPermissions = _.map(permissionsHash, (permissions, id) => {
    const split = _.map(permissions, (str) => {
      const [type, action] = str.split(':')
      return { type, action }
    })

    const byCategory = _.mapValues(_.groupBy(split, 'type'), (list) =>
      _.map(list, 'action').join(', '),
    )

    return { companyId: id, permissions: byCategory }
  })

  const { expiresAt } = authState
  const now = useNow()

  const expiresIn = formatMilliseconds(expiresAt - now)

  return (
    <Page.Centered>
      <Json.Table title='Auth State' json={{ ...authState, expiresIn }} />
      <Button onClick={renewSession}>Renew Session</Button>
      <Json.Table title='Session User' json={user} />
      <Json.Table title='Session Person' json={person} />
      <Json.Table title='Session Employee' json={employee} />

      <Json.Table title='Session Permissions' json={displayPermissions} />
    </Page.Centered>
  )
}

export default Settings
