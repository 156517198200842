import { forwardRef } from 'react'

import { withLabel, withFormValue } from 'peach/decorators'
import { humanizeKey } from 'peach/helpers'
import styled from 'styled-components/macro'

const Input = styled.input`
  display: block;
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.border};
  background-color: ${(p) => p.theme.background};
  color: ${(p) => p.theme.text};
  width: ${(p) => p.width};
  background-color: ${(p) => p.theme.background};

  &:disabled {
    color: #888;
    border-color: ${(p) => p.theme.border};
    background-color: ${(p) => p.theme.offset};
  }
`

const DateInput = forwardRef((props, ref) => {
  const {
    formKey,
    onChange,
    width,
    value,
    onEnter,
    onEscape,
    onArrowUp,
    onArrowDown,
    onKeyDown,
    placeholder,

    ...rest
  } = props

  return (
    <Input
      {...rest}
      type='date'
      width={width || '100%'}
      onChange={(e) => onChange(e.target.value)}
      value={value || ''}
      placeholder={placeholder || humanizeKey(formKey)}
      ref={ref}
    />
  )
})

export default withFormValue(withLabel(DateInput))
