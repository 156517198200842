import { useDataMutation, useQueryApi } from '../useQueryApi'

const useCreateDrawRate = (options) => {
  const [api, params] = useQueryApi(options)

  const { personId, loanId, drawId } = params

  return useDataMutation({
    invalidationKey: [
      'people',
      personId,
      'loans',
      loanId,
      'draws',
      drawId,
      'rates',
    ],

    mutationFn: (options) => {
      return api.draw.rates.post({
        pathArgs: { personId, loanId, drawId },
        body: options,
      })
    },
  })
}

export default useCreateDrawRate
