import { useDataQuery, useQueryApi } from '../useQueryApi'

const useLoanFeesQuery = (options) => {
  const [api, { personId, loanId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['people', personId, 'loans', loanId, 'fees'],

    queryFn: () => {
      return api.loan.fees.get({ pathArgs: { personId, loanId } })
    },
  })
}

export default useLoanFeesQuery
