import { useState } from 'react'

import { withTrigger, withOpenAction } from 'peach/decorators'
import { jsonDiff } from 'peach/helpers'
import { useWrite } from 'peach/hooks'

import KeyboardNavArea from '../$internal/KeyboardNavArea'
import useFocusReturn from '../$internal/useFocusReturn'
import { FormProvider } from '../Form/FormProvider'

import BigModal from './BigModal'
import ModalDebugger from './ModalDebugger/ModalDebugger'
// import FullPage from '../Page/FullPage'
import ModalPage from './ModalPages/ModalPage'
import ModalPagesContainer from './ModalPages/ModalPagesContainer'

const resolveCanDo = (canDo, state, diff, onDo) => {
  return _.isFunction(canDo)
    ? canDo(state, diff)
    : _.isBoolean(canDo)
    ? canDo
    : !!onDo
}

const Modal = (props) => {
  const {
    title,
    trigger,
    canCancel,
    onCancel,
    canSubmit,
    submitOnEnter,
    onSubmit,

    initialValue,
    startOpen,
    children,
    render,
    isOpen,
    onOpen,
    onClose,
    value,
    onChange,
    big,
    tabs,
    pages,
    ...rest
  } = props

  useFocusReturn()

  const [showDebugValue, setShowDebugValue] = useState(false)

  const [state, setState] = useState(initialValue || {})

  const $value = _.isUndefined(value) ? state : value

  // const hasChanged = !_.isEqual(state, initialState)

  const diff = jsonDiff(initialValue, state)

  const [handleSubmit, sending, resp, error, clear] = useWrite(async () => {
    await onSubmit(state, diff)
    onClose()
  })

  const handleChange = (val) => {
    if (resp || error) clear()
    setState(val)
    if (onChange) onChange(val)
  }

  const disabled = sending

  const $canSubmit = !disabled && resolveCanDo(canSubmit, state, diff, onSubmit)

  const $onCancel = onCancel || onClose

  const $canCancel = resolveCanDo(canCancel, state, diff, $onCancel)

  const $pages = tabs || pages

  const renderProps = {
    title,
    error,
    onClearError: clear,
    disabled: disabled,
    value: $value,
    onChange: handleChange,
    canSubmit: $canSubmit,
    onSubmit: handleSubmit,
    canCancel: $canCancel,
    onCancel: $onCancel,
    onClear: clear,
    sending,
    isSubmitting: sending,
    onOpen,
    onClose,
    isOpen,
    big: big || $pages,
    noPadding: $pages,
  }

  const debugValue = showDebugValue && (
    <ModalDebugger
      value={$value}
      initialValue={initialValue}
      onChange={handleChange}
    />
  )

  const contents = render ? render(renderProps) : children

  const wrappedContents = $pages ? (
    <ModalPagesContainer>{contents}</ModalPagesContainer>
  ) : (
    contents
  )

  return (
    <FormProvider value={state} onChange={handleChange} disabled={sending}>
      <BigModal
        {...rest}
        {...renderProps}
        debugValue={debugValue}
        onToggleDebugger={() => setShowDebugValue((val) => !val)}
      >
        <KeyboardNavArea
          focusOnMount
          onEnter={submitOnEnter && $canSubmit && handleSubmit}
          fullHeight={$pages}
        >
          {wrappedContents}
        </KeyboardNavArea>
      </BigModal>
    </FormProvider>
  )
}

const WrappedModal = withOpenAction(withTrigger(Modal))

WrappedModal.Page = ModalPage

export { Modal as BareModal }

export default WrappedModal
