import { LoadingContainer, ModelViewer, Page } from 'peach/components'
import { useLoanRatesQuery } from 'peach/data'

const LoanRates = ({ person, personId, loanId }) => {
  const [rates, query] = useLoanRatesQuery()

  return (
    <LoadingContainer query={query}>
      <Page.Full>
        <ModelViewer json={rates} />
      </Page.Full>
    </LoadingContainer>
  )
}

export default LoanRates
