import { useDataQuery, useQueryApi } from '../useQueryApi'

const useLoanTransactionQuery = (options) => {
  const [api, { companyId, personId, loanId, transactionId }] =
    useQueryApi(options)

  return useDataQuery({
    queryKey: [
      'companies',
      companyId,
      'people',
      personId,
      'loans',
      loanId,
      'transactions',
      transactionId,
    ],

    queryFn: () => {
      return api.loan.transaction.get({
        pathArgs: { companyId, personId, loanId, transactionId },
      })
    },
  })
}

export default useLoanTransactionQuery
