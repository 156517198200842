import { Page, Model, LoadingContainer, Panels, Bar } from 'peach/components'
import { useTemplateDescriptorsQuery } from 'peach/data'

const CompanyDescriptors = () => {
  const [descriptors, query] = useTemplateDescriptorsQuery()

  return (
    <LoadingContainer query={query}>
      <Panels header={<Bar title='Template Descriptors' />}>
        <Page.Full>
          <Model.List title='Descriptors' json={descriptors} />
        </Page.Full>
      </Panels>
    </LoadingContainer>
  )
}

export default CompanyDescriptors
