import color from 'peach/helpers/color/color'

const makeDarkThemeFromConfig = (colors = {}) => {
  const highlight = colors.primary || 'hsl(211, 81%, 54%)'
  const linkText = colors.primary || 'hsl(211, 81%, 54%)'

  return {
    text: '#EEE',
    background: '#222',
    labelText: '#BBB',
    border: '#383838',
    offset: '#181818',
    hover: 'rgba(255, 255, 255, 0.12)',
    banner: '#282828',
    highlight: color.preventTooDark(highlight),
    mutedText: '#777',
    invertedText: '#000',
    disabledText: '#888',
    linkText: color.preventTooDark(linkText),
  }
}

export default makeDarkThemeFromConfig
