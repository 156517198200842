import { useState, useMemo } from 'react'

import {
  Page,
  LoadingContainer,
  Panels,
  Bar,
  MiniTable,
  Json,
  Input,
  Link,
  Stack,
} from 'peach/components'
import { useTemplatesQuery, useTemplateDescriptorsQuery } from 'peach/data'
import { humanizeKey, getName } from 'peach/helpers'
import { useRouteMatch } from 'peach/router'

const TemplateSubjects = () => {
  const { url } = useRouteMatch()
  const descriptorsQuery = useTemplateDescriptorsQuery()

  const templatesQuery = useTemplatesQuery()
  const loading = descriptorsQuery.isLoading || templatesQuery.isLoading

  const error = descriptorsQuery.error || templatesQuery.error

  const descriptors = descriptorsQuery.data

  const templatesByDescirptorId = useMemo(() => {
    return _.groupBy(templatesQuery.data, 'descriptorId')
  }, [templatesQuery.data])

  const groups = _.groupBy(descriptors, 'subject')

  const [quickSearch, setQuickSearch] = useState('')

  const search = (
    <Input
      value={quickSearch}
      onChange={setQuickSearch}
      width='320px'
      placeholder='Search Subjects…'
      bare
    />
  )

  const subjects = _.map(groups, (descriptors, subject) => {
    const templates = _.flatten(
      _.compact(_.map(descriptors, ({ id }) => templatesByDescirptorId[id])),
    )
    return { subject, descriptors, templates }
  })

  const filteredSubjects = _.filter(subjects, ({ subject = '' }) => {
    const query = quickSearch.trim().toLowerCase()
    return subject.toLowerCase().includes(query)
  })

  const rows = _.map(
    filteredSubjects,
    ({ subject, descriptors, templates }) => {
      const activeTemplateIds = _.compact(_.map(descriptors, 'activeVersionId'))
      const activeTempaltes = _.filter(templates, ({ id }) =>
        _.includes(activeTemplateIds, id),
      )

      return (
        <MiniTable.Row key={subject}>
          <Link to={`${url}/${subject}`}>{humanizeKey(subject)}</Link>
          <Stack>
            {_.map(descriptors, ({ channel }) => {
              return (
                <Link key={channel} to={`${url}/${subject}/${channel}`}>
                  {humanizeKey(channel)}
                </Link>
              )
            })}
          </Stack>
          <Json.List json={_.map(activeTempaltes, getName)} compact />
        </MiniTable.Row>
      )
    },
  )

  return (
    <LoadingContainer loading={loading} error={error}>
      <Panels header={<Bar left={search} />}>
        <Page.Full>
          <MiniTable
            columns={['Subject', 'Channels', 'Active Templates']}
            fixed
          >
            {rows}
          </MiniTable>
        </Page.Full>
      </Panels>
    </LoadingContainer>
  )
}

export default TemplateSubjects
