const defaultAdvance = {
  externalId: '',
  status: 'settled',
  amount: 30,
  advanceDetails: {
    description: 'Advance',
    pointOfSaleType: 'online',
    categoryId: '',
    merchantId: '',
    merchantName: '',
    metadata: {},
  },
  metadata: {},
}

export { defaultAdvance }
