import { createContext, useContext, useMemo } from 'react'

const defaults = {
  hasLeftSideSpace: false,
  onClose: () => {},
}

const MenuContext = createContext(defaults)

const MenuConfigProvider = ({ hasLeftSideSpace, onClose, children }) => {
  const value = useMemo(() => {
    return { ...defaults, hasLeftSideSpace, onClose }
  }, [hasLeftSideSpace, onClose])

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>
}

const useMenuConfig = () => useContext(MenuContext)

export { MenuConfigProvider, useMenuConfig }
