import { useFormValue } from './FormProvider'
import FormSection from './FormSection'

const FormIf = (props) => {
  const {
    children,
    if: condition,
    then: affirmativeValue,
    else: alternativeValue,
    section,
    label,
  } = props

  const value = useFormValue()

  const showContents = condition ? _.iteratee(condition)(value ?? {}) : false

  const contents =
    (showContents ? children || affirmativeValue : alternativeValue) || null

  const showSection = (section || label) && contents

  return showSection ? (
    <FormSection label={label}>{contents}</FormSection>
  ) : (
    contents
  )
}

export default FormIf
