import { Switch, Route, Redirect } from 'react-router-dom'

import Environments from './auth/Environments'
import Logout from './auth/Logout'

const UNAUTHENTICATED_ROUTES = ['/logout', '/environments']

const UnauthenticatedRoutes = () => {
  return (
    <Switch>
      <Route path='/environments' exact>
        <Environments />
      </Route>

      <Route path='/logout' exact>
        <Logout />
      </Route>
      <Redirect to='/environments' />
    </Switch>
  )
}

export { UNAUTHENTICATED_ROUTES }

export default UnauthenticatedRoutes
