import { useEffect, useRef } from 'react'

const useFocusReturn = () => {
  const focusRef = useRef(document.activeElement)

  useEffect(() => {
    const node = focusRef.current
    return () => {
      if (node) node.focus()
    }
  }, [])
}

export default useFocusReturn
