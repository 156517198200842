import styled from 'styled-components/macro'

const Button = styled.button`
  padding: 6px 12px;
  margin: 12px;
`

export const Warning = styled.div`
  border: 3px solid red;
  padding: 30px;
  background-color: #fcc;
  overflow-y: auto;
`

const Code = styled.div`
  font-size: 10px;
  white-space: pre;
  font-family: monospace;
`

const Stack = ({ title, text }) => {
  return (
    <div>
      <h3>{title}</h3>
      <Code>{text}</Code>
    </div>
  )
}

const DefaultFallback = ({ error, onReset, componentStack }) => {
  return (
    <Warning>
      <Button onClick={() => window.location.reload()}>Reload Page</Button>

      <Button onClick={onReset}>Reload Component</Button>

      <h1>Error: {error?.message || 'Unknown Error'}</h1>

      {error?.stack && <Stack title='Call Stack' text={error.stack} />}

      {componentStack && (
        <Stack title='Component Stack' text={componentStack} />
      )}
    </Warning>
  )
}

export default DefaultFallback
