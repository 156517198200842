import { useDataQuery, useQueryApi } from '../useQueryApi'

const MINUTE = 60 * 1000

const useSessionPermissionsQuery = (options) => {
  const [api] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['session', 'permissions'],

    queryFn: async () => {
      const resp = await api.auth.permissions.get()
      return resp.data
    },

    staleTime: 5 * MINUTE,
  })
}

export default useSessionPermissionsQuery
