const warn = _.once((key, ...args) => {
  console.warn(
    'storage function called with no namespace.  this is a no-op, key: ',
    key,
    ...args,
  )
})

const wrap = (namespace, func) => {
  return (key, ...args) => {
    const finalKey = `[${namespace}]/[${key}]`
    return namespace ? func(finalKey, ...args) : warn(key, ...args)
  }
}

const retrieve = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key))
  } catch (e) {
    return undefined
  }
}

const store = (key, value) => {
  try {
    if (value) {
      localStorage.setItem(key, JSON.stringify(value))
    } else {
      localStorage.removeItem(key)
    }
  } catch (error) {
    console.warn(`storage error, key: '${key}', value: `, value, error)
  }
}

const has = (key) => {
  return !!localStorage.getItem(key)
}

const makeClear = (namespace) => {
  return () => {
    try {
      const keys = _.keys(localStorage)
      _.each(keys, (key) => {
        if (_.startsWith(key, `[${namespace}]/[`)) {
          localStorage.removeItem(key)
        }
      })
    } catch (error) {
      console.warn(`error trying to clear namespace '${namespace}'`, error)
    }
  }
}

const getAll = (namespace) => {
  try {
    const keys = _.keys(localStorage)
    const result = Object.create(null)
    _.each(keys, (key) => {
      const prefix = `[${namespace}]/[`
      if (_.startsWith(key, prefix)) {
        const itemKey = key.replace(prefix, '').slice(0, -1)
        result[itemKey] = retrieve(key)
      }
    })
    return result
  } catch (error) {
    console.warn(`error trying to clear namespace '${namespace}'`, error)
  }
}

const makeGetAll = (namespace) => () => getAll(namespace)

const log = console.log.bind(console)

const makePrintAll = (namespace) => () => {
  const data = getAll(namespace)
  log(`namespace: '${namespace}'\n\n`, JSON.stringify(data, null, 2))
}

const makeStorage = (namespace) => {
  return {
    get: wrap(namespace, retrieve),
    set: wrap(namespace, store),
    has: wrap(namespace, has),
    clear: makeClear(namespace),
    getAll: makeGetAll(namespace),
    printAll: makePrintAll(namespace),
  }
}

export { retrieve, store, has }

export default makeStorage
