import Form from './Form'
import FormIf from './FormIf'
import FormList from './FormList'
import { FormProvider, FormDebugger } from './FormProvider'
import FromSection from './FormSection'
import FormText from './FormText'
import InlineForm from './InlineForm'
import FormRow from './Row/FormRow'

Form.Section = FromSection
Form.Inline = InlineForm
Form.Provider = FormProvider
Form.List = FormList
Form.If = FormIf
Form.Text = FormText
Form.Row = FormRow
Form.Debugger = FormDebugger

export { FromSection, FormProvider, InlineForm }

export default Form
