import {
  LoadingContainer,
  Page,
  Model,
  Panels,
  Bar,
  Modal,
} from 'peach/components'
import { useLoanTypeDynamicFeeTypesQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { Switch, Route, Redirect, useParams, useRouteMatch } from 'peach/router'

import CreateDynamicFeeTypeForm from './CreateDynamicFeeTypeForm'
import DynamicFeeType from './DynamicFeeType'

const DynamicFeeTypes = () => {
  const { path, url } = useRouteMatch()
  const { loanTypeId } = useParams()

  const api = useApi()

  const [dynamicFeeTypes, feeTypesQuery] = useLoanTypeDynamicFeeTypesQuery({
    loanTypeId,
  })

  const sendCreateDynamicFeeType = async (dynamicFeeType) => {
    await api.company.dynamicFeeTypes.post({
      pathArgs: { loanTypeId },
      body: dynamicFeeType,
    })
    feeTypesQuery.refetch()
  }

  const createDynamicFeeType = (
    <Modal
      trigger
      title='Create Dynamic Fee Type'
      onSubmit={sendCreateDynamicFeeType}
      initialValue={{ feeType: 'modificationFee' }}
      pages
    >
      <CreateDynamicFeeTypeForm />
    </Modal>
  )

  const header = <Bar title='Dynamic Fee Types' right={createDynamicFeeType} />

  return (
    <LoadingContainer query={feeTypesQuery}>
      <Switch>
        <Route path={path} exact>
          <Panels header={header}>
            <Page.Full>
              <Model.List title='Dynamic Fee Types' json={dynamicFeeTypes} />
            </Page.Full>
          </Panels>
        </Route>
        <Route path={`${path}/:dynamicFeeTypeId`}>
          <DynamicFeeType />
        </Route>
        <Redirect to={url} />
      </Switch>
    </LoadingContainer>
  )
}

export default DynamicFeeTypes
