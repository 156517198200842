import PaginationInputControl from './PaginationInputControl'

const getCaption = (label = 'Items', paginationInfo) => {
  const { pageCount, page, limit, count } = paginationInfo || {}

  if (count === 0) return `0 ${label}`

  if (count === 1) return `1 ${label}`

  if (pageCount === 1) return `${count} ${label}`

  const lower = (page - 1) * count + 1
  const upper = lower + count - 1

  return `${lower} - ${upper} of ${limit} ${label}`
}

const PaginationInput = (props) => {
  const { label, query, loading, disabled, paginationInfo } = props

  const isLoading = loading || query?.isFetching || query?.isLoading

  const { pageCount, page, count, onPageChange } = paginationInfo || {}

  const $caption =
    isLoading && !count ? 'Loading…' : getCaption(label, paginationInfo)

  return (
    <PaginationInputControl
      caption={$caption}
      totalPages={pageCount}
      page={page}
      onPageChange={onPageChange}
      onRefresh={null}
      loading={isLoading}
      disabled={disabled}
    />
  )
}

export default PaginationInput
