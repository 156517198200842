const getNewValue = (newValue, prevValue) => {
  return _.isFunction(newValue) ? newValue(prevValue) : newValue
}

const resolve = (newValue, defaultValue) => {
  return (prevValue) => getNewValue(newValue, prevValue ?? defaultValue)
}

const removeKey = (obj, key) => {
  const newObj = { ...obj }
  delete newObj[key]
  return newObj
}

export { getNewValue, resolve, removeKey }
