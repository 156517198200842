import { Breadcrumb } from 'peach/components'
import { usePersonQuery } from 'peach/data'
import { getName } from 'peach/helpers'

const PersonBreadcrumb = ({ personId, ...rest }) => {
  const [person, { isLoading }] = usePersonQuery({ personId })

  const name = isLoading ? 'Loading…' : getName(person)

  return <Breadcrumb title={name} {...rest} />
}

export default PersonBreadcrumb
