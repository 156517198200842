import { useDataQuery, useQueryApi } from '../useQueryApi'

const usePaymentInstrumentQuery = (options) => {
  const [api, { companyId, personId, paymentInstrumentId }] =
    useQueryApi(options)

  return useDataQuery({
    queryKey: [
      'companies',
      companyId,
      'borrowers',
      personId,
      'payment-instruments',
      paymentInstrumentId,
    ],

    queryFn: async () => {
      return api.person.paymentInstrument.get({
        pathArgs: { personId, paymentInstrumentId },
      })
    },
  })
}

export default usePaymentInstrumentQuery
