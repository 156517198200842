import { InputHTMLAttributes, forwardRef } from 'react'

import { Label } from 'peach/components'
import styled, { css } from 'styled-components/macro'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;
  width: 100%;
`

const focused = css`
  border-color: ${(p) => p.theme.highlight};
  box-shadow: none;
`
export const StyledInput = styled.input<{ isFocused?: boolean }>`
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.border};
  background-color: ${(p) => p.theme.background};
  color: ${(p) => p.theme.text};
  width: ${(p) => p.width};
  background-color: ${(p) => p.theme.background};

  &:disabled {
    color: #888;
    border-color: ${(p) => p.theme.border};
    background-color: ${(p) => p.theme.offset};
  }

  ${(p) => p.isFocused && focused}

  &:focus {
    ${focused}
  }
`

export type TextInputProps = InputHTMLAttributes<HTMLInputElement> & {
  className?: string
  label?: string
}

const Input = forwardRef<HTMLInputElement, TextInputProps>(
  ({ onChange, prefix, width, disabled, className, label, ...props }, ref) => (
    <Wrapper>
      {label && <Label>{label}</Label>}
      <StyledInput
        className={className}
        width={width}
        ref={ref}
        onChange={(e) => {
          onChange?.(e)
        }}
        disabled={disabled}
        {...props}
      />
    </Wrapper>
  ),
)

export default Input
