import { useDataQuery, useQueryApi } from '../useQueryApi'

const MINUTE = 60 * 1000

const useBorrowersQuery = (options) => {
  const [api, { companyId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'borrowers'],

    queryFn: () =>
      api.company.people.get({
        pathArgs: { companyId },
        queryParams: { sortBy: ['-createdAt'] },
      }),

    enabled: !!companyId,

    staleTime: 5 * MINUTE,
  })
}

export default useBorrowersQuery
