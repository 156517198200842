import { forwardRef } from 'react'

import Button from '../../components/Button/Button'
import Menu from '../../components/Menu/Menu'

import { useActions } from './withActionTrigger'

const ActionsButton = forwardRef((props, ref) => {
  const { label, isOpen, onOpen, onClose, disabled, ...rest } = props

  const onClick = isOpen ? onClose : onOpen

  return (
    <Button
      ref={ref}
      disabled={disabled}
      onClick={onClick}
      iconRight={'▼'}
      {...rest}
    >
      {label || 'Actions'}
    </Button>
  )
})

const ActionsMenu = forwardRef(({ label }, ref) => {
  const { actions } = useActions()

  const count = _.size(actions)

  if (count === 0) {
    return null
  }

  if (count === 1) {
    const { label, onClick, disabled } = _.first(actions)
    return (
      <Button onClick={onClick} disabled={disabled}>
        {label}
      </Button>
    )
  }

  const sortedActions = _.orderBy(actions, ['label'], ['asc'])

  const items = _.map(sortedActions, ({ id, label, onClick, disabled }) => {
    return (
      <Menu.Item key={id} onClick={onClick} disabled={disabled}>
        {label}
      </Menu.Item>
    )
  })

  const renderTrigger = ({ isOpen, onOpen, onClose, ref }) => {
    return (
      <ActionsButton
        disabled={_.isEmpty(actions)}
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        ref={ref}
      />
    )
  }
  return <Menu trigger={renderTrigger}>{items}</Menu>
})

export default ActionsMenu
