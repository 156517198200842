import { LoadingContainer } from 'peach/components'
import { useReadData, useApi } from 'peach/hooks'
import { ListSubroutes } from 'peach/router'

const CompanyTemplatesRoutes = () => {
  const api = useApi()

  const [templates, loading, error] = useReadData(() => {
    return api.communicator.templates.get()
  })

  return (
    <LoadingContainer loading={loading} error={error}>
      <ListSubroutes
        title='Templates'
        paramKey='templateId'
        json={templates}
        page
      />
    </LoadingContainer>
  )
}

export default CompanyTemplatesRoutes
