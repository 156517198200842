import {
  LoadingContainer,
  ModelViewer,
  Page,
  Modal,
  Select,
  NumericInput,
  Form,
  Input,
} from 'peach/components'
import { useWorkflowStepTypeQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { useParams } from 'peach/router'

const WorkflowStepType = () => {
  const { companyId, workflowStepTypeId } = useParams()
  const [workflowStepType, query] = useWorkflowStepTypeQuery({
    companyId,
    workflowStepTypeId,
  })

  const api = useApi()

  const sendEditWorkflowStepType = async (workflowStepType) => {
    const {
      descriptionShort,
      descriptionLong,
      decisionQuestion,
      decisionQuestionType,
      mandatory,
      visibility,
      version,
      versionStatus,
      workItems,
    } = workflowStepType
    await api.company.workflowStepType.put({
      pathArgs: { companyId, workflowStepTypeId },
      body: _.omitBy(
        {
          descriptionShort,
          descriptionLong,
          decisionQuestion,
          decisionQuestionType,
          mandatory,
          visibility,
          version,
          versionStatus,
          workItems,
        },
        _.isNil,
      ),
    })
    query.refetch()
  }

  const editWorkflowStepType = (
    <Modal
      trigger
      title='Edit Workflow Type'
      onSubmit={sendEditWorkflowStepType}
      initialValue={workflowStepType}
      pages
    >
      <Modal.Page page='Workflow Type'>
        <Input formKey='refId' disabled />
        <Input formKey='descriptionShort' />
        <Input formKey='descriptionLong' />
        <Input formKey='decisionQuestion' />
        <Select
          formKey='decisionQuestionType'
          options={['yesNo', 'approveReject']}
        />
        <Select boolean formKey='mandatory' />
        <Select
          formKey='visibility'
          options={[
            'normal',
            'closingApproved',
            'closingDenied',
            'closingFalsePositive',
            'closingNoOutcome',
          ]}
        />
        <NumericInput formKey='version' />
        <Select
          formKey='versionStatus'
          options={['draft', 'active', 'deprecated']}
        />
        <Form.List
          formKey='workItems'
          add
          remove
          renderItem={(props) => <Input {...props} />}
        />
      </Modal.Page>
    </Modal>
  )

  return (
    <LoadingContainer query={query}>
      {editWorkflowStepType}
      <Page.Full>
        <ModelViewer json={workflowStepType} />
      </Page.Full>
    </LoadingContainer>
  )
}

export default WorkflowStepType
