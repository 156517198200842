import { useDataQuery, useQueryApi } from '../useQueryApi'

const useLoanDrawsQuery = (options) => {
  const [api, { personId, loanId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['people', personId, 'loans', loanId, 'draws'],

    queryFn: () => {
      return api.loan.draws.get({
        pathArgs: { personId, loanId },
      })
    },

    enabled: !!(personId && loanId),
  })
}

export default useLoanDrawsQuery
