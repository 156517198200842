import { Select } from 'peach/components'
import { useTeamsQuery } from 'peach/data'

const CompanyTeamSelect = (props) => {
  const [teams] = useTeamsQuery()

  const options = _.map(teams, (role) => {
    const { id, name } = role

    return {
      value: id,
      label: name ? `${name} (${id})` : id,
    }
  })

  return <Select {...props} label='Team' options={options} />
}

export default CompanyTeamSelect
