import { useMutation } from 'react-query'

import useInvalidateQuery from './useInvalidateQuery'

/* 
const {
   data,
   error,
   isError,
   isIdle,
   isLoading,
   isPaused,
   isSuccess,
   mutate,
   mutateAsync,
   reset,
   status,
 } = useMutation(mutationFn, {
   mutationKey,
   onError,
   onMutate,
   onSettled,
   onSuccess,
   useErrorBoundary,
 })

 mutate(variables, {
   onError,
   onSettled,
   onSuccess,
 })
 */

const useDataMutation = (options) => {
  const invalidateQueries = useInvalidateQuery()

  // peach options
  const {
    // peach options
    mutationFn,
    invalidationKey,
    invalidationKeys,
    // react-query options
    onSettled,

    ...restOptions
  } = options

  const mutationOptions = {
    onSettled: () => {
      if (invalidationKey) invalidateQueries(invalidationKey)
      if (invalidationKeys) {
        _.each(invalidationKeys, (key) => invalidateQueries(key))
      }

      if (onSettled) onSettled()
    },
    ...restOptions,
  }

  const mutation = useMutation(mutationFn, mutationOptions)

  const { mutate } = mutation

  const send = async (sendOptions) => {
    return new Promise((resolve, reject) => {
      mutate(sendOptions, {
        onSuccess: (result) => resolve(result),
        onError: (error) => reject(error),
      })
    })
  }

  const extras = { invalidateQueries }

  /* 
  mutation = { 
    data,
    error,
    isError,
    isIdle,
    isLoading,
    isPaused,
    isSuccess,
    mutate,
    mutateAsync,
    reset,
    status,
  }
  */

  return [send, mutation, extras]
}

export default useDataMutation
