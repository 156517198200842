import { useEmployeeQuery } from 'peach/data'

import ModelLink from './ModelLink'

const EmployeeLink = ({ employeeId, jsonKey }) => {
  const [employee, query] = useEmployeeQuery({ employeeId })

  return (
    <ModelLink
      id={employeeId}
      model={employee}
      query={query}
      jsonKey={jsonKey}
    />
  )
}

export default EmployeeLink
