import { LoadingContainer, Page, Model, Panels, Bar } from 'peach/components'
import { useLoanTypeNotificationsQuery } from 'peach/data'

const LoanTypeNotifications = () => {
  const [notifications, query] = useLoanTypeNotificationsQuery()

  const header = <Bar title='Notifications' />

  return (
    <LoadingContainer query={query}>
      <Panels header={header}>
        <Page.Full>
          <Model.Item title='Notifications' json={notifications} />
        </Page.Full>
      </Panels>
    </LoadingContainer>
  )
}

export default LoanTypeNotifications
