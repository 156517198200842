import { useState } from 'react'

import {
  Input,
  Form,
  Select,
  NumericInput,
  Modal,
  Divider,
} from 'peach/components'
import { useFormValueAndOnChange } from 'peach/components/Form/FormProvider'
import { useDynamicFeesQuery } from 'peach/data'
import { BorrowerSelect } from 'peach/data/components'
import { useParams } from 'peach/router'
import { LoanTypeSelect } from 'peach/scopes/company'
import { ContactAddressSelect } from 'peach/scopes/person'
import styled from 'styled-components/macro'

import {
  blankInstallmentLoan,
  defaultInstallmentLoan,
  migrationLoanWithEPWithOriginationFee,
  migrationLoanWithEPAndDynamicFees,
} from './installmentLoanExamples'
import SourceItem from './SourceItem'

const statuses = [
  'originated',
  'pending',
  'declined',
  'active',
  'frozen',
  'accelerated',
  'chargedOff',
  'fraudulent',
  'paidOff',
  'canceled',
]

const originationLicenses = ['nationalBank', 'stateLicense', 'bankSubsidiary']

const paymentFrequencies = [
  'weekly',
  'everyTwoWeeks',
  'twiceMonthly',
  'monthly',
  'singleTerm',
]

const servicedByOptions = [
  'creditor',
  'thirdPartyServicer',
  'debtCollectionAgency',
]

const loanReloationTypes = ['coBorrower', 'coSigner', 'applicant']

const InstallmentSource = ({ onChange }) => {
  const [selectedSource, setSelectedSource] = useState(null)

  return (
    <Modal.Page page='Source'>
      {_.map(
        [
          blankInstallmentLoan,
          defaultInstallmentLoan,
          migrationLoanWithEPWithOriginationFee,
          migrationLoanWithEPAndDynamicFees,
        ],
        (source, index) => (
          <SourceItem
            key={index}
            id={index}
            {...source}
            onClick={onChange}
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
          />
        ),
      )}
    </Modal.Page>
  )
}

const Basics = ({ loanTypeKind }) => {
  return (
    <Modal.Page page='Basics (Required)'>
      <Select
        formKey='type'
        options={['installment', 'lineOfCredit']}
        required
        disabled
      />

      <LoanTypeSelect formKey='loanTypeId' kind={loanTypeKind} required />

      <Select formKey='servicedBy' options={servicedByOptions} required />

      <Select formKey='status' options={statuses} required />

      <Form.If if={{ type: 'lineOfCredit' }}>
        <Select boolean required formKey='newDrawsAllowed' />
      </Form.If>

      <Divider margin='16px 0' />
      <OptionalFields>Optional Fields</OptionalFields>

      <Input formKey='nickname' />

      <Input formKey='externalId' />

      <Select boolean formKey='isClosed' />

      <Select boolean formKey='muteLoanNotifications' />
    </Modal.Page>
  )
}

const AdditionalPeople = () => {
  return (
    <Modal.Page page='Additional People'>
      <Form.List formKey='additionalPeople' add remove>
        <Form.Row count={2}>
          <BorrowerSelect formKey='personId' label='Person' />
          <Select formKey='loanRelationType' options={loanReloationTypes} />
        </Form.Row>
      </Form.List>
    </Modal.Page>
  )
}

const InstallmentAtOrigination = ({ migrate, setMigrate }) => {
  const { value } = useFormValueAndOnChange({ formKey: 'loanTypeId' })

  const { companyId } = useParams()

  const [dynamicFees, query] = useDynamicFeesQuery({
    companyId,
    loanTypeId: value,
  })

  return (
    <Modal.Page page='At Origination (Required)'>
      <Form.Provider formKey='atOrigination'>
        <NumericInput formKey='amountFinanced' required />
        <NumericInput formKey='duration' required />
        <Input formKey='originatingCreditorName' required />
        <Select
          formKey='originationLicense'
          options={originationLicenses}
          required
        />
        <Form.List formKey='interestRates' required add remove>
          <Form.Row>
            <NumericInput
              formKey='days'
              label='Days'
              placeholder='Leave blank if last rate item'
              required
            />
            <NumericInput formKey='rate' required />
            <Select formKey='interestType' options={['absolute', 'spread']} />
          </Form.Row>
        </Form.List>

        <Select
          formKey='paymentFrequency'
          required
          options={paymentFrequencies}
        />

        <ContactAddressSelect formKey='personAddressId' required />

        <Divider margin='16px 0' />
        <OptionalFields>Optional Fields</OptionalFields>

        <NumericInput formKey='specificDays' tooltip={specificDaysTooltip} />

        <NumericInput formKey='aprNominal' />
        <NumericInput formKey='aprEffective' />
        <Form.Provider formKey='fees' section>
          {!!value && query.isSuccess && dynamicFees?.length > 0 && (
            <Form.List formKey='dynamicFees' add remove>
              <Select
                formKey='apiName'
                options={_.map(dynamicFees, (fee) => fee?.name?.apiName)}
              />
              <NumericInput
                formKey='feeAmount'
                label='Fee Amount'
                placeholder='Number (Optional)'
              />
              <NumericInput
                formKey='feeCapAmount'
                label='Fee CapAmount'
                placeholder='Number (Optional)'
              />
            </Form.List>
          )}
          <NumericInput formKey='originationFeeAmount' />
          <NumericInput formKey='serviceFeeAmount' />
          <NumericInput formKey='serviceFeeCapAmount' />
          <NumericInput formKey='totalOtherFeesAmount' />
        </Form.Provider>

        <NumericInput formKey='principalAmount' />
        <Input formKey='enforcedEndDate' />
        <NumericInput
          formKey='bufferToPushOutSchedule'
          tooltip='The number of calendar days that will be added to the activation date. The resulting date will be used to push out due dates as if the loan was activated on that date (activation date + buffer). Interest will still be accrued normally from the activation date. Not valid for the singleTerm frequency.'
        />
        <NumericInput formKey='periodicPaymentAmount' />
        <NumericInput formKey='downPaymentAmount' />
        <NumericInput formKey='totalInterestAmount' />
        <NumericInput formKey='totalDiscountAmount' />
        <NumericInput formKey='totalInterestCapAmount' />

        <Form.List formKey='promoRates' add remove>
          <Form.Row>
            <NumericInput formKey='days' tooltip={promoRateDaysTooltip} />
            <NumericInput
              formKey='rate'
              tooltip='The annual promo rate for the period defined. Loans cannot accrue negative interest rate. If the promo rate is greater than the interest rate, a loan will accrue 0% interest rate. The format is decimal. For example, 0.01 is a 1% annual rate, 0.15 is a 15% annual rate.'
            />
          </Form.Row>
        </Form.List>

        <Form.List formKey='expectedPayments' add remove>
          <Form.Row>
            <Input formKey='date' required />
            <Select
              formKey='paymentType'
              options={[
                'periodicPayment',
                'originationFee',
                'drawFee',
                'dynamicFee',
                'downPayment',
                'discountCredit',
              ]}
              required
            />
            <NumericInput formKey='amount' required />
            <NumericInput formKey='principalAmount' />
            <NumericInput formKey='interestAmount' />
            <NumericInput formKey='interestBeforeDiscountAmount' />
            <Input formKey='unroundedPrincipalAmount' />
            <Input formKey='unroundedInterestAmount' />
            <Input formKey='unroundedInterestBeforeDiscountAmount' />
            <Select boolean formKey='isForcedAmount' />
            {!!value && query.isSuccess && dynamicFees?.length > 0 && (
              <Form.Provider formKey='dynamicFeeDetails' section>
                <Select
                  formKey='dynamicFeeTypeId'
                  options={_.map(dynamicFees, (fee) => ({
                    label: fee?.name?.apiName,
                    value: fee?.id,
                  }))}
                />
              </Form.Provider>
            )}
          </Form.Row>
        </Form.List>

        <Form.List formKey='advanceSchedule' add remove>
          <Form.Row>
            <Select
              formKey='status'
              options={['scheduled', 'settled', 'pending', 'canceled']}
              required
            />
            <NumericInput formKey='amount' required />
            <Input formKey='advanceDate' />
            <Form.Provider formKey='advanceTimeOfDay' section>
              <NumericInput formKey='hour' required />
              <NumericInput formKey='minute' required />
              <NumericInput formKey='second' />
            </Form.Provider>

            <Form.Provider formKey='advanceDetails' section>
              <Input formKey='description' />
              <Input formKey='pointOfSaleType' />
              <Input formKey='categoryId' />
              <Input formKey='merchantId' />
              <Input formKey='merchantName' />
            </Form.Provider>
          </Form.Row>
        </Form.List>
      </Form.Provider>
      <br />
      <br />
    </Modal.Page>
  )
}

const Migration = () => {
  return (
    <Modal.Page page='Migration'>
      <Form.Provider formKey='migration'>
        <Select
          formKey='migrationStatus'
          options={[
            'prepMigration',
            'migrating',
            'nonMigrated',
            'completed',
            'failed',
          ]}
          required
        />
        <Input
          formKey='activatedDate'
          label='Activated Date'
          placeholder='e.g. "2022-05-26"'
          required
        />
        <Form.Provider formKey='activatedTimeOfDay' section>
          <NumericInput formKey='hour' required />
          <NumericInput formKey='minute' required />
          <NumericInput formKey='second' />
        </Form.Provider>
      </Form.Provider>
    </Modal.Page>
  )
}

const LOCAtOrigination = () => {
  const { value } = useFormValueAndOnChange({ formKey: 'loanTypeId' })

  const { companyId } = useParams()

  const [dynamicFees, query] = useDynamicFeesQuery({
    companyId,
    loanTypeId: value,
  })

  return (
    <Modal.Page page='At Origination (Required)'>
      <Form.Provider formKey='atOrigination'>
        <NumericInput formKey='creditLimitAmount' required />
        <Input formKey='originatingCreditorName' required />
        <Select
          formKey='originationLicense'
          options={originationLicenses}
          required
        />
        <Form.List formKey='interestRates' required add remove>
          <Form.Row>
            <NumericInput formKey='days' required />
            <NumericInput formKey='rate' required />
            <Select formKey='interestType' options={['absolute', 'spread']} />
          </Form.Row>
        </Form.List>

        <Select
          formKey='paymentFrequency'
          required
          options={paymentFrequencies}
        />

        <Divider margin='16px 0' />
        <OptionalFields>Optional Fields</OptionalFields>

        <NumericInput formKey='specificDays' tooltip={specificDaysTooltip} />

        <ContactAddressSelect formKey='personAddressId' />

        <NumericInput formKey='aprNominal' />
        <NumericInput formKey='aprEffective' />
        <Form.Provider formKey='fees' section>
          {!!value && query.isSuccess && dynamicFees?.length > 0 && (
            <Form.List formKey='dynamicFees' add remove>
              <Select
                formKey='apiName'
                options={_.map(dynamicFees, (fee) => fee?.name?.apiName)}
              />
              <NumericInput
                formKey='feeAmount'
                label='Fee Amount'
                placeholder='Number (Optional)'
              />
              <NumericInput
                formKey='feeCapAmount'
                label='Fee CapAmount'
                placeholder='Number (Optional)'
              />
            </Form.List>
          )}
          <NumericInput formKey='originationFeeAmount' />
          <NumericInput formKey='serviceFeeAmount' />
          <NumericInput formKey='serviceFeeCapAmount' />
          <NumericInput formKey='totalOtherFeesAmount' />
        </Form.Provider>

        <NumericInput
          formKey='bufferToPushOutSchedule'
          tooltip='The number of calendar days that will be added to the activation date. The resulting date will be used to push out due dates as if the loan was activated on that date (activation date + buffer). Interest will still be accrued normally from the activation date. Not valid for the singleTerm frequency.'
        />

        <NumericInput formKey='downPaymentAmount' />

        <Form.List formKey='promoRates' add remove>
          <Form.Row>
            <NumericInput formKey='days' tooltip={promoRateDaysTooltip} />
            <NumericInput
              formKey='rate'
              tooltip='The annual promo rate for the period defined. Loans cannot accrue negative interest rate. If the promo rate is greater than the interest rate, a loan will accrue 0% interest rate. The format is decimal. For example, 0.01 is a 1% annual rate, 0.15 is a 15% annual rate.'
            />
          </Form.Row>
        </Form.List>
      </Form.Provider>
      <br />
    </Modal.Page>
  )
}

const CreateInstallmentLoanForm = ({ onChange }) => {
  return (
    <>
      <Basics loanTypeKind='installment' />

      <InstallmentAtOrigination />

      <Migration />

      <AdditionalPeople />

      <InstallmentSource onChange={onChange} />
    </>
  )
}

const CreateLineOfCreditLoanForm = () => {
  return (
    <>
      <Basics loanTypeKind='lineOfCredit' />

      <LOCAtOrigination />

      <AdditionalPeople />
    </>
  )
}

export { CreateInstallmentLoanForm, CreateLineOfCreditLoanForm }

const OptionalFields = styled.p`
  margin: 8px 0 8px 10px;
  color: rgb(102, 102, 102);
  font-weight: 600;
`

const specificDaysTooltip = (
  <>
    Specific days designate the day(s) that due dates will fall on. The allowed
    values for specific day(s) depends on the paymentFrequency.
    <br />
    <br />
    weekly - provide one number in the valid range 1-7. 1=Monday...7=Sunday.
    <br />
    <br />
    For example: [1].
    <br />
    <br />
    everyTwoWeeks - provide two inputs. The first input (required) is an integer
    in the valid range 1-7. 1=Monday...7=Sunday.
    <br />
    <br />
    The second input is a date that indicates when to start the cycle. If the
    second input is not passed, the system will default to the first valid start
    date based on the first input (the day of week passed in). If this date is
    provided then it can override the minimum due date gap specified on the loan
    type.
    <br />
    <br />
    For example: [1, "2020-09-16"].
    <br />
    <br />
    twiceMonthly - provide two numbers in the valid range 1-31. 1=first day of a
    month. Use 31 to indicate the last day of a month.
    <br />
    <br />
    For example: [1, 15] or [15, 31].
    <br />
    <br />
    monthly - provide one number in the valid range 1-31. 1=first day of a
    month. Use 31 to indicate the last day of a month.
    <br />
    <br />
    For example: [1].
    <br />
    <br />
    singleTerm - provide one number to indicate duration as a number of calendar
    days or provide a valid calendar date in the future. This option is only
    valid for use on the installment type and at loan origination. For example:
    [90] or ["2020-09-16"].
    <br />
    <br />
    For weekly, everyTwoWeeks, twiceMonthly, monthly: If specificDays is left
    null, then the system will calculate due dates according to the
    paymentFrequency relative to the origination or activation date (or use
    existing specific days in the case of change due date).
    <br />
    <br />
    For singleTerm: The specificDays is mandatory.
  </>
)

const promoRateDaysTooltip = (
  <>
    Number of calendar days the promo rate applies to. Use null to indicate that
    the promo rate applies to the end of the loan duration. For example:
    <br />
    <br />
    {`"days": 30 ,"rate": 0.1`},<br />
    <br />
    {`"days": null, "rate": 0.01`}
    <br />
    <br />
    The loan will receive interest discount of 10% for the first 30 days. From
    day 31 until the end, the loan will receive interest discount of 1%.
    <br />
    <br />
  </>
)
