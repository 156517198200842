import { useMemo } from 'react'

const useCompanyApi = (api, companyId) => {
  const companyApi = useMemo(() => {
    return _.mapValues(api, (section) => {
      return _.mapValues(section, (path) => {
        return _.mapValues(path, (caller) => {
          return (options = {}) => {
            const { pathArgs, ...rest } = options
            const newPathArgs = { companyId, ...pathArgs }
            const newOptions = { ...rest, pathArgs: newPathArgs }
            return caller(newOptions)
          }
        })
      })
    })
  }, [api, companyId])

  return companyApi
}

export default useCompanyApi
