import {
  LoadingContainer,
  Page,
  Model,
  Modal,
  Select,
  NumericInput,
  Form,
  Input,
} from 'peach/components'
import { useWorkflowStepTypesQuery } from 'peach/data'
import { useApi } from 'peach/hooks'

const WorkflowStepTypes = () => {
  const [workflowStepTypes, query] = useWorkflowStepTypesQuery()

  const api = useApi()

  const sendCreateWorkflowStepType = async (workflowStepType) => {
    await api.company.workflowStepTypes.post({ body: workflowStepType })
    query.refetch()
  }

  const createWorkflowStepType = (
    <Modal
      trigger
      title='Create Workflow Step Type'
      onSubmit={sendCreateWorkflowStepType}
      initialValue={{}}
      pages
    >
      <Modal.Page page='Workflow Step Type'>
        <Input formKey='descriptionShort' />
        <Input formKey='descriptionLong' />
        <Input formKey='decisionQuestion' />
        <Select
          formKey='decisionQuestionType'
          options={['yesNo', 'approveReject']}
        />
        <Select boolean formKey='mandatory' />
        <Select
          formKey='visibility'
          options={[
            'normal',
            'closingApproved',
            'closingDenied',
            'closingFalsePositive',
            'closingNoOutcome',
          ]}
        />
        <NumericInput formKey='version' />
        <Select
          formKey='versionStatus'
          options={['draft', 'active', 'deprecated']}
        />
        <Form.List
          formKey='workItems'
          add
          remove
          renderItem={(props) => <Input {...props} />}
        />
      </Modal.Page>
    </Modal>
  )

  return (
    <LoadingContainer query={query}>
      {createWorkflowStepType}
      <Page.Full>
        <Model.List
          title='Workflow Step Types'
          json={workflowStepTypes}
          withRelated
          withDetails
          withTimestamps
        />
      </Page.Full>
    </LoadingContainer>
  )
}

export default WorkflowStepTypes
