import { parseError } from 'peach/helpers'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  width: 100%;
  max-width: 550px;
`
const ErrorBox = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  color: red;
  background-color: rgb(255, 196, 196);
  border: 1px solid red;
  box-shadow: 0 10px 10px rgba(255, 128, 128, 0.2);
`

const Bar = styled.div`
  padding: 12px;
  border-bottom: 1px solid red;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Title = styled.span`
  display: inline-block;
  flex-basis: 400px;
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;

  font-size: 20px;
  font-weight: bold;
`

const Message = styled.div`
  width: 100%;
  padding: 24px 12px;
  font-size: 14px;
  text-align: left;
  white-space: pre-wrap;
  overflow-y: auto;
`

const ClearButton = styled.button`
  margin: 0;
  padding: 4px 12px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  border-radius: 4px;
  font-size: 24px;
  color: red;
  &:hover {
    background-color: red;
    color: white;
  }
`

const LoadingErrorBox = ({ error, onClear }) => {
  const titleStatus = error?.status ? ` (${error.status})` : ''

  const title = 'Error' + titleStatus

  return (
    <Wrapper>
      <ErrorBox>
        <Bar>
          <Title>{title}</Title>
          {onClear && <ClearButton onClick={onClear}>✗</ClearButton>}
        </Bar>
        <Message>{parseError(error)}</Message>
      </ErrorBox>
    </Wrapper>
  )
}

export default LoadingErrorBox
