import { humanizeKey } from 'peach/helpers'

const exists = (val) => typeof val !== 'undefined' && val !== null

const formatOptions = (optionsList, opts = {}) => {
  const { notes } = opts

  const formattedList = _.map(optionsList, (option) => {
    if (_.isString(option)) {
      return {
        label: humanizeKey(option),
        value: option,
        note: notes ? option : null,
        disabled: false,
      }
    }

    if (exists(option?.value)) {
      const { label, value, note, disabled } = option
      return {
        label: label || humanizeKey(value),
        value,
        note: notes ? note || value : null,
        disabled: !!disabled,
      }
    }
  })

  return _.uniqBy(_.compact(formattedList), (option) => option.value)
}

export default formatOptions
