import { useState, useRef } from 'react'

import { useOnChangeEffect, useOnUnmountEffect } from 'peach/hooks'

const useHasChanged = (options, wait = 250) => {
  const { value, onChange, onBlur } = options || {}

  const [hasChanged, setHasChanged] = useState(false)
  const delayRef = useRef()

  useOnChangeEffect(() => {
    // TODO: prevent marking touched right after initial render
    clearTimeout(delayRef.current)
    if (!hasChanged) {
      delayRef.current = _.delay(() => setHasChanged(true), wait)
      return undefined
    }
  }, [value])

  useOnUnmountEffect(() => clearTimeout(delayRef.current))

  const handleBlur = (...args) => {
    // if (delayRef.current) {
    //   setHasChanged(true)
    //   clearTimeout(delayRef.current)
    // }
    if (onBlur) onBlur(...args)
  }
  const handleChange = (...args) => {
    if (onChange) onChange(...args)
  }
  return {
    hasChanged,
    onChange: handleChange,
    onBlur: handleBlur,
  }
}

export default useHasChanged
