import useAuthenticatedRead from './useAuthenticatedRead'

const useReadData = (promiseFn, deps) => {
  const readFn = async () => {
    if (_.some(deps, (dep) => !dep)) return undefined

    const resp = await promiseFn()

    return resp?.data || resp
  }
  return useAuthenticatedRead(readFn, deps)
}

export default useReadData
