import { forwardRef } from 'react'

import useAsyncOnChange from 'peach/components/Textarea/useAsyncOnChange'
import { withLabel, withFormValue } from 'peach/decorators'
import { humanizeKey } from 'peach/helpers'
import styled, { css } from 'styled-components/macro'

import ClearIcon from './ClearIcon'

const Wrapper = styled.span`
  display: flex;
  flex: 1 1 auto;
  position: relative;
`
const focused = css`
  border-color: ${(p) => p.theme.highlight};
  box-shadow: none;
`
export const StyledInput = styled.input`
  padding: 8px 12px;
  border-radius: 4px;
  border: 1px solid ${(p) => p.theme.border};
  background-color: ${(p) => p.theme.background};
  color: ${(p) => p.theme.text};
  width: ${(p) => p.width};
  background-color: ${(p) => p.theme.background};

  &:disabled {
    color: #888;
    border-color: ${(p) => p.theme.border};
    background-color: ${(p) => p.theme.offset};
  }

  ${(p) => p.isFocused && focused}

  &:focus {
    ${focused}
  }
`

const ClearWrapper = styled.span`
  display: inline-block;
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 6px;
`

const Input = forwardRef((props, ref) => {
  const {
    formKey,
    isFocused,
    width,
    onEnter,
    onEscape,
    onArrowUp,
    onArrowDown,
    onKeyDown,
    placeholder,
    type,
    nullable,
    delay,
    ...rest
  } = props

  // todo: figure out how to separate this async stuff out and opt into it

  const { value, onChange, onFocus, onBlur } = useAsyncOnChange(props, delay)

  const displayType = type || (formKey === 'password' ? 'password' : 'text')

  const handleKeyDown = (event) => {
    if (onKeyDown) onKeyDown(event)

    switch (event.key) {
      case 'Enter':
        if (onEnter) onEnter(event)
        break
      case 'Escape':
        if (onEscape) onEscape(event)
        break
      case 'ArrowUp':
        if (onArrowUp) onArrowUp(event)
        break
      case 'ArrowDown':
        if (onArrowDown) onArrowDown(event)
        break
      default: {
      }
    }
  }

  return (
    <Wrapper>
      <StyledInput
        key={formKey}
        isFocused={isFocused}
        {...rest}
        width={width || '100%'}
        onChange={(e) => onChange(e.target.value)}
        value={value || ''}
        placeholder={placeholder || humanizeKey(formKey)}
        onKeyDown={handleKeyDown}
        type={displayType}
        onFocus={onFocus}
        onBlur={onBlur}
        ref={ref}
      />
      {nullable && value !== null && (
        <ClearWrapper>
          <ClearIcon onClick={() => onChange(null)} />
        </ClearWrapper>
      )}
    </Wrapper>
  )
})

export { Input as RawInput }

export default withFormValue(withLabel(Input))
