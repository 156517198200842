import { useDataQuery, useQueryApi } from '../useQueryApi'

const useTemplatesQuery = (options) => {
  const [api, { companyId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'templates'],

    queryFn: () => {
      return api.communicator.templates.get({ queryParams: { companyId } })
    },
  })
}

export default useTemplatesQuery
