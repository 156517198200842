import { Switch, Route } from 'peach/router'

import PersonCase from './PersonCase'
import PersonCases from './PersonCases'

const PersonCasesRoutes = () => {
  return (
    <Switch>
      <Route path='/companies/:companyId/borrowers/:personId/cases' exact>
        <PersonCases />
      </Route>

      <Route path='/companies/:companyId/borrowers/:personId/cases/:caseId'>
        <PersonCase />
      </Route>
    </Switch>
  )
}

export default PersonCasesRoutes
