import { getName, truncateString } from 'peach/helpers'
import styled, { css } from 'styled-components/macro'

import Json from '../Json'
import Link from '../Link/Link'
import Stack from '../Stack/Stack'

const nameStyle = css`
  font-weight: bold;
  font-size: 1.05em;
  // word-break: break-word;
`

const BigLink = styled(Link)`
  ${nameStyle}
`

const BigName = styled.span`
  ${nameStyle}
`

const ItemInfo = ({ item, url, noLink }) => {
  const { id, externalId, status } = item || {}

  const name = truncateString(getName(item), 50)

  const displayName = noLink ? (
    <BigName>{name}</BigName>
  ) : (
    <BigLink to={`${url}/${id}`}>{name}</BigLink>
  )

  return (
    <Stack>
      {displayName}

      <Json.Value type='id' value={id} noLink />

      {externalId && <Json.Value type='id' value={`Ext: ${externalId}`} />}

      {status && <Json.Value type='string' value={`Status: ${status}`} />}
    </Stack>
  )
}

export default ItemInfo
