import { useDataQuery, useQueryApi } from '../useQueryApi'

const useLoanAutopayQuery = (options) => {
  const { disabled } = options || {}

  const [api, { companyId, personId, loanId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['people', personId, 'loans', loanId, 'autopay'],

    queryFn: async () => {
      try {
        const resp = await api.loan.autopay.get({
          pathArgs: { personId, loanId },
        })
        return resp
      } catch (error) {
        // this is to guard against expected 404s
        if (error?.status === 404) {
          return error
        } else {
          throw error
        }
      }
    },

    disabled: !companyId || !personId || !loanId || disabled,
  })
}

export default useLoanAutopayQuery
