import { useDataQuery, useQueryApi } from '../useQueryApi'

const useCaseTypesQuery = (options) => {
  const [api, { companyId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['case-types'],

    queryFn: () => {
      return api.caseTypes.caseTypes.get({
        pathArgs: { companyId },
      })
    },
  })
}

export default useCaseTypesQuery
