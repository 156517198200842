import { useEffect, useState } from 'react'

import useAuthedRequest from 'core/api/useAuthedRequest'
import useAuthManagement from 'core/auth/useAuthManagement'
import { useApiBase } from 'core/env/useEnv'
import { useStorage } from 'core/storage/StorageProvider'
import _ from 'lodash'
import { useDataQuery, useQueryApi } from 'peach/data/useQueryApi'

const usePaginatedQuery = (options) => {
  const [api, { companyId, queryParams, queryKey, page, queryFnPath }] =
    useQueryApi(options)
  const apiBase = useApiBase()
  const storage = useStorage()
  const [authState, authApi] = useAuthManagement({ apiBase, storage })
  const { token, apiKey } = authState
  const { setRequiresReauth } = authApi
  const [executeRequest] = useAuthedRequest({
    apiBase,
    token,
    apiKey,
    on401: setRequiresReauth,
  })

  const [nextUrl, setNextUrl] = useState('')

  const resp = useDataQuery({
    queryKey: [...queryKey, page],

    queryFn: () => {
      if (nextUrl) {
        return executeRequest({
          method: 'GET',
          paginationUrl: nextUrl,
        })
      } else {
        const apiMethod = _.get(api, queryFnPath)

        if (!apiMethod) {
          throw new Error(`queryFnPath: ${queryFnPath} does not exist`)
        }

        return apiMethod({
          pathArgs: { companyId },
          queryParams,
        })
      }
    },

    keepPreviousData: true,
  })

  useEffect(() => {
    setNextUrl(resp?.data?.nextUrl)
  }, [resp])

  return resp
}

export default usePaginatedQuery
