import { useEffect, useRef } from 'react'

/*

initializeWithValue is relevant for some particular onChange mechanics. 
it's subtle enough that it should probably just be a separate hook

*/

const usePrevious = (value, initializeWithValue) => {
  const previousRef = useRef(initializeWithValue ? value : undefined)

  useEffect(() => {
    previousRef.current = value
  }, [value])

  return previousRef.current
}

export default usePrevious
