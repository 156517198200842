import { useDataQuery, useQueryApi } from '../useQueryApi'

const useDynamicFeesQuery = (options) => {
  const [api, { companyId, loanTypeId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: [
      'companies',
      companyId,
      'loan-types',
      loanTypeId,
      'dynamic-fee-types',
    ],

    queryFn: () => {
      return api.company.dynamicFeeTypes.get({
        pathArgs: { companyId, loanTypeId },
      })
    },

    enabled: !!companyId && !!loanTypeId,
  })
}

export default useDynamicFeesQuery
