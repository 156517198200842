import { Breadcrumb } from 'peach/components'
import { Switch, Route, Redirect } from 'peach/router'

import CompanyRoutes from './CompanyRoutes'

const CompaniesRoutes = () => {
  return (
    <>
      <Breadcrumb to='/companies' />
      <Switch>
        <Route path='/companies/:companyId'>
          <CompanyRoutes />
        </Route>

        <Redirect to='/companies' />
      </Switch>
    </>
  )
}

export default CompaniesRoutes
