import { Select } from 'peach/components'
import { useEmployeesQuery } from 'peach/data'
import { getName } from 'peach/helpers'

const CompanyEmployeeSelect = (props) => {
  const [employees] = useEmployeesQuery()

  const options = _.map(employees, (employee) => {
    return {
      value: employee.id,
      label: getName(employee),
    }
  })

  return <Select label='Employee' {...props} options={options} />
}

export default CompanyEmployeeSelect
