import {
  LoadingContainer,
  Page,
  Modal,
  ModelViewer,
  Panels,
  Bar,
  DateInput,
  NumericInput,
  Form,
} from 'peach/components'
import { useDrawRateQuery } from 'peach/data'
import useCreateDrawRate from 'peach/data/loan/useCreateDrawRate'

import defaultDrawRate from './defaultDrawRate'

const DrawRates = () => {
  const [drawRates, query] = useDrawRateQuery()

  const [sendCreateDrawRate, mutation] = useCreateDrawRate()

  const header = <Bar title='Draw Rates' />

  const createDrawRate = (
    <Modal
      title='Create Draw Rate'
      onSubmit={sendCreateDrawRate}
      initialValue={defaultDrawRate}
    >
      <Form.Provider formKey='promoRate' section>
        <NumericInput formKey='annualRate' />
        <DateInput formKey='startDate' />
        <DateInput formKey='endDate' />
      </Form.Provider>
      <Form.Provider formKey='interestRate' section>
        <NumericInput formKey='annualRate' />
        <DateInput formKey='startDate' />
        <DateInput formKey='endDate' />
      </Form.Provider>
    </Modal>
  )

  return (
    <Panels header={header}>
      {createDrawRate}
      <LoadingContainer queries={[query, mutation]}>
        <Page.Full>
          <ModelViewer json={drawRates} />
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default DrawRates
