import {
  LoadingContainer,
  Page,
  Modal,
  Model,
  Panels,
  Bar,
  Input,
  NumericInput,
  DateInput,
  Select,
  Textarea,
  Breadcrumb,
} from 'peach/components'
import { useDrawPurchaseQuery } from 'peach/data'
import { getName } from 'peach/helpers'
import { useApi } from 'peach/hooks'
import { useParams } from 'peach/router'

const DrawPurchase = () => {
  const [purchase, query] = useDrawPurchaseQuery()
  const { personId, loanId, drawId, purchaseId } = useParams()
  const api = useApi()

  const header = <Bar title='Purchase' />

  const sendEditPurchase = async (purchase) => {
    await api.draw.purchase.put({
      pathArgs: { personId, loanId, drawId, purchaseId },
      body: purchase,
    })
    query.refetch()
  }

  const editPurchase = (
    <Modal
      title='Edit Purchase'
      onSubmit={sendEditPurchase}
      initialValue={purchase}
    >
      <NumericInput formKey='amount' />
      <DateInput formKey='purchaseDate' required />
      <Input formKey='externalId' />
      <Select formKey='type' options={['regular', 'refund', 'cashBack']} />
      <Select formKey='status' options={['settled', 'pending', 'canceled']} />
      <Textarea.Json formkey='metadata' />
    </Modal>
  )

  return (
    <Panels header={header}>
      <Breadcrumb title={getName(purchase)} />
      {editPurchase}
      <LoadingContainer query={query}>
        <Page.Full>
          <Model.Item json={purchase} />
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default DrawPurchase
