import { LoadingContainer, Json, Page } from 'peach/components'
import { useReadData, useApi } from 'peach/hooks'

const Home = () => {
  const api = useApi()

  const [peachy, loadingPeachy, peachyError] = useReadData(() => {
    return api.peach.peachy.get()
  })

  const [pit, loadingPit, pitError] = useReadData(() => {
    return api.peach.pit.get()
  })

  const [flags, loadingFlags, flagsError] = useReadData(() => {
    return api.peach.flags.get()
  })

  return (
    <LoadingContainer
      loading={loadingPeachy || loadingPit || loadingFlags}
      error={peachyError || pitError || flagsError}
    >
      <Page>
        <Json.Table title='Peachy' json={peachy} />
        <Json.Table title='Pit' json={pit} />
        <Json.Table title='Flags' json={flags} />
      </Page>
    </LoadingContainer>
  )
}

export default Home
