import { Component } from 'react'

import DefaultFallback from './DefaultFallback'

class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: null,
    componentStack: null,
  }

  componentDidCatch(error, info) {
    const { componentStack } = info || {}

    this.setState({
      hasError: true,
      error,
      componentStack,
    })
  }

  onReset = () => {
    this.setState({
      hasError: false,
      error: null,
      componentStack: null,
    })

    if (this.props.onReset) this.props.onReset()
  }

  render() {
    const { hasError, error, componentStack } = this.state
    const { onReset } = this
    const { fallback, children } = this.props

    if (!hasError) return children

    const renderProps = { error, onReset, componentStack }

    return (
      (_.isFunction(fallback) ? fallback(renderProps) : fallback) || (
        <DefaultFallback {...renderProps} />
      )
    )
  }
}

export default ErrorBoundary
