const truncateString = (str, count = 50) => {
  if (!_.isString(str)) return str

  if (_.size(str) < count + 2) {
    return str
  }

  return str.slice(0, count) + '…'
}

export default truncateString
