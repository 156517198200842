import { Modal } from 'peach/components'
import { useApi } from 'peach/hooks'
import { useLoanId } from 'peach/scopes/loan'
import { usePersonId } from 'peach/scopes/person'

import DeferScheduleForm from './DeferScheduleForm'

const DeferScheduleModal = ({ onSuccess }) => {
  const personId = usePersonId()
  const loanId = useLoanId()

  const api = useApi()

  const sendDeferSchedule = async (options) => {
    await api.loan.deferSchedule.post({
      pathArgs: { personId, loanId },
      body: options,
    })
    if (onSuccess) onSuccess()
  }

  const initialValue = {
    bufferToPushOutSchedule: 0,
    waiveFee: false,
  }
  return (
    <Modal
      title='Defer Schedule'
      onSubmit={sendDeferSchedule}
      initialValue={initialValue}
      tabs
      render={({ value, onChange }) => {
        return (
          <DeferScheduleForm
            value={value}
            personId={personId}
            loanId={loanId}
          />
        )
      }}
    />
  )
}

export default DeferScheduleModal
