import { usePersonQuery } from 'peach/data'

import ModelLink from './ModelLink'

const PersonLink = ({ personId, jsonKey }) => {
  const [person, query] = usePersonQuery({ personId })

  return (
    <ModelLink id={personId} model={person} query={query} jsonKey={jsonKey} />
  )
}

export default PersonLink
