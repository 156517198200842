import { Panels, Breadcrumbs, Breadcrumb } from 'peach/components'
import {
  ActionsContainer,
  ActionsMenu,
} from 'peach/decorators/withActionTrigger'
import styled from 'styled-components/macro'

import AppRoutes from './AppRoutes'
const Main = styled.div`
  background-color: ${({ theme }) => theme.background};
  height: 100%;
`

const Bar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 50px;
`

const ActionsWrapper = styled.span`
  display: inline-block;
  padding: 4px 12px;
`

// const home = '🏠🛖🏚🏠🏡'

const AppController = () => {
  const controls = (
    <Bar>
      <Breadcrumbs />
      <ActionsWrapper>
        <ActionsMenu />
      </ActionsWrapper>
    </Bar>
  )
  return (
    <ActionsContainer>
      <Main>
        <Panels header={controls}>
          <Breadcrumb title='🏠' to='/' />
          <AppRoutes />
        </Panels>
      </Main>
    </ActionsContainer>
  )
}

export default AppController
