import getAll from './getAll'

const mapConfig = (apiConfig, executeRequest) => {
  const { getFetchOptions } = executeRequest

  return _.mapValues(apiConfig, (section) => {
    return _.mapValues(section, (pathPattern) => {
      const makeCaller = (method) => {
        const caller = (options) => {
          return executeRequest({ pathPattern, method, ...options })
        }

        caller.getFetchOptions = (options) => {
          return getFetchOptions({ pathPattern, method, ...options })
        }

        return caller
      }

      const callers = {
        get: makeCaller('GET'),
        getAll: (options) => {
          const apiOptions = { pathPattern, method: 'GET', ...options }
          return getAll({ executeRequest, apiOptions })
        },
        post: makeCaller('POST'),
        put: makeCaller('PUT'),
        patch: makeCaller('PATCH'),
        delete: makeCaller('DELETE'),
      }

      callers._pathPattern = pathPattern

      return callers
    })
  })
}

export default mapConfig
