import { useDataQuery, useQueryApi } from '../useQueryApi'

const useLoanQuery = (options) => {
  const [api, { personId, loanId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['people', personId, 'loans', loanId],

    queryFn: () => {
      return api.person.loan.get({
        pathArgs: { personId, loanId },
      })
    },
  })
}

export default useLoanQuery
