import executeRequest from '../api/executeRequest'

const createSession = (apiBase, options) => {
  const { username, password, userType, companyId, domain } = options

  return executeRequest({
    method: 'POST',
    pathPattern: '/auth/token',
    apiBase,
    body: { username, password },
    queryParams: domain ? { domain } : { userType, companyId },
  })
}

// import jwt_decode from 'jwt-decode'

// // checking if token has expired by decoding jwt token for exp property and
// // comparing it with current Date.now() which is the same UTC format as jwt exp
// export default function isTokenExpired(token) {
//   try {
//     const expirationTime = token && jwt_decode(token).exp
//     return Date.now() >= expirationTime * 1000
//   } catch {
//     return false
//   }
// }

const renewSession = (apiBase, { token }) => {
  return executeRequest({
    method: 'PUT',
    pathPattern: '/auth/token',
    token,
    apiBase,
  })
}

const validateApiKey = (apiBase, apiKey) => {
  return executeRequest({
    method: 'GET',
    pathPattern: '/auth/permissions',
    apiKey,
    apiBase,
  })
}

const requestOneTimeCode = (apiBase, companyId, options) => {
  return executeRequest({
    method: 'POST',
    pathPattern: '/companies/{companyId}/auth/send-one-time-code',
    apiBase,
    pathArgs: { companyId },
    body: options,
  })
}

export { createSession, renewSession, validateApiKey, requestOneTimeCode }
