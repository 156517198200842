import { useState } from 'react'

import {
  Json,
  Page,
  LoadingContainer,
  Panels,
  Bar,
  Select,
  MiniTable,
  Card,
  Form,
} from 'peach/components'
import { useDocumentsQuery } from 'peach/data'
import { humanizeKey, getLink } from 'peach/helpers'
import { useCompanyId } from 'peach/scopes/company'

/*

  - if type is other, than it should be borrower uploaded 
  - otherwise it's likely a service upload
  - in the future the agents may be able to upload on behalf of a borrower 
  
*/

const filterDocuments = (docs, filters) => {
  let $docs = docs

  const { documentType, uploadedByUserType } = filters || {}

  if (documentType) {
    $docs = _.filter($docs, { type: documentType })
  }
  if (uploadedByUserType) {
    $docs = _.filter(
      $docs,
      (doc) => doc.uploadedBy?.userType === uploadedByUserType,
    )
  }

  return $docs
}

const options = [
  { label: 'Documents', value: true },
  { label: 'Include Archived', value: false },
]

const Documents = () => {
  const companyId = useCompanyId()

  const [filters, setFilters] = useState({
    documentType: undefined,
    uploadedByUserType: 'borrower',
    includeArchived: true,
  })

  const [documents, query] = useDocumentsQuery({
    includeArchived: filters.includeArchived,
  })

  const sortedDocuments = _.sortBy(documents, 'createdAt').reverse()

  const types = _.uniq(_.map(documents, 'type'))

  const filteredDocs = filterDocuments(sortedDocuments, filters)

  const header = (
    <Bar
      right={
        <Form.Provider value={filters} onChange={setFilters}>
          <Select formKey='documentType' options={types} bare nullable />
          <Select
            formKey='uploadedByUserType'
            options={['borrower', 'agent', 'service']}
            bare
            nullable
          />
          <Select formKey='includeArchived' options={options} bare />
        </Form.Provider>
      }
    />
  )

  const columns = ['Document', 'Status', 'Loan ID', 'Timestamps']

  const renderRows = (docs) => {
    return _.map(docs, (doc) => {
      const {
        id,
        status,
        archived,
        loanId,
        type,
        uploadedBy,
        createdAt,
        updatedAt,
      } = doc
      const { userType } = uploadedBy || {}
      const displayType = humanizeKey(type)
      const link = getLink({ companyId, ...doc })
      return (
        <MiniTable.Row key={id}>
          <Card to={link} model={doc} subtitle={displayType} />
          <Json.List
            humanizeKeys
            json={{ status, archived, uploadedBy: userType }}
          />
          {loanId}
          <Json.List humanizeKeys json={{ createdAt, updatedAt }} />
        </MiniTable.Row>
      )
    })
  }

  const grouped = _.groupBy(filteredDocs, 'type')
  const renderTable = (docs, type) => {
    return (
      <MiniTable
        key={type}
        title={humanizeKey(type) + ` (${_.size(docs)})`}
        columns={columns}
      >
        {renderRows(_.take(docs, 1000))}
      </MiniTable>
    )
  }

  const content =
    _.size(grouped) > 1
      ? _.map(grouped, (group, type) => renderTable(group, type))
      : renderTable(filteredDocs, 'Documents')

  return (
    <Panels header={header}>
      <LoadingContainer query={query}>
        <Page.Full>{content}</Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default Documents
