import { Page, Model } from 'peach/components'
import { useCompanyQuery } from 'peach/data'
import { useReadData, useApi } from 'peach/hooks'

const CompanyBootstrap = () => {
  const [company] = useCompanyQuery()

  const companyId = company?.id
  const api = useApi()

  const getBootstrap = async (type) => {
    return api.peach.bootstrap.get({
      queryParams: { appDomainType: type, companyId },
    })
  }

  const [borrower] = useReadData(
    async () => await getBootstrap('borrower'),
    [companyId],
  )

  const [agent] = useReadData(
    async () => await getBootstrap('agent'),
    [companyId],
  )

  const [admin] = useReadData(
    async () => await getBootstrap('admin'),
    [companyId],
  )

  return (
    <Page.Full>
      <Model.Item title='Borrower' json={borrower} />
      <Model.Item title='Agent' json={agent} />
      <Model.Item title='Admin' json={admin} />
    </Page.Full>
  )
}

export default CompanyBootstrap
