import { ApiProvider, useApi } from 'core/api/ApiProvider'

import { useSetLastId } from './LastCompanyIdProvider'
import useCompanyApi from './useCompanyApi'

const CompanyManager = ({ companyId, render, children }) => {
  const api = useApi()

  // this is a version of the api object that pre-applies the companyId.
  // useQueryApi does this but for any route params and so is much nicer,
  // but many call sites may still use this
  const companyApi = useCompanyApi(api, companyId)

  useSetLastId('companyId', companyId)

  return <ApiProvider api={companyApi}>{children}</ApiProvider>
}

export default CompanyManager
