import { forwardRef } from 'react'

import { useFormIsHidden } from 'peach/components/Form/FormProvider/useFormState'
import { humanizeKey, getComponentName } from 'peach/helpers'

import ItemLabel from './ItemLabel'
const withLabel = (Component) => {
  const Label = forwardRef((props, ref) => {
    const {
      formKey,
      label,
      note,
      placeholder,
      bare,
      required,
      meta,
      isChanged,
      isFocused,
      recentlyActive,
      tooltip,
      ...rest
    } = props

    const isHidden = useFormIsHidden()

    const displayLabel = label || placeholder || humanizeKey(formKey)
    const displayPlaceholder = placeholder || label || humanizeKey(formKey)

    const renderProps = {
      placeholder: displayPlaceholder,
      formKey: formKey,
      ref,
    }

    return bare ? (
      <Component {...renderProps} label={displayLabel} {...rest} />
    ) : (
      <ItemLabel
        note={note}
        label={displayLabel}
        required={required}
        meta={meta}
        isChanged={isChanged}
        isFocused={isFocused}
        recentlyActive={recentlyActive}
        isHidden={isHidden}
        tooltip={tooltip}
      >
        <Component {...renderProps} label={displayLabel} {...rest} />
      </ItemLabel>
    )
  })

  Label.displayName = getComponentName(Component, 'withLabel')

  return Label
}

export default withLabel
