import { useLoanQuery } from 'peach/data'

import ModelLink from './ModelLink'

const LoanLink = ({ loanId, jsonKey }) => {
  const [loan, query] = useLoanQuery({ loanId })

  return <ModelLink id={loanId} model={loan} query={query} jsonKey={jsonKey} />
}

export default LoanLink
