import { Card } from 'peach/components'

import SearchResultsGroup from './SearchResultsGroup'

const Group = (props) => {
  const { label, list, loading, onClick } = props

  return (
    <SearchResultsGroup key={label} label={label} loading={loading}>
      {_.map(list, (item = {}, index) => {
        const { id, to, label, subtitle, note } = item

        const key = `${id} - ${to}`
        if (!id && to) {
          // a dev tools search result
          return (
            <Card
              key={key}
              title={label}
              subtitle={subtitle}
              to={to}
              note={note}
              onClick={onClick}
            />
          )
        }

        // an peach platform object
        return <Card key={key} model={item} onClick={onClick} />
      })}
    </SearchResultsGroup>
  )
}

const SearchResults = (props) => {
  const { loading, results, onClick } = props

  const grouped = _.groupBy(results, 'group')

  const displayGroups = _.compact(
    _.map(grouped, (group, key) => {
      if (!_.isEmpty(group)) {
        return (
          <Group
            key={key}
            label={key}
            list={group}
            onClick={onClick}
            loading={loading}
          />
        )
      }
    }),
  )

  return <>{displayGroups}</>
}

export default SearchResults
