import { useState, useEffect } from 'react'

const getNow = () => Date.now()

const useNow = (internval = 300) => {
  const [now, setNow] = useState(getNow)

  useEffect(() => {
    const id = setInterval(() => setNow(getNow()), internval)
    return () => clearTimeout(id)
  }, [internval])

  return now
}

export default useNow
