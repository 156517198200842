import { createContext, useContext } from 'react'

const ApiContext = createContext({})

const ApiProvider = ({ api, children }) => {
  return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}

const useApi = () => useContext(ApiContext)

export { ApiProvider, useApi }
