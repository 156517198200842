import ModelViewer from '../ModelViewer/ModelViewer'

import ModelList from './ModelList'

const Model = ModelViewer

Model.Item = ModelViewer
Model.Viewer = ModelViewer
Model.List = ModelList

export default Model
