import { useRouteMatch } from 'peach/router'

import JsonTable from '../Json/JsonTable'

import ModelTable from './ModelTable'
import ModelWrapper from './ModelWrapper'

const parseJson = (json) => {
  if (_.isObject(json) && json.data) {
    const { data, ...meta } = json
    return [meta, data]
  }
  return [null, json]
}

const ModelList = (props) => {
  const { title, url, json, page, note, ...rest } = props
  const { url: matchUrl } = useRouteMatch()

  const $url = url || matchUrl

  const [meta, data] = parseJson(json)

  return (
    <ModelWrapper title={title} json={json} page={page} note={note}>
      {!_.isEmpty(meta) && <JsonTable json={meta} />}

      <ModelTable title={title} url={$url} json={data} {...rest} />
    </ModelWrapper>
  )
}

export default ModelList
