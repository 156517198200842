import {
  LoadingContainer,
  Page,
  Model,
  Modal,
  Select,
  NumericInput,
  Form,
  Input,
} from 'peach/components'
import { useWorkflowTypesQuery } from 'peach/data'
import { useApi } from 'peach/hooks'

const WorkflowTypes = () => {
  const [workflowTypes, query] = useWorkflowTypesQuery()

  const api = useApi()

  const sendCreateWorkflowType = async (workflowType) => {
    await api.company.workflowTypes.post({ body: workflowType })
    query.refetch()
  }

  const createWorkflowType = (
    <Modal
      trigger
      title='Create Workflow Type'
      onSubmit={sendCreateWorkflowType}
      initialValue={{}}
      pages
    >
      <Modal.Page page='Workflow Type'>
        <Input formKey='name' />
        <Input formKey='description' />
        <NumericInput formKey='version' />
        <Select
          formKey='versionStatus'
          options={['draft', 'active', 'deprecated']}
        />
        <Form.List
          formKey='steps'
          add
          remove
          renderItem={(props) => <Input {...props} />}
        />
        <Form.List formKey='approvalConditions' add remove>
          <Input formKey='stepTypeRefId' />
          <Select
            formKey='stepStatus'
            options={['due', 'approved', 'rejected', 'skipped']}
          />
        </Form.List>
      </Modal.Page>
    </Modal>
  )

  return (
    <LoadingContainer query={query}>
      {createWorkflowType}
      <Page.Full>
        <Model.List
          title='Workflow Types'
          json={workflowTypes}
          withRelated
          withDetails
          withTimestamps
        />
      </Page.Full>
    </LoadingContainer>
  )
}

export default WorkflowTypes
