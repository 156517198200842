import { MiniTable, Json } from 'peach/components'

const FancyLedgerTable = ({ ledger }) => {
  const columns = ['Entry', 'IDs', 'Data', 'Dates']

  const rows = _.map(ledger, (entry) => {
    const {
      id,
      bucketId,
      companyId,
      eventId,
      insertId,
      ledgerId,
      bucketName: rawBucketName,
      effectiveDate,
      entryIndex,
      eventName,
      ledgerType,
      pairIndex,
      postedDate,
      transactionType,
      value,
      createdAt,
    } = entry

    const bucketName = _.str.titleize(_.str.humanize(rawBucketName))

    const nameInfo = { bucketName, eventName, id }
    const ids = { companyId, eventId, bucketId, insertId, ledgerId }
    const data = { entryIndex, ledgerType, pairIndex, transactionType, value }
    const dates = { effectiveDate, postedDate, createdAt }

    return (
      <MiniTable.Row key={id}>
        <Json.List json={nameInfo} compact humanizeKeys />
        <Json.List json={ids} compact humanizeKeys />
        <Json.List json={data} compact humanizeKeys />
        <Json.List json={dates} compact humanizeKeys />
      </MiniTable.Row>
    )
  })

  return (
    <MiniTable fixed columns={columns} compact>
      {rows}
    </MiniTable>
  )
}

export default FancyLedgerTable
