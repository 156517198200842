import styled from 'styled-components/macro'

import ItemValidationMessage from './ItemValidationMessage'
import Tooltip from './LocalTooltip'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  padding-bottom: 12px;
`
const Label = styled.label`
  color: ${(p) => p.theme.labelText};
  font-size: 12px;
  display: block;
  font-weight: bold;
  padding: 0 12px 4px;
`

const Note = styled.div`
  font-size: 12px;
  padding: 4px 12px 0;
  font-style: italic;
  color: ${(p) => p.theme.mutedText};
`

const Required = styled.span`
  vertical-align: super;
  font-size: 0.9em;
  color: ${(p) => p.theme.highlight};
  padding-left: 2px;
`

const ContentsWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  position: relative;
`

const MessageWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 8px;
`

// const asterisks = '*✹✸✷✶✵✳︎﹡＊⩮❊❉✻✲⊛＊﹡'

const ItemLabel = ({ label, required, note, meta, children, tooltip }) => {
  const labelSuffix = required && <Required>✲</Required>

  return (
    <Wrapper>
      {label && (
        <Label>
          {label}
          {labelSuffix}{' '}
          {tooltip && (
            <Tooltip
              content={tooltip}
              backgroundColor={'white'}
              width={500}
              animation='shift-away'
            />
          )}
        </Label>
      )}
      <ContentsWrapper>
        {children}
        <MessageWrapper>
          <ItemValidationMessage meta={meta} />
        </MessageWrapper>
      </ContentsWrapper>
      {note && <Note>{note}</Note>}
    </Wrapper>
  )
}

export default ItemLabel
