import { Select } from 'peach/components'
import { useCaseTypesQuery } from 'peach/data'

// const caseTypes = [
//   'generic',
//   'bankruptcy',
//   'ceaseCommunication',
//   'collection',
//   'deceased',
//   'disputeOfDebt',
//   'federalEmergency',
//   'identityTheft',
//   'legalAction',
//   'militaryDuty',
//   'debtValidation',
// ]

const CaseTypeIdSelect = (props) => {
  const [caseTypes, { isLoading }] = useCaseTypesQuery()

  const options = _.map(caseTypes, ({ id, name }) => {
    return { value: id, label: name }
  })
  return <Select options={options} loading={isLoading} searchable {...props} />
}

export default CaseTypeIdSelect
