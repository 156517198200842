import { useState } from 'react'

import { Json, Button, Textarea, Page } from 'peach/components'
import { useCompanyQuery } from 'peach/data'
import { useWrite, useApi } from 'peach/hooks'

const CompanyImportTemplates = () => {
  const [company] = useCompanyQuery()
  const companyId = company?.id
  const api = useApi()

  const [body, setBody] = useState()

  const [send, , resp] = useWrite(async () => {
    return api.company.templatesImport.post({
      queryParams: { companyId },
      body: JSON.parse(body),
    })
  })
  return (
    <Page>
      <Textarea
        label='Templates (usually paste from file in peach/peach repo)'
        value={body}
        onChange={setBody}
      />
      <Button onClick={send}>Import Templates</Button>
      {resp && <Json title='Response' json={resp} />}
    </Page>
  )
}

export default CompanyImportTemplates
