/* 
api.getAll() is a special case of a GET operation.
it will recursively get all results and return them as if limit=infinity.  
this is a dangerous operation in the case of unbounded collections (i.e. it could indefinitely load)
and should only be used as a stop-gap measure until a better solution can be found 
(usually better filter parameters in an API so that we only query for what we need when we need it)

note:  the `limit` parameter takes on the meaning of 'chunk size' in this context

*/

// guarding against some live-lock behavior where we fetch forever
const MAX_PAGES = 25

const check = (str, condition) => {
  if (!condition) throw new Error(`api.getAll() ${str}`)
}

const append = (prevResult, newResp) => {
  const { data: prevData, count: prevCount, ...restPrev } = prevResult || {}
  const { data: newData, count: newCount, ...restNew } = newResp || {}
  return {
    ...restPrev,
    ...restNew,
    count: (prevCount || 0) + newCount,
    data: (prevData || []).concat(newData),
  }
}

const getAll = async (options) => {
  const {
    executeRequest,
    apiOptions,
    paginationUrl,
    result,
    pageCounter = 0,
  } = options

  const resp = await executeRequest({
    ...apiOptions,
    method: 'GET',
    paginationUrl,
  })

  check('must return a paginated list', _.isArray(resp?.data))

  const newResult = append(result, resp)

  if (resp.nextUrl) {
    check(
      'nextUrl must be distinct from previous nextUrl',
      resp.nextUrl !== paginationUrl,
    )
    check(`must be less than ${MAX_PAGES} pages`, pageCounter < MAX_PAGES)

    return getAll({
      executeRequest,
      apiOptions,
      paginationUrl: resp.nextUrl,
      result: newResult,
      pageCounter: pageCounter + 1,
    })
  } else {
    return newResult
  }
}

export default getAll
