import { LoadingContainer, Select, Modal, Page, Model } from 'peach/components'
import { useDocumentQuery } from 'peach/data'
import { useClickAction } from 'peach/decorators/withActionTrigger'
import { useApi } from 'peach/hooks'
import { useParams } from 'peach/router'

const Document = () => {
  const { personId, documentId } = useParams()
  const api = useApi()

  const [doc, query] = useDocumentQuery()

  const sendUpdateDoc = async (doc, diff) => {
    await api.person.document.put({
      pathArgs: { personId, documentDescriptorId: documentId },
      body: diff,
    })
    query.refetch()
  }

  const openDocument = async ({ action }) => {
    const { url } = await api.person.documentContent.get({
      pathArgs: { personId, documentDescriptorId: documentId },
      queryParams: { returnUrl: true, disposition: 'inline' },
    })

    if (action === 'download') window.location.href = url

    if (action === 'view') window.open(url, '_blank', 'fullscreen=yes')

    return url
  }

  useClickAction({
    label: 'Download Document',
    onClick: () => openDocument({ action: 'download' }),
  })

  useClickAction({
    label: 'View Document',
    onClick: () => openDocument({ action: 'view' }),
  })

  const editDocument = (
    <Modal title='Edit Document' initialValue={doc} onSubmit={sendUpdateDoc}>
      <Select boolean formKey='archived' />
    </Modal>
  )

  return (
    <LoadingContainer query={query}>
      {editDocument}
      <Page.Full>
        <Model.Viewer title='Document' json={doc} />
      </Page.Full>
    </LoadingContainer>
  )
}

export default Document
