import { useEffect, useRef } from 'react'

import { useStableCallback } from 'peach/hooks'

const EVENTS = ['mousedown', 'touchstart']

const bind = (fn) => {
  EVENTS.forEach((event) => document.addEventListener(event, fn))
}

const unbind = (fn) => {
  EVENTS.forEach((event) => document.removeEventListener(event, fn))
}

const getAncestorIndex = (node) => {
  const count = document.body.children.length

  for (let i = 0; i < count; i++) {
    if (document.body.children[i].contains(node)) {
      return i
    }
  }
  return Infinity
}

const isSameLevelOrAfter = (containerNode, clickedNode) => {
  const containerindex = getAncestorIndex(containerNode)
  const clickIndex = getAncestorIndex(clickedNode)

  return containerindex >= clickIndex
}

//

const useOnClickOutside = (func, passedRef, ...moreRefs) => {
  const localRef = useRef()

  const $ref = passedRef ?? localRef

  const $refs = [$ref, ...moreRefs]

  const onDocumentClick = useStableCallback((event) => {
    if (
      _.every($refs, (ref) => {
        if (ref && ref.current) {
          if (isSameLevelOrAfter(ref.current, event.target)) {
            if (!ref.current.contains(event.target)) {
              return true
            }
          }
        }
      })
    ) {
      func(event)
    }
  })

  useEffect(() => {
    bind(onDocumentClick)
    return () => unbind(onDocumentClick)
  }, [onDocumentClick])

  return [$ref]
}

export default useOnClickOutside
