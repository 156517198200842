import { useReducer, useRef, useEffect, useMemo } from 'react'

import useStableCallback from '../useStableCallback/useStableCallback'

const getInitialState = () => {
  return {
    loading: false,
    resp: undefined,
    error: undefined,
  }
}

const reducer = (state, { type, resp, error }) => {
  switch (type) {
    case 'loading':
      return { ...state, loading: true, error: undefined }
    case 'resp':
      return { loading: false, resp: resp || {}, error: undefined }
    case 'error':
      return {
        loading: false,
        resp: undefined,
        error: error || new Error('Unknown Error'),
      }
    default:
      return state
  }
}

const useRead = (promiseFn, deps = []) => {
  const mounted = useRef(true)

  useEffect(() => {
    return () => (mounted.current = false)
  }, [])

  const [state, dispatch] = useReducer(reducer, undefined, getInitialState)

  const read = async (...args) => {
    let isActiveRequest = true

    if (mounted.current) {
      dispatch({ type: 'loading' })

      try {
        const resp = await promiseFn(...args)

        if (mounted.current && isActiveRequest) {
          dispatch({ type: 'resp', resp })
        }
      } catch (error) {
        if (mounted.current && isActiveRequest) {
          dispatch({ type: 'error', error })
        }
        console.error('useRead captured an error', error)
      }

      return () => (isActiveRequest = false)
    }
  }

  useEffect(() => {
    read()
  }, deps) // eslint-disable-line

  const refresh = useStableCallback(read)

  const { loading, resp, error } = state

  const result = useMemo(() => {
    const res = [resp, loading, error, refresh]
    _.extend(res, { data: resp, loading, error, refresh })
    return res
  }, [resp, loading, error, refresh])

  return result
}

export default useRead
