import getFocusableElements from './getFocusableElements'
import getNodes from './getNodes'

const focusFirstFocusableItem = (...nodesOrRefs) => {
  const nodes = getNodes(nodesOrRefs)
  for (const node of nodes) {
    const els = getFocusableElements(node)
    const first = _.first(els)
    if (first) {
      try {
        first.focus()
      } catch {}
      return
    }
  }
}

export default focusFirstFocusableItem
