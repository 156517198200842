import getFocusableElements from './getFocusableElements'

const focusNextFocusableElement = (parentNode, options = {}) => {
  const { increment = 1, wrap } = options || {}

  const focused = document.activeElement
  const focusables = getFocusableElements(parentNode)
  const count = _.size(focusables)
  if (count === 0) {
    return
  }

  const index = _.indexOf(focusables, focused)

  if (index === -1) {
    focusables[0].focus()
  } else {
    const newIndex = index + increment

    if (newIndex > count - 1) {
      if (wrap) focusables[0].focus()
    } else if (newIndex < 0) {
      if (wrap) _.last(focusables).focus()
    } else {
      focusables[newIndex].focus()
    }
  }
}

export default focusNextFocusableElement
