import { useState } from 'react'

import { Page, Stack, Button } from 'peach/components'

const ScratchError = () => {
  const [renderError, setRenderError] = useState(false)

  const throwFromClick = () => {
    throw new Error('Thrown from a click Handler')
  }

  if (renderError) {
    throw new Error('Thrown from render ')
  }

  return (
    <Page>
      <Stack>
        <Button onClick={throwFromClick}>Throw from a click handler</Button>
        <Button onClick={() => setRenderError(true)}>Throw from render</Button>
      </Stack>
    </Page>
  )
}

export default ScratchError
