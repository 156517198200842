import Company from 'pages/companies/Company'
import LoanView from 'pages/loan/LoanView'
import PeopleRoutes from 'pages/people/PeopleRoutes'
import { Breadcrumb } from 'peach/components'
import { useSessionCompaniesQuery } from 'peach/data'
import { Switch, Route, useParams } from 'peach/router'
import { CompanyManager } from 'peach/scopes/company'

const CompanyRoutes = () => {
  const { companyId } = useParams()

  const [companies] = useSessionCompaniesQuery()

  const company = _.find(companies, { id: companyId })

  return (
    <CompanyManager companyId={companyId}>
      <Breadcrumb title={company?.legalName} to={`/companies/${companyId}`} />

      <Switch>
        <Route path='/companies/:companyId/borrowers/:personId/loans/:loanId'>
          <LoanView />
        </Route>

        <Route path='/companies/:companyId/borrowers/:personId'>
          <Breadcrumb
            title='Borrowers'
            to={`/companies/${companyId}/borrowers`}
          />
          <PeopleRoutes />
        </Route>

        <Route path='/companies/:companyId'>
          <Company />
        </Route>
      </Switch>
    </CompanyManager>
  )
}

export default CompanyRoutes
