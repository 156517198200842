import { useDataMutation, useQueryApi } from 'peach/data/useQueryApi'

const useCreatePerson = (options) => {
  const [api, params] = useQueryApi(options)

  const { companyId } = params

  return useDataMutation({
    invalidationKey: ['companies', companyId, 'borrowers'],
    mutationFn: async (options) => {
      await api.company.people.post({
        pathArgs: { companyId },
        body: options,
      })
    },
  })
}

export default useCreatePerson
