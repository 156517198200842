import {
  LoadingContainer,
  Panels,
  Bar,
  Modal,
  ModelList,
  Page,
} from 'peach/components'
import { usePersonCasesQuery } from 'peach/data'
import { humanizeKey } from 'peach/helpers'
import { useApi } from 'peach/hooks'
import { useParams } from 'peach/router'

import CreateCaseForm from './CreateCaseForm'

const defaultCase = { metaData: {} }

const PersonCases = () => {
  const api = useApi()

  const { personId } = useParams()

  const [cases, query] = usePersonCasesQuery()

  const sendCreateCase = async (personCase) => {
    await api.person.cases.post({
      pathArgs: { personId },
      queryParams: { validate: false },
      body: personCase,
    })
    query.refetch()
  }

  const $cases = _.sortBy(cases, 'createdAt').reverse()

  const createCase = (
    <Modal
      title='Create Case'
      onSubmit={sendCreateCase}
      initialValue={defaultCase}
    >
      <CreateCaseForm />
    </Modal>
  )

  const header = <Bar title='All Cases' />

  const casesByType = _.groupBy($cases, 'caseType')

  const contents = _.map(casesByType, (group, key) => {
    return (
      <ModelList key={key} title={`${humanizeKey(key)} Cases`} json={group} />
    )
  })

  return (
    <Panels header={header}>
      {createCase}
      <LoadingContainer query={query}>
        <Page.Full>{contents}</Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default PersonCases
