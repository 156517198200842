import { useMemo } from 'react'

import { useSessionPermissionsQuery } from 'peach/data'
import { useCompanyId } from 'peach/scopes/company'

const useHasPermission = (permissionKey, options) => {
  const { companyId: passedCompanyId } = options || {}
  const appCompanyId = useCompanyId()
  const companyId = passedCompanyId || appCompanyId

  const [permissions] = useSessionPermissionsQuery()

  const hashesByCompany = useMemo(() => {
    return _.mapValues(permissions, (keyList) => {
      const hash = {}
      _.each(keyList, (key) => (hash[key] = true))
      return hash
    })
  }, [permissions])

  return hashesByCompany?.[companyId]?.[permissionKey]
}

export default useHasPermission
