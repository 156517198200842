import { useState, useCallback } from 'react'

import { useOnChangeEffect } from 'peach/hooks'

// this value and onChange returned from  this hook handle strings
// and allow for values like `100`, `100`., and `100.00`

//  the value and onChange passed in handle numbers or null and
// the onChange will only be called when the number changes.

// ex: even if the string value changes from `10` to `10.`, the number onChange will not be called

const isInt = (val) => val.match(/^\d+$/)

// checks that a new number string is a valid number
// and that it only has max two decimal places
const checkString = (newStr, oldStr) => {
  if (!newStr) return ''

  const [whole, decimal, rest] = newStr.split('.')

  if (rest !== undefined) return oldStr

  if (decimal) {
    if (!isInt(decimal)) return oldStr
  }

  if (whole) {
    if (!isInt(whole)) return oldStr
  }

  return newStr
}

const parseToNumber = (str) => {
  const parsed = parseFloat(str, 10)
  return _.isFinite(parsed) ? parsed : null
}

const parseToString = (num) => (_.isFinite(num) ? `${num}` : '')

const useNumericValue = ({ value, onChange }) => {
  const passedValue = _.isFinite(value) ? value : null

  const [str, setStr] = useState(() => parseToString(value))

  const handleChange = useCallback((newStr = '') => {
    setStr((oldStr) => checkString(newStr, oldStr))
  }, [])

  const newValue = parseToNumber(str)

  useOnChangeEffect(
    ([prevPassedValue, prevNewValue]) => {
      if (passedValue !== newValue) {
        /* istanbul ignore else  */
        if (passedValue !== prevPassedValue) {
          setStr(parseToString(passedValue))
        } else if (newValue !== prevNewValue) {
          onChange(newValue)
        }
      }
    },
    [passedValue, newValue],
  )

  return [str, handleChange]
}

export default useNumericValue
