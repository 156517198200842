import { Select } from 'peach/components'
import { useCompanyPermissionsQuery } from 'peach/data'

const CompanyPermissionSelect = ({ omitPermissions, ...rest }) => {
  const omitIndex = _.keyBy(omitPermissions, 'action')

  const [companyPermissions] = useCompanyPermissionsQuery()

  const filteredPermission = _.reject(
    companyPermissions,
    ({ action }) => omitIndex[action],
  )

  const options = _.map(filteredPermission, (permission) => {
    const { action } = permission

    return { value: action, label: action }
  })

  return <Select {...rest} searchable label='Permission' options={options} />
}

export default CompanyPermissionSelect
