import { Panels, ListNav2, Breadcrumb } from 'peach/components'
import { useLoanDrawQuery } from 'peach/data'
import { getName } from 'peach/helpers'
import { Switch, Redirect, Route, useRouteMatch } from 'peach/router'

import DrawBalances from './DrawBalances'
import DrawPurchase from './DrawPurchase'
import DrawPurchases from './DrawPurchases'
import DrawRates from './DrawRates'
import LoanDraw from './LoanDraw'

const items = [['Draw', '', true], '/balances', '/purchases', '/rates']

const LoanDrawRoutes = () => {
  const { path, url } = useRouteMatch()

  const [draw] = useLoanDrawQuery()

  const nav = <ListNav2 items={items} baseUrl={url} />

  return (
    <Panels left={nav}>
      <Breadcrumb title={getName(draw)} />
      <Switch>
        <Route path={`${path}`} exact>
          <LoanDraw />
        </Route>
        <Route path={`${path}/balances`} crumb>
          <DrawBalances />
        </Route>
        <Route path={`${path}/rates`} crumb>
          <DrawRates />
        </Route>
        <Route path={`${path}/purchases`} crumb>
          <Switch>
            <Route path={`${path}/purchases/:purchaseId`}>
              <DrawPurchase />
            </Route>
            <Route>
              <DrawPurchases />
            </Route>
          </Switch>
        </Route>
        <Redirect to={url} />
      </Switch>
    </Panels>
  )
}

export default LoanDrawRoutes
