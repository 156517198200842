import { Select } from 'peach/components'
import { useLoanQuery, useLoanTypeDynamicFeeTypesQuery } from 'peach/data'

const LoanFeeTypeSelect = ({ loanTypeId, ...rest }) => {
  const [loan] = useLoanQuery()

  const $loanTypeId = loanTypeId || loan?.loanTypeId

  const [dynamicFeeTypes, feeTypesQuery] = useLoanTypeDynamicFeeTypesQuery({
    loanTypeId: $loanTypeId,
  })

  const options = _.map(dynamicFeeTypes, (dynamicFeeType) => {
    const { id, name } = dynamicFeeType
    const { apiName, displayName } = name || {}

    const label = displayName || apiName || id

    const value = apiName

    const note = `${apiName} / ${id}`

    return { label, value, note }
  })

  return (
    <Select
      {...rest}
      label='Dynamic Fee Type'
      loading={feeTypesQuery?.isLoading}
      options={options}
      notes
    />
  )
}

export default LoanFeeTypeSelect
