import { Children } from 'react'

import { withLabel } from 'peach/decorators'
import styled, { css } from 'styled-components/macro'

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: top;
`

const Item = styled.span`
  display: inline-block;
  padding-right: 12px;
  flex-grow: 1;
  flex-shrink: 1;
  ${(p) =>
    p.percentage &&
    css`
      flex-basis: ${p.percentage};
    `}
`

const FormRow = ({ children, count }) => {
  const $count = Children.count(children) || count
  const percentage = count ? `${100 / $count}%` : undefined

  const items = Children.map(children, (child, index) => {
    return (
      <Item percentage={percentage} key={index}>
        {child}
      </Item>
    )
  })
  return <Row>{items}</Row>
}

export default withLabel(FormRow)
