import { useEffect } from 'react'

const logWarning = _.once((key, name) => {
  const displayName = name ? `a '${name}'` : 'unknown Component'
  console.warn(
    `Woops! for ${displayName} you passed fromKey={'${key}'} and almost certainly meant 'formKey' (FORM not FROM).`,
  )
})

const useLogKeyWarning = (props = {}, name = '') => {
  const { fromKey } = props

  useEffect(() => {
    if (fromKey) logWarning(fromKey, name)
  }, [fromKey, name])
}

export default useLogKeyWarning
