import styled from 'styled-components/macro'

const Wrapper = styled.div`
  font-style: italic;
  padding: 16px 8px;
  font-size: 12px;
  color: ${(p) => p.theme.mutedText};
`

const Note = (props) => {
  const { children } = props
  return <Wrapper>{children}</Wrapper>
}

export default Note
