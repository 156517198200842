import { useDataQuery, useQueryApi } from '../useQueryApi'

const useContactQuery = (options) => {
  const [api, { companyId, personId, contactId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: [
      'companies',
      companyId,
      'borrowers',
      personId,
      'contacts',
      contactId,
    ],

    queryFn: () => {
      return api.person.contact.get({
        pathArgs: { companyId, personId, contactId },
      })
    },

    enabled: !!(companyId && personId && contactId),
  })
}

export default useContactQuery
