import { ReactNode } from 'react'

import variables from 'core/theme/variables'
import Icon, { IconProps } from 'peach/components/Icon/Icon'
import {
  DropdownIndicatorProps,
  GroupBase,
  MenuListProps,
  OptionProps,
  Props,
  ValueContainerProps,
  components,
} from 'react-select'
import styled from 'styled-components'

export type SelectOption<T = string> = {
  label: string | JSX.Element
  sublabel?: string | JSX.Element
  value: T
  icon?: IconProps['name']
  isDisabled?: boolean
}

export type OptionsOrGroups<T = string> = readonly (
  | SelectOption<T>
  | GroupBase<SelectOption<T>>
)[]

export const DropdownIndicator = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => (
  <components.DropdownIndicator {...props}>
    <Icon
      name='arrow_drop_down'
      color={variables.colorBlack90}
      fontSize='24px'
    />
  </components.DropdownIndicator>
)

// Workaround JedWatson/react-select#5625
export const MenuList = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  innerProps,
  ...props
}: MenuListProps<Option, IsMulti, Group>) => (
  <components.MenuList
    {...props}
    innerProps={{ ...innerProps, role: 'listbox' }}
  />
)

export const Option = <
  Value,
  IsMulti extends boolean,
  Group extends GroupBase<SelectOption<Value>>,
>({
  innerProps,
  ...props
}: OptionProps<SelectOption<Value>, IsMulti, Group>) => (
  <components.Option
    {...props}
    innerProps={{
      ...innerProps,
      role: 'option',
      'aria-selected': props.isSelected,
    }}
  >
    {props.data.icon && (
      <Icon name={props.data.icon} fontSize='24px' margin='0 8px 0 0' />
    )}
    <div>
      {props.data.label}
      {props.data.sublabel && <Sublabel>{props.data.sublabel}</Sublabel>}
    </div>
  </components.Option>
)

export const ValueContainer = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  children,
  ...props
}: ValueContainerProps<Option, IsMulti, Group> & {
  selectProps: Props<Option, IsMulti, Group> & { prefix?: ReactNode }
}) => (
  <>
    {props.selectProps.prefix && <Prefix>{props.selectProps.prefix}</Prefix>}
    <components.ValueContainer {...props}>{children}</components.ValueContainer>
  </>
)

const Sublabel = styled.div`
  font-size: 14px;
  font-weight: 300;
  color: ${variables.colorBlack60};
  text-transform: capitalize;
`

const Prefix = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px 0 12px;
  color: ${variables.colorBlack70};
`
