import { useUsersQuery } from 'peach/data'
import { getName, getLink, searchObjects, humanizeKey } from 'peach/helpers'

const useUsersPages = ({ query, companyId }) => {
  const [users, usersQuery] = useUsersQuery({ companyId })

  const pages = _.map(users, (user) => {
    return {
      group: 'Users',
      label: getName(user),
      to: getLink(user),
      subtitle: humanizeKey(`${user.type} User`),
    }
  })

  const results = searchObjects(pages, query, {
    max: 5,
    keys: ['label', 'subtitle'],
  })

  return [results, usersQuery]
}

export default useUsersPages
