const formatAmount = (value) => {
  if (!_.isFinite(value)) {
    return value ? `[${value}]` : value === '' ? `''` : `${value}`
  }

  const fixed = value.toFixed(2)

  if (parseFloat(fixed) === value) {
    return `${fixed}`
  } else {
    return `${value}`
  }
}

export default formatAmount
