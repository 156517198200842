const makeMatches = (rawQuery = '') => {
  const query = rawQuery.trim().toLowerCase()

  return (str = '') => str.toLowerCase().includes(query)
}

const makeArray = (val) => {
  return _.isArray(val) ? val : _.isString(val) ? [val] : undefined
}

const objMatches = (obj, rawQuery = '', { omit, keys } = {}) => {
  const query = rawQuery.trim().toLowerCase()

  if (!query) return true

  const tokens = _.compact(query.split(' '))

  return _.every(tokens, (token) => {
    const matchFn = makeMatches(token)

    const $obj = keys ? _.pick(obj, keys) : obj

    return _.some($obj, (val, key) => {
      if (_.includes(omit, key)) return
      if (!(_.isString(val) || _.isFinite(val))) return
      return matchFn('' + val)
    })
  })
}

const searchObjects = (list, query, { max, keys, omit } = {}) => {
  const options = {
    omit: makeArray(omit),
    keys: makeArray(keys),
  }

  const results = _.filter(list, (entry) => {
    return !query || objMatches(entry, query, options)
  })
  return max ? _.take(results, max) : results
}

export default searchObjects
