import CenteredPage from '../Page/CenteredPage'

import Markdown from './Markdown'

const Page = (props) => {
  return (
    <CenteredPage>
      <Markdown {...props} />
    </CenteredPage>
  )
}

Markdown.Page = Page

export { Page }

export default Markdown
