import CodeMirror from '@uiw/react-codemirror'
import { withFormValue, withLabel } from 'peach/decorators'
import styled from 'styled-components/macro'

const Wrapper = styled.div`
  width: 100%;
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 4px;
  background-color: ${(p) => p.theme.offset};
`

const TopSpacer = styled.div`
  height: 16px;
  border-bottom: 1px solid ${(p) => p.theme.border};
`
const BottomSpacer = styled.div`
  height: 8px;
  border-top: 1px solid ${(p) => p.theme.border};
`

const Mirror = styled(CodeMirror)`
  & > .CodeMirror {
    height: auto;
    line-height: 1.6;
    font-size: 14px;
    z-index: 0;
    pre {
      z-index: 1;
    }
  }
`

const CodeEditor = ({ value, onChange }) => {
  const options = {
    mode: 'jinja2',
    lineNumbers: true,
    lineWrapping: true,
    viewportMargin: Infinity,
    height: 'auto',
  }
  return (
    <Wrapper>
      <TopSpacer />
      <Mirror
        value={value}
        onChange={(val) => {
          console.log('val', val)
          onChange(val)
        }}
        options={options}
      />
      <BottomSpacer />
    </Wrapper>
  )
}

export default withFormValue(withLabel(CodeEditor))
