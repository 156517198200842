const countryCodes = [
  {
    label: 'Afghanistan',
    value: 'AF',
    countryCode: '004',
  },
  {
    label: 'Åland Islands',
    value: 'AX',
    countryCode: '248',
  },
  {
    label: 'Albania',
    value: 'AL',
    countryCode: '008',
  },
  {
    label: 'Algeria',
    value: 'DZ',
    countryCode: '012',
  },
  {
    label: 'American Samoa',
    value: 'AS',
    countryCode: '016',
  },
  {
    label: 'Andorra',
    value: 'AD',
    countryCode: '020',
  },
  {
    label: 'Angola',
    value: 'AO',
    countryCode: '024',
  },
  {
    label: 'Anguilla',
    value: 'AI',
    countryCode: '660',
  },
  {
    label: 'Antarctica',
    value: 'AQ',
    countryCode: '010',
  },
  {
    label: 'Antigua and Barbuda',
    value: 'AG',
    countryCode: '028',
  },
  {
    label: 'Argentina',
    value: 'AR',
    countryCode: '032',
  },
  {
    label: 'Armenia',
    value: 'AM',
    countryCode: '051',
  },
  {
    label: 'Aruba',
    value: 'AW',
    countryCode: '533',
  },
  {
    label: 'Australia',
    value: 'AU',
    countryCode: '036',
  },
  {
    label: 'Austria',
    value: 'AT',
    countryCode: '040',
  },
  {
    label: 'Azerbaijan',
    value: 'AZ',
    countryCode: '031',
  },
  {
    label: 'Bahamas',
    value: 'BS',
    countryCode: '044',
  },
  {
    label: 'Bahrain',
    value: 'BH',
    countryCode: '048',
  },
  {
    label: 'Bangladesh',
    value: 'BD',
    countryCode: '050',
  },
  {
    label: 'Barbados',
    value: 'BB',
    countryCode: '052',
  },
  {
    label: 'Belarus',
    value: 'BY',
    countryCode: '112',
  },
  {
    label: 'Belgium',
    value: 'BE',
    countryCode: '056',
  },
  {
    label: 'Belize',
    value: 'BZ',
    countryCode: '084',
  },
  {
    label: 'Benin',
    value: 'BJ',
    countryCode: '204',
  },
  {
    label: 'Bermuda',
    value: 'BM',
    countryCode: '060',
  },
  {
    label: 'Bhutan',
    value: 'BT',
    countryCode: '064',
  },
  {
    label: 'Bolivia (Plurinational State of)',
    value: 'BO',
    countryCode: '068',
  },
  {
    label: 'Bonaire, Sint Eustatius and Saba',
    value: 'BQ',
    countryCode: '535',
  },
  {
    label: 'Bosnia and Herzegovina',
    value: 'BA',
    countryCode: '070',
  },
  {
    label: 'Botswana',
    value: 'BW',
    countryCode: '072',
  },
  {
    label: 'Bouvet Island',
    value: 'BV',
    countryCode: '074',
  },
  {
    label: 'Brazil',
    value: 'BR',
    countryCode: '076',
  },
  {
    label: 'British Indian Ocean Territory',
    value: 'IO',
    countryCode: '086',
  },
  {
    label: 'Brunei Darussalam',
    value: 'BN',
    countryCode: '096',
  },
  {
    label: 'Bulgaria',
    value: 'BG',
    countryCode: '100',
  },
  {
    label: 'Burkina Faso',
    value: 'BF',
    countryCode: '854',
  },
  {
    label: 'Burundi',
    value: 'BI',
    countryCode: '108',
  },
  {
    label: 'Cabo Verde',
    value: 'CV',
    countryCode: '132',
  },
  {
    label: 'Cambodia',
    value: 'KH',
    countryCode: '116',
  },
  {
    label: 'Cameroon',
    value: 'CM',
    countryCode: '120',
  },
  {
    label: 'Canada',
    value: 'CA',
    countryCode: '124',
  },
  {
    label: 'Cayman Islands',
    value: 'KY',
    countryCode: '136',
  },
  {
    label: 'Central African Republic',
    value: 'CF',
    countryCode: '140',
  },
  {
    label: 'Chad',
    value: 'TD',
    countryCode: '148',
  },
  {
    label: 'Chile',
    value: 'CL',
    countryCode: '152',
  },
  {
    label: 'China',
    value: 'CN',
    countryCode: '156',
  },
  {
    label: 'Christmas Island',
    value: 'CX',
    countryCode: '162',
  },
  {
    label: 'Cocos (Keeling) Islands',
    value: 'CC',
    countryCode: '166',
  },
  {
    label: 'Colombia',
    value: 'CO',
    countryCode: '170',
  },
  {
    label: 'Comoros',
    value: 'KM',
    countryCode: '174',
  },
  {
    label: 'Congo',
    value: 'CG',
    countryCode: '178',
  },
  {
    label: 'Congo, Democratic Republic of the',
    value: 'CD',
    countryCode: '180',
  },
  {
    label: 'Cook Islands',
    value: 'CK',
    countryCode: '184',
  },
  {
    label: 'Costa Rica',
    value: 'CR',
    countryCode: '188',
  },
  {
    label: `Côte d'Ivoire`,
    value: 'CI',
    countryCode: '384',
  },
  {
    label: 'Croatia',
    value: 'HR',
    countryCode: '191',
  },
  {
    label: 'Cuba',
    value: 'CU',
    countryCode: '192',
  },
  {
    label: 'Curaçao',
    value: 'CW',
    countryCode: '531',
  },
  {
    label: 'Cyprus',
    value: 'CY',
    countryCode: '196',
  },
  {
    label: 'Czechia',
    value: 'CZ',
    countryCode: '203',
  },
  {
    label: 'Denmark',
    value: 'DK',
    countryCode: '208',
  },
  {
    label: 'Djibouti',
    value: 'DJ',
    countryCode: '262',
  },
  {
    label: 'Dominica',
    value: 'DM',
    countryCode: '212',
  },
  {
    label: 'Dominican Republic',
    value: 'DO',
    countryCode: '214',
  },
  {
    label: 'Ecuador',
    value: 'EC',
    countryCode: '218',
  },
  {
    label: 'Egypt',
    value: 'EG',
    countryCode: '818',
  },
  {
    label: 'El Salvador',
    value: 'SV',
    countryCode: '222',
  },
  {
    label: 'Equatorial Guinea',
    value: 'GQ',
    countryCode: '226',
  },
  {
    label: 'Eritrea',
    value: 'ER',
    countryCode: '232',
  },
  {
    label: 'Estonia',
    value: 'EE',
    countryCode: '233',
  },
  {
    label: 'Eswatini',
    value: 'SZ',
    countryCode: '748',
  },
  {
    label: 'Ethiopia',
    value: 'ET',
    countryCode: '231',
  },
  {
    label: 'Falkland Islands (Malvinas)',
    value: 'FK',
    countryCode: '238',
  },
  {
    label: 'Faroe Islands',
    value: 'FO',
    countryCode: '234',
  },
  {
    label: 'Fiji',
    value: 'FJ',
    countryCode: '242',
  },
  {
    label: 'Finland',
    value: 'FI',
    countryCode: '246',
  },
  {
    label: 'France',
    value: 'FR',
    countryCode: '250',
  },
  {
    label: 'French Guiana',
    value: 'GF',
    countryCode: '254',
  },
  {
    label: 'French Polynesia',
    value: 'PF',
    countryCode: '258',
  },
  {
    label: 'French Southern Territories',
    value: 'TF',
    countryCode: '260',
  },
  {
    label: 'Gabon',
    value: 'GA',
    countryCode: '266',
  },
  {
    label: 'Gambia',
    value: 'GM',
    countryCode: '270',
  },
  {
    label: 'Georgia',
    value: 'GE',
    countryCode: '268',
  },
  {
    label: 'Germany',
    value: 'DE',
    countryCode: '276',
  },
  {
    label: 'Ghana',
    value: 'GH',
    countryCode: '288',
  },
  {
    label: 'Gibraltar',
    value: 'GI',
    countryCode: '292',
  },
  {
    label: 'Greece',
    value: 'GR',
    countryCode: '300',
  },
  {
    label: 'Greenland',
    value: 'GL',
    countryCode: '304',
  },
  {
    label: 'Grenada',
    value: 'GD',
    countryCode: '308',
  },
  {
    label: 'Guadeloupe',
    value: 'GP',
    countryCode: '312',
  },
  {
    label: 'Guam',
    value: 'GU',
    countryCode: '316',
  },
  {
    label: 'Guatemala',
    value: 'GT',
    countryCode: '320',
  },
  {
    label: 'Guernsey',
    value: 'GG',
    countryCode: '831',
  },
  {
    label: 'Guinea',
    value: 'GN',
    countryCode: '324',
  },
  {
    label: 'Guinea-Bissau',
    value: 'GW',
    countryCode: '624',
  },
  {
    label: 'Guyana',
    value: 'GY',
    countryCode: '328',
  },
  {
    label: 'Haiti',
    value: 'HT',
    countryCode: '332',
  },
  {
    label: 'Heard Island and McDonald Islands',
    value: 'HM',
    countryCode: '334',
  },
  {
    label: 'Holy See',
    value: 'VA',
    countryCode: '336',
  },
  {
    label: 'Honduras',
    value: 'HN',
    countryCode: '340',
  },
  {
    label: 'Hong Kong',
    value: 'HK',
    countryCode: '344',
  },
  {
    label: 'Hungary',
    value: 'HU',
    countryCode: '348',
  },
  {
    label: 'Iceland',
    value: 'IS',
    countryCode: '352',
  },
  {
    label: 'India',
    value: 'IN',
    countryCode: '356',
  },
  {
    label: 'Indonesia',
    value: 'ID',
    countryCode: '360',
  },
  {
    label: 'Iran (Islamic Republic of)',
    value: 'IR',
    countryCode: '364',
  },
  {
    label: 'Iraq',
    value: 'IQ',
    countryCode: '368',
  },
  {
    label: 'Ireland',
    value: 'IE',
    countryCode: '372',
  },
  {
    label: 'Isle of Man',
    value: 'IM',
    countryCode: '833',
  },
  {
    label: 'Israel',
    value: 'IL',
    countryCode: '376',
  },
  {
    label: 'Italy',
    value: 'IT',
    countryCode: '380',
  },
  {
    label: 'Jamaica',
    value: 'JM',
    countryCode: '388',
  },
  {
    label: 'Japan',
    value: 'JP',
    countryCode: '392',
  },
  {
    label: 'Jersey',
    value: 'JE',
    countryCode: '832',
  },
  {
    label: 'Jordan',
    value: 'JO',
    countryCode: '400',
  },
  {
    label: 'Kazakhstan',
    value: 'KZ',
    countryCode: '398',
  },
  {
    label: 'Kenya',
    value: 'KE',
    countryCode: '404',
  },
  {
    label: 'Kiribati',
    value: 'KI',
    countryCode: '296',
  },
  {
    label: `Korea (Democratic People's Republic of)`,
    value: 'KP',
    countryCode: '408',
  },
  {
    label: 'Korea, Republic of',
    value: 'KR',
    countryCode: '410',
  },
  {
    label: 'Kuwait',
    value: 'KW',
    countryCode: '414',
  },
  {
    label: 'Kyrgyzstan',
    value: 'KG',
    countryCode: '417',
  },
  {
    label: `Lao People's Democratic Republic`,
    value: 'LA',
    countryCode: '418',
  },
  {
    label: 'Latvia',
    value: 'LV',
    countryCode: '428',
  },
  {
    label: 'Lebanon',
    value: 'LB',
    countryCode: '422',
  },
  {
    label: 'Lesotho',
    value: 'LS',
    countryCode: '426',
  },
  {
    label: 'Liberia',
    value: 'LR',
    countryCode: '430',
  },
  {
    label: 'Libya',
    value: 'LY',
    countryCode: '434',
  },
  {
    label: 'Liechtenstein',
    value: 'LI',
    countryCode: '438',
  },
  {
    label: 'Lithuania',
    value: 'LT',
    countryCode: '440',
  },
  {
    label: 'Luxembourg',
    value: 'LU',
    countryCode: '442',
  },
  {
    label: 'Macao',
    value: 'MO',
    countryCode: '446',
  },
  {
    label: 'Madagascar',
    value: 'MG',
    countryCode: '450',
  },
  {
    label: 'Malawi',
    value: 'MW',
    countryCode: '454',
  },
  {
    label: 'Malaysia',
    value: 'MY',
    countryCode: '458',
  },
  {
    label: 'Maldives',
    value: 'MV',
    countryCode: '462',
  },
  {
    label: 'Mali',
    value: 'ML',
    countryCode: '466',
  },
  {
    label: 'Malta',
    value: 'MT',
    countryCode: '470',
  },
  {
    label: 'Marshall Islands',
    value: 'MH',
    countryCode: '584',
  },
  {
    label: 'Martinique',
    value: 'MQ',
    countryCode: '474',
  },
  {
    label: 'Mauritania',
    value: 'MR',
    countryCode: '478',
  },
  {
    label: 'Mauritius',
    value: 'MU',
    countryCode: '480',
  },
  {
    label: 'Mayotte',
    value: 'YT',
    countryCode: '175',
  },
  {
    label: 'Mexico',
    value: 'MX',
    countryCode: '484',
  },
  {
    label: 'Micronesia (Federated States of)',
    value: 'FM',
    countryCode: '583',
  },
  {
    label: 'Moldova, Republic of',
    value: 'MD',
    countryCode: '498',
  },
  {
    label: 'Monaco',
    value: 'MC',
    countryCode: '492',
  },
  {
    label: 'Mongolia',
    value: 'MN',
    countryCode: '496',
  },
  {
    label: 'Montenegro',
    value: 'ME',
    countryCode: '499',
  },
  {
    label: 'Montserrat',
    value: 'MS',
    countryCode: '500',
  },
  {
    label: 'Morocco',
    value: 'MA',
    countryCode: '504',
  },
  {
    label: 'Mozambique',
    value: 'MZ',
    countryCode: '508',
  },
  {
    label: 'Myanmar',
    value: 'MM',
    countryCode: '104',
  },
  {
    label: 'Namibia',
    value: 'NA',
    countryCode: '516',
  },
  {
    label: 'Nauru',
    value: 'NR',
    countryCode: '520',
  },
  {
    label: 'Nepal',
    value: 'NP',
    countryCode: '524',
  },
  {
    label: 'Netherlands',
    value: 'NL',
    countryCode: '528',
  },
  {
    label: 'New Caledonia',
    value: 'NC',
    countryCode: '540',
  },
  {
    label: 'New Zealand',
    value: 'NZ',
    countryCode: '554',
  },
  {
    label: 'Nicaragua',
    value: 'NI',
    countryCode: '558',
  },
  {
    label: 'Niger',
    value: 'NE',
    countryCode: '562',
  },
  {
    label: 'Nigeria',
    value: 'NG',
    countryCode: '566',
  },
  {
    label: 'Niue',
    value: 'NU',
    countryCode: '570',
  },
  {
    label: 'Norfolk Island',
    value: 'NF',
    countryCode: '574',
  },
  {
    label: 'North Macedonia',
    value: 'MK',
    countryCode: '807',
  },
  {
    label: 'Northern Mariana Islands',
    value: 'MP',
    countryCode: '580',
  },
  {
    label: 'Norway',
    value: 'NO',
    countryCode: '578',
  },
  {
    label: 'Oman',
    value: 'OM',
    countryCode: '512',
  },
  {
    label: 'Pakistan',
    value: 'PK',
    countryCode: '586',
  },
  {
    label: 'Palau',
    value: 'PW',
    countryCode: '585',
  },
  {
    label: 'Palestine, State of',
    value: 'PS',
    countryCode: '275',
  },
  {
    label: 'Panama',
    value: 'PA',
    countryCode: '591',
  },
  {
    label: 'Papua New Guinea',
    value: 'PG',
    countryCode: '598',
  },
  {
    label: 'Paraguay',
    value: 'PY',
    countryCode: '600',
  },
  {
    label: 'Peru',
    value: 'PE',
    countryCode: '604',
  },
  {
    label: 'Philippines',
    value: 'PH',
    countryCode: '608',
  },
  {
    label: 'Pitcairn',
    value: 'PN',
    countryCode: '612',
  },
  {
    label: 'Poland',
    value: 'PL',
    countryCode: '616',
  },
  {
    label: 'Portugal',
    value: 'PT',
    countryCode: '620',
  },
  {
    label: 'Puerto Rico',
    value: 'PR',
    countryCode: '630',
  },
  {
    label: 'Qatar',
    value: 'QA',
    countryCode: '634',
  },
  {
    label: 'Réunion',
    value: 'RE',
    countryCode: '638',
  },
  {
    label: 'Romania',
    value: 'RO',
    countryCode: '642',
  },
  {
    label: 'Russian Federation',
    value: 'RU',
    countryCode: '643',
  },
  {
    label: 'Rwanda',
    value: 'RW',
    countryCode: '646',
  },
  {
    label: 'Saint Barthélemy',
    value: 'BL',
    countryCode: '652',
  },
  {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    value: 'SH',
    countryCode: '654',
  },
  {
    label: 'Saint Kitts and Nevis',
    value: 'KN',
    countryCode: '659',
  },
  {
    label: 'Saint Lucia',
    value: 'LC',
    countryCode: '662',
  },
  {
    label: 'Saint Martin (French part)',
    value: 'MF',
    countryCode: '663',
  },
  {
    label: 'Saint Pierre and Miquelon',
    value: 'PM',
    countryCode: '666',
  },
  {
    label: 'Saint Vincent and the Grenadines',
    value: 'VC',
    countryCode: '670',
  },
  {
    label: 'Samoa',
    value: 'WS',
    countryCode: '882',
  },
  {
    label: 'San Marino',
    value: 'SM',
    countryCode: '674',
  },
  {
    label: 'Sao Tome and Principe',
    value: 'ST',
    countryCode: '678',
  },
  {
    label: 'Saudi Arabia',
    value: 'SA',
    countryCode: '682',
  },
  {
    label: 'Senegal',
    value: 'SN',
    countryCode: '686',
  },
  {
    label: 'Serbia',
    value: 'RS',
    countryCode: '688',
  },
  {
    label: 'Seychelles',
    value: 'SC',
    countryCode: '690',
  },
  {
    label: 'Sierra Leone',
    value: 'SL',
    countryCode: '694',
  },
  {
    label: 'Singapore',
    value: 'SG',
    countryCode: '702',
  },
  {
    label: 'Sint Maarten (Dutch part)',
    value: 'SX',
    countryCode: '534',
  },
  {
    label: 'Slovakia',
    value: 'SK',
    countryCode: '703',
  },
  {
    label: 'Slovenia',
    value: 'SI',
    countryCode: '705',
  },
  {
    label: 'Solomon Islands',
    value: 'SB',
    countryCode: '090',
  },
  {
    label: 'Somalia',
    value: 'SO',
    countryCode: '706',
  },
  {
    label: 'South Africa',
    value: 'ZA',
    countryCode: '710',
  },
  {
    label: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
    countryCode: '239',
  },
  {
    label: 'South Sudan',
    value: 'SS',
    countryCode: '728',
  },
  {
    label: 'Spain',
    value: 'ES',
    countryCode: '724',
  },
  {
    label: 'Sri Lanka',
    value: 'LK',
    countryCode: '144',
  },
  {
    label: 'Sudan',
    value: 'SD',
    countryCode: '729',
  },
  {
    label: 'Suriname',
    value: 'SR',
    countryCode: '740',
  },
  {
    label: 'Svalbard and Jan Mayen',
    value: 'SJ',
    countryCode: '744',
  },
  {
    label: 'Sweden',
    value: 'SE',
    countryCode: '752',
  },
  {
    label: 'Switzerland',
    value: 'CH',
    countryCode: '756',
  },
  {
    label: 'Syrian Arab Republic',
    value: 'SY',
    countryCode: '760',
  },
  {
    label: 'Taiwan, Province of China',
    value: 'TW',
    countryCode: '158',
  },
  {
    label: 'Tajikistan',
    value: 'TJ',
    countryCode: '762',
  },
  {
    label: 'Tanzania, United Republic of',
    value: 'TZ',
    countryCode: '834',
  },
  {
    label: 'Thailand',
    value: 'TH',
    countryCode: '764',
  },
  {
    label: 'Timor-Leste',
    value: 'TL',
    countryCode: '626',
  },
  {
    label: 'Togo',
    value: 'TG',
    countryCode: '768',
  },
  {
    label: 'Tokelau',
    value: 'TK',
    countryCode: '772',
  },
  {
    label: 'Tonga',
    value: 'TO',
    countryCode: '776',
  },
  {
    label: 'Trinidad and Tobago',
    value: 'TT',
    countryCode: '780',
  },
  {
    label: 'Tunisia',
    value: 'TN',
    countryCode: '788',
  },
  {
    label: 'Turkey',
    value: 'TR',
    countryCode: '792',
  },
  {
    label: 'Turkmenistan',
    value: 'TM',
    countryCode: '795',
  },
  {
    label: 'Turks and Caicos Islands',
    value: 'TC',
    countryCode: '796',
  },
  {
    label: 'Tuvalu',
    value: 'TV',
    countryCode: '798',
  },
  {
    label: 'Uganda',
    value: 'UG',
    countryCode: '800',
  },
  {
    label: 'Ukraine',
    value: 'UA',
    countryCode: '804',
  },
  {
    label: 'United Arab Emirates',
    value: 'AE',
    countryCode: '784',
  },
  {
    label: 'United Kingdom of Great Britain and Northern Ireland',
    value: 'GB',
    countryCode: '826',
  },
  {
    label: 'United States of America',
    value: 'US',
    countryCode: '840',
  },
  {
    label: 'United States Minor Outlying Islands',
    value: 'UM',
    countryCode: '581',
  },
  {
    label: 'Uruguay',
    value: 'UY',
    countryCode: '858',
  },
  {
    label: 'Uzbekistan',
    value: 'UZ',
    countryCode: '860',
  },
  {
    label: 'Vanuatu',
    value: 'VU',
    countryCode: '548',
  },
  {
    label: 'Venezuela (Bolivarian Republic of)',
    value: 'VE',
    countryCode: '862',
  },
  {
    label: 'Viet Nam',
    value: 'VN',
    countryCode: '704',
  },
  {
    label: 'Virgin Islands (British)',
    value: 'VG',
    countryCode: '092',
  },
  {
    label: 'Virgin Islands (U.S.)',
    value: 'VI',
    countryCode: '850',
  },
  {
    label: 'Wallis and Futuna',
    value: 'WF',
    countryCode: '876',
  },
  {
    label: 'Western Sahara',
    value: 'EH',
    countryCode: '732',
  },
  {
    label: 'Yemen',
    value: 'YE',
    countryCode: '887',
  },
  {
    label: 'Zambia',
    value: 'ZM',
    countryCode: '894',
  },
  {
    label: 'Zimbabwe',
    value: 'ZW',
    countryCode: '716',
  },
]

export default countryCodes
