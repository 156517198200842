import humanizeKey from './humanizeKey'

const exists = (val) => typeof val !== 'undefined' && val !== null

const formatOption = (option, { notes, disabled } = {}) => {
  if (_.isString(option)) {
    return {
      label: humanizeKey(option),
      value: option,
      note: notes ? option : null,
      disabled: false,
    }
  }

  if (exists(option?.value)) {
    const { label, value, note, disabled } = option
    return {
      label: label || humanizeKey(value),
      value,
      note: notes ? note || value : null,
      disabled: !!disabled,
    }
  }
}

const formatOptions = (optionsList, { notes, disabled } = {}) => {
  return _.compact(
    _.map(optionsList, (option) => {
      return formatOption(option, { notes, disabled })
    }),
  )
}

export default formatOptions
