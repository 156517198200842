import { forwardRef, useRef } from 'react'

import { Popover } from '@reach/popover'
import useOnClickOutside from 'peach/components/Modal/useClickOutside'
import { useToggle } from 'peach/hooks'
import styled from 'styled-components/macro'

import KeyboardNavArea from '../$internal/KeyboardNavArea'
// `positionDefault, positionMatchWidth, positionRight`
import { positionMatchWidthMaxHeight } from '../Select/positions'

import MenuItem from './MenuItem'
import MenuTrigger from './MenuTrigger'
import { MenuConfigProvider } from './useMenuConfig'

const List = styled.div`
  margin-top: 2px;
  min-height: 16px;
  min-width: 240px;
  padding: 1px;
  border-radius: 5px;
  overflow-y: auto;
  background-color: ${(p) => p.theme.offset};
  border: 1px solid ${(p) => p.theme.border};
  box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);

  & > * {
    margin-top: 4px;
    margin-bottom: 4px;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`

const Content = styled.div`
  flex-grow: 1;
  overflow-y: auto;
  padding: 4px;
`

const Menu = forwardRef((props, ref) => {
  const {
    selectable,
    startOpen,
    children,
    render,
    label,
    searchControls,
    controls,
    disabled,
    primary,
    trigger,
    ...rest
  } = props

  const localRef = useRef()
  const $ref = ref || localRef

  const [isOpen, onOpen, onClose] = useToggle(startOpen)

  const contentRef = useRef()

  useOnClickOutside(onClose, contentRef, $ref)

  const renderProps = { onClose }

  const content = render ? render(renderProps) : children

  const isDisabled = disabled || _.isEmpty(content)

  const triggerProps = {
    ref: $ref,
    isOpen,
    onOpen,
    onClose,
    disabled: isDisabled,

    primary,
  }

  const displayTrigger = _.isFunction(trigger) ? (
    trigger(triggerProps)
  ) : trigger ? (
    <span>{trigger}</span>
  ) : (
    <MenuTrigger
      {...triggerProps}
      primary={primary}
      label={label || 'Actions'}
    />
  )

  return (
    <>
      {displayTrigger}

      {!isDisabled && isOpen && (
        <Popover
          ref={contentRef}
          targetRef={$ref}
          position={positionMatchWidthMaxHeight}
        >
          <KeyboardNavArea arrowFocus onEscape={onClose}>
            <List {...rest} ref={contentRef}>
              <MenuConfigProvider
                hasLeftSideSpace={selectable}
                onClose={onClose}
              >
                <Content>{content}</Content>
              </MenuConfigProvider>
            </List>
          </KeyboardNavArea>
        </Popover>
      )}
    </>
  )
})

const MenuDivider = styled.div`
  border-bottom: 1px solid ${(p) => p.theme.border};
  margin-top: 8px;
  margin-bottom: 8px;
`
Menu.Divider = MenuDivider

Menu.Item = MenuItem

Menu.Link = MenuItem

export default Menu
