import styled from 'styled-components/macro'

const Wrapper = styled.div`
  line-height: 1;
  border: 1px solid ${(p) => p.theme.border};
  border-radius: 3px;
  padding: 2px;
  background-color: ${(p) => p.theme.background};
`

const Label = styled.span`
  line-height: 1;
  display: inline-block;
  padding: 2px 8px;
  font-size: 12px;
  color: ${(p) => p.theme.mutedText};
`

const Button = styled.button`
  line-height: 1;
  border: none;
  background-color: transparent;
  cursor: default;
  padding: 4px 8px;
  border-radius: 3px;
  color: ${(p) => p.theme.mutedText};
  &:disabled {
    color: ${(p) => p.theme.disabledText};
  }
  &:not(:disabled):hover {
    background-color: ${(p) => p.theme.hover};
    color: ${(p) => p.theme.text};
    cursor: pointer;
  }
`

const PaginationControl = (props) => {
  const {
    caption,
    disablePrevious,
    onPrevious,
    disableNext,
    onNext,
    loading,
    onRefresh,
    isCurrentlyPaged,
    onClearPagination,
  } = props

  return (
    <Wrapper>
      <Label>{caption}</Label>
      <Button disabled={disablePrevious} onClick={onPrevious}>
        {'❮'}
      </Button>{' '}
      <Button disabled={disableNext} onClick={onNext}>
        {'❯'}
      </Button>{' '}
      {onRefresh && (
        <>
          <Button disabled={loading} onClick={onRefresh}>
            {'↻'}
          </Button>{' '}
        </>
      )}
      <Button disabled={!isCurrentlyPaged} onClick={onClearPagination}>
        {'⨂'}
      </Button>
    </Wrapper>
  )
}

export default PaginationControl
