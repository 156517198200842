import { LoadingContainer, ModelViewer, Page } from 'peach/components'
import { useLoanObligationsQuery } from 'peach/data'

const LoanObligations = ({ person, personId, loanId }) => {
  const [obligations, query] = useLoanObligationsQuery()

  return (
    <LoadingContainer query={query}>
      <Page.Full>
        <ModelViewer json={obligations} />
      </Page.Full>
    </LoadingContainer>
  )
}

export default LoanObligations
