import { LoadingContainer, Page, Model } from 'peach/components'
import { useLoanExpectedPaymentsQuery } from 'peach/data'

import DeferScheduleModal from './ExpectedPayments/DeferScheduleModal'

const LoanExpectedPayments = () => {
  const [expectedPayments, query] = useLoanExpectedPaymentsQuery()

  const refresh = () => query.refetch()

  return (
    <LoadingContainer query={query}>
      <DeferScheduleModal onSuccess={refresh} />
      <Page.Full>
        <Model.Item title='Expected Payments' json={expectedPayments} />
      </Page.Full>
    </LoadingContainer>
  )
}

export default LoanExpectedPayments
