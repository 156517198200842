import { Fragment, forwardRef } from 'react'

import { Link as RouterLink } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'

import { useCrumbs } from './Crumb'

const SafeLink = forwardRef((props, ref) => {
  // peeling off props that react-router yells about
  const { isAlone, isCategory, ...rest } = props
  return <RouterLink {...rest} ref={ref} />
})

const Crumb = styled.span`
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  flex-direction: column;
  height: 36px;
  justify-content: center;
`

const linkStyles = css`
  display: inline-block;
  line-height: 1;
  text-decoration: none;
  color: ${(p) => p.theme.text};
  &:hover {
    text-decoration: underline;
  }
  padding: 4px;
  margin-left: 4px;
  margin-right: 4px;
  transition: color 0.5s, background-color 0.5s, transform 0.5s;
`

const Link = styled(SafeLink)`
  ${linkStyles};
  color: ${(p) => (p.isCategory ? p.theme.mutedText : p.theme.linkText)};
  font-size: ${(p) => (p.isCategory ? '9px' : p.isAlone ? '14px' : '12px')};
  ${(p) =>
    p.isCategory &&
    css`
      padding-bottom: 2px;
    `}

  ${(p) =>
    p.isAlone &&
    css`
      padding-top: 6px;
    `}
`

const Seperator = styled.span`
  display: inline-block;
  font-size: 16px;
  color: ${(p) => p.theme.disabledText};
  padding-left: 2px;
  padding-right: 2px;
`

const CrumbLink = ({ crumb }) => {
  const { title, to, categoryTitle, categoryTo } = crumb || {}

  return (
    <Crumb>
      {categoryTo && (
        <Link key={categoryTo} to={categoryTo} isCategory>
          {categoryTitle}
        </Link>
      )}
      <Link key={to} to={to} isAlone={!categoryTo}>
        {title}
      </Link>
    </Crumb>
  )
}

const isCategory = (crumb = {}) => {
  const { to, category } = crumb || {}
  if (_.isBoolean(category)) return category
  const lastSegment = _.last((to || '').split('/'))
  if (_.includes(['people', 'borrowers'], lastSegment)) return true
  if (_.last(lastSegment) === 's') return true
  return false
}

const CrumbsList = ({ separator = '›', Wrapper = 'div' }) => {
  const { crumbs } = useCrumbs()

  const compact = _.size(crumbs) > 2

  const displayCrumbs = []

  const reverseCrumbs = [...crumbs].reverse()

  _.each(reverseCrumbs, (crumb, i) => {
    const previous = _.last(displayCrumbs)

    if (compact && isCategory(crumb) && previous && !previous.categoryTo) {
      previous.categoryTo = crumb.to
      previous.categoryTitle = crumb.title
    } else {
      displayCrumbs.push({ ...crumb })
    }
  })

  displayCrumbs.reverse()

  return (
    <Wrapper>
      {_.map(displayCrumbs, (crumb, i) => {
        const notLast = i < _.size(displayCrumbs) - 1
        return (
          <Fragment key={crumb.id}>
            <CrumbLink crumb={crumb} />

            {notLast && <Seperator>{separator}</Seperator>}
          </Fragment>
        )
      })}
    </Wrapper>
  )
}

export default CrumbsList
