import color from 'peach/helpers/color/color'
import { css } from 'styled-components/macro'

const buttonStyles = css<{
  iconLeft?: boolean
  iconRight?: boolean
  primary?: boolean
}>`
  position: relative;
  font-size: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-left: ${(p) => (p.iconLeft ? '12px' : '16px')};
  padding-right: ${(p) => (p.iconRight ? '12px' : '16px')};
  border-radius: 4px;
  cursor: pointer;
  color: ${(p) => (p.primary ? p.theme.invertedText : p.theme.text)};
  border: 1px solid
    ${(p) => color.borderify(p.primary ? p.theme.highlight : p.theme.banner)};
  background-color: ${(p) => (p.primary ? p.theme.highlight : p.theme.banner)};

  &:disabled {
    color: ${(p) =>
      color.disableify(p.primary ? p.theme.invertedText : p.theme.text)};
    background-color: ${(p) =>
      color.disableify(p.primary ? p.theme.highlight : p.theme.banner)};
    cursor: default;
  }

  &:not(:disabled):hover {
    background-color: ${(p) => {
      return color.getHoverColor(p.primary ? p.theme.highlight : p.theme.banner)
    }};
  }

  &,
  * {
    transition: background-color 0.15s, border-color 0.15s, color 0.05s;
  }

  &:focus {
    // border-color: ${(p) => p.theme.invertedText};
    // box-shadow: 0 0 0 2px ${(p) => p.theme.banner},
      0 0 0 3px ${(p) => p.theme.highlight};
  }

  ${(p) =>
    p.primary &&
    css`
      &:focus {
        box-shadow: none;
        &:after {
          content: '';
          position: absolute;
          top: -4px;
          left: -4px;
          right: -4px;
          bottom: -4px;
          border-radius: inherit;
          border: 1px solid ${(p) => p.theme.highlight};

          pointer-events: none;
        }
      }
    `}
`

export default buttonStyles
