import { useLoanTransactionQuery } from 'peach/data'

import ModelLink from './ModelLink'

const PaymentInstrumentLink = ({ transactionId, jsonKey }) => {
  const [transaction, query] = useLoanTransactionQuery({ transactionId })

  return (
    <ModelLink
      id={transactionId}
      model={transaction}
      query={query}
      jsonKey={jsonKey}
    />
  )
}

export default PaymentInstrumentLink
