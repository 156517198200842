import ArrayTable from './ArrayTable'
import Json from './Json'
import JsonList from './JsonList'
import JsonTable from './JsonTable'
import JsonValue from './JsonValue'

Json.Value = JsonValue
Json.Table = JsonTable
Json.ArrayTable = ArrayTable
Json.List = JsonList

export { JsonTable, JsonValue, ArrayTable }

export default Json
