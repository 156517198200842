import { Page, Model, LoadingContainer, Panels, Bar } from 'peach/components'
import { useTemplatesQuery, useTemplateDescriptorsQuery } from 'peach/data'
import { humanizeKey } from 'peach/helpers'
import { useParams } from 'peach/router'

const TemplateSubject = () => {
  const { subjectKey, channelKey } = useParams()

  const [descriptorsList, descriptorsQuery] = useTemplateDescriptorsQuery()

  const [templatesList, templatesQuery] = useTemplatesQuery()

  const descriptor = _.find(descriptorsList, {
    subject: subjectKey,
    channel: channelKey,
  })

  const templates = _.filter(templatesList, { descriptorId: descriptor?.id })

  return (
    <LoadingContainer queries={[descriptorsQuery, templatesQuery]}>
      <Panels header={<Bar title={humanizeKey(subjectKey)} />}>
        <Page.Full>
          <Model.Item title='Descriptor' json={descriptor} />
          <Model.List title='Templates' json={templates} noLink />
        </Page.Full>
      </Panels>
    </LoadingContainer>
  )
}

export default TemplateSubject
