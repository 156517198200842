import { FC, useState } from 'react'

import { LoadingContainer } from 'peach/components'
import { StyledButton } from 'peach/components/Button/Button'
import Input from 'peach/components/Input'
import { useApi, useReadData, useWrite } from 'peach/hooks'
import { FormProvider, useForm } from 'react-hook-form'
import * as R from 'remeda'
import styled from 'styled-components/macro'

import { Error, FormActions, Section, SectionLabel, Title } from './Components'
import RoleConfig from './RoleConfig'
import { Company } from './types'

type CreateRoleFormValues = {
  alias?: string
  actions?: string[]
}

type CreateRoleFormProps = {
  company: Company
  updateCompany: (obj: Company) => void
  title: string
  role?: string
  rolesQuery?: any
}

const CreateRoleForm: FC<CreateRoleFormProps> = ({
  company,
  updateCompany,
  title,
  role,
  rolesQuery,
}) => {
  const api: any = useApi()

  const [editActions, setEditActions] = useState<boolean>(false)
  const form = useForm<CreateRoleFormValues>({
    values: role ? RoleConfig[role] : {},
  })

  const { actions } = form.watch()

  const [permissionsHash] = useReadData(() => {
    return api.auth.permissions.get()
  }, [])

  const hasPermission = !!R.find(
    permissionsHash?.[company?.companyId ?? ''] ?? [],
    (permission) => permission === 'role:list',
  )

  const [onCreate, isPending, , error, clear] = useWrite(async (values) => {
    const resp = await api.company.roles.post({
      body: values,
      pathArgs: { companyId: company?.companyId },
    })

    if (resp?.status === 201 && !R.isEmpty(resp?.data)) {
      updateCompany({
        ...company,
        roles: [
          ...R.pipe(
            company?.roles ?? [],
            R.reject((role) => role?.alias === role),
          ),
          {
            alias: role,
            id: resp.data.id,
          },
        ],
      })

      rolesQuery?.refetch?.()
    }

    return resp
  })

  return (
    <>
      {rolesQuery.isLoading ? (
        'Loading...'
      ) : rolesQuery?.isError || !rolesQuery?.isSuccess ? (
        <>
          <ErrorMessage>
            {hasPermission && rolesQuery?.error?.status === 403
              ? `Whoops! Error fetching roles. This new company may not have all the roles setup yet, even though your user indicates it does have the correct permission to access this company's roles. You may need to wait a few minutes to proceed.`
              : `Whoops! Error fetching roles. Looks like you don't have the right permissions to continue.`}
          </ErrorMessage>
          <StyledButton onClick={() => rolesQuery?.refetch?.()}>
            Refetch roles
          </StyledButton>
        </>
      ) : (
        <LoadingContainer loading={isPending}>
          <Title>{title}</Title>
          <form
            onSubmit={form.handleSubmit(async (values) => {
              try {
                clear()
                await onCreate(values)
              } catch (e) {
                console.log('Error with create actions: ', e)
              }
            })}
          >
            <FormProvider {...form}>
              <Input
                type='text'
                label='Alias'
                required
                {...form.register('alias')}
              />

              {!editActions && <Code>{JSON.stringify(actions ?? [])}</Code>}

              {editActions && (
                <>
                  <StyledFormToggle
                    type='button'
                    onClick={() => setEditActions(!editActions)}
                  >
                    {editActions ? 'Cancel Edit Actions' : 'Edit Actions'}
                  </StyledFormToggle>

                  <Section>
                    <SectionLabel>Actions</SectionLabel>

                    {R.pipe(
                      actions ?? [],
                      R.map.indexed((action, i) => (
                        <Row key={i}>
                          <StyledInput
                            type='text'
                            {...form.register(`actions.${i}`)}
                          />
                          <StyledButton
                            type='button'
                            onClick={() =>
                              form.setValue(
                                'actions',
                                R.pipe(
                                  // @ts-ignore
                                  actions ?? [],
                                  R.splice(i, 1, []),
                                ),
                              )
                            }
                          >
                            Delete
                          </StyledButton>
                        </Row>
                      )),
                    )}
                    <StyledButton
                      type='button'
                      onClick={() =>
                        form.setValue('actions', [...(actions || []), ''])
                      }
                    >
                      Add Action
                    </StyledButton>
                  </Section>
                </>
              )}

              <StyledButton
                type='button'
                onClick={() => setEditActions(!editActions)}
              >
                {editActions ? 'Cancel Edit Actions' : 'Edit Actions'}
              </StyledButton>

              {error && <Error>{error.message}</Error>}
              <FormActions>
                <StyledButton type='submit' primary>
                  Submit
                </StyledButton>
              </FormActions>
            </FormProvider>
          </form>
        </LoadingContainer>
      )}
    </>
  )
}

export default CreateRoleForm

const ErrorMessage = styled.div`
  margin-bottom: 16px;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

const StyledInput = styled(Input)`
  flex: 1 auto;
`

const StyledFormToggle = styled(StyledButton)`
  margin-bottom: 16px;
`

const Code = styled.code`
  display: block;
  margin-bottom: 16px;
  word-break: break-all;
  font-size: 12px;
  color: #888;
`
