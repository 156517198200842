import { useAuthApi, useAuthState } from 'core/auth/AuthProvider'
import { useEnvApi } from 'core/env/useEnv'
import { Input, Select, Form, Button } from 'peach/components'
import { BareModal as Modal } from 'peach/components/Modal/Modal'
import { useWrite } from 'peach/hooks'
import { useHistory } from 'peach/router'

import EnvTable from '../env/EnvTable'

const LoginModal = () => {
  const { env } = useEnvApi()

  const { companyId, userType } = env || {}
  const history = useHistory()

  const goToApp = () => history.push('/')

  const authState = useAuthState()
  const { apiKey, showAuth, username, lastAuthMethod } = authState

  const {
    validateApiKey,
    createSession,
    cancelAuthPrompt,
    requestOneTimeCode,
  } = useAuthApi()

  const initialValue = {
    ...env,
    authMethod: lastAuthMethod || (apiKey ? 'apiKey' : 'Basic'),
    username: username,
    adminCompanyId: 'CP-L9BN-5J52',
  }

  const [sendRequestOneTimeCode, , oneTimeCodeResp] = useWrite(
    async ({ username } = {}) => {
      await requestOneTimeCode(companyId, {
        username,
        userType,
        channel: 'email',
      })
    },
  )

  const sendLogin = async (loginData) => {
    const {
      authMethod,
      apiKey,
      username,
      password,
      oneTimeCode,
      adminCompanyId,
    } = loginData

    switch (authMethod) {
      case 'adminBasic': {
        await createSession({
          companyId: adminCompanyId,
          userType: 'agent',
          username,
          password,
          authMethod,
        })
        goToApp()
        break
      }
      case 'OneTimeCodeEmail': {
        await createSession({
          userType: 'agent',
          companyId,
          username,
          password: oneTimeCode,
          authMethod,
        })
        goToApp()
        break
      }
      case 'apiKey':
        await validateApiKey(apiKey)
        goToApp()
        break
      case 'Basic': {
        await createSession({
          companyId,
          userType,
          username,
          password,
          authMethod,
        })
        goToApp()
        break
      }

      default:
        throw new Error(`Unknown Auth Method '${authMethod}'`)
    }
  }

  const authTypeOptions = [
    'Basic',
    'adminBasic',
    'apiKey',
    'OneTimeCodeEmail',
    { value: 'OneTimeCodeText', disabled: true },
    { value: 'Google', disabled: true },
  ]

  return (
    <Modal
      isOpen={showAuth}
      onClose={cancelAuthPrompt}
      title='Login'
      onSubmit={sendLogin}
      initialValue={initialValue}
      submitOnEnter
      render={({ value }) => {
        return (
          <>
            <EnvTable env={env} />

            <Select formKey='authMethod' options={authTypeOptions} />

            <Form.If if={{ authMethod: 'adminBasic' }}>
              <Input formKey='username' />
              <Input formKey='password' />
              <Input formKey='adminCompanyId' />
            </Form.If>

            <Form.If if={{ authMethod: 'Basic' }}>
              <Input formKey='username' />
              <Input formKey='password' />
            </Form.If>

            <Form.If if={{ authMethod: 'OneTimeCodeEmail' }}>
              <Input
                formKey='username'
                note='Code will be sent to email or phone number on file regardless of username entered'
              />
              <Button
                onClick={() =>
                  sendRequestOneTimeCode({ username: value.username })
                }
              >
                Request OTC
              </Button>
              <Input disabled={!oneTimeCodeResp} formKey='oneTimeCode' />
            </Form.If>

            <Form.If if={{ authMethod: 'apiKey' }}>
              <Input formKey='apiKey' placeholder='API Key' />
            </Form.If>
          </>
        )
      }}
    />
  )
}

export default LoginModal
