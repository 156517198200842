import { useMemo } from 'react'

import { searchObjects, getName } from 'peach/helpers'

import getCompanyPages from '../../Header/AppSearch/pages/companyPages'

import appPages from './pages/appPages'

const personPages = []

const loanPages = []

const usePagesSearch = (query, params) => {
  const { companyId, company } = params || {}

  const companyPages = useMemo(() => {
    const companyName = getName(company)
    return getCompanyPages({ companyId, companyName })
  }, [companyId, company])

  const pages = useMemo(() => {
    return [...appPages, ...companyPages, ...personPages, ...loanPages]
  }, [companyPages])

  const filteredPages = searchObjects(pages, query)

  return filteredPages
}

export default usePagesSearch
