import ModelWrapper from '../ModelList/ModelWrapper'

import Data from './Data'

const ModelViewer = ({ title, json, page, note, bare, compact }) => {
  return (
    <ModelWrapper title={title} json={json} page={page} note={note} bare={bare}>
      <Data json={json} compact={compact} />
    </ModelWrapper>
  )
}

export default ModelViewer
