import { Input, Form, Page } from 'peach/components'
import { withFormValue } from 'peach/decorators'

import CodeEditor from './CodeEditor'

const TemplateEditor = ({ value, onChange }) => {
  return (
    <Page.Full>
      <Form.Provider value={value || {}} onChange={onChange}>
        <Input formKey='subjectLineTemplate' />
        <CodeEditor formKey='contentHtmlTemplate' />
        <CodeEditor formKey='contentTextTemplate' />
      </Form.Provider>
    </Page.Full>
  )
}

export default withFormValue(TemplateEditor)
