import { LoadingContainer, ModelViewer, Page } from 'peach/components'
import { useCaseTypeQuery } from 'peach/data'
import { useParams } from 'peach/router'

const CaseType = () => {
  const { caseTypeId } = useParams()
  const [caseTypes, query] = useCaseTypeQuery({ caseTypeId })

  const caseType = _.find(caseTypes, { id: caseTypeId })

  return (
    <LoadingContainer query={query}>
      <Page.Full>
        <ModelViewer json={caseType} />
      </Page.Full>
    </LoadingContainer>
  )
}

export default CaseType
