import {
  LoadingContainer,
  Page,
  Model,
  Panels,
  Bar,
  Modal,
  Input,
  Form,
  Textarea,
  Select,
} from 'peach/components'
import { DateInput, NumericInput } from 'peach/components/index'
import { useLoanAdvancesQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { useParams } from 'peach/router'

import { defaultAdvance } from './defaultAdvance'

const LoanAdvances = () => {
  const api = useApi()
  const { personId, loanId } = useParams()
  const [advances, query] = useLoanAdvancesQuery()

  const header = <Bar title='Advances' />

  const sendCreateLoanAdvances = async (options) => {
    await api.loan.advances.post({
      pathArgs: { personId, loanId },
      body: options,
    })
    query.refetch()
  }

  const createLoanAdvance = (
    <Modal
      title='Create Loan Advance'
      onSubmit={sendCreateLoanAdvances}
      initialValue={defaultAdvance}
    >
      <Input formKey='externalId' />
      <Select
        formKey='status'
        options={['scheduled', 'settled', 'pending', 'canceled']}
        nullable
      />
      <DateInput formKey='advanceDate' />
      <NumericInput formKey='amount' />
      <Form.Provider formKey='advanceDetails'>
        <Input formKey='description' />
        <Input formKey='pointOfSaleType' />
        <Input formKey='categoryId' />
        <Input formKey='merchantId' />
        <Input formKey='merchantName' />
        <Textarea.Json formKey='metadata' />
      </Form.Provider>
    </Modal>
  )

  return (
    <Panels header={header}>
      {createLoanAdvance}
      <LoadingContainer query={query}>
        <Page.Full>
          <Model.Item json={advances} />
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default LoanAdvances
