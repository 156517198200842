import { useAnimatedIsOpen } from 'peach/hooks'
import styled from 'styled-components/macro'

const Content = styled.div`
  transition: 0.2s;

  opacity: 0;
  transform: translateY(-5px) scale(0.96);

  &.is-in {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }

  &.is-out {
    opacity: 0;
    transform: translateY(-5px) scale(0.96);
  }
  height: 100%;
`

const SlidingWindow = ({ children, isOpen }) => {
  const [isMounted, $isOpen] = useAnimatedIsOpen(isOpen, 200)

  return isMounted ? (
    <Content className={$isOpen ? 'is-in' : 'is-out'}>{children}</Content>
  ) : null
}

export default SlidingWindow
