import { useState, useEffect } from 'react'

// this is an increadibly fussy hook
// the goal is to calculate when an animated show/hide type component
// should be mounted, and when it it's show/hide value should be triggered, in order to

// (1) support in and out animtions
// (2) completely unmount the shown component so that
//     nothing lingers in the DOM and all component state is
//     fully reset

// --- the act of opening ---

// closed state
// parent isOpen = false
// isMounted = false
// pased down isOpen = false

// opening state
// parent isOpen = true (inciting incident)
// isMounted = true
// passed down isOpen = false

// opening (one tick later)
// parent isOpen = true
// isMounted = true
// passed down isOpen = true

// --- the act of closing ---

// open state
// parent isOpen = true
// isMounted = true
// passed down isOpen = true

// closing state
// parent isOpen = false
// isMounted = true
// passed down isOpen = false

// closed state (a 200ms timeout later)
// parent isOpen = false
// isMounted = false
// passed down isOpen = false

const useAnimatedIsOpen = (isOpen, wait = 200) => {
  const [isActive, setIsActive] = useState(false)

  // todo: rename isActive to something that makes sense

  useEffect(() => {
    if (isOpen) {
      const id = setTimeout(() => setIsActive(true), 10)
      return () => clearTimeout(id)
    } else {
      const id = setTimeout(() => setIsActive(false), wait)
      return () => clearTimeout(id)
    }
  }, [isOpen, wait])

  const isMounted = isOpen || isActive

  const $isOpen = isOpen && isActive

  return [isMounted, $isOpen]
}

export default useAnimatedIsOpen
