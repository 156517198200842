import styled from 'styled-components/macro'

import Textarea from '../Textarea/Textarea'

const Wrapper = styled.div`
  background-color: ${(p) => p.theme.offset};
  width: 100%;
  position: relative;
  font-size: 14px;
`

const Raw = ({ value, onChange }) => {
  return (
    <Wrapper>
      <Textarea value={value} onChange={onChange} />
    </Wrapper>
  )
}

export default Raw
