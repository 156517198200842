import { LoadingContainer, Modal, Model, Input } from 'peach/components'
import { useCompanyRolesQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { Switch, Route, Redirect, useRouteMatch, useParams } from 'peach/router'

import CompanyRole from './CompanyRole'

const CompanyRoles = () => {
  const { url, path } = useRouteMatch()
  const { companyId } = useParams()
  const [roles, query] = useCompanyRolesQuery()
  const api = useApi()

  const sendAddRole = async (newRole) => {
    return api.company.roles.post({
      pathArgs: { companyId },
      body: newRole,
    })
  }

  const addRole = (
    <Modal
      trigger
      title='Add Role'
      initialValue={{ alias: '' }}
      onSubmit={sendAddRole}
    >
      <Input formKey='alias' />
    </Modal>
  )

  return (
    <LoadingContainer query={query}>
      <Switch>
        <Route path={`${path}`} exact>
          {addRole}
          <Model.List title='Roles' json={roles} page />
        </Route>
        <Route path={`${path}/:roleId`}>
          <CompanyRole />
        </Route>
        <Redirect to={url} />
      </Switch>
    </LoadingContainer>
  )
}

export default CompanyRoles
