import { getName, humanizeKey } from 'peach/helpers'
import { useLink } from 'peach/helpers/getLink'

import DisplayCard from './DisplayCard'

const Card = ({ model, title, subtitle, note, to, ...rest }) => {
  const modelLink = useLink(model)
  const $to = to || modelLink || '#'

  const $title = title || getName(model)

  const $subtitle = subtitle || humanizeKey(model?.object)
  const $note = note || model?.id

  return (
    <DisplayCard
      title={$title}
      to={$to}
      subtitle={$subtitle}
      note={$note}
      {...rest}
    />
  )
}

export default Card
