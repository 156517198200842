import {
  ModelViewer,
  Form,
  Input,
  Modal,
  Page,
  Select,
  CountryCodeSelect,
  StateCodeSelect,
  DateInput,
} from 'peach/components'
import { usePersonQuery } from 'peach/data'
import { useApi } from 'peach/hooks'

const PersonRecord = () => {
  const api = useApi()

  const [person, query] = usePersonQuery()

  const personId = person?.id

  const { borrowerType, status, dateOfBirth, name, businessDetails } =
    person || {}

  const pluckedName = _.pick(name, [
    'prefix',
    'firstName',
    'middleName',
    'lastName',
    'maidenLastName',
    'suffix',
    'preferredFirstName',
  ])

  const initialValue =
    borrowerType === 'business'
      ? { borrowerType, status, businessDetails }
      : { borrowerType, dateOfBirth, status, name: pluckedName }

  const send = async (newBorrower) => {
    await api.people.person.put({
      pathArgs: { personId },
      body: newBorrower,
    })
    query.refetch()
  }

  const editBorrower = (
    <Modal
      action
      trigger
      title='Edit Borrower'
      onSubmit={send}
      initialValue={initialValue}
    >
      <Select formKey='status' options={['active', 'inactive']} />

      <Form.If if={({ borrowerType }) => borrowerType !== 'business'}>
        <DateInput formKey='dateOfBirth' />
        <Form.Provider formKey='name' section>
          <Input formKey='prefix' />
          <Input formKey='firstName' />
          <Input formKey='middleName' />
          <Input formKey='lastName' />
          <Input formKey='maidenLastName' />
          <Input formKey='suffix' />
          <Input formKey='preferredFirstName' />
        </Form.Provider>
      </Form.If>

      <Form.If if={{ borrowerType: 'business' }}>
        <Form.Provider formKey='businessDetails' section>
          <Select
            formKey='businessType'
            options={[
              'LLC',
              'cCorporation',
              'sCorporation',
              'generalPartnership',
              'limitedPartnership',
              'soleProprietor',
              'nonProfit',
              'other',
            ]}
          />
          <Input formKey='businessName' />
          <Input formKey='businessLegalName' />
          <CountryCodeSelect formKey='incorporatedCountry' />
          <StateCodeSelect formKey='incorporatedState' />
        </Form.Provider>
      </Form.If>
    </Modal>
  )

  return (
    <Page.Full>
      {editBorrower}
      <ModelViewer json={person} />
    </Page.Full>
  )
}

export default PersonRecord
