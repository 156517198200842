import {
  Page,
  Model,
  LoadingContainer,
  Panels,
  Bar,
  MiniTable,
} from 'peach/components'
import { useTemplateDescriptorsQuery, useTemplatesQuery } from 'peach/data'
import { humanizeKey } from 'peach/helpers'
import { useParams, useRouteMatch, Link } from 'peach/router'

const TemplateSubject = () => {
  const { url } = useRouteMatch()
  const { subjectKey } = useParams()

  const [descriptorsList, descriptorsQuery] = useTemplateDescriptorsQuery()

  const [templatesList, templatesQuery] = useTemplatesQuery()

  const descriptors = _.filter(descriptorsList, { subject: subjectKey })

  const descriptorIds = _.map(descriptors, 'id')

  const templates = _.filter(templatesList, ({ descriptorId }) => {
    return _.includes(descriptorIds, descriptorId)
  })

  const rows = _.map(descriptors, (descriptor) => {
    const { channel, language, sensitive } = descriptor
    return (
      <MiniTable.Row>
        <Link to={`${url}/${channel}`}>{humanizeKey(channel)}</Link>
        {language}
        {sensitive ? 'True' : 'False'}
      </MiniTable.Row>
    )
  })

  return (
    <LoadingContainer queries={[descriptorsQuery, templatesQuery]}>
      <Panels header={<Bar title={humanizeKey(subjectKey)} />}>
        <Page.Full>
          <MiniTable columns={['Channel', 'Language', 'Sensitive']}>
            {rows}
          </MiniTable>
          <Model.List title='Descriptors' json={descriptors} noLink />
          <Model.List title='Templates' json={templates} noLink />
        </Page.Full>
      </Panels>
    </LoadingContainer>
  )
}

export default TemplateSubject
