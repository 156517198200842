import { FC } from 'react'

import { LoadingContainer } from 'peach/components'
import { StyledButton } from 'peach/components/Button/Button'
import Input from 'peach/components/Input'
import { useApi, useWrite } from 'peach/hooks'
import { FormProvider, useForm } from 'react-hook-form'
import * as R from 'remeda'

import { Error, FormActions, Title } from './Components'
import { Company } from './types'

type CreateTeamFormValues = {
  name: string
}

type CreateTeamFormProps = {
  company: Company
  updateCompany: (obj: Company) => void
  title: string
}

const CreateTeamForm: FC<CreateTeamFormProps> = ({
  company,
  updateCompany,
  title,
}) => {
  const api: any = useApi()

  const form = useForm<CreateTeamFormValues>({
    values: {
      name: 'Peach Tier 2 Support',
    },
  })

  const [onCreate, isPending, , error, clear] = useWrite(async (values) => {
    const resp = await api.company.teams.post({
      body: values,
      pathArgs: { companyId: company?.companyId },
    })

    if (resp?.status === 200 && !R.isEmpty(resp?.data)) {
      updateCompany({
        ...company,
        teams: [
          ...(company?.teams ?? []),
          {
            id: resp?.data?.id,
            name: resp?.data?.name,
            companyId: resp?.data?.companyId,
          },
        ],
      })
    }
  })

  return (
    <LoadingContainer loading={isPending}>
      <Title>{title}</Title>
      <form
        onSubmit={form.handleSubmit(async (values) => {
          try {
            clear()
            await onCreate(values)
          } catch (e) {
            console.log('Error with create actions: ', e)
          }
        })}
      >
        <FormProvider {...form}>
          <Input
            type='text'
            label='Team name'
            required
            {...form.register('name')}
          />

          {error && <Error>{error.message}</Error>}
          <FormActions>
            <StyledButton type='submit' primary>
              Submit
            </StyledButton>
          </FormActions>
        </FormProvider>
      </form>
    </LoadingContainer>
  )
}

export default CreateTeamForm
