const activeBalancesOrder = () => [
  'overdueFee',
  'overdueAccruedInterest',
  'overduePrincipal',
  'dueFee',
  'dueAccruedInterest',
  'duePrincipal',
  'nonDueFee',
  'nonDueAccruedInterest',
  'nonDuePrincipal',
]

const acceleratedBalancesOrder = () => [
  'nonAccrualFee',
  'nonAccrualInterest',
  'nonAccrualPrincipal',
]

const chargedOffBalancesOrder = () => [
  'chargedOffPrincipal',
  'chargedOffFee',
  'chargedOffAccruedInterest',
]

const waterfallMultiLoansOrder = () => [
  'highestInterestRate',
  'lowestInterestRate',
  'oldestLoanDate',
  'newestLoanDate',
  'highestOutstandingBalance',
  'lowestOutstandingBalance',
  'activeFirst',
  'chargedOffFirst',
]

const baseLoanType = {
  name: '',
  assetType: 'personalClosedEndUnsecured',
  managedByPeach: true,
  paymentAllowedWhenChargedOff: true,
  paydayNotices: {
    sendFirstPaymentWithdrawalNotice: false,
    sendConsumerRightsNotice: false,
  },
  automatedDebtValidationNotification: [
    ['IL', 'US', 'debtValidationNoticeAutomatic'],
  ],
  dueDates: {
    periodType: 'periodEnd',
    isShowRevisedTermsOnFrequencyChange: true,
    gapBetweenDueDates: {
      weekly: {
        min: 4,
        max: 10,
      },
      everyTwoWeeks: {
        min: 7,
        max: 21,
      },
      twiceMonthly: {
        min: 7,
        max: 23,
      },
      monthly: {
        min: 10,
        max: 46,
      },
    },
    dayType: 'business',
    dateAdjustment: 'backward',
    allowedFrequencyNewLoan: {
      isDueDatesAvailableWeekly: true,
      isDueDatesAvailableEveryTwoWeeks: true,
      isDueDatesAvailableMonthly: true,
      isDueDatesAvailableTwiceMonthly: true,
    },
    allowedFrequencyChanges: {
      weeklyToWeeklyDay: true,
      weeklyToEveryTwoWeeks: true,
      weeklyToTwiceMonthly: true,
      weeklyToMonthly: true,
      everyTwoWeeksToEveryTwoWeeksDay: true,
      everyTwoWeeksToWeekly: true,
      everyTwoWeeksToTwiceMonthly: true,
      everyTwoWeeksToMonthly: true,
      twiceMonthlyToTwiceMonthlyDay: true,
      twiceMonthlyToWeekly: true,
      twiceMonthlyToEveryTwoWeeks: true,
      twiceMonthlyToMonthly: true,
      monthlyToMonthlyDay: true,
      monthlyToWeekly: true,
      monthlyToEveryTwoWeeks: true,
      monthlyToTwiceMonthly: true,
    },
  },
  autoPay: {
    achDebitTiming: 'initiateOnDueDate',
    achDebitTimingShiftOnNonBusinessDay: 'forward',
    amountChangeNotificationBuffer: 10,
    amountChangePercentageThreshold: 0.1,
    numberOfConsecutiveFailedAttempts: 0,
    paymentsRetry: {
      isRetryAutopayFailedPayments: false,
      maxNumberOfAutoRetriesPerPayment: 0,
      retryDayMethod: 'fixedBuffer',
      retryBufferDayType: 'business',
      retryBufferInDays: 0,
      doNotRetryAfterMaxDays: 0,
      retryDayOfWeek: 0,
      retryDayOfWeekMinBuffer: 0,
    },
    allowedFrequency: {
      isDueDatesAvailableWeekly: false,
      isDueDatesAvailableEveryTwoWeeks: false,
      isDueDatesAvailableMonthly: true,
      isDueDatesAvailableTwiceMonthly: true,
      twiceMonthlyOptions: [
        [1, 15],
        [15, 31],
      ],
      monthlyOptions: [1, 5, 10, 15, 20],
    },
  },
  futurePay: {
    achDebitTiming: 'initiateOnDueDate',
    achDebitTimingShiftOnNonBusinessDay: 'forward',
  },
  fees: {
    originationFeeChargeLogic: 'onTopPeriodicPayment',
    originationFeeAPRCalcOverride: 'atOrigination',
  },
  refunds: {
    isRefundAllowed: true,
  },
  extraFields: {},
  duePayments: {
    amortizationMethod: 'amortizationEqual',
  },
  interest: {
    accrualMethod: 'simple',
    dailyInterestCalculation: 365,
    periodAccrualDurationType: 'actual',
    aprMethod: 'usRule',
    isInterestAmountCap: false,
    accrueInterestAfterTermEnds: false,
    annualInterestRateRange: [
      {
        country: 'US',
        maxRate: 0.1,
        minRate: 0,
        state: 'TX',
      },
    ],
    aprRange: [
      {
        country: 'US',
        maxRate: 0.1,
        minRate: 0,
        state: 'TX',
      },
    ],
  },
  terms: {
    accelerationDueWithin: 30,
    chargeOffDueWithin: 30,
    durationExtensionMaxDays: 15,
    rightToCureBuffer: 30,
  },
  paymentWaterfall: {
    multi: [
      {
        transactionType: 'payment',
        loansOrder: waterfallMultiLoansOrder(),
      },
      {
        transactionType: 'serviceCredit',
        loansOrder: waterfallMultiLoansOrder(),
      },
    ],
    single: [
      {
        transactionType: 'payment',
        loanStatus: 'active',
        balancesOrder: activeBalancesOrder(),
      },
      {
        transactionType: 'payment',
        loanStatus: 'accelerated',
        balancesOrder: acceleratedBalancesOrder(),
      },
      {
        transactionType: 'payment',
        loanStatus: 'chargedOff',
        balancesOrder: chargedOffBalancesOrder(),
      },
      {
        transactionType: 'serviceCredit',
        loanStatus: 'active',
        balancesOrder: activeBalancesOrder(),
      },
      {
        transactionType: 'serviceCredit',
        loanStatus: 'accelerated',
        balancesOrder: acceleratedBalancesOrder(),
      },
      {
        transactionType: 'serviceCredit',
        loanStatus: 'chargedOff',
        balancesOrder: chargedOffBalancesOrder(),
      },
    ],
  },
  creditReporting: {
    isReportingToBureaus: true,
    isReportingToEquifax: true,
    isReportingToExperian: true,
    isReportingToTransunion: true,
  },
}

const defaultInstallmentLoanType = {
  ...baseLoanType,
  description: 'A perfectly peachy installment loan type',
  type: 'installment',
  installmentType: 'standard',
  statements: {
    lateFeeUpToAmount: 100,
    installmentStatementCreationDaysOffsets: {
      everyTwoWeeks: 0,
      monthly: 0,
      twiceMonthly: 0,
      weekly: 0,
    },
    isCreateAndSendPDF: false,
    statementsEnabled: true,
  },
  snapshots: {
    reportDaysWhenChargedOff: 10,
    reportDaysWhenPaidOff: 10,
  },
  useExternalIdInUI: false,
  allowPrepayments: false,
}

const defaultLineOfCreditLoanType = {
  ...baseLoanType,
  type: 'lineOfCredit',
  lineOfCreditType: 'revolving',
  assetType: 'personalClosedEndUnsecured',
  dueDates: {
    gapBetweenDueDates: {
      weekly: {
        min: 4,
        max: 10,
      },
      everyTwoWeeks: {
        min: 7,
        max: 21,
      },
      twiceMonthly: {
        min: 7,
        max: 23,
      },
      monthly: {
        min: 10,
        max: 46,
      },
    },
    periodType: 'periodBegin',
    dayType: 'calendar',
    dateAdjustment: 'backward',
    allowedFrequencyNewLoan: {
      isDueDatesAvailableWeekly: true,
      isDueDatesAvailableEveryTwoWeeks: true,
      isDueDatesAvailableMonthly: true,
      isDueDatesAvailableTwiceMonthly: true,
      isDueDatesAvailableSingleTerm: true,
    },
    allowedFrequencyChanges: {
      weeklyToWeeklyDay: true,
      weeklyToEveryTwoWeeks: true,
      weeklyToTwiceMonthly: true,
      weeklyToMonthly: true,
      everyTwoWeeksToEveryTwoWeeksDay: true,
      everyTwoWeeksToWeekly: true,
      everyTwoWeeksToTwiceMonthly: true,
      everyTwoWeeksToMonthly: true,
      twiceMonthlyToTwiceMonthlyDay: true,
      twiceMonthlyToWeekly: true,
      twiceMonthlyToEveryTwoWeeks: true,
      twiceMonthlyToMonthly: true,
      monthlyToMonthlyDay: true,
      monthlyToWeekly: true,
      monthlyToEveryTwoWeeks: true,
      monthlyToTwiceMonthly: true,
    },
    isShowRevisedTermsOnFrequencyChange: false,
  },
  autoPay: {
    achDebitTiming: 'initiateOnDueDate',
    achDebitTimingShiftOnNonBusinessDay: 'forward',
    amountChangeNotificationBuffer: 14,
    amountChangePercentageThreshold: 0.3,
    numberOfConsecutiveFailedAttempts: 0,
    paymentsRetry: {
      isRetryAutopayFailedPayments: true,
      maxNumberOfAutoRetriesPerPayment: 0,
      retryDayMethod: 'fixedBuffer',
      retryBufferDayType: 'business',
      retryBufferInDays: 0,
      doNotRetryAfterMaxDays: 0,
      retryDayOfWeek: 0,
      retryDayOfWeekMinBuffer: 0,
    },
  },
  futurePay: {
    achDebitTiming: 'initiateOnDueDate',
    achDebitTimingShiftOnNonBusinessDay: 'forward',
  },
  fees: {
    originationFeeChargeLogic: 'onTopPeriodicPayment',
    originationFeeAPRCalcOverride: 'noOverride',
    drawFeeChargeLogic: 'onTopPeriodicPayment',
  },
  refunds: {
    isRefundAllowed: true,
  },
  extraFields: {},
  interest: {
    accrualMethod: 'simple',
    dailyInterestCalculation: 365,
    periodAccrualDurationType: 'fixed',
    aprMethod: 'usRule',
    isInterestAmountCap: false,
    annualInterestRateRange: [],
    aprRange: [],
  },
  duePayments: {
    amortizationMethod: 'amortizationEqual',
  },
  terms: {
    accelerationDueWithin: 120,
    chargeOffDueWithin: 120,
    closeLineAfterInactivePeriods: 0,
  },
  statements: {
    statementsEnabled: true,
    lateFeeUpToAmount: 100,
    isCreateAndSendPDF: true,
    locStatementCreationDaysOffset: 21,
    pauseStatementsAfterInactivePeriods: 0,
  },
}

export { defaultInstallmentLoanType, defaultLineOfCreditLoanType }
