import { useMemo, useState, useCallback, useEffect } from 'react'

import jwt_decode from 'jwt-decode'
import { useWrite, usePeachGlobal, useOnMountEffect } from 'peach/hooks'

import * as rawAuthApi from './authApi'
import useOnActivity from './useOnActivity'

const useAuthManagement = ({ apiBase, storage }) => {
  const [username, setUsername] = useState(() => storage.get('username'))
  useEffect(() => storage.set('username', username), [username, storage])

  const [lastAuthMethod, setLastAuthMethod] = useState(() =>
    storage.get('lastAuthMethod'),
  )
  useEffect(
    () => storage.set('lastAuthMethod', lastAuthMethod),
    [lastAuthMethod, storage],
  )

  const [token, setToken] = useState(() => storage.get('token'))
  const storeToken = useCallback(
    (token) => {
      setToken(token)
      storage.set('token', token)
    },
    [setToken, storage],
  )

  const [apiKey, setApiKey] = useState(() => storage.get('apiKey'))

  const [requiresAuth, setRequiresAuth] = useState(() => !(apiKey || token))

  const [showAuth, setShowAuth] = useState(requiresAuth)

  useEffect(() => {
    if (requiresAuth) setShowAuth(true)
  }, [requiresAuth])

  const storeApiKey = useMemo(() => {
    return (apiKey) => {
      setApiKey(apiKey)
      storage.set('apiKey', apiKey)
    }
  }, [storage])

  const [createSession, isLoggingIn] = useWrite(
    async (options) => {
      try {
        const { username, authMethod } = options
        const resp = await rawAuthApi.createSession(apiBase, options)
        setUsername(username)
        setLastAuthMethod(authMethod)
        storeToken(resp.token)
        setRequiresAuth(false)
        return resp
      } catch (err) {
        throw err
      }
    },
    { throwOnError: true },
  )

  const [requestOneTimeCode] = useWrite((companyId, options) => {
    return rawAuthApi.requestOneTimeCode(apiBase, companyId, options)
  })

  const [renewSession, isRenewing] = useWrite(async () => {
    if (!token) return // we are almost certainly using an API key
    try {
      const resp = await rawAuthApi.renewSession(apiBase, { token })
      storeApiKey('')
      storeToken(resp.token)
      setRequiresAuth(false)
      setShowAuth(false)
      return resp
    } catch (err) {
      throw err
    }
  })

  const [validateApiKey, validating] = useWrite(async (apiKey) => {
    storeToken('')
    await rawAuthApi.validateApiKey(apiBase, apiKey)
    storeApiKey(apiKey)
    setLastAuthMethod('apiKey')
    setRequiresAuth(false)
    setShowAuth(false)
  })

  const logout = useCallback(() => {
    storeToken('')
    storeApiKey('')
    setRequiresAuth(false)
  }, [storeToken, storeApiKey])

  const setRequiresReauth = useCallback(() => setRequiresAuth(true), [])

  const showAuthPrompt = useCallback(() => setShowAuth(true), [])

  const cancelAuthPrompt = useCallback(() => setShowAuth(false), [])

  const authApi = useMemo(() => {
    return {
      createSession,
      renewSession,
      logout,
      validateApiKey,
      setRequiresReauth,
      showAuthPrompt,
      cancelAuthPrompt,
      requestOneTimeCode,
    }
  }, [
    createSession,
    renewSession,
    logout,
    validateApiKey,
    setRequiresReauth,
    showAuthPrompt,
    cancelAuthPrompt,
    requestOneTimeCode,
  ])

  const authState = useMemo(() => {
    const decodedToken = token ? jwt_decode(token) || {} : {}

    const { exp, iat, personId, sub, companyId } = decodedToken

    return {
      apiBase,
      username,
      lastAuthMethod,
      apiKey,
      token,
      showAuth,
      requiresReauth: !!apiBase && requiresAuth,
      isLoggedIn: !!(token || apiKey),
      isLoggingIn: !!(isLoggingIn || validating),
      isRenewing,
      decodedToken,
      expiresAt: exp ? exp * 1000 : undefined,
      issuedAt: iat ? iat * 1000 : undefined,
      personId,
      companyId,
      userId: sub,
    }
  }, [
    apiBase,
    username,
    lastAuthMethod,
    apiKey,
    token,
    isLoggingIn,
    validating,
    isRenewing,
    requiresAuth,
    showAuth,
  ])

  // renew once when the page loads
  useOnMountEffect(renewSession)

  // renew every 5 minutes if the user is active
  useOnActivity(renewSession, { delayInMinutes: 5 })

  usePeachGlobal('authState', authState)

  usePeachGlobal('authApi', authApi)

  return [authState, authApi]
}
export default useAuthManagement
