import { useDataQuery, useQueryApi } from '../useQueryApi'

const useTeamQuery = (options) => {
  const [api, { companyId, teamId }] = useQueryApi(options)

  return useDataQuery({
    queryKey: ['companies', companyId, 'teams', teamId],

    queryFn: () => {
      return api.company.teams.get({
        pathArgs: { companyId, teamId },
      })
    },

    enabled: !!(companyId && teamId),
  })
}

export default useTeamQuery
