import { Page, MiniTable, Card, Json, LoadingContainer } from 'peach/components'
import {
  useSessionCompaniesQuery,
  useSessionPermissionsQuery,
} from 'peach/data'
import { humanizeKey } from 'peach/helpers'

const join = (...args) => _.map(_.compact(args), humanizeKey).join(' / ')

const companyRow = ({ company, disabled }) => {
  const {
    id,
    agentAuthType,
    agentAuthValueType,
    borrowerAuthType,
    borrowerAuthValueType,
    config,
    timezone,
    name,
    brandName,
    legalName,
    type,
  } = company

  const color = config?.brandAssets?.colors?.primary

  const processedByPeach = config?.payment?.processedByPeach

  const details = {
    type,
    processedByPeach,
    timezone,
  }

  const auth = {
    agentAuth: join(agentAuthValueType, agentAuthType),
    borrowerAuth: join(borrowerAuthValueType, borrowerAuthType),
  }

  const names = {
    name,
    brandName,
    legalName,
  }

  return (
    <MiniTable.Row key={id}>
      <Card model={company} color={color} disabled={disabled} />
      <Json.List json={details} humanizeKeys compact limit={3} />
      <Json.List json={auth} humanizeKeys compact limit={3} />
      <Json.List json={names} humanizeKeys compact limit={3} />
    </MiniTable.Row>
  )
}

const Companies = () => {
  const [companies, companiesQuery] = useSessionCompaniesQuery()

  const [permissions, permissionsQuery] = useSessionPermissionsQuery()

  const annotatedCompanies = _.map(companies, (company) => {
    return {
      company,
      disabled: !_.includes(permissions?.[company.id], 'company:read'),
    }
  })

  const sortedCompanies = _.sortBy(annotatedCompanies, 'disabled')

  const rows = _.map(sortedCompanies, ({ company, disabled }) => {
    return companyRow({ company, disabled })
  })

  return (
    <LoadingContainer queries={[companiesQuery, permissionsQuery]}>
      <Page.Full title='Companies'>
        <MiniTable columns={['Company', 'Details', 'Auth', 'Names']} fixed>
          {rows}
        </MiniTable>
      </Page.Full>
    </LoadingContainer>
  )
}

export default Companies
