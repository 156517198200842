import { Warning } from 'core/errors/DefaultFallback'
import { ModelViewer, Input, Select, Modal, Page } from 'peach/components'
import { useLoansQuery, useLoanQuery, useRefreshAllLoanData } from 'peach/data'
import { useApi, useHasPermission } from 'peach/hooks'
import { useLoanId } from 'peach/scopes/loan'
import { usePersonId } from 'peach/scopes/person'

const LoanBasics = () => {
  const personId = usePersonId()
  const loanId = useLoanId()

  // todo:  replace this with a hook that just issues the right invalidation
  const [, loansQuery] = useLoansQuery()

  const refreshLoans = () => loansQuery.refetch()

  const [loan, loanQuery] = useLoanQuery()
  const refreshALlLoanData = useRefreshAllLoanData()

  const api = useApi()
  const {
    status,
    type,
    assetDisplayName = '[No assetDisplayName provided]',
  } = loan || {}

  const sendEditLoan = async (newLoan, diff) => {
    console.log({ newLoan, diff })

    await api.person.loan.put({
      pathArgs: { personId, loanId },
      body: diff,
    })
    loanQuery.refetch()
    loansQuery.refetch()
  }

  const sendActivateLoan = async (activationOptions) => {
    const { status } = loan || {}

    if (status === 'pending') {
      await api.person.loan.put({
        pathArgs: { personId, loanId },
        body: { status: 'originated' },
      })
    }

    await api.loan.activate.post({
      pathArgs: { personId, loanId },
      body: activationOptions,
    })
    refreshALlLoanData()
    refreshLoans()
  }

  const amortizationOptions = [
    'expectedPaymentsAtOrigination',
    'amortizeAtActivation',
  ]

  const initialOptions = {
    amortizationAtActivation: 'amortizeAtActivation',
  }

  const activateInstallmentLoan = (
    <Modal
      title='Activate Installment Loan'
      onSubmit={sendActivateLoan}
      initialValue={initialOptions}
      disabled={status === 'active'}
    >
      <Select
        formKey='amortizationAtActivation'
        options={amortizationOptions}
        nullable
      />
      {loan?.status === 'pending' && (
        <Warning>
          This loan is in a pending status. This action will first set the loan
          to "originated" and then "active"
        </Warning>
      )}
    </Modal>
  )

  const activateLineOfCredit = (
    <Modal
      title='Activate Line of Credit'
      onSubmit={sendActivateLoan}
      initialValue={{}}
      disabled={status === 'active'}
    />
  )

  const hasPermission = useHasPermission('loan:update.force')

  const editLoan = (
    <Modal
      title={`Edit ${assetDisplayName}`}
      onSubmit={sendEditLoan}
      initialValue={loan}
    >
      <Input formKey='nickname' />
      <Input
        formKey='externalId'
        note={`requires 'loan:update.force' permission`}
        disabled={!hasPermission}
        nullable
      />
      {/* <LoanStatusSelect formKey='status' /> */}
    </Modal>
  )

  const sendDeleteLoan = async () => {
    await api.person.loan.delete({
      pathArgs: { personId, loanId },
    })
    loanQuery.refetch()
    refreshLoans()
  }

  const deleteLoan = (
    <Modal
      title={`Delete ${assetDisplayName}`}
      onSubmit={sendDeleteLoan}
      initialValue={loan}
    >
      <Input formKey='id' disabled />
      <Input formKey='nickname' disabled />
      <Input formKey='externalId' disabled />
    </Modal>
  )

  const activationModal =
    type === 'installment'
      ? activateInstallmentLoan
      : type === 'lineOfCredit'
      ? activateLineOfCredit
      : null

  return (
    <Page.Full>
      {deleteLoan}
      {activationModal}
      {editLoan}
      <ModelViewer json={loan} />
    </Page.Full>
  )
}

export default LoanBasics
