const planets = [
  'Mercury',
  'Venus',
  'Earth',
  'Mars',
  'Jupiter',
  'Saturn',
  'Urinus',
  'Neptune',
  'Pluto',
]

const banks = _.map(planets, (planet) => `Bank of ${planet}`)

const blankInstallmentLoan = {
  title: 'Blank Installment Loan',
  description: 'Blank installment loan with no data.',
}

const defaultInstallmentLoan = {
  title: 'Default Installment Loan',
  description:
    'Basic installment loan with random `amountFinanced` and `duration`. No fees with low interest rate.',
  data: {
    type: 'installment',
    loanTypeId: null,
    servicedBy: 'creditor',
    status: 'originated',
    atOrigination: {
      amountFinanced: _.random(2, 200) * 100,
      duration: _.random(1, 6) * 6,
      originationLicense: 'nationalBank',
      originatingCreditorName: _.sample(banks),
      interestRates: [
        {
          days: null,
          rate: 0.145,
        },
      ],
      aprNominal: 0.2,
      aprEffective: 0.2,
      paymentFrequency: 'monthly',
      originationFeeAmount: 0,
      personAddressId: null,
    },
  },
}

const migrationLoanWithEPWithOriginationFee = {
  title: 'Migration Loan With EP With Origination Fee',
  description:
    'Migration loan with defined expected payments with an origination fee.',
  data: {
    type: 'installment',
    loanTypeId: 'LT-N2BQ-MJD8',
    servicedBy: 'creditor',
    status: 'originated',
    newDrawsAllowed: false,
    atOrigination: {
      amountFinanced: 825.0,
      duration: 6,
      expectedPayments: [
        {
          amount: 146.01,
          date: '2023-12-01',
          isForcedAmount: true,
          paymentType: 'periodicPayment',
          principalAmount: 146.01,
          interestAmount: 0,
        },
        {
          amount: 8.99,
          date: '2024-01-01',
          paymentType: 'dynamicFee',
          dynamicFeeDetails: {
            dynamicFeeTypeId: 'FT-7NBP-61KG',
          },
        },
        {
          amount: 146.01,
          date: '2024-01-01',
          isForcedAmount: true,
          paymentType: 'periodicPayment',
          principalAmount: 146.01,
          interestAmount: 0,
        },
        {
          amount: 8.99,
          date: '2024-02-01',
          paymentType: 'dynamicFee',
          dynamicFeeDetails: {
            dynamicFeeTypeId: 'FT-7NBP-61KG',
          },
        },
        {
          amount: 146.01,
          date: '2024-02-01',
          isForcedAmount: true,
          paymentType: 'periodicPayment',
          principalAmount: 146.01,
          interestAmount: 0,
        },
        {
          amount: 8.99,
          date: '2024-03-01',
          paymentType: 'dynamicFee',
          dynamicFeeDetails: {
            dynamicFeeTypeId: 'FT-7NBP-61KG',
          },
        },
        {
          amount: 146.01,
          date: '2024-03-01',
          isForcedAmount: true,
          paymentType: 'periodicPayment',
          principalAmount: 146.01,
          interestAmount: 0,
        },
        {
          amount: 8.99,
          date: '2024-04-01',
          paymentType: 'dynamicFee',
          dynamicFeeDetails: {
            dynamicFeeTypeId: 'FT-7NBP-61KG',
          },
        },
        {
          amount: 146.01,
          date: '2024-04-01',
          isForcedAmount: false,
          paymentType: 'periodicPayment',
          principalAmount: 146.01,
          interestAmount: 0,
        },
        {
          amount: 8.99,
          date: '2024-05-01',
          paymentType: 'dynamicFee',
          dynamicFeeDetails: {
            dynamicFeeTypeId: 'FT-7NBP-61KG',
          },
        },
        {
          amount: 145.86,
          date: '2024-05-01',
          isForcedAmount: false,
          paymentType: 'periodicPayment',
          principalAmount: 145.86,
          interestAmount: 0,
        },
      ],
      interestRates: [
        {
          days: null,
          rate: 0.209,
        },
      ],
      originatingCreditorName: 'Hatch Bank',
      originationLicense: 'bankSubsidiary',
      paymentFrequency: 'monthly',
      periodicPaymentAmount: 146.01,
      specificDays: [26],
      fees: {
        originationFeeAmount: 24.99,
      },
    },
    nickname: 'Peachie Loan (Migration)',
    migration: {
      migrationStatus: 'prepMigration',
      activatedDate: '2023-11-01',
      activatedTimeOfDay: {
        hour: 1,
        minute: 0,
        second: 53,
      },
    },
  },
}

const migrationLoanWithEPAndDynamicFees = {
  title: 'Migration Loan With EP And Dynamic Fees',
  description:
    'Migration loan with defined expected payments that contains a service fee with each periodic payment.',
  data: {
    type: 'installment',
    loanTypeId: 'LT-N2BQ-MJD8',
    servicedBy: 'creditor',
    status: 'originated',
    newDrawsAllowed: false,
    atOrigination: {
      amountFinanced: 825.0,
      duration: 6,
      expectedPayments: [
        {
          amount: 146.01,
          date: '2023-12-01',
          isForcedAmount: true,
          paymentType: 'periodicPayment',
          principalAmount: 146.01,
          interestAmount: 0,
        },
        {
          amount: 8.99,
          date: '2024-01-01',
          paymentType: 'dynamicFee',
          dynamicFeeDetails: {
            dynamicFeeTypeId: 'FT-7NBP-61KG',
          },
        },
        {
          amount: 146.01,
          date: '2024-01-01',
          isForcedAmount: true,
          paymentType: 'periodicPayment',
          principalAmount: 146.01,
          interestAmount: 0,
        },
        {
          amount: 8.99,
          date: '2024-02-01',
          paymentType: 'dynamicFee',
          dynamicFeeDetails: {
            dynamicFeeTypeId: 'FT-7NBP-61KG',
          },
        },
        {
          amount: 146.01,
          date: '2024-02-01',
          isForcedAmount: true,
          paymentType: 'periodicPayment',
          principalAmount: 146.01,
          interestAmount: 0,
        },
        {
          amount: 8.99,
          date: '2024-03-01',
          paymentType: 'dynamicFee',
          dynamicFeeDetails: {
            dynamicFeeTypeId: 'FT-7NBP-61KG',
          },
        },
        {
          amount: 146.01,
          date: '2024-03-01',
          isForcedAmount: true,
          paymentType: 'periodicPayment',
          principalAmount: 146.01,
          interestAmount: 0,
        },
        {
          amount: 8.99,
          date: '2024-04-01',
          paymentType: 'dynamicFee',
          dynamicFeeDetails: {
            dynamicFeeTypeId: 'FT-7NBP-61KG',
          },
        },
        {
          amount: 146.01,
          date: '2024-04-01',
          isForcedAmount: false,
          paymentType: 'periodicPayment',
          principalAmount: 146.01,
          interestAmount: 0,
        },
        {
          amount: 8.99,
          date: '2024-05-01',
          paymentType: 'dynamicFee',
          dynamicFeeDetails: {
            dynamicFeeTypeId: 'FT-7NBP-61KG',
          },
        },
        {
          amount: 145.86,
          date: '2024-05-01',
          isForcedAmount: false,
          paymentType: 'periodicPayment',
          principalAmount: 145.86,
          interestAmount: 0,
        },
      ],
      interestRates: [
        {
          days: null,
          rate: 0.209,
        },
      ],
      originatingCreditorName: 'Hatch Bank',
      originationLicense: 'bankSubsidiary',
      paymentFrequency: 'monthly',
      periodicPaymentAmount: 146.01,
      specificDays: [26],
      fees: {
        dynamicFees: [
          {
            apiName: 'serviceFee',
          },
        ],
      },
    },
    nickname: 'Peachie Loan (Migration)',
    migration: {
      migrationStatus: 'prepMigration',
      activatedDate: '2023-11-01',
      activatedTimeOfDay: {
        hour: 1,
        minute: 0,
        second: 53,
      },
    },
  },
}

export {
  blankInstallmentLoan,
  defaultInstallmentLoan,
  migrationLoanWithEPWithOriginationFee,
  migrationLoanWithEPAndDynamicFees,
}
