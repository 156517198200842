import { useLoanTypeQuery } from 'peach/data'

import ModelLink from './ModelLink'

const LoanTypeLink = ({ loanTypeId, jsonKey }) => {
  const [loanType, query] = useLoanTypeQuery({ loanTypeId })

  return (
    <ModelLink
      id={loanTypeId}
      model={loanType}
      query={query}
      jsonKey={jsonKey}
    />
  )
}

export default LoanTypeLink
