import {
  LoadingContainer,
  Page,
  Modal,
  Model,
  Panels,
  Bar,
  Form,
  NumericInput,
} from 'peach/components'
import { useLoanDrawsQuery } from 'peach/data'
import { useApi } from 'peach/hooks'
import { useParams } from 'peach/router'
import { LoanStatusSelect } from 'peach/scopes/loan'

import { defaultDraw } from './defaultDraw'
// import { humanizeKey } from 'peach/helpers'

const LoanDraws = () => {
  const [draws, query] = useLoanDrawsQuery()
  const { personId, loanId } = useParams()
  const api = useApi()

  const header = <Bar title='Draws' />

  const sendCreateLoanDraws = async (options) => {
    await api.loan.draws.post({
      pathArgs: { personId, loanId },
      body: options,
    })
    query.refetch()
  }

  const createLoanDraw = (
    <Modal
      title='Create Loan Draw'
      onSubmit={sendCreateLoanDraws}
      initialValue={defaultDraw}
    >
      <LoanStatusSelect formKey='status' />
      <Form.Provider formKey='atOrigination'>
        <Form.List formKey='interestRates' add remove>
          <Form.Row>
            <NumericInput formKey='days' />
            <NumericInput formKey='rate' />
          </Form.Row>
        </Form.List>
      </Form.Provider>
    </Modal>
  )

  return (
    <Panels header={header}>
      {createLoanDraw}
      <LoadingContainer query={query}>
        <Page.Full>
          <Model.List json={draws} />
        </Page.Full>
      </LoadingContainer>
    </Panels>
  )
}

export default LoanDraws
