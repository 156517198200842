import { useTeamQuery } from 'peach/data'

import ModelLink from './ModelLink'

const TeamLink = ({ teamId, jsonKey }) => {
  const [team, query] = useTeamQuery({ teamId })

  return <ModelLink id={teamId} model={team} query={query} jsonKey={jsonKey} />
}

export default TeamLink
