import { Switch, Route, Redirect, useRouteMatch } from 'peach/router'

import LoanType from './LoanType'
import LoanTypes from './LoanTypes'

const LoanTypesRoutes = () => {
  const { path, url } = useRouteMatch()

  return (
    <Switch>
      <Route path={path} exact>
        <LoanTypes />
      </Route>

      <Route path={`${path}/:loanTypeId`}>
        <LoanType />
      </Route>

      <Redirect to={url} />
    </Switch>
  )
}

export default LoanTypesRoutes
